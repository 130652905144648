import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function KilisMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 250 140"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Kilis" transform="translate(0, 0)">
            <NavLink to={"/kilis/" + id2}>
              <g
                id="658"
                transform="translate(24.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="musabeyli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="60 0 39 5 39 32 0 53 7 64 28 78 67 74 92 47 75 34 77 18 60 9"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kilis/" + id2}>
              <g
                id="656"
                transform="translate(0.000000, 53.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="24 0 0 26 26 33 71 37 100 58 101 79 185 75 186 51 164 18 149 30 132 35 104 31 91 21 52 25 31 11"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kilis/" + id2}>
              <g
                id="657"
                transform="translate(185.000000, 83.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="elbeyli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="1 21 8 0 63 26 33 48 0 45"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kilis/" + id2}>
              <g
                id="659"
                transform="translate(91.000000, 47.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="polateli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="25 0 73 24 58 36 41 41 13 37 0 27"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default KilisMap;
