import React, { useEffect } from "react";
import styles from "../../css/ads/ad.module.css"

function Ad() {
  useEffect(() => {
    // Google AdSense scriptini dinamik olarak eklemek için
    const script = document.createElement('script');
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1307877394809532";
    script.crossOrigin = "anonymous";
    script.async = true;
    document.head.appendChild(script); // script'leri <head> içine ekleyin

    // Google AdSense reklam kodunu yerleştirmek için
    const adScript = document.createElement('script');
    adScript.innerHTML = `(adsbygoogle = window.adsbygoogle || []).push({});`;
    document.head.appendChild(adScript); // script'leri <head> içine ekleyin
  }, []);

  return (
    <div className={styles.ad}>
      {/* Reklam alanı */}
      <ins className="adsbygoogle"
           style={{ display: 'block' }}
           data-ad-client="ca-pub-1307877394809532"
           data-ad-slot="3873434549"
           data-ad-format="auto"
           data-full-width-responsive="true"></ins>
    </div>
  );
}

export default Ad;
