import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function CanakkaleMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 550 400"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Çanakkale" transform="translate(0, 0)">
            <NavLink to={"/canakkale/" + id2}>
              <g
                id="263"
                transform="translate(189.884892, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gelibolu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="163.942027 4 114.965984 0 100.97283 29 117.964518 51 104.970874 62 91.9772299 58 75.9850527 73 7.01878885 97 0.0222113571 115 14.0153663 156 57.9938535 125 60.9923867 114 79.983097 104 78.9835859 94 157.944961 63 145.950828 47 163.942027 22"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/canakkale/" + id2}>
              <g
                id="261"
                transform="translate(120.107914, 96.133333)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="eceabat"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="76.7957673 0.866666667 22.8221694 29.8666667 26.8202137 43.8666667 36.8153244 54.8666667 0.832925892 114.866667 17.8246141 118.866667 58.804568 93.8666667 54.8065237 73.8666667 83.7923448 59.8666667 69.7991898 18.8666667"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/canakkale/" + id2}>
              <g
                id="265"
                transform="translate(219.625899, 109.866667)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="lapseki"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="132.201998 10.1333333 122.206887 40.1333333 90.2225327 85.1333333 67.233778 95.1333333 20.2567577 88.1333333 9.26213592 68.1333333 0.266536286 51.1333333 13.2601802 36.1333333 58.2381784 0.133333333 104.215688 4.13333333"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/canakkale/" + id2}>
              <g
                id="258"
                transform="translate(308.848921, 98.422222)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="biga"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="42.978976 21.5777778 65.9677307 3.57777778 86.9574632 10.5777778 110.945729 0.577777778 121.940351 21.5777778 112.944751 22.5777778 120.94084 36.5777778 167.91786 61.5777778 157.922749 81.5777778 127.937417 114.577778 83.9589299 135.577778 29.9853321 97.5777778 0.999511071 96.5777778 32.9838653 51.5777778"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/canakkale/" + id2}>
              <g
                id="255"
                transform="translate(116.676259, 160.222222)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="170.183418 44.7777778 123.206398 37.7777778 103.216177 0.777777778 94.2205769 10.7777778 70.2323112 13.7777778 67.233778 37.7777778 56.2391562 44.7777778 44.2450234 66.7777778 23.2552909 72.7777778 10.261647 68.7777778 0.266536286 98.7777778 69.2328002 107.777778 85.2249773 99.7777778 100.217643 84.7777778 119.208354 88.7777778 124.205909 73.7777778 143.196619 83.7777778 163.186841 68.7777778"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/canakkale/" + id2}>
              <g
                id="260"
                transform="translate(279.107914, 194.555556)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="can"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="30.7405183 0.444444444 7.75176364 10.4444444 0.755186142 34.4444444 4.75323043 61.4444444 5.7527415 87.4444444 30.7405183 94.4444444 39.7361179 105.444444 77.7175386 88.4444444 90.7111825 61.4444444 113.699937 39.4444444 59.7263393 1.44444444"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/canakkale/" + id2}>
              <g
                id="257"
                transform="translate(172.726619, 228.888889)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="bayramic"
                  d="M146.117413,71.1111111 L137.121813,60.1111111 L112.134036,53.1111111 C112.134036,53.1111111 111.490588,35.6872196 111.134525,27.1111111 C109.824736,18.020553 107.136481,0.111111111 107.136481,0.111111111 L87.1462597,15.1111111 L68.1555493,5.11111111 L63.157994,20.1111111 L44.1672836,16.1111111 L29.1746176,31.1111111 L13.1824405,39.1111111 L14.1819515,70.1111111 L0.188796536,102.111111 L8.1848851,117.111111 L74.1526158,120.111111 L90.1447929,110.111111 L122.129147,109.111111 L143.11888,95.1111111 L146.117413,71.1111111 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/canakkale/" + id2}>
              <g
                id="262"
                transform="translate(108.669065, 258.644444)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ezine"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="77.2399944 9.35555556 8.27373053 0.355555556 0.277641964 92.3555556 40.2580848 87.3555556 43.256618 82.3555556 56.2502619 90.3555556 72.2424391 87.3555556 64.2463505 72.3555556 78.2395055 40.3555556"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/canakkale/" + id2}>
              <g
                id="266"
                transform="translate(315.712230, 212.866667)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yenice"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="121.074108 0.133333333 77.0956206 21.1333333 54.106866 43.1333333 41.113222 70.1333333 3.13180136 87.1333333 0.133268143 111.133333 53.1073549 129.133333 79.0946427 146.133333 122.073619 127.133333 150.059929 77.1333333 141.064329 30.1333333"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/canakkale/" + id2}>
              <g
                id="256"
                transform="translate(78.928058, 339.900000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ayvacik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="167.951177 9.1 101.983446 6.1 85.9912691 9.1 72.9976252 1.1 69.999092 6.1 30.0186492 11.1 1.03282811 60.1 30.0186492 72.1 82.9927359 68.1 148.960467 54.1"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/canakkale/" + id2}>
              <g
                id="264"
                transform="translate(0.000000, 147.633333)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gokceada"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="79.9608857 11.3666667 73.9638192 27.3666667 88.9564853 29.3666667 78.9613746 39.3666667 18.9907103 44.3666667 0 33.3666667 0 22.3666667 32.9838653 5.36666667 71.9647971 0.366666667"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/canakkale/" + id2}>
              <g
                id="259"
                transform="translate(62.913669, 278.100000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bozcaada"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="25.0433052 0.9 1.05503946 1.9 2.05455053 6.9 23.044283 22.9 32.0398827 19.9"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default CanakkaleMap;
