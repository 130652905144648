import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function IgdirMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 420 150"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Iğdır" transform="translate(0, 0)">
            <NavLink to={"/igdir/" + id2}>
              <g
                id="469"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="tuzluca"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 53 57 8 94 0 147 25 128 118 81 101 66 120 46 119 47 74"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/igdir/" + id2}>
              <g
                id="466"
                transform="translate(128.000000, 19.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="19 6 98 0 140 38 162 47 147 108 0 99"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/igdir/" + id2}>
              <g
                id="468"
                transform="translate(226.000000, 8.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="karakoyunlu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 11 28 0 58 8 42 49"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/igdir/" + id2}>
              <g
                id="467"
                transform="translate(268.000000, 16.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="aralik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="16 0 121 85 139 116 90 76 63 100 7 111 22 50 0 41"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default IgdirMap;
