import React, { useContext } from "react";
import styles from "../../css/cards/editor.module.css";
import { Context } from "../../context/Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

function ProvinceEditor(props) {
  const { infos } = useContext(Context);
  const { selectedPromise } = props;
  console.log(selectedPromise)

  // Function to handle the button click and close the editor
  const handleCloseEditor = () => {
    props.onClose(); // Call the onClose prop passed from ProvincePromises
  };

  return (
    <div className={styles.container}>
      {infos.map((data, index) => {
        return data.promises.map((promise, index) => {
          if (selectedPromise === promise.id.toString()) {
            return (
              <div key={index} className={styles.card}>
                <div className={styles.header}>
                  <p className={styles.header_p}>{promise.promise}</p>
                  <FontAwesomeIcon
                    onClick={handleCloseEditor}
                    style={{
                      display: "inline-block",
                      float: "right",
                      marginRight: "16px",
                    }}
                    icon={faTimes}
                  />
                </div>
                <div className={styles.date_container}>
                  <p className={styles.date_header}>VAAT TARİHİ</p>
                  <h2 className={styles.date}>{promise.promise_date}</h2>
                </div>
                <div className={styles.conclusion_container}>
                  <p className={styles.conclusion_header}>SONUÇ</p>
                  <p className={styles.conclusion}>
                    {promise.editor_conclusion}
                  </p>
                </div>
                <div className={styles.note_container}>
                  <p className={styles.note_header}>NOT</p>
                  <p className={styles.note}>{promise.editor_notes}</p>
                </div>
              </div>
            );
          }
        });
      })}
    </div>
  );
}

export default ProvinceEditor;
