import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function TokatMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 600 320"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Tokat" transform="translate(0, 0)">
            <NavLink to={"/tokat/" + id2}>
              <g
                id="971"
                transform="translate(0.000000, 161.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="zile"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="88 0 13 36 0 66 6 83 36 112 66 117 104 104 133 121 164 103 175 70 157 66 151 25"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/tokat/" + id2}>
              <g
                id="969"
                transform="translate(88.000000, 97.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="turhal"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 64 9 37 39 43 51 37 61 50 88 50 125 26 115 7 119 0 148 3 158 33 140 85 144 101 129 107 104 103 87 118 87 134 69 130 63 89"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/tokat/" + id2}>
              <g
                id="964"
                transform="translate(236.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="erbaa"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 100 21 109 25 102 9 86 7 58 14 43 30 36 30 30 15 26 9 22 11 13 22 10 45 4 63 0 72 6 89 16 125 50 86 86 80 116 10 130"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/tokat/" + id2}>
              <g
                id="965"
                transform="translate(316.000000, 50.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="niksar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="45 0 111 6 128 9 137 20 142 34 113 32 107 41 92 43 80 85 122 103 110 119 55 96 31 72 0 66 6 36"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/tokat/" + id2}>
              <g
                id="967"
                transform="translate(419.000000, 82.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="resadiye"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="39 2 49 20 88 30 109 28 124 26 138 30 126 67 133 94 125 106 97 127 10 106 7 87 19 71 29 57 19 47 0 44 1 34 26 33 30 23 8 17 4 9 10 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/tokat/" + id2}>
              <g
                id="963"
                transform="translate(396.000000, 91.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="basciftlik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="42 62 52 48 42 38 23 35 24 25 49 24 53 14 31 8 27 0 12 2 0 44"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/tokat/" + id2}>
              <g
                id="437"
                transform="translate(331.000000, 146.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="almus"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="185 63 180 89 149 86 119 84 92 67 57 81 24 76 0 81 10 35 19 9 40 0 95 23 98 42 78 36 66 29 54 23 39 11 40 21 41 34 52 30 66 40 80 40 91 46 101 48 102 45 98 42"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/tokat/" + id2}>
              <g
                id="960"
                transform="translate(228.000000, 116.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="4 82 11 109 24 134 15 157 16 192 62 197 80 177 95 116 103 111 122 39 143 30 119 6 88 0 18 14 0 66"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/tokat/" + id2}>
              <g
                id="966"
                transform="translate(175.000000, 198.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="pazar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="64 27 42 39 0 33 0 17 17 2 42 6 57 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/tokat/" + id2}>
              <g
                id="797"
                transform="translate(164.000000, 225.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="artova"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="79 48 56 51 12 49 0 39 11 6 53 12 75 0 88 25"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/tokat/" + id2}>
              <g
                id="970"
                transform="translate(176.000000, 273.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yesilyurt"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="68 35 20 39 19 19 0 1 44 3 67 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/tokat/" + id2}>
              <g
                id="968"
                transform="translate(133.000000, 264.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sulusaray"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="64 48 13 55 0 18 31 0 44 11 62 28"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default TokatMap;
