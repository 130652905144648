import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function GaziantepMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 450 300"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Gaziantep" transform="translate(0, 0)">
            <NavLink to={"/gaziantep/" + id2}>
              <g
                id="415"
                transform="translate(39.000000, 74.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="nurdagi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="77 2.27373675e-13 53 30 21 43 0 62 38 110 80 100 101 95 116 73 109 46 95 27 90 4"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/gaziantep/" + id2}>
              <g
                id="412"
                transform="translate(0.000000, 136.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="islahiye"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="39 0 6 40 0 73 16 96 56 112 80 86 119 65 119 38 77 48"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/gaziantep/" + id2}>
              <g
                id="418"
                transform="translate(148.000000, 59.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sehitkamil"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 61 20 63 112 0 150 50 168 85 162 126 134 115 103 99 84 104 54 95 48 81 29 89 7 88"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/gaziantep/" + id2}>
              <g
                id="419"
                transform="translate(260.000000, 52.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yavuzeli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 7 21 4 51 0 115 22 105 41 38 57"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/gaziantep/" + id2}>
              <g
                id="414"
                transform="translate(298.000000, 93.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="nizip"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 16 18 51 12 92 41 151 70 127 80 112 113 106 103 85 105 83 82 69 75 67 69 59 70 45 63 33 62 24 66 22 67 24 71 15 67 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/gaziantep/" + id2}>
              <g
                id="416"
                transform="translate(249.000000, 174.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="oguzeli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="61 11 33 0 7 29 0 78 55 104 79 91 92 91 90 70"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/gaziantep/" + id2}>
              <g
                id="413"
                transform="translate(339.000000, 199.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="karkamis"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 45 2 66 26 64 87 26 84 19 78 19 75 13 76 4 72 0 39 5 29 21"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/gaziantep/" + id2}>
              <g
                id="417"
                transform="translate(140.000000, 140.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sahinbey"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="109 112 102 133 80 100 32 76 15 63 17 47 0 38 0 29 15 7 37 8 56 0 62 14 92 23 111 18 142 34 116 63"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/gaziantep/" + id2}>
              <g
                id="411"
                transform="translate(281.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="araban"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 56 23 9 51 0 126 24 98 60 94 74 30 52"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default GaziantepMap;
