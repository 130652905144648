import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function SivasMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 1000 680"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Sivas" transform="translate(0, 0)">
            <NavLink to={"/sivas/" + id2}>
              <g
                id="919"
                transform="translate(435.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="koyulhisar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="143 0 101 13 96 46 67 56 54 44 44 46 42 68 22 61 1 39 8 66 0 78 17 112 46 111 97 141 121 103 112 80 116 77 123 82 127 79 121 64 150 24"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sivas/" + id2}>
              <g
                id="920"
                transform="translate(532.000000, 24.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="susehri"
                  d="M53,0 L69,35 L64,71 C64,71 78,74 79,74 C80,74 96,86 96,86 L92,97 L73,92 L60,95 L79,103 L64,114 L68,137 L79,158 L54,158 L40,136 L22,160 L0,117 L24,79 L15,56 L19,53 L26,58 L30,55 L24,40 L53,0 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/sivas/" + id2}>
              <g
                id="909"
                transform="translate(596.000000, 112.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="akincilar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="15 15 24 15 24 22 32 21 35 9 46 14 65 8 65 5 68 0 90 10 76 62 47 80 15 70 4 49 0 26"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sivas/" + id2}>
              <g
                id="914"
                transform="translate(672.000000, 122.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gulova"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="14 0 42 17 75 18 72 45 36 48 23 62 0 52"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sivas/" + id2}>
              <g
                id="917"
                transform="translate(541.000000, 160.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="imranli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="102 32 131 62 117 90 97 127 44 142 18 110 0 38 13 24 31 0 45 22 70 22"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sivas/" + id2}>
              <g
                id="912"
                transform="translate(402.000000, 79.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="dogansar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="33 0 51 34 51 47 0 46 5 20"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sivas/" + id2}>
              <g
                id="924"
                transform="translate(428.000000, 111.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="zara"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="25 2 25 15 0 83 15 144 41 158 32 175 48 185 44 203 0 224 4 248 38 256 157 191 131 159 113 87 126 73 104 30 53 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sivas/" + id2}>
              <g
                id="916"
                transform="translate(279.000000, 103.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="hafik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="174 23 123 22 62 17 35 0 0 14 17 37 67 114 60 150 142 235 149 232 193 211 197 193 181 183 190 166 164 152 149 91"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sivas/" + id2}>
              <g
                id="923"
                transform="translate(29.000000, 112.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yildizeli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="250 5 217 0 193 5 185 10 170 71 152 91 106 86 59 90 8 97 0 114 22 129 42 181 126 221 158 204 206 178 224 114 268 29"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sivas/" + id2}>
              <g
                id="908"
                transform="translate(187.000000, 141.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="110 0 66 85 48 149 0 175 15 202 3 224 28 270 74 250 107 185 149 178 206 212 234 197 152 112 159 76"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sivas/" + id2}>
              <g
                id="922"
                transform="translate(261.000000, 319.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ulas"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="132 34 75 0 33 7 0 72 37 117 56 111 52 83"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sivas/" + id2}>
              <g
                id="910"
                transform="translate(215.000000, 391.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="altinyayla"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 20 7 77 40 106 83 45 46 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sivas/" + id2}>
              <g
                id="921"
                transform="translate(60.000000, 293.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sarkisla"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="162 175 113 199 85 189 0 69 19 37 11 0 95 40 127 23 142 50 130 72 155 118"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sivas/" + id2}>
              <g
                id="913"
                transform="translate(0.000000, 362.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gemerek"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="60 0 46 30 0 63 16 78 23 89 8 105 31 108 52 126 75 130 137 124 145 120"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sivas/" + id2}>
              <g
                id="918"
                transform="translate(255.000000, 335.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="kangal"
                  d="M173,0 C173.000051,2.91265678e-07 177,24 177,24 L211,32 L200,43 L232,54 L245,50 L291,147 L259,148 L243,186 L181,168 L181,187 L121,165 L98,192 L42,186 L0,162 L43,101 L62,95 L59,67 L138,18 L166,3 C166,3 172.999949,-2.91265678e-07 173,0 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/sivas/" + id2}>
              <g
                id="911"
                transform="translate(455.000000, 287.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="divrigi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="183 0 209 74 194 86 198 95 194 97 197 170 181 167 161 185 119 191 91 195 45 98 31 102 0 91 11 80 130 15"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sivas/" + id2}>
              <g
                id="915"
                transform="translate(231.000000, 500.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gurun"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="205 22 224 88 165 153 97 163 15 176 0 125 66 21 122 27 145 -3.55271368e-15"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default SivasMap;
