import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function YozgatMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 700 430"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Yozgat" transform="translate(0, 0)">
            <NavLink to={"/yozgat/" + id2}>
              <g
                id="1042"
                transform="translate(0.000000, 86.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yerkoy"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="38 15 6 23 0 36 19 66 52 87 95 96 127 137 128 145 117 146 120 165 136 173 146 148 167 128 176 100 163 82 104 49 80 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/yozgat/" + id2}>
              <g
                id="1029"
                transform="translate(80.000000, 67.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 19 22 11 56 27 82 18 138 0 146 8 145 17 192 9 193 18 173 32 178 66 170 83 211 170 174 193 124 133 104 131 96 119 83 101 24 69"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/yozgat/" + id2}>
              <g
                id="1039"
                transform="translate(250.000000, 54.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sorgun"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="22 22 36 9 75 0 86 41 117 65 139 61 142 83 124 109 115 108 99 148 85 152 87 175 73 180 57 173 41 183 0 96 8 79 3 45 23 31"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/yozgat/" + id2}>
              <g
                id="1031"
                transform="translate(283.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="aydincik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="43 54 53 28 84 17 78 0 21 0 7 5 2 22 12 33 0 44 3 63"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/yozgat/" + id2}>
              <g
                id="1035"
                transform="translate(325.000000, 17.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cekerek"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="42 0 72 29 102 34 85 93 64 98 42 102 11 78 0 37 11 11"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/yozgat/" + id2}>
              <g
                id="1036"
                transform="translate(410.000000, 38.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kadisehri"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="17 13 55 0 84 17 97 54 14 68 0 72"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/yozgat/" + id2}>
              <g
                id="254"
                transform="translate(349.000000, 92.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="akdagmadeni"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="75 14 72 35 44 71 40 93 23 93 25 71 16 70 0 110 22 113 45 103 92 114 93 124 81 142 151 141 181 153 200 121 192 84 172 33 150 17 158 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/yozgat/" + id2}>
              <g
                id="1037"
                transform="translate(372.000000, 106.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="saraykent"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="52 0 38 4 17 9 20 31 2 57 0 79 17 79 22 57 49 21"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/yozgat/" + id2}>
              <g
                id="1038"
                transform="translate(287.000000, 195.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sarikaya"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="143 39 99 52 84 95 66 99 53 86 23 96 0 82 4 42 20 32 36 39 50 34 48 11 62 7 84 10 107 0 154 11 155 21"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/yozgat/" + id2}>
              <g
                id="1034"
                transform="translate(369.000000, 233.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cayiralan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="161 12 147 42 102 74 43 118 0 66 2 57 17 14 61 1 131 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/yozgat/" + id2}>
              <g
                id="1033"
                transform="translate(369.000000, 299.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="candir"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="43 52 22 55 2 35 0 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/yozgat/" + id2}>
              <g
                id="1032"
                transform="translate(230.000000, 237.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bogazliyan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="141 53 123 57 110 44 80 54 57 40 61 0 24 23 0 47 32 57 40 81 63 110 32 149 32 176 53 173 63 184 104 186 120 169 158 148 158 141 146 129 147 120 161 117 141 97 139 62"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/yozgat/" + id2}>
              <g
                id="1041"
                transform="translate(205.000000, 284.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yenifakili"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="25 0 0 24 25 36 22 66 51 94 57 102 88 63 65 34 57 10"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/yozgat/" + id2}>
              <g
                id="1040"
                transform="translate(136.000000, 186.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sefaatli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 73 24 90 69 122 94 98 118 74 68 14 48 12 40 0 31 28 10 48"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default YozgatMap;
