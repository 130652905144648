import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function DenizliMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 600 540"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Denizli" transform="translate(0, 0)">
            <NavLink to={"/denizli/" + id2}>
              <g
                id="302"
                transform="translate(23.851302, 66.981444)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="buldan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="64.2561204 0.999723042 31.2158369 18.9947378 13.1938641 45.9872599 1.17921551 47.986706 12.1926433 59.9833825 44.2317061 85.9761816 82.2780932 84.9764585 94.2927418 75.9789512 95.2939625 61.9828286"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/denizli/" + id2}>
              <g
                id="307"
                transform="translate(87.951677, 66.981444)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="guney"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0.155745444 0.999723042 79.2521817 3.99889217 87.2619474 37.9894756 80.2534024 68.9808899 57.2253261 74.9792281 31.1935875 61.9828286"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/denizli/" + id2}>
              <g
                id="303"
                transform="translate(166.959116, 83.354686)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cal"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="8.25450854 21.6162338 12.2593914 20.6165107 21.2703778 6.62038814 40.2935713 0.622049893 94.3594898 24.6154029 101.368035 32.6131872 61.3192063 114.590477 31.2825849 112.591031 13.2606121 68.6032167 19.2679364 52.6076481 1.24596355 52.6076481"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/denizli/" + id2}>
              <g
                id="299"
                transform="translate(187.829006, 52.096679)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bekilli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="20.4249025 31.880057 27.4334475 32.87978 24.4297854 26.8814418 4.40537114 30.880334 0.400488285 28.8808879 4.40537114 20.8831035 34.4419925 0.888642704 45.4554203 0.888642704 76.4932624 22.8825496 90.5103524 26.8814418 73.4896003 55.87341"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/denizli/" + id2}>
              <g
                id="306"
                transform="translate(260.873619, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="civril"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0.444986983 107.970088 7.45353197 115.967873 38.4913741 170.95264 62.5206712 150.958179 90.5548511 142.960395 109.578045 124.96538 139.614666 94.973689 134.608562 76.9786742 124.596355 62.9825516 123.595135 36.9897525 105.573162 15.9955687 71.5316576 0 50.5060226 18.9947378 32.4840498 27.9922452 17.4657391 78.9781203"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/denizli/" + id2}>
              <g
                id="298"
                transform="translate(228.078078, 114.612693)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="baklan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="71.2869147 56.3399474 32.2393069 95.329146 20.2246584 94.329423 0.200244143 83.3324695 40.2490726 1.35518012"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/denizli/" + id2}>
              <g
                id="301"
                transform="translate(234.040904, 169.686324)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bozkurt"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="65.3240892 1.26631585 59.3167649 29.258561 31.2825849 110.236127 19.2679364 113.235297 7.25328783 99.2391739 1.24596355 58.2505292 14.2618328 39.2557914 26.2764814 40.2555145"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/denizli/" + id2}>
              <g
                id="305"
                transform="translate(263.855032, 197.967378)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cardak"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="29.502637 0.977506974 56.5355962 22.9714139 40.5160648 26.9703061 44.5209477 34.9680904 59.5392584 35.9678134 71.5539069 42.9658747 78.5624519 40.9664286 81.566114 47.9644899 1.46845705 81.9550733"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/denizli/" + id2}>
              <g
                id="308"
                transform="translate(166.959116, 194.990425)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="honaz"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="81.3436206 13.9516904 61.3192063 2.95473699 31.2825849 0.955290906 4.24962569 16.9508596 1.24596355 50.941443 18.2667157 73.9350729 45.2996749 70.9359038 74.3350756 73.9350729 68.3277513 32.9464282"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/denizli/" + id2}>
              <g
                id="17"
                transform="translate(99.877328, 264.948822)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="acipayam"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="153.431512 17.9727987 141.416863 3.9766761 112.381463 0.977506974 110.379021 35.9678134 67.3265306 56.9619973 55.311882 129.941779 11.2581707 162.93264 1.24596355 176.928762 20.2691571 191.924608 53.3094406 192.924331 70.3301927 173.929593 100.366814 160.933194 124.396111 168.930978 142.418084 143.937902 152.430291 127.942333 174.457147 98.9503651 173.455926 89.9528577 149.426629 73.957289 154.432733 45.9650438 146.422967 22.9714139"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/denizli/" + id2}>
              <g
                id="300"
                transform="translate(47.702605, 373.607609)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="beyagac"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="53.4206874 68.2699757 27.3889488 49.2752379 1.3572103 28.281054 51.4182459 0.288808879 42.4072595 26.281608 63.4328945 54.2738531"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/denizli/" + id2}>
              <g
                id="314"
                transform="translate(32.795541, 239.644721)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="tavas"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="78.3399584 188.236741 57.3143235 160.244496 66.3253099 134.251696 41.2947921 91.2636057 11.2581707 81.2663753 17.265495 60.2721914 1.24596355 36.2788384 28.2789228 25.2818849 40.2935713 0.288808879 66.3253099 10.2860393 95.3607105 21.2829928 135.409539 34.2793923 134.408318 83.2658213 122.39367 155.24588"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/denizli/" + id2}>
              <g
                id="309"
                transform="translate(0.000000, 275.368158)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="kale"
                  d="M49.0598149,126.520505 L38.0463871,121.52189 L28.03418,87.5313063 L5.00610356,62.5382303 L4.00488285,49.5418307 L7.00854499,49.5418307 L8.0097657,57.5396151 L11.0134278,55.540169 L11.0134278,49.5418307 L20.0244143,46.5426616 L20.0244143,42.5437694 L11.0134278,45.5429386 L7.00854499,40.5443234 L10.0122071,33.5462621 L0,20.5498625 L15.0183107,16.5509704 L34.0415042,0.55540169 L50.0610356,24.5487547 L44.0537114,45.5429386 L74.0903327,55.540169 L99.1208505,98.5282598 L49.0598149,126.520505 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/denizli/" + id2}>
              <g
                id="297"
                transform="translate(44.721192, 193.501949)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="babadag"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="28.3679202 46.4315813 1.33496095 12.4409979 29.3691409 1.44404439 51.3959966 1.44404439 54.3996587 18.4393361 62.4094244 19.4390591 54.3996587 56.4288117"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/denizli/" + id2}>
              <g
                id="310"
                transform="translate(98.386622, 178.617183)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkezefendi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="8.74399422 34.3238244 21.7598635 1.33296406 52.7977056 7.33130231 54.800147 20.3277018 29.7696292 82.3105304 0.734228523 71.313577"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/denizli/" + id2}>
              <g
                id="312"
                transform="translate(44.721192, 142.893747)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="saraykoy"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="75.4252937 37.0564007 73.4228523 0.0666482028 61.4082037 9.06415558 23.3618166 10.0638786 22.3605959 19.061386 2.33618166 29.0586164 1.33496095 63.0491998 29.3691409 52.0522464 51.3959966 52.0522464 54.3996587 69.0475381 62.4094244 70.0472611"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/denizli/" + id2}>
              <g
                id="311"
                transform="translate(117.765805, 128.008981)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="pamukkale"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="67.4600267 140.916517 50.4392746 145.915132 10.3904461 132.918732 35.4209639 70.9359038 33.4185224 57.9395043 2.38068036 51.941166 0.378238936 14.9514135 1.37945965 0.955290906 27.4111982 13.9516904 50.4392746 7.9533522 68.4612474 7.9533522 62.4539231 23.9489209 80.4758959 67.9367347 53.4429367 83.9323034 50.4392746 117.922887"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/denizli/" + id2}>
              <g
                id="304"
                transform="translate(119.256512, 425.704287)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cameli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="105.016928 8.17551287 74.9803067 61.1608341 71.9766446 82.155018 59.961996 105.148648 45.944906 110.147263 23.9180504 100.150033 5.89607753 77.1564027 7.89851895 52.1633267 0.889973967 31.1691428 33.9302575 32.1688659 50.9510096 13.1741281 80.987631 0.177728541"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/denizli/" + id2}>
              <g
                id="313"
                transform="translate(166.959116, 264.948822)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="serinhisar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="45.2996749 0.977506974 43.2972335 35.9678134 0.244742841 56.9619973 1.24596355 8.97529131 18.2667157 3.9766761"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default DenizliMap;
