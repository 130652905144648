import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function CorumMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 600 470"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Çorum" transform="translate(0, 0)">
            <NavLink to={"/corum/" + id2}>
              <g
                id="293"
                transform="translate(0.000000, 276.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sungurlu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="66 186 45 158 33 119 34 105 25 100 0 78 38 58 58 28 47 6 54 0 69 7 64 27 80 38 93 18 109 22 99 43 115 54 147 51 176 65 178 76 186 88 181 106 172 128 133 139 130 163 108 171"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/corum/" + id2}>
              <g
                id="284"
                transform="translate(130.000000, 382.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bogazkale"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="51 0 60 1 62 63 34 73 0 57 3 33 42 22"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/corum/" + id2}>
              <g
                id="294"
                transform="translate(64.000000, 240.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ugurludag"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="5 43 50 20 80 0 91 1 101 13 100 42 83 87 51 90 35 79 45 58 29 54 16 74 0 63"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/corum/" + id2}>
              <g
                id="283"
                transform="translate(6.000000, 157.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bayat"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="108 103 93 34 76 37 61 0 0 8 40 58 37 70 35 94 50 96 48 119 63 126"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/corum/" + id2}>
              <g
                id="286"
                transform="translate(67.000000, 109.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="iskilip"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="47 151 77 131 88 132 122 115 79 17 68 9 14 0 21 38 1.77635684e-15 48 15 85 32 82"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/corum/" + id2}>
              <g
                id="290"
                transform="translate(146.000000, 126.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="oguzlar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 0 23 12 68 61 57 83 43 98"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/corum/" + id2}>
              <g
                id="285"
                transform="translate(169.000000, 136.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="dodurga"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 2 28 0 75 19 45 51"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/corum/" + id2}>
              <g
                id="287"
                transform="translate(64.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kargi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="71 118 120 82 92 81 92 74 122 74 139 69 160 43 165 32 117 7 70 0 32 15 7 51 0 79 17 109"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/corum/" + id2}>
              <g
                id="292"
                transform="translate(135.000000, 35.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="osmancik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="99 0 93 13 80 27 71 39 66 44 49 47 0 83 11 91 34 103 62 101 109 120 135 121 138 112 151 100 154 45 118 30"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/corum/" + id2}>
              <g
                id="288"
                transform="translate(203.000000, 155.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="lacin"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="67 1 75 41 50 37 31 54 0 54 11 32 41 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/corum/" + id2}>
              <g
                id="281"
                transform="translate(147.000000, 192.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="131 4 146 17 171 8 184 11 205 19 183 26 169 53 151 66 171 147 146 155 103 140 53 159 29 149 0 135 17 90 18 61 8 49 42 32 56 17 87 17 106 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/corum/" + id2}>
              <g
                id="289"
                transform="translate(298.000000, 211.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="mecitozu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="54 0 61 18 80 16 95 32 112 43 107 55 72 104 20 128 0 47 18 34 32 7"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/corum/" + id2}>
              <g
                id="291"
                transform="translate(318.000000, 315.000000)"
                fill="#f0f0f0"
                stroke="#000000"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ortakoy"
                  stroke="#000000"
                  points="0 24 0 51 15 46 49 46 59 18 52 0"
                  fill="#f0f0f0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/corum/" + id2}>
              <g
                id="282"
                transform="translate(176.000000, 332.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="alaca"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="142 7 142 34 137 51 147 62 135 73 139 92 124 105 77 113 78 104 70 96 16 113 14 52 5 51 10 32 1 19 0 9 24 19 74 0 117 15"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default CorumMap;
