function Reducer(state, action) {
  switch (action.type) {
    case "SET_HOVER":
      return {
        ...state,
        isHovered: action.payload,
      };
    case "GET_ID":
      return {
        ...state,
        id: action.payload,
      };
    case "GET_DATA":
      return {
        ...state,
        infos: action.payload,
      };
    case "GET_DISTRICT":
      return {
        ...state,
        district: action.payload,
      };
    case "GET_ARTİCLE":
      return {
        ...state,
        articles: action.payload,
      };
    case "GET_MOUSEX":
      return {
        ...state,
        mouseX: action.payload,
      };
    case "GET_MOUSEY":
      return {
        ...state,
        mouseY: action.payload,
      };
    case "SET_HOVER2":
      return {
        ...state,
        isHovered2: action.payload,
      };
    case "GET_ID2":
      return {
        ...state,
        id2: action.payload,
      };

    case "GET_MOUSEX2":
      return {
        ...state,
        mouseX2: action.payload,
      };
    case "GET_MOUSEY2":
      return {
        ...state,
        mouseY2: action.payload,
      };
    case "GET_DATA2":
      return {
        ...state,
        district: action.payload,
      };
    case "GET_LATESTUPDATE":
      return {
        ...state,
        latestUpdate: action.payload,
      };
    case "GET_SPOTİFY":
      return {
        ...state,
        spotify: action.payload,
      };
    case "GET_YOUTUBE":
      return {
        ...state,
        youtube: action.payload,
      };
    case "GET_ID3":
      return {
        ...state,
        id3: action.payload,
      };
      case "GET_POPUP":
        return {
          ...state,
          popup: action.payload,
        };
        case "GET_BLOG":
        return {
          ...state,
          blog: action.payload,
        };
        case "GET_SHUFFLE":
          return {
            ...state,
            shuffle: action.payload,
          };
    default:
      return state;
  }
}
export default Reducer;
