import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function BartinMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 250 180"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Bartın" transform="translate(0, 0)">
            <NavLink to={"/bartin/" + id2}>
              <g
                id="175"
                transform="translate(118.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kurucasile"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="56 0 34 5 21 4 0 12 16 37 68 22"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bartin/" + id2}>
              <g
                id="173"
                transform="translate(0.000000, 22.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="186 0 189 7 173 23 105 69 102 84 84 96 75 116 91 138 58 154 47 141 49 121 37 99 39 80 0 63 27 48 29 40 59 22 81 31 109 31 134 15"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bartin/" + id2}>
              <g
                id="174"
                transform="translate(59.000000, 12.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="amasra"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="59 0 52 3 43 9 34 16 21 17 0 32 22 41 50 41 75 25"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bartin/" + id2}>
              <g
                id="176"
                transform="translate(75.000000, 29.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ulus"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="114 0 120 25 103 51 109 61 124 58 129 61 111 66 99 68 72 85 48 117 16 131 0 109 9 89 27 77 30 62 99 15"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default BartinMap;
