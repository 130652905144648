import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function AgriMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 1000 800"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Ağrı" transform="translate(0, 0)">
            <NavLink to={"/agri/" + id2}>
              <g
                id="52"
                transform="translate(101.000000, 233.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="patnos"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="130 5 78 0 0 29 44 141 99 105 142 57 148 15"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/agri/" + id2}>
              <g
                id="54"
                transform="translate(37.000000, 125.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="tutak"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="142 108 129 66 148 27 115 3 111 0 16 5 24 50 0 76 64 137"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/agri/" + id2}>
              <g
                id="51"
                transform="translate(152.000000, 116.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="hamur"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="27 117 79 122 103 107 97 31 75 15 73 0 0 12 33 36 14 74"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/agri/" + id2}>
              <g
                id="53"
                transform="translate(249.000000, 66.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="taslicay"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="6 157 37 133 63 69 95 31 59 1 39 0 0 81"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/agri/" + id2}>
              <g
                id="48"
                transform="translate(286.000000, 97.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="diyadin"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 102 88 154 115 127 127 99 119 49 104 27 104 8 58 0 26 38"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/agri/" + id2}>
              <g
                id="47"
                transform="translate(148.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="140 66 141 21 94 0 18 14 37 34 19 104 0 125 4 128 77 116 79 132 101 147"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/agri/" + id2}>
              <g
                id="50"
                transform="translate(0.000000, 14.000000)"
                fill="#ff8700"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="eleskirt"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="166 0 112 17 66 22 0 67 53 117 148 111 167 90 185 20"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/agri/" + id2}>
              <g
                id="49"
                transform="translate(308.000000, 48.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="dogubayazit"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 19 15 0 62 17 209 26 265 14 262 110 166 141 105 148 97 98 82 76 82 57 36 49"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default AgriMap;
