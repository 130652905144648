import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function KonyaMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 1000 850"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Konya" transform="translate(0, 0)">
            <NavLink to={"/konya/" + id2}>
              <g
                id="701"
                transform="translate(282.000000, 760.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="taskent"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 43 18 13 105 0 116 32 96 46 57 66 30 75"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="689"
                transform="translate(262.000000, 710.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="hadim"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="20 93 8 74 8 67 13 61 0 35 12 31 45 3 75 10 110 0 120 28 160 54 142 79 136 82 125 50 38 63"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="679"
                transform="translate(177.000000, 633.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bozkir"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="93 144 98 138 85 112 97 108 130 80 160 87 195 77 212 49 179 47 151 29 130 30 134 7 114 28 101 27 127 5 76 0 64 32 46 54 0 74 27 102"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="674"
                transform="translate(157.000000, 617.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ahirli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="96 16 75 0 65 7 74 22 73 35 59 39 49 40 36 30 38 41 27 30 0 49 20 90 66 70 84 48"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="703"
                transform="translate(215.000000, 624.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yalihuyuk"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="7 0 0 6 8 14 1 32 15 28 16 15"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="700"
                transform="translate(102.000000, 508.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="seydisehir"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="55 158 22 116 9 115 7 75 0 70 19 51 74 27 88 0 110 4 129 62 130 109 120 116 113 122 109 106 101 106 99 110 82 109 78 103 75 114 67 108 61 114 75 125 76 134 82 139"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="684"
                transform="translate(7.000000, 577.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="derebucak"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="95 1 79 16 47 4 7 0 0 28 10 34 52 58 91 56 104 46 102 6"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="678"
                transform="translate(14.000000, 415.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="beysehir"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="34 86 0 119 3 151 0 162 40 166 72 178 88 163 107 144 162 120 177 93 179 28 161 23 146 4 124 0 114 40 77 60 72 70 79 75 81 66 91 68 92 83 99 83 105 102 90 111 54 122 52 132 37 129 37 96"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="691"
                transform="translate(43.000000, 402.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="huyuk"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 17 41 0 73 19 95 13 85 53 48 73 41 65 41 57 18 36 15 26"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="685"
                transform="translate(67.000000, 316.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="doganhisar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="17 86 27 66 0 42 44 0 57 37 76 41 64 76 49 105"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="692"
                transform="translate(111.000000, 229.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ilgin"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="5 192 27 186 49 190 69 177 106 171 122 146 108 119 121 65 104 21 110 0 84 8 77 22 46 18 37 58 15 65 0 87 13 124 32 128 20 163"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="342"
                transform="translate(0.000000, 248.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="aksehir"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="126 46 67 12 58 14 44 16 34 12 30 0 0 20 45 91 67 110 111 68"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="702"
                transform="translate(67.000000, 195.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="tuzlukcu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 65 1 51 13 42 16 19 39 3 53 0 65 13 79 16 79 44 90 52 81 92 59 99"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="704"
                transform="translate(81.000000, 76.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yunak"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="24 122 0 105 4 91 26 66 32 39 83 19 135 28 151 9 195 0 224 15 250 54 230 99 213 96 209 113 222 126 208 153 150 130 140 153 114 161 107 175 76 171 65 163 65 135 51 132 39 119"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="681"
                transform="translate(106.000000, 33.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="celtik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="7 82 4 69 20 68 25 51 0 39 3 7 38 16 54 0 73 12 95 51 126 52 110 71 59 62"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="680"
                transform="translate(305.000000, 67.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cihanbeyli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 24 69 5 92 16 120 0 141 14 138 36 153 82 206 67 193 112 196 126 216 130 233 145 252 167 221 223 168 221 92 193 45 199 36 172 46 133 17 124 6 108 26 63"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="696"
                transform="translate(374.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kulu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 72 7 58 23 59 44 36 49 13 69 16 78 7 90 0 108 11 174 59 180 98 172 107 146 114 137 134 84 149 69 103 72 81 51 67 23 83"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="698"
                transform="translate(280.000000, 172.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sarayonu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="70 94 61 66 71 28 42 19 31 3 14 0 10 17 23 30 9 57 32 71 5 116 0 201 65 171 68 153"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="677"
                transform="translate(348.000000, 260.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="altinekin"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="2 6 0 65 113 117 141 77 170 65 178 30 126 28 50 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="695"
                transform="translate(331.000000, 325.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="karatay"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="187 0 158 12 130 52 0 136 4 199 19 222 30 207 26 178 60 169 129 204 155 192 192 141 239 106"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="693"
                transform="translate(215.000000, 206.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kadinhani"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="66 166 70 82 97 37 74 23 17 0 5 23 0 44 17 88 4 142 18 169 37 177"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="699"
                transform="translate(239.000000, 325.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="selcuklu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="109 0 106 18 42 47 13 58 0 99 31 134 92 136 222 52"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="683"
                transform="translate(160.000000, 375.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="derbent"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="92 8 79 49 33 68 15 63 0 44 20 31 57 25 73 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="697"
                transform="translate(191.000000, 424.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="meram"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="48 0 2 19 0 84 21 88 40 146 54 140 64 159 80 145 99 146 138 146 124 120 144 100 140 37 79 35"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="682"
                transform="translate(307.000000, 494.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cumra"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="179 23 198 82 140 142 125 142 106 118 84 125 60 145 49 186 21 168 0 169 4 146 13 136 18 144 27 137 26 129 18 127 4 137 3 113 43 109 37 83 22 76 8 50 28 30 43 53 54 38 50 8 84 0 153 35"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="675"
                transform="translate(231.000000, 564.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="akoren"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="80 67 73 74 22 69 1 53 0 6 14 0 24 19 40 5 98 6 113 13 119 39 79 43"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="694"
                transform="translate(486.000000, 431.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="karapinar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="2.27373675e-13 86 19 145 29 163 47 159 67 170 120 116 164 103 200 63 126 0 84 0 37 35"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="686"
                transform="translate(612.000000, 416.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="emirgazi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="2.27373675e-13 15 124 0 145 6 149 15 132 21 120 38 87 43 74 78"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="687"
                transform="translate(650.000000, 431.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="eregli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="36 63 0 103 33 184 82 210 114 169 120 147 143 167 166 167 164 120 181 106 184 73 111 0 94 6 82 23 49 28"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="690"
                transform="translate(727.000000, 578.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="halkapinar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="89 20 115 69 115 95 92 106 45 117 0 142 5 63 37 22 43 0 66 20"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/konya/" + id2}>
              <g
                id="688"
                transform="translate(356.000000, 612.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="guneysinir"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="76 24 57 0 35 7 11 27 0 68 33 70 50 57 51 28"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default KonyaMap;
