import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function IspartaMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 550 400"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Isparta" transform="translate(0, 0)">
            <NavLink to={"/isparta/" + id2}>
              <g
                id="477"
                transform="translate(0.000000, 144.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="keciborlu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 91 28 44 45 20 83 0 99 6 100 54 91 70 82 66 50 76 18 84 12 100 4 100"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/isparta/" + id2}>
              <g
                id="476"
                transform="translate(91.000000, 151.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gonen"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="8 0 43 10 41 44 3 68 0 63 9 47"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/isparta/" + id2}>
              <g
                id="481"
                transform="translate(83.000000, 90.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="uluborlu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 54 22 17 62 0 46 33 64 72 52 71 16 61"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/isparta/" + id2}>
              <g
                id="478"
                transform="translate(129.000000, 63.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="senirkent"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="16 27 60 0 73 19 61 44 68 56 88 62 64 94 45 101 18 99 0 60"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/isparta/" + id2}>
              <g
                id="482"
                transform="translate(189.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yalvac"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 63 14 54 49 31 77 3 109 0 135 28 180 99 144 123 118 125 84 95 41 115 28 116 36 88 46 75 42 69 13 82"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/isparta/" + id2}>
              <g
                id="480"
                transform="translate(296.000000, 99.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sarkikaraagac"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="73 0 122 43 112 63 71 80 64 88 48 86 44 92 49 99 19 112 1 95 5 84 15 65 0 46 11 26 37 24"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/isparta/" + id2}>
              <g
                id="475"
                transform="translate(230.000000, 95.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gelendost"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 20 14 39 13 85 71 89 81 69 66 50 77 30 43 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/isparta/" + id2}>
              <g
                id="474"
                transform="translate(174.000000, 125.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="egirdir"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="127 59 69 55 56 72 54 90 44 91 37 73 24 61 25 44 35 38 36 27 44 19 43 0 19 32 0 39 11 65 7 163 11 188 26 212 51 169 91 144 107 147 107 127 91 106 95 93 140 96 141 86 123 69"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/isparta/" + id2}>
              <g
                id="473"
                transform="translate(132.000000, 161.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="atabey"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 34 2 0 15 1 42 3 53 29 29 38"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/isparta/" + id2}>
              <g
                id="471"
                transform="translate(94.000000, 190.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="38 5 0 29 23 69 47 83 87 98 91 0 67 9"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/isparta/" + id2}>
              <g
                id="479"
                transform="translate(200.000000, 269.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sutculer"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 68 3 75 8 71 11 81 14 83 20 79 18 97 22 106 24 110 35 122 57 112 131 96 141 57 81 3 65 0 25 25"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/isparta/" + id2}>
              <g
                id="472"
                transform="translate(265.000000, 218.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="aksu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="49 3 4 0 0 13 16 35 16 54 76 108 73 76 63 68 68 30"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/isparta/" + id2}>
              <g
                id="483"
                transform="translate(314.000000, 198.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yenisarbademli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="32 0 1 13 0 23 19 50 14 88 24 96 58 63 46 25"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default IspartaMap;
