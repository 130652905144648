import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function AfyonMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 700 500"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Afyonkarahisar" transform="translate(0, 0)">
            <NavLink to={"/afyonkarahisar/" + id2}>
              <g
                id="39"
                transform="translate(163.883375, 9.637119)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ihsaniye"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="121.990715 25.336297 95.0026015 28.3340184 80.0092052 6.35072826 61.0175698 1.35452596 14.0382614 38.326423 1.04398463 76.2975605 60.0180101 103.277053 120.991155 85.2907247 126.988514 70.3021178 120.991155 54.3142704"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/afyonkarahisar/" + id2}>
              <g
                id="28"
                transform="translate(156.997519, 85.357341)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="7.92984071 0.577338933 0.932922437 19.5629077 44.9135516 48.5408811 45.9131113 64.5287284 60.9065076 84.5135377 54.9091491 116.489232 128.876571 136.474042 128.876571 70.5241712 118.880973 64.5287284 127.877011 9.57050308 66.9038662 27.5568314"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/afyonkarahisar/" + id2}>
              <g
                id="43"
                transform="translate(97.779156, 104.631579)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sinanpasa"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="60.1512847 0.288669467 0.177699512 59.2438567 28.1653726 81.2271468 71.146442 86.2233491 92.1371968 129.190689 114.127511 97.2149942 120.12487 65.2392994 105.131474 45.2544902 104.131914 29.2666428"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/afyonkarahisar/" + id2}>
              <g
                id="38"
                transform="translate(38.560794, 163.831025)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="hocalar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="59.3960618 0.0444106872 27.4101497 26.0246627 32.4079485 51.0056742 11.4171936 71.9897239 0.42203634 82.981369 33.4075082 98.9692163 83.3854959 65.9942811 95.3802129 41.0132696 88.3832946 22.0277008"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/afyonkarahisar/" + id2}>
              <g
                id="42"
                transform="translate(71.612903, 185.858726)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sandikli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="55.3311855 0 97.3126951 4.99620231 118.30345 47.9635421 137.295085 97.9255652 109.307412 134.897462 61.328544 115.911893 29.3426319 138.894424 20.3465941 123.905817 18.3474746 97.9255652 0.355399023 76.9415155 50.3333867 43.9665803 62.3281037 18.9855688"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/afyonkarahisar/" + id2}>
              <g
                id="45"
                transform="translate(188.672457, 201.002770)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="suhut"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="23.2342112 0.843803056 1.24389658 32.8194978 20.2355319 82.7815209 53.2210038 118.754177 56.219683 149.730632 114.194149 113.757975 94.2029537 82.7815209 132.186224 37.8157001 97.2016329 20.8286123"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/afyonkarahisar/" + id2}>
              <g
                id="33"
                transform="translate(285.074442, 152.817175)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cobanlar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0.799647803 69.0142078 0.799647803 3.06433741 59.7736733 1.06585649 46.7793965 45.0324368 35.7842392 86.0012957"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/afyonkarahisar/" + id2}>
              <g
                id="40"
                transform="translate(275.434243, 34.418283)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="iscehisar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="70.4134315 119.464748 10.4398463 121.463229 0.444248779 115.467787 9.44028656 60.5095613 15.4376451 45.5209543 9.44028656 29.533107 10.4398463 0.555133589 28.4319219 10.5475382 29.4314816 19.5407024 48.4231169 49.5179162 49.4226767 96.4822179 64.416073 107.473863"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/afyonkarahisar/" + id2}>
              <g
                id="30"
                transform="translate(302.977667, 23.404432)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bayat"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="41.8704475 130.478599 36.8726487 118.487713 21.8792524 107.496068 20.8796926 60.5317666 1.88805731 30.5545528 0.888497559 21.5613886 35.8730889 17.5644268 47.867806 0.577338933 59.862523 2.57581986 66.8594413 17.5644268 61.8616425 64.5287284 67.859001 77.5188544 67.859001 105.497587"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/afyonkarahisar/" + id2}>
              <g
                id="36"
                transform="translate(362.196030, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="emirdag"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0.64416073 25.980252 15.637557 24.9810115 59.6181862 0 87.6058593 1.99848092 99.6005763 34.9734161 170.569319 60.9536681 167.57064 92.9293629 192.559633 103.921008 188.561394 121.907336 171.568879 122.906577 174.567558 135.896703 169.569759 162.876195 159.574162 156.880752 41.6261106 118.909615 8.64063876 128.902019 8.64063876 100.923287 2.64328024 87.9331606 7.641079 40.9688589"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/afyonkarahisar/" + id2}>
              <g
                id="31"
                transform="translate(330.521092, 118.398892)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bolvadin"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="191.249099 38.4818604 73.3010486 0.510722902 40.3155767 10.5031275 14.3270231 35.484139 1.33274634 79.4507193 59.307212 90.4423644 82.2970864 82.4484407 87.2948851 72.4560361 101.288722 80.4499598 102.288281 87.444643"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/afyonkarahisar/" + id2}>
              <g
                id="44"
                transform="translate(396.625310, 155.570637)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sultandagi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="135.140479 7.30555804 125.144881 1.31011527 36.1840631 50.2728979 30.1867046 66.2607452 19.1915473 63.2630239 22.1902265 91.2417568 1.1994717 105.231123 27.1880253 133.209856 57.1748179 113.225047 44.1805411 90.2425163 47.1792204 81.2493522 70.1690947 83.2478331 106.153246 68.2592262 110.151485 79.2508712 133.141359 63.2630239 108.152365 46.275936 112.150604 32.2865696"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/afyonkarahisar/" + id2}>
              <g
                id="32"
                transform="translate(282.320099, 196.872576)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cay"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="115.504683 63.9291842 136.495437 49.9398177 133.496758 21.9610848 107.508205 24.9588062 103.509966 17.964123 107.508205 11.9686802 49.5337389 0.977035118 38.5385816 41.945894 0.555310974 86.9117148 20.546506 117.888169 55.5310974 95.9048789 83.5187705 66.9269055"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/afyonkarahisar/" + id2}>
              <g
                id="41"
                transform="translate(100.533499, 301.504155)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kiziloren"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="80.3868166 19.2520329 32.4079485 0.266464123 0.42203634 23.2489947 5.41983511 40.2360826 40.4044265 44.2330444"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/afyonkarahisar/" + id2}>
              <g
                id="35"
                transform="translate(75.744417, 283.606648)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="dinar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="133.163572 0.177642749 105.175899 37.1495398 65.1935084 62.1305513 30.208917 58.1335895 1.22168414 88.1108033 11.2172817 130.078903 52.1992316 165.052319 69.1917474 141.070548 107.175018 121.085739 129.165333 84.1138415 169.147723 67.1267536 166.149043 36.1502993"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/afyonkarahisar/" + id2}>
              <g
                id="37"
                transform="translate(28.920596, 371.717452)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="evciler"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="48.0455055 0 29.0538702 17.9863283 1.06619707 25.980252 10.0622349 40.9688589 44.0472665 43.9665803 58.041103 41.9680994"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/afyonkarahisar/" + id2}>
              <g
                id="29"
                transform="translate(48.200993, 413.019391)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="basmakci"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="38.760706 0.666160307 24.7668694 2.66464123 14.7712719 37.6380574 24.7668694 34.640336 39.7602657 41.6350192 40.7598255 50.6281834 0.777435364 72.6114735 3.77611462 79.6061567 29.7646682 73.610714 46.757184 76.6084353 51.7549828 82.6038781 79.7426559 35.6395764"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/afyonkarahisar/" + id2}>
              <g
                id="34"
                transform="translate(0.000000, 396.498615)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="dazkiri"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="72.967862 19.1854169 38.9828304 16.1876955 29.9867926 1.19908855 5.99735852 21.1838978 2.50505985e-12 49.1626307 26.9881133 71.1459208 44.9801889 63.1519971 54.9757864 64.1512376 62.9722645 54.158833"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default AfyonMap;
