import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function ElazigMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 550 350"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Elazığ" transform="translate(0, 0)">
            <NavLink to={"/elazig/" + id2}>
              <g
                id="151"
                transform="translate(48.000000, 83.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="agin"
                  d="M21,0 L43,6 L29,34 L19,35 L7,13 L21,0 Z M2,15 L21,48 L30,42 L45,52 L42,57 L19,55 L0,24 L2,15 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/elazig/" + id2}>
              <g
                id="359"
                transform="translate(26.000000, 134.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="keban"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="64 6 67 14 57 19 69 27 72 18 71 10 82 3 96 14 108 0 106 69 52 64 0 36 4 33 45 31 41 4"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/elazig/" + id2}>
              <g
                id="177"
                transform="translate(0.000000, 175.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="baskil"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="35 0 26 3 27 19 8 32 0 61 7 73 35 88 91 103 117 95 140 118 156 97 134 42 150 29 132 28 78 23"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/elazig/" + id2}>
              <g
                id="353"
                transform="translate(132.000000, 128.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="2 6 7 0 144 32 151 56 115 82 125 87 175 67 213 65 226 101 192 81 163 107 126 121 96 115 24 144 2 89 17 76 2.84217094e-13 75"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/elazig/" + id2}>
              <g
                id="363"
                transform="translate(140.000000, 243.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sivrice"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 50 17 62 42 52 62 53 69 68 116 56 116 41 140 24 138 10 112 21 99 12 118 6 88 0 16 29"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/elazig/" + id2}>
              <g
                id="361"
                transform="translate(256.000000, 209.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="maden"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 90 58 105 116 72 102 20 68 0 39 26 41 32 22 44 24 58 0 75"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/elazig/" + id2}>
              <g
                id="355"
                transform="translate(358.000000, 210.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="alacakaya"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="14 71 33 68 35 35 66 37 49 0 18 20 0 19"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/elazig/" + id2}>
              <g
                id="362"
                transform="translate(345.000000, 105.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="palu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 88 9 93 14 105 42 83 49 67 68 48 106 47 110 13 138 0 166 19 151 43 118 86 94 86 62 105 31 125 13 124"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/elazig/" + id2}>
              <g
                id="360"
                transform="translate(283.000000, 98.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kovancilar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="111 74 97 79 84 90 21 82 7 79 0 58 11 45 79 24 84 17 117 36 160 7 202 0 200 7 172 20 168 54 130 55"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/elazig/" + id2}>
              <g
                id="358"
                transform="translate(367.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="karakocan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="118 98 122 19 103 0 62 35 28 29 6 46 22 77 0 115 33 134 76 105"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/elazig/" + id2}>
              <g
                id="356"
                transform="translate(407.000000, 191.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="aricik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="56 0 99 49 85 51 17 56 0 19 32 0"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default ElazigMap;
