import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function AmasyaMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 450 300"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Amasya" transform="translate(0, 0)">
            <NavLink to={"/amasya/" + id2}>
              <g
                id="65"
                transform="translate(97.000000, 185.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="goynucek"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 96 22 96 35 66 110 30 85 0 64 10 38 1 3 50 10 68"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/amasya/" + id2}>
              <g
                id="64"
                transform="translate(108.000000, 70.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="99 145 108 118 138 124 150 118 160 131 187 131 223 107 214 88 173 52 164 3 131 0 98 35 83 61 61 68 37 61 26 69 9 69 0 77 15 93 32 104 27 116 53 125 74 115"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/amasya/" + id2}>
              <g
                id="69"
                transform="translate(145.000000, 59.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="suluova"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="94 11 22 0 8 22 9 38 0 44 0 72 24 79 46 72 61 46"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/amasya/" + id2}>
              <g
                id="68"
                transform="translate(61.000000, 18.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merzifon"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="106 41 94 24 64 20 65 0 10 4 30 45 26 66 4 74 0 105 21 113 28 131 47 129 56 121 73 121 84 113 84 85 93 79 92 63"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/amasya/" + id2}>
              <g
                id="66"
                transform="translate(3.000000, 0.000000)"
                fill="#ff0000"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gumushacikoy"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="68 22 54 23 34 3 16 0 13 55 0 67 26 83 45 120 58 123 62 92 84 84 88 63"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/amasya/" + id2}>
              <g
                id="67"
                transform="translate(0.000000, 67.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="hamamozu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="3 0 0 9 8 49 23 62 48 53 29 16"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/amasya/" + id2}>
              <g
                id="70"
                transform="translate(272.000000, 26.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="tasova"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 47 26 25 31 4 34 0 74 7 68 18 70 26 94 32 86 40 86 54 94 61 98 54 113 58 113 64 97 71 90 86 92 114 108 130 104 137 82 128 54 125 50 132 9 96"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default AmasyaMap;
