import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function KirsehirMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 450 330"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Kırşehir" transform="translate(0, 0)">
            <NavLink to={"/kirsehir/" + id2}>
              <g
                id="648"
                transform="translate(66.000000, 89.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="118 236 139 209 137 114 122 104 127 76 165 20 112 0 105 1 103 35 76 63 0 129 13 144 38 148 42 153 48 147 52 156 63 150 68 154 65 159 79 160 99 168 102 179 87 174 81 167 66 166 75 184 86 187"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kirsehir/" + id2}>
              <g
                id="654"
                transform="translate(205.000000, 167.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="mucur"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="2 132 36 139 49 122 55 105 74 99 68 86 70 78 90 79 87 51 105 38 123 10 122 0 87 2 41 7 15 22 0 37"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kirsehir/" + id2}>
              <g
                id="651"
                transform="translate(190.000000, 110.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="boztepe"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="102 59 108 32 43 0 5 56 0 84 15 94 30 79 56 64"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kirsehir/" + id2}>
              <g
                id="652"
                transform="translate(180.000000, 21.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cicekdagi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="118 121 130 103 106 86 90 78 87 59 98 58 97 49 65 9 22 0 0 69 53 89"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kirsehir/" + id2}>
              <g
                id="649"
                transform="translate(115.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="akcakent"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="87 21 54 0 23 11 0 54 28 66 17 82 58 91 65 90"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kirsehir/" + id2}>
              <g
                id="650"
                transform="translate(79.000000, 54.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="akpinar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="36 0 0 43 19 55 25 69 42 70 65 99 92 71 94 37 53 28 64 12"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kirsehir/" + id2}>
              <g
                id="653"
                transform="translate(0.000000, 97.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kaman"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="79 0 51 3 0 45 3 62 12 68 10 71 15 75 19 72 44 103 52 97 60 104 60 115 68 122 144 56 121 27 104 26 98 12"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default KirsehirMap;
