import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function AdiyamanMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>     
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 550 300"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Adıyaman" transform="translate(0, 0)">
            <NavLink to={"/adiyaman/" + id2}>
              <g
                id="19"
                transform="translate(44.000000, 155.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="besni"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="154 111 121 21 70 0 53 2 0 78 8 88 36 79 111 103 126 108"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/adiyaman/" + id2}>
              <g
                id="18"
                transform="translate(165.000000, 79.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="merkez"
                  d="M33,187 L75,165 L67,157 L57,156 L47,144 L45,132 L34,121 L48,115 L58,131 L74,149 L77,140 L62,108 L69,93 L81,119 L87,110 L93,119 L115,93 C115,93 102,25 101,24 C100,23 93,13 93,13 L19,-1.77635684e-15 L10,9 L0,97 L33,187 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/adiyaman/" + id2}>
              <g
                id="22"
                transform="translate(0.000000, 105.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="golbasi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="44 128 34 113 16 115 0 88 15 50 52 0 94 9 114 50 97 52"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/adiyaman/" + id2}>
              <g
                id="26"
                transform="translate(94.000000, 88.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="tut"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 26 81 0 71 88 20 67"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/adiyaman/" + id2}>
              <g
                id="20"
                transform="translate(168.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="celikhan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="16 79 17 67 0 45 10 34 75 0 102 14 87 49 100 64 90 92"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/adiyaman/" + id2}>
              <g
                id="25"
                transform="translate(255.000000, 14.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sincik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="15 0 91 34 48 66 45 83 11 89 3 78 13 50 0 35"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/adiyaman/" + id2}>
              <g
                id="23"
                transform="translate(266.000000, 48.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kahta"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="80 0 92 12 89 51 112 49 122 79 116 88 131 99 124 103 110 95 114 111 101 113 103 120 87 118 97 138 85 137 76 107 71 117 53 88 51 98 75 140 73 146 40 147 6 148 19 127 14 124 0 55 34 49 37 32"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/adiyaman/" + id2}>
              <g
                id="21"
                transform="translate(355.000000, 1.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gerger"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="3 59 62 32 81 0 120 5 116 12 111 30 91 46 71 59 45 69 46 76 61 69 63 80 46 101 47 121 33 126 23 96 0 98"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/adiyaman/" + id2}>
              <g
                id="24"
                transform="translate(250.000000, 195.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="samsat"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="22 1 20 12 12 14 10 23 1 28 0 48 23 44 33 26 39 24 39 14 50 17 59 13 57 0"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default AdiyamanMap;
