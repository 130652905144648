import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function TunceliMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 450 290"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Tunceli" transform="translate(0, 0)">
            <NavLink to={"/tunceli/" + id2}>
              <g
                id="993"
                transform="translate(0.000000, 140.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cemisgezek"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="44 0 88 55 71 104 59 103 40 81 37 111 27 123 12 113 23 106 16 93 16 79 0 67 0 38"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/tunceli/" + id2}>
              <g
                id="997"
                transform="translate(11.000000, 48.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ovacik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="33 92 77 89 94 73 92 97 169 88 220 40 210 11 153 0 16 55 0 75"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/tunceli/" + id2}>
              <g
                id="998"
                transform="translate(71.000000, 195.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="pertek"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 49 39 64 67 60 120 90 148 69 138 25 132 19 85 3 49 29 17 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/tunceli/" + id2}>
              <g
                id="995"
                transform="translate(203.000000, 173.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="mazgirt"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="86 8 67 0 40 13 7 13 0 41 6 47 15 47 25 89 80 77 102 39"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/tunceli/" + id2}>
              <g
                id="996"
                transform="translate(241.000000, 83.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="nazimiye"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="71 81 83 13 0 0 24 25 18 55 29 90 48 98"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/tunceli/" + id2}>
              <g
                id="992"
                transform="translate(156.000000, 83.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="merkez"
                  d="M85,0 L75,5 L24,53 L0,115 L47,131 L54,103 L87,103 L114,90 L103,55 L109,25 L85,0 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/tunceli/" + id2}>
              <g
                id="999"
                transform="translate(221.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="pulumur"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 59 41 25 48 4 100 17 132 3 189 0 211 18 160 54 168 65 134 95 103 96 20 83 10 88"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/tunceli/" + id2}>
              <g
                id="994"
                transform="translate(44.000000, 121.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="hozat"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="136 15 59 24 61 0 44 16 0 19 44 74 76 103 112 77"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default TunceliMap;
