import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function SamsunMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 600 300"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Samsun" transform="translate(0, 0)">
            <NavLink to={"/samsun/" + id2}>
              <g
                id="887"
                transform="translate(0.000000, 114.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="vezirkopru"
                  d="M0,59 L5,50 L13,48 L16,37 L24,39 L26,31 L23,25 L61,0 L61,6 L70,8 L72,24 L84,35 L91,50 L87,55 L90,58 L102,53 L118,59 L116,67 L122,68 L127,58 L140,56 L145,46 L166,44 L169,54 L165,68 L170,68 L181,46 L178,40 L179,35 L199,20 L218,46 L207,51 L195,94 L195,107 L162,122 L107,126 L93,127 L73,107 L55,104 L19,89 L0,59 Z M144,23 L137,33 L125,44 L110,47 L118,49 L128,47 L141,42 L148,34 L151,32 L144,23 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/samsun/" + id2}>
              <g
                id="879"
                transform="translate(161.000000, 160.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="havza"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="57 0 72 7 94 6 109 12 94 39 99 83 84 83 79 101 62 109 60 120 42 117 30 100 0 96 1 76 34 61 34 48 46 5"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/samsun/" + id2}>
              <g
                id="882"
                transform="translate(221.000000, 243.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ladik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="24 0 39 0 109 0 118 5 113 26 87 48 53 45 0 37 2 26 19 18"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/samsun/" + id2}>
              <g
                id="881"
                transform="translate(255.000000, 148.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kavak"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="84 100 87 96 77 81 79 72 94 65 88 45 77 46 67 33 63 18 43 0 15 24 -1.77635684e-15 51 5 95 75 95"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/samsun/" + id2}>
              <g
                id="873"
                transform="translate(332.000000, 213.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="asarcik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="10 31 50 38 61 42 73 39 77 28 73 19 59 8 52 3 35 6 17 0 2 7 0 16"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/samsun/" + id2}>
              <g
                id="877"
                transform="translate(343.000000, 146.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="canik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="6 67 0 47 4 32 8 17 18 8 36 0 52 13 51 22 40 24 33 29 43 50 35 57 41 70 24 73"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/samsun/" + id2}>
              <g
                id="880"
                transform="translate(322.000000, 132.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ilkadim"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="21 61 10 62 0 49 10 41 17 6 43 0 52 2 57 14 40 22 29 31"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/samsun/" + id2}>
              <g
                id="874"
                transform="translate(285.000000, 105.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="atakum"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="80 27 67 21 49 0 25 5 16 11 0 11 5 35 13 43 33 61 37 76 47 68 54 33"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/samsun/" + id2}>
              <g
                id="883"
                transform="translate(272.000000, 53.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="19Mayis"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="62 52 46 31 44 7 37 5 38 13 34 13 30 4 17 0 3 5 0 20 13 63 29 63 38 57"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/samsun/" + id2}>
              <g
                id="876"
                transform="translate(167.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bafra"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="149 60 153 40 148 35 140 24 136 13 111 0 90 11 53 25 61 38 62 58 0 118 3 146 19 133 33 128 39 126 46 125 46 132 32 134 51 160 66 167 88 166 103 172 131 148 123 140 118 116 105 73 108 58 122 53 135 57 138 47 131 33 131 24 146 40 147 51 142 58"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/samsun/" + id2}>
              <g
                id="374"
                transform="translate(138.000000, 25.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="alacam"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="82 0 42 12 31 27 9 41 4 59 0 77 11 99 6 112 13 121 17 128 32 121 29 93 91 33 90 13"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/samsun/" + id2}>
              <g
                id="888"
                transform="translate(147.000000, 33.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yakakent"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="33 4 18 0 0 33 23 19"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/samsun/" + id2}>
              <g
                id="875"
                transform="translate(376.000000, 217.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ayvacik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="15 4 49 0 75 3 73 17 78 17 83 5 91 23 88 49 83 48 78 46 61 51 39 54 26 59 2 53 0 44 6 34 17 38 29 35 33 24 29 15"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/samsun/" + id2}>
              <g
                id="885"
                transform="translate(376.000000, 152.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="tekkekoy"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="19 7 30 6 40 0 61 29 49 65 15 69 8 64 2 51 10 44 0 23 7 18 18 16"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/samsun/" + id2}>
              <g
                id="878"
                transform="translate(416.000000, 115.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="carsamba"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 37 20 22 26 1 61 0 59 6 66 8 67 2 80 7 73 23 82 60 56 89 47 84 42 91 35 105 9 102 21 66"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/samsun/" + id2}>
              <g
                id="886"
                transform="translate(489.000000, 122.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="terme"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="7 0 25 8 39 18 54 23 58 47 67 56 94 61 82 73 52 81 47 100 31 84 20 77 17 61 9 53 0 16"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/samsun/" + id2}>
              <g
                id="884"
                transform="translate(459.000000, 175.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="salipazari"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="39 0 12 30 3 36 0 47 8 66 5 91 14 92 55 77 77 47 61 31 50 24 47 8"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default SamsunMap;
