import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function ArdahanMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 450 320"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Ardahan" transform="translate(0, 0)">
            <NavLink to={"/ardahan/" + id2}>
              <g
                id="123"
                transform="translate(37.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="posof"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 56 15 11 76 0 73 31 95 30 137 66 72 56 45 70 18 83"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ardahan/" + id2}>
              <g
                id="120"
                transform="translate(82.000000, 56.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="damal"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 14 28 45 90 37 92 10 27 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ardahan/" + id2}>
              <g
                id="122"
                transform="translate(55.000000, 70.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="hanak"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 13 5 49 52 66 99 69 124 63 117 23 56 31 27 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ardahan/" + id2}>
              <g
                id="119"
                transform="translate(154.000000, 66.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cildir"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="20 0 65 19 52 34 74 58 106 47 139 70 137 93 104 83 88 124 55 146 45 147 0 73 25 67 18 27"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ardahan/" + id2}>
              <g
                id="118"
                transform="translate(10.000000, 119.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="144 20 98 17 50 0 8 68 0 89 70 73 151 110 189 94"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ardahan/" + id2}>
              <g
                id="121"
                transform="translate(0.000000, 192.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gole"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="161 37 149 109 80 119 29 70 0 44 10 16 80 0"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default ArdahanMap;
