import React, { useContext, useEffect, useState } from "react";
import styles from "../../css/cards/province_card.module.css";
import { Context } from "../../context/Context";
import { useLocation } from "react-router-dom";

function DistrictCard() {
  const { district, id2 } = useContext(Context);
  const [progress, setProgress] = useState(0);
  const location = useLocation();
  const { pathname } = location;
  const words = pathname.split("/").filter((word) => word.trim() !== "");

  let url = "https://api.ibrahimaluc.com";

  
  

  
  const selectedDistrictInfo = district.find(
    (info) => info.url_name === id2 && info.url_province_name === words[0]
  );

  useEffect(() => {
    if (selectedDistrictInfo) {
      const animationDuration = 1000;
      const step = 5;
      let currentPercentage = 0;

      const interval = setInterval(() => {
        currentPercentage += step;
        if (currentPercentage >= selectedDistrictInfo.mayor.election_rate) {
          currentPercentage = selectedDistrictInfo.mayor.election_rate;
          clearInterval(interval);
        }
        setProgress(currentPercentage);
      }, animationDuration / (selectedDistrictInfo.mayor.election_rate / step));

      return () => clearInterval(interval);
    }
  }, [selectedDistrictInfo]);

  return (
    <>
      {selectedDistrictInfo && (
        <div className={styles.card}>
          <img
            className={styles.mayor_photo}
            src={url + selectedDistrictInfo.mayor.mayor_photo}
            alt="Mayor "
          />
          <div className={styles.card_content}>
            <h3 className={styles.header}>Aldığı Oy Oranı</h3>
            <div className={styles.progress_bar}>
              <div
                className={styles.progress_bar_inner}
                style={{ width: `${progress}%` }}
              >
                {progress}%
              </div>
            </div>
            <h3 className={styles.city_type}>
              {selectedDistrictInfo.mayor.city_type_mayor}
            </h3>
            <h2 className={styles.mayor_name}>
              {selectedDistrictInfo.mayor.mayor_name}
            </h2>
            <h3 className={styles.mayor_party}>
              {selectedDistrictInfo.mayor.mayor_party}
            </h3>
            <div className={styles.lover}>
              <img
                src={url + selectedDistrictInfo.mayor.party_logo}
                alt="Party Logo"
                className={styles.party_logo}
              />
              <div className={styles.election_year}>
                <span className={styles.year_info}>Seçilme Yılı</span>
                <p className={styles.year}>
                  {selectedDistrictInfo.mayor.election_year}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DistrictCard;
