import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function TekirdagMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 440 320"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Tekirdağ" transform="translate(0, 0)">
            <NavLink to={"/tekirdag/" + id2}>
              <g
                id="951"
                transform="translate(45.243619, 52.484150)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="hayrabolu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="151.358726 45.320923 134.393041 36.3388245 109.443505 14.3825836 94.4737835 12.3865617 76.5101174 13.3845727 50.5625997 0.410430365 30.6029707 5.4004851 16.6312304 3.40446321 27.6090263 33.3447916 21.6211376 54.3030215 0.663527203 75.2612514 42.5787481 102.207547 43.5767295 123.165777 58.5464513 116.1797 69.5242472 117.177711 82.4980061 96.2194813 124.413227 71.2692076 106.449561 52.3069996"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/tekirdag/" + id2}>
              <g
                id="953"
                transform="translate(0.000000, 127.590778)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="malkara"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="114.767867 42.071083 108.779978 65.0253348 113.769885 107.939806 67.8627386 126.902014 18.9616475 122.90997 3.9919258 89.9756085 11.9757774 29.0969407 0 21.1128531 7.9838516 14.1267765 45.9071467 0.15462319 87.8223676 27.1009188 88.820349 48.0591487 103.790071 41.073072"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/tekirdag/" + id2}>
              <g
                id="957"
                transform="translate(108.584687, 123.066282)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="suleymanpasa"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="145.900583 58.57171 137.916731 39.609502 115.961139 32.6234254 102.987381 13.6612174 86.0216959 16.6552502 63.0681226 2.68309697 61.0721597 0.687075077 19.1569388 25.6373488 6.18317996 46.5955787 0.195291258 69.5498305 5.18519851 112.464301 24.1468461 114.460323 43.1084936 109.470268 65.0640855 117.454356 80.0338072 105.478225 99.9934362 65.5577867"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/tekirdag/" + id2}>
              <g
                id="958"
                transform="translate(49.767981, 231.654179)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sarkoy"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="123.880791 8.86645969 105.917125 26.8306567 91.9453844 47.7888866 50.0301635 71.7411494 29.0725531 73.7371713 12.1068685 81.7212589 0.131091054 65.7530837 18.0947572 40.80281 18.0947572 22.838613 64.0019038 3.87640495 82.9635514 5.87242684 101.925199 0.882372107"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/tekirdag/" + id2}>
              <g
                id="955"
                transform="translate(151.113689, 88.680115)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="muratli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="78.4220444 0.142859059 45.4886565 9.12495759 0.579491281 16.1110342 20.5391203 37.0692641 43.4926936 51.0414174 60.4583783 48.0473845 73.4321371 67.0095925 95.3830744 73.9956692 98.3816734 30.0831875"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/tekirdag/" + id2}>
              <g
                id="950"
                transform="translate(228.932715, 46.149856)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ergene"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="19.5646664 0.756658628 0.603018847 42.6731184 20.5626478 72.6134469 57.4879615 76.6054906 72.4576832 68.6214031 83.4354792 70.617425 105.391071 61.6353264 103.395108 53.6512388 92.4173122 56.6452717 78.4428451 45.6671513 62.473551 27.7029542 33.5364067 29.6989761 22.5586107 13.7308009"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/tekirdag/" + id2}>
              <g
                id="956"
                transform="translate(247.935035, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="saray"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="135.289842 0 141.277731 7.98408758 121.318102 22.9542518 131.297916 34.9303832 129.301953 44.9104926 93.374621 51.8965693 74.4129735 72.8547992 66.4291219 61.8766787 48.4654558 57.884635 43.4755486 73.8528101 14.5340865 75.848832 3.55629056 59.8806569 0.562346208 45.9085036 3.55629056 38.922427 96.3685654 4.99005474"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/tekirdag/" + id2}>
              <g
                id="952"
                transform="translate(291.368910, 44.340058)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kapakli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="85.8680785 0.570435003 75.888264 23.5246868 47.9447834 28.5147415 31.9770802 47.4769495 16.009377 47.4769495 0.0416738417 29.5127525 5.03158109 13.5445773 23.9932286 17.5366211 30.9790988 28.5147415 49.9407463 7.55651164"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/tekirdag/" + id2}>
              <g
                id="948"
                transform="translate(306.751740, 66.962536)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cerkezkoy"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="60.5054334 0.902208411 40.5458044 36.8306025 29.5680085 31.8405478 14.5982867 35.8325916 0.626546413 24.8544712 16.5942496 24.8544712 32.5619528 5.89226315"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/tekirdag/" + id2}>
              <g
                id="949"
                transform="translate(246.125290, 98.634006)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="corlu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="101.172255 5.15913278 80.2146441 53.0636583 58.2590522 57.0557021 36.3034603 72.0258663 34.3074974 85.0000086 8.35997969 83.0039867 0.376128088 64.0417787 3.37007244 20.129297 40.2953861 24.1213408 55.2651078 16.1372532 66.2429038 18.1332751 88.1984957 9.15117657 86.2025328 1.16708899 90.1944586 0.169078045"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/tekirdag/" + id2}>
              <g
                id="954"
                transform="translate(279.605568, 151.118156)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="marmaraereglisi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="46.7343657 0.579508409 55.7161987 23.5337602 43.7404213 40.4999463 35.7565697 47.4860229 17.7929036 46.488012 0.827218961 32.5158587 2.82318186 19.5417164 24.7787738 4.5715522"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default TekirdagMap;
