import React, { useContext, useState } from "react";
import styles from "../../css/cards/explanation.module.css";
import { Context } from "../../context/Context";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import holder from "../../assets/image_holder.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import facebook from "../../assets/facebookb.svg";
import share from "../../assets/Linkb.svg";
import twitter from "../../assets/xb.svg";
import whatsapp from "../../assets/whatsappb.svg";
import telegram from "../../assets/telegramb.svg";

function ProvinceHallExplanation(props) {
  const { infos } = useContext(Context);
  const { selectedAnnouncement } = props;

  let url = "https://api.ibrahimaluc.com";
  const handleCloseEditor = () => {
    props.onClose();
  };

  const [activeImage, setActiveImage] = useState("");

  const handleActiveSlide = (promise, splide) => {
    const activeSlideIndex = splide.index;
    setActiveImage(url + promise.statement_images[activeSlideIndex]);
  };

  const handleCopyToClipboard = () => {
    toast.success("Kopyalandı!", {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 2000,
    });
  };

  return (
    <div className={styles.container}>
      {infos.map((data, index) => {
        return data.promises.map((promise, promiseIndex) => {
          if (selectedAnnouncement === promise.id.toString()) {
            return (
              <div key={promiseIndex} className={styles.card}>
                <div className={styles.header}>
                  <p className={styles.header_p}>Başkanın Açıklaması</p>
                  <FontAwesomeIcon
                    onClick={handleCloseEditor}
                    style={{
                      display: "inline-block",
                      float: "right",
                      marginRight: "16px",
                    }}
                    icon={faTimes}
                  />
                </div>

                <div className={styles.mayor_picture_container}>
                  <img
                    className={styles.mayor_photo}
                    src={url + data.mayor.mayor_photo}
                    alt="Mayor"
                  />
                </div>

                <div className={styles.date_container}>
                  <p className={styles.date_header}>Açıklama Tarihi</p>
                  <h2 className={styles.date}>{promise.statement_date}</h2>
                </div>

                {promise.statement_images.length > 0 ? (
                  <div className={styles.splide}>
                    {activeImage && (
                      <img
                        src={activeImage}
                        alt=""
                        className={styles.slider_active_image}
                      />
                    )}
                    <Splide
                      className={styles.splide_container}
                      options={{
                        type: "loop",
                        perPage: 3,
                        autoplay: true,
                        interval: 10000,
                        speed: 2000,
                        pagination: false,
                        focus: "center",
                      }}
                      onActive={(splide) => handleActiveSlide(promise, splide)}
                    >
                      {promise.statement_images.map((image, index) => (
                        <SplideSlide
                          className={styles.splide_slide}
                          key={index}
                        >
                          <img
                            className={styles.slider_pics}
                            src={url + image}
                            alt=""
                          />
                        </SplideSlide>
                      ))}
                    </Splide>
                  </div>
                ) : (
                  <div className={styles.splide}>
                    <img
                      src={holder}
                      alt=""
                      className={styles.slider_active_image_holder}
                    />

                    <Splide
                      className={styles.splide_container}
                      options={{
                        type: "loop",
                        perPage: 3,
                        autoplay: true,
                        interval: 10000,
                        speed: 2000,
                        pagination: false,
                        focus: "center",
                      }}
                      onActive={(splide) => handleActiveSlide(promise, splide)}
                    >
                      <SplideSlide className={styles.splide_slide} key={index}>
                        <img
                          className={styles.slider_pics_holder}
                          src={holder}
                          alt=""
                        />
                      </SplideSlide>
                    </Splide>
                  </div>
                )}

                <div className={styles.explanation_container}>
                  <p className={styles.explanation_header}>Açıklama</p>
                  <div className={styles.update}>
                    <p>Güncelleme Tarihi</p>
                    <b>{promise.updated_at}</b>
                  </div>

                  <p className={styles.explanation}>{promise.statement}</p>
                </div>

                <div className={styles.footer_container}>
                  <p>Sosyal Medyada Paylaş</p>
                  <div className={styles.footer_icons}>
                    <CopyToClipboard text="deneme copy">
                      <img onClick={handleCopyToClipboard} className={styles.icon} src={share} alt="" />
                    </CopyToClipboard>
                    <FacebookShareButton
                      url={"https://www.example.com"}
                      quote={"Dummy text!"}
                    >
                      <img className={styles.icon} src={facebook} alt="" />
                    </FacebookShareButton>
                    <TwitterShareButton url="deneme">
                      <img className={styles.icon} src={twitter} alt="" />
                    </TwitterShareButton>

                    <WhatsappShareButton>
                      <img className={styles.icon} src={whatsapp} alt="" />
                    </WhatsappShareButton>
                    <TelegramShareButton url="deneme">
                      <img className={styles.icon} src={telegram} alt="" />
                    </TelegramShareButton>
                  </div>
                </div>
              </div>
            );
          } else {
            return null;
          }
        });
      })}
    </div>
  );
}

export default ProvinceHallExplanation;
