import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function HakkariMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 520 380"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Hakkari" transform="translate(0, 0)">
            <NavLink to={"/hakkari/" + id2}>
              <g
                id="445"
                transform="translate(10.000000, 2.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="merkez"
                  d="M31,59 L119,38 L181,0 L193,43 L155,64 L163,167 L49,175 L0,142 L31,59 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/hakkari/" + id2}>
              <g
                id="448"
                transform="translate(165.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yuksekova"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="38 44 122 0 163 7 185 27 185 107 118 133 79 177 38 176 25 190 7 168 0 66"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/hakkari/" + id2}>
              <g
                id="447"
                transform="translate(244.000000, 107.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="semdinli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="106 0 168 47 172 104 121 94 40 169 14 127 31 87 0 70 39 26"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/hakkari/" + id2}>
              <g
                id="446"
                transform="translate(0.000000, 143.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cukurca"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="190 47 163 76 70 72 20 51 0 24 10 0 58 33 173 26"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default HakkariMap;
