import React from "react";
import Navbar from "../components/Navbar";
import MailSubscribe from "../components/blogPage/MailSubscribe";
import LastUpdated from "../components/blogPage/LastUpdated";
import AllBlogs from "../components/blogPage/AllBlogs";
import Advised from "../components/blogPage/Advised";
import Footer from "../components/Footer";
import styles from "../css/blogPage/main.module.css"

function BlogPage() {
  window.scrollTo(0, 0);
  return (
    <>
      <Navbar />
      <MailSubscribe />
      <LastUpdated />
      <div className={styles.container}>
        <AllBlogs />
        <Advised />
      </div>
      <Footer/>
    </>
  );
}

export default BlogPage;
