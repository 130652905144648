import React, { useContext, useEffect } from "react";
import { Context } from "../../context/Context";
import styles from "../../css/blogPage/allBlogs.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import slugify from "slugify";

function AllBlogs() {
  const { blog, dispatch } = useContext(Context);

  const myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    "Token 8f6c14510d09a853e6145cc52567a9bebe86c172"
  );

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  useEffect(() => {
    fetch("https://api.ibrahimaluc.com/api/blog/", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: "GET_BLOG",
          payload: data.articles,
        });
      })
      .catch((error) => {
        console.error("Hata:", error);
      });
  }, []);
  let url = "https://api.ibrahimaluc.com";
  return (
    <div className={styles.container}>
      <p className={styles.header}>Tüm Yazılar</p>
      <div className={styles.card_container}>
        {blog.map((article, index) => {
          const encodedTitle = slugify(article.title, {
            remove: /[*+~.()'"!:@]/g, // Remove special characters
            lower: true, // Convert to lowercase
          });
          const articleUrl = `/blog/${encodedTitle}`;
          return (
            <Link
              to={{ pathname: articleUrl, state: { article } }}
              key={index}
              className={styles.card}
            >
              <img
                className={styles.article_picture}
                src={url + article.image}
                alt=""
              />
              <div className={styles.body}>
                <p className={styles.article_title}>{article.title}</p>
                <div
                  className={styles.article}
                  dangerouslySetInnerHTML={{
                    __html: article.html,
                  }}
                />
                <div className={styles.lover_part}>
                  <div className={styles.author}>
                    <img
                      className={styles.author_picture}
                      src={url + article.author.photo}
                      alt=""
                    />
                    <p className={styles.author_name}>
                       {article.author.name}
                    </p>
                  </div>
                  <div>
                    <span className={styles.time}>{article.updated_at} |</span>
                    <FontAwesomeIcon className={styles.icon} icon={faClock} />
                    <span className={styles.read_time}>
                      {article.avg_read_minute} dk
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default AllBlogs;
