import React, { useContext } from "react";
import styles from "../../css/cards/cityhall_detail.module.css";
import { Context } from "../../context/Context";
import { useLocation } from "react-router-dom";
import Pin from "../../assets/pin.svg";

function DistrictHallDetail() {
  const { district } = useContext(Context);

  let url = "https://api.ibrahimaluc.com";
  const location = useLocation();
  const { pathname } = location;
  const words = pathname.split("/").filter((word) => word.trim() !== "");

  // Find the matching data
  const matchedData = district.find((data) => data.url_name === words[1]);

  if (!matchedData) {
    // If there is no matching data, display the "Veri Yok" message
    return (
      <div className={styles.card}>
        <img
          className={styles.mayor_photo}
          src=""
          alt="Mayor"
        />
        <div className={styles.province_area}>
          <div className={styles.location}>
            <img className={styles.icon} src={Pin} alt="" />
          </div>
          <div>
          <div className={styles.province_name}>{words[0]}</div>
            <div className={styles.district_name}>{words[1]}</div>
           
          </div>
        </div>

        <div className={styles.card_content}>
          <h2 className={styles.mayor_name}>....</h2>
          <h3 className={styles.mayor_party}>
            ....
          </h3>
          <div className={styles.lover}>
            <img
              src=""
              alt="Party Logo"
              className={styles.party_logo}
            />
            <div className={styles.election_year}>
              <span className={styles.year_info}>Seçilme Yılı</span>
              <p className={styles.year}>....</p>
            </div>
          </div>
        </div>
      
      </div>
    );
  }
  const formattedBio = matchedData.mayor.mayor_bio.replace(
    /[\r\n]+/g,
    "<br />"
  );

  return (
    <div className={styles.card}>
      <img
        className={styles.mayor_photo}
        src={url + matchedData.mayor.mayor_photo}
        alt="Mayor"
      />
      <div className={styles.province_area}>
        <div className={styles.location}>
          <img className={styles.icon} src={Pin} alt="" />
        </div>
        <div>
          <div className={styles.province_name}>
            {matchedData.province_name}
          </div>
          <div className={styles.district_name}>{matchedData.name}</div>
        </div>
      </div>

      <div className={styles.card_content}>
        <h2 className={styles.mayor_name}>{matchedData.mayor.mayor_name}</h2>
        <h3 className={styles.mayor_party}>{matchedData.mayor.mayor_party}</h3>
        <div className={styles.lover}>
          <img
            src={url + matchedData.mayor.party_logo}
            alt="Party Logo"
            className={styles.party_logo}
          />
          <div className={styles.election_year}>
            <span className={styles.year_info}>Seçilme Yılı</span>
            <p className={styles.year}>{matchedData.mayor.election_year}</p>
          </div>
        </div>
      </div>
      <div
        className={styles.bio}
        dangerouslySetInnerHTML={{ __html: formattedBio }}
      ></div>
    </div>
  );
}

export default DistrictHallDetail;
