import React from "react";
import { useContext } from "react";
import { Context } from "../../context/Context";
import { NavLink } from "react-router-dom";
import arrow from "../../assets/arrows.svg";
import styles from "../../css/map/mainmap.module.css";

function TurkeyMap() {
  const { dispatch, id } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER",
      payload: true,
    });
    const svgElement = event.target;

    svgElement.dataset.originalFill = svgElement.getAttribute("fill");
    svgElement.dataset.originalOpacity = svgElement.getAttribute("opacity");

    svgElement.setAttribute("fill", "#00abcd");
    svgElement.setAttribute("opacity", 0.7);
  };
  const handleMouseLeave = (event) => {
    dispatch({ type: "SET_HOVER", payload: false });
    const svgElement = event.target;

    svgElement.setAttribute("fill", "#F0F0F0");
    svgElement.setAttribute("opacity", 1);
  };

  return (
    <div className={styles.container}>
      <div className="map-area">
        <svg
          id="countryMap"
          viewBox="80 80 760 350"
          preserveAspectRatio="xMidYMid meet"
          className="relative"
        >
          <NavLink to={"/" + id}>
            <path
              id="adana"
              data-cityid="1"
              d="m494.65 287.24l-1.67-5.1-3.24-2.49-2.75 0.93-5.7 5.55-3.69 7.92-8.2 3.15-2.64 3.79-2.94 1.08 0.78 6.55-3.13 2.78-0.48 3.85-2-2.01-12.41 3.68-10.28-1.03-1.31 10.19-2.65 1.7 0.12 4.46 7.29 3.06-1.34 4.5 2.97 5.09 4.1 1.16-0.94 4.02 1.76 2.32-0.09 5.53-2.42-0.38-5.97 3.51 4.07 0.71 12.39 7.82 3.29-2.37 5.2 1.24 5.46-6.58-3.64 2.77 2.36-3.03-3.79 1.01-0.22-1.18 3.55-2.4 5.82-0.79 7.25-7.62 3.25-5.43 0.03-4.71-0.66-2.06-3.2-0.86-4.27 2.07 1.72-13.78 1.73-5.01 6.75-7.85 4.37-0.86-0.84-1.52 2.54-3.52-1.28-2.98-2.68-1.3 2.46-5.32 3.27 0.14 1.53-9.75z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="adiyaman"
              data-cityid="2"
              d="m604.15 291.68l-4.61-0.94-4.06 5.62-8.41 3.47-10.76-7.22-3.51-0.25-0.9 2.15-7.44-1.74-3.23 4.16 0.5 4.79-3.63 3.37-9.51 0.05-6.22-2.49-7.21 12.49 0.58 5.49 3.05-0.85 1.9 4.58 5.47-1.15 5.79 2.5 5.85-0.12 4.9 2.82 5.35-3.74 5.66 0.37 8.35-6.41 6.62-1.72-1.92-2.44 4.55-0.55-1.08-2.05 2.52-0.12-0.19-2.24-2.14-0.17 2.96-1.07-0.08-3.6 4.53-5.25 2.59-0.29 3.73-7.21z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="afyonkarahisar"
              data-cityid="3"
              d="m315.58 252.54l-6.59-2.68-0.52-2.28-8.2-5.67-0.31-2.54-8.32-0.28-0.24 2.85-2.44-0.01-0.07 1.59-5.03-1.84 0.77 3.83-7.57 3.24-6.69-2.92-2.65-5.06-6.9 3.61-2.68 4.62 0.96 3.28-12.74 13-4.31 11.58 6.78 3.82 3.48 9.67-16.28 10.32 1.49 5.91 7.37 4.24 6.87-0.36 2.93-3.53 1.87-6.64 2.2 0.2 0.75-2.08 2.42-0.53 5.39-6.76 11.81-5.88 1.03-2.67 6-2.9 2.07-3.84 4.15 0.12 3.35 5.26 2.2 0.69-0.78-3.38 13.13-9.53-1.66-3.83 2.31-8.84 1.01-2.38 3.53-0.98z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="agri"
              data-cityid="4"
              d="m804.95 220.07l-0.82-2.12-5.7 0.74-7.21-3.78-2.86 1.34-8.63-3.05-3.17 0.63-7.06-3.63-4.49 3.23-2.9-7.15 0.45-3.01-2.19-0.87-18.12 0.84-14.44 3.25-7.36 4.46 8.78 8.47-0.38 2.79-4.55 4.76 3.5 1.45 1.49 4.87 2.53 0.71 3.9 8.05 1.15 12.52 7.58 0.19 1.37-4.13 2.12-0.1 0.07-2.82 5.46-4.91 2.48-7.52 3.85-1.77 4.53 0.51 7.68 7.67 2.36 0.15 5.69-5.76 4.21-2.66 3.33 0.26 1.24-2.29 6.29-0.25 2.41 2.07 4.82-1.82 0.04-7.5 2.54-3.8z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="amasya"
              data-cityid="5"
              d="m498.1 163.71l-2.95-2.06 0.93-3.64-3.37-1.09-0.68-3.39-4.91-0.09-6.03 7.1-14.02-1.81-2.64-3.06-4.79-0.76-0.87-3.29-8.34-0.85-4.93-2.62-3.14 4.02 1.04 4.73-3.1 5.48 2.9 5.08 8.87-0.34 8.98 7.58-4.09 3.95-1.8 5.3 2.4 6.73 16.68-12.42 4.85-0.44 4.31 2.75 4.87-3.04 6.32-8.53 0.06-3.33 3.29-1.7z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="ankara"
              data-cityid="6"
              d="m398.23 249.73l-1.22-1.54h-3.33l-7.88-6.48-4.58-5.86 1.04-1.01-3.27-3.78 0.16-2.92-3.01-1.59 1.41-0.21-2-1.85 0.47-5.09-4.73-0.32 3.78-3.85 0.8-8 2.66-1.18 0.83-2.38 0.68 1 3.39-2.17-0.75-3.04 4.48-2.37 2.12-11.05-9.96-4.01-2.11 0.3-2.74 3.8-4.36-6.73-3.75-0.36-3.23-3.7-3.09 1.6 0.23-5.92-4.43-1.73-3.72-4.56-3.98-0.34-2.62 1.52-0.57 2.61-2.67-0.4 0.92 6.04-3.25 1.04-1.84-2.05-4.35 1.72-2.93 3.73 0.52 2.44-3.64 0.41-7.51 5.65-3.87-1.4-6.24 3.23-3.23-1.82-4.17 1.16 1.95-3.94-7.62 1.4-4.79-1.6-2.68 1.01-0.13 5.76-8.29 4.16 0.63 3.93 3.33 1.85 6.9-2.14 2.91 1.27 7.54-0.76 0.45 2.26 2.49-1.38 5.42 1.24 2.43-1.69-0.45 2.25 2.38-0.44-0.78 2.34 1.66 0.15 0.78 3.4 3.77 1.02-0.14 1.83 1.45-0.69-0.21 2.03-3.81 1.25-1.05 3.24 7.16 4.62-2.15 2.67 3.93 5.44-0.82 2.63 2.03 6-7.74 6.95 9.66 9.22 5.42-3.37 9.66 2.63 7.65-6.12 5.9-1.9 1.12-2.04-1.67-4.35 5.35 0.27 3.34 2.51 4.94-2.7-1.39 3.03 1.67 3.77 3.01 2.56 2.98-1.12 1.99 3.27-1.19 7.75 1.12 4.83 4.06 4.14 4.67 0.93 4.73-3.85 2.63-6.19-2.86-4.87 6.16-2.56z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="antalya"
              data-cityid="7"
              d="m349.16 381.87l-5.51-22.13-5.37-2.98-21.91-26.22-10.13 1.04-6.96-4.49-10.52 3.46-4.66-0.59-1.52 5.43-4.55 2.1-20.18-4.86-9.54 3.2-0.67 2.98-5.1 3.18 1.38 3.62-1.26 2.36-4.06 1.71 1.47 5.5-3.81 10.72-6.94 7.5-5.58 1.48-2.36 6.85 3.07 2.66 1.5-1.68 0.72 1.79 8.28 1.11 2.01 3.49 1.42-1.53 2.77 0.95 4.96-4.27 2.43 0.82 2.73-2.78 2.21 1.34 2.72-3.17 8.57 1.86-0.16 3.05 3.18-3.28-0.8-1.63 2.38-0.98-2.01-2.95 4.43-9.14-1.35-1.46 0.69-8.21 4.91-4.44 2.36 1.83 9.39-0.53 12.49 2.28 17.16 9.84 9.38 2.9 10.43 14.55 8.4 6.52 2.01-0.79 1.38-7.53z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="artvin"
              data-cityid="8"
              d="m732.34 135.22l-2.9-4.96-13.1-3.86-5.4 0.98-3.1-1.51-5.16 4.65-4.34-3.22-6.26-1.23-5.58 7.19-6 2.86 4.99 10.31-8.37 14.39 7.29 4.77-1.2 4.36 1.28 3.07 3.81 0.06 6.48-6 7.09 1.99 2.99-6.45-0.91-3.43 4.83-4.96 13.05 2.23 2.06-2.99-0.56-4.93 5.96-5.22 2.99-7.72z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="aydin"
              data-cityid="9"
              d="m204.72 301.42l-2.88-0.54-1.95-3.62-8.38-3.26-2.35 1.62-3.77-1.3-2.36 3.84-4.66-0.2-6.05 3.21-13.81 0.77-1.11-2.19-2.08-0.19-3.8 3.85-0.03 4.72-2.3 0.7-4.98-4.97-1.08 9.31-8.85 3.01 8.05 3.59-1.68 1.89 0.3 3.7 1.79 0.06-1.38 5.66 2.19 0.85 3.05-0.4-0.42-1.16 3.54-2.23 0.87 1.59-1.91 1.35 3.72-1.54-2.04-3.97 3.43-3.94 4.12-0.21 7.82 4.23 2.88-1.22 8.58 1.71 1.29-2.11 2.62 0.38 1.78-3.63 0.32 2.67 10.73 1.89 1.69-1.46-1.23-0.98 6.53-3.73 2.69-5.47-3.74-11.28 3.73 1.11 0.94-1.64z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="balikesir"
              data-cityid="10"
              d="m211.16 221.88l-7.39-0.86-4.21-7.59-6.29 1.49-4.62-5.13-5.48-2.21-7.73-18.04 3.51-0.82 2.17-6.29-10.31 2.38-2.4-1.94 5.21-4.49-11.36-2.32-1.6 2.06 7.07 5.63-4.55 3.32-4.92-1.03-3.78 1.27-5.32 7.47 2.59 4.95-0.86 2.66 3.58 3.59-2.43 6.7-6.84 5.23-12.32-3.99-9.63 2.55-2.4 7.88 8.55-1.54 2.9 1.66-0.6 2.84-5.34 2.91 0.19 2.13-4.04 2.45-1.13 3.07-1.55-0.88 0.82-1.29-1.58 2.11 4.11 0.92 2.04 4.49 13.5-9.86 13.3-2.1 5.31 3.56 4.96-0.92 3.14 2.3 3.64-1.81 1.91 2.05-0.87 2.79 7.38 7.55 7.44 0.25 9.79-3.53 4.01 0.75-1.68-6.77 7.77-1.52 4.01-4.65-0.71-5.96 2.77-3.3z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="bilecik"
              data-cityid="11"
              d="m274.56 194.08l-2.94-6.02-4.1-1.44-0.44-2.88-11.75 0.72-3.05-4.66-2.2 1.37-1.91-4.53-1.22 0.23-2.52 1.81 0.48 2.37-2.78 3.3 0.74 5.65-5.11 5.71 2.44 9.26-1.25 9.85 11.53 4.38-1.71-3.27 3.11-4.92 2.9-1.46 5.61 0.62-0.09-5.57 2.36 0.68 0.53-2.92 3.83-1.13 2.95-4.99 4.06-1.81z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="bingol"
              data-cityid="12"
              d="m681.2 248.2l-4.5-8.45-0.12-8.85-8.51-2.84-4.98 3.46-6.69 0.5 0.07-6.2-1.58-0.48-10.35 1.57-3.38 7.39-3.69 2.4-3.66-0.54-3.39 8.06 0.52 2.97 11.09-4.82 1.87 1.78-2.5 11 1.27 3.94 4.28 2.83-8.87 8.03 6.88 7.36 5.83-7.16 4.85 2.71 3-1.98 5.15 1.87 8.13-5.72 5.41-1.72-1.98-9.29 5.78-7.06z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="bitlis"
              data-cityid="13"
              d="m756.47 258.1l-1.9-2.66-3.48 0.22-2.26-4.7-2.49-0.66-1.89 4.46-19.11 0.33-8.29 2.2-4.14 4.59 0.9 2.67-6.03 5.23-0.47 2.28-10.88-2.33-3.18 1.5-1.23 3.66 4.46 2.72-0.94 8.28 1.77 2.44 9.28 2.54 1.91 2.08 10.26-4.34 1.27 4.85 4.36 0.37-0.46 1.91 5.5 4.54 4.26 0.86 3.85-13.26-2.68-2.27 4.47-4.05-0.57-5.63 7.91-5.55 1.93-4.44 7.6-7.6z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="bolu"
              data-cityid="14"
              d="m348.15 163.42l-2.71-5.72-3.73 1.57-3.97-0.59 3.13-3.32-0.39-1.39-2.34 0.71 0.36-3.66-6.18-1.23-3.04 2.13-4.96-1.81-6.87 4.18-1.26-1.43-4.6 2.12-4.54 3.92 0.71 3.36-4.83 2.05-2.27 5.6-4.55 1.42-5.3-3.77-7.25 1.69-1.34 7.46-4.49 1.32-3.41-1.09-2.5 1.7-1.15 5.76 1.81 1.54-1.74 0.9 2.8 7.2 1.87-1 5.4 2.59 9.25-5.37-0.51-5.12 2.68-1.01 4.79 1.6 7.62-1.4-1.95 3.94 4.17-1.16 3.23 1.82 6.24-3.23 3.87 1.4 7.51-5.65 3.64-0.41-0.52-2.44 2.93-3.73 4.35-1.72 1.84 2.05 3.25-1.04-0.92-6.04 2.67 0.4 2.75-4.51z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="burdur"
              data-cityid="15"
              d="m284.1 329.96l-5.1-7.01-1.12-6.03-9.89-2.21-3.32-6.35-7.37 2.23-11.07-0.48-2.94 3.37-6.99 2.01 0.8 2.41-5.02-0.64-1.41 4.36 2.5 1.94-1.83 1.72 2.59 5.5-6.86 9.7 0.1 5.21-1.66-1.12-1.59 1.8 2.44 2.91-3.91 4.76 3.6 2.76 3.41-1.76 4.03-5.7 9.01-1.25 1.42-2.48-1.38-3.62 8.32-7.44 11.66-1.52 5.56 3.06 9.95 1.4 3.69-1.07 2.34-5.51z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="bursa"
              data-cityid="16"
              d="m248.17 176.64l-2.78-0.64-0.37-2.29-7.34-1.97-5.75 4.62-3.17-2.32-0.88 1.34-3.89-1.35-1.21 2.16-3.26 0.19-3.1-2.74-4.53-0.17-1.41 4.84 3.65-0.18 2.66 2.58-2.69 0.85-1.19 2.65-31.47-1.86-2.49 6.37-3.51 0.82 7.17 17.51 6.04 2.74 2.94 4.25 2.59 0.9 5.38-1.51 4.21 7.59 13.94 0.81 9.03-10.28 0.99-3.33 5.24 1.06 2.96-1.24 3.13 1.53 1.14-4.6-2.44-9.26 5.11-5.71-0.77-5.52 2.81-3.43-0.48-2.37 3.65-2.1z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="canakkale"
              data-cityid="17"
              d="m154.48 187.31l-8.07-3.58-1.44-5.07-3.1 2.02-4.42-0.39-2.19 2.63-11.42-0.23-8.07 8.82-4.42 0.96v3.96l-3.38 5-5.45 1.3-1.58 7.43 0.97 8.96-3.684 8.9 2.784 1.12 8.76-1.32 11.08-3.47 1.23-6.89 6.63-2.93 9.45 0.18 3.71 2.79 4.73-0.07 5.44-4.75 2.31-6.16-3.46-4.13 0.86-2.66-2.74-4.25 5.37-7.9z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="canakkale"
              data-cityid="17"
              d="m134.58 167.89l-1.51-2.42-8.07 0.37-1.16 3.49 3.25 0.4 0.59 3.61-23.39 12.94 2.03 5.32-3.93 8.36 7.69-4.72-0.64-3.09 8.88-6.02 4.2-6.54 8.84-4.72 2.71-5.56z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="cankiri"
              data-cityid="18"
              d="m408.17 181.18l-2.13-8.25 1.42-4.05-2.35-3.05 0.86-3.09-11.19-5.36 1.55-9.19-8.98 2.94-4.76-1.41-4.92 3.37-6.25-4.43-4.17 0.73-2.61 4.08-8.32 0.85-3.26 4.91-5.41 2.52 0.06 3.1 4.41-0.12 3.72 4.56 4.43 1.73-0.23 5.92 3.09-1.6 3.23 3.7 3.75 0.36 4.36 6.73 2.74-3.8 3.55-0.25 3.47 3.01 10.71 0.91 1.63 1.98 4.78 0.87 3.71-1.38 3.1-6.1z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="corum"
              data-cityid="19"
              d="m461.22 175.1l-8.5-7.71-8.36 0.32-2.46-1.83-1.6-3.5 3.1-5.48-1.04-4.73 2.57-3.85-6.51-0.84 2.05-2.58-3.36-5.11-1.94 2.16-5.42-2.63-0.2-2.05-9.41-1.28-1.73 2.94-2.61-1.21-4.71 5.42 0.52 4.74 3.58 3.39-3.84 8.11-8.72 1.5 3.34 1.86-0.86 3.09 2.35 3.05-1.42 4.05 2.11 8.68-3.09 5.86-3.9 1.35 9.36 14.43-0.29 2.45 5.84-0.02 3.86-3.42 5.95 2.55 2.04-3.49 5.54 1.21 2.75-2.48 1.35 2.34 4.48 0.44 6-2.5-2.04-3.19 2.96-2.52-1.76-0.6-0.29-3.16 3.55 1.67 6.07-2.24-1.44-5.71 1.3-4.69 4.78-4.43z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="denizli"
              data-cityid="20"
              d="m252.34 289.93l-3.41-9.09-2.88-0.73-0.63-2.42-7.37 0.03-2.89 2.03-0.9 7.71-5.45-0.69-3.21-2.42-6.67 4.04-0.02 3-3.75-3.05-9.98 0.13-0.76 2.62-3.71 0.08-0.68 3.29-3.36 1.44 4.62 2.23 0.55 2.75 2.88 0.54-1.12 2.11-3.73-1.11 3.74 11.28-2.69 5.47-6.53 3.73 2.11 2.25-0.64 2.36 1.23 2.98 2.21 0.01 1.48 2.12-0.13 2.5 3.83 6.42 4.12-0.13 3.3 4.64 2.12-0.22 1.34 8.47 2.17-1.77 5.41 1.13 3.42-4.38-2.44-2.91 1.59-1.8 1.66 1.12-0.1-5.21 6.86-9.7-2.59-5.5 1.83-1.72-2.5-1.94 1.41-4.36 5.02 0.64-0.8-2.41 6.99-2.01 1.6-2.66-7.37-4.24-0.29-3.95-1.84-1.02 9.91-8.08 7.03-3.3z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="diyarbakir"
              data-cityid="21"
              d="m683.99 277.14l-4.86-11.7-1.54-0.61-13.8 7.92-5.15-1.87-3 1.98-4.85-2.71-4.13 3.53-0.75 3.17-14.84 0.24-3.43 4.83-6.75 0.65-4.7 4.57-10.15-4.24-2.26 2.23-4.32-0.22-0.79 5.23 5.48 1.78-3.73 7.21-1.54-0.18 14.26 5.59 6.55 5.28 5.84-3.05 1.24 14.58 2.46 2.57 8.26-2.39 5.9 1.68 6.5-3.93 8.44 1.81-0.03-8.4 7.69 4.52 6.74-4.5 0.66-1.79-2.57-1.51 0.54-2.47 4.43-4.57 3.93-7.9-1.67-9.46 5.85-7.46z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="edirne"
              data-cityid="22"
              d="m134.58 112.8l-2.48-11.16-4.12 1.58-2.02-1-1.49 1.56-4.82-1.09-3.38 7.93-6.48 0.2-0.28 5.09 8.99 5.3 1.56 13.15-1.97 1.73-2.62-0.78-4.32 4.28-2.91-0.12-0.69 6.85 2.21 4.7-1.52 1.77 1.2 2.27-2.72 0.71 0.08 1.91-1.2-1-5.17 8.61-3.493 0.65 3.273 6.26 13.09-1.01 2.86 1.31 2.91-2.26 5.8-0.74 0.93-5.56-2.69-4.21 1.48-6.87-2.61-3.12 8.17-3.34 1.35-3.01 0.93-3.98-3.26-8.16 5.07-9.1-1.21-2.11-2.53 1.41-0.4-2.13 2.84-1.47 1.31-4.4z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="elazig"
              data-cityid="23"
              d="m646.95 261.92l-4.28-2.83-1.27-3.94 2.56-9.76-1.36-2.99-6.01 1.74-2.93 2.74-4.81 0.09-1.62 2.18 1.93 1.97-1.25 4.56-3.29 4.14-8.62 0.67-3.23 2.56-15.58-6.72-11.99 1.05 0.56-5.21-3.54-2.92-4.78 5.9 4.11 4.06-0.94 4.43-6.7 0.11-0.1 2.52-3.28 2.27-0.64 4.68 4.43 3.94 8.91 2.91 3.73-1.03 6.28 5.65 3.73-1.42 6.07 1.96 3-2.33 10.15 4.24 4.7-4.57 6.75-0.65 3.43-4.83 13.89 0.22-6.85-7.78 8.67-7.26z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="erzincan"
              data-cityid="24"
              d="m658.78 222.72l-0.53-4.21-4.51-4.76v-3.86l-10.29-4.85 0.87-3.79-10.34-1.72-11.26 8.02-9.1 1.36-11.29-3.5-1.65-2.67-8.17-4.1-0.56 1.3-5.83-1.36-1.16 3.22-2.61-0.53-5.36 2.91-3.07-1.17-2.8 3.54 3.49 4.58-4.34 3.42-1.54 8.77-1.64 1.57 4.13 6.34-2.75 12.89 2.6 1.85 4.53-1.24 9.21 6.09 0.59-9.11 6.43-2.22-1.62-4.07 8.08-6.68 7.54 0.2 9.51-2.2 4.71 1.31 4.4-2.01 0.95-4.33 9.18 2.35 12.99-1.26-2.64 4.01 11.83-0.98 1.9-2.66z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="erzurum"
              data-cityid="25"
              d="m731.39 170.01l-0.63-5.43-6.62-3.63-2.43-4.43-12.26-2.56-5.5 5.23 0.91 3.43-3.54 6.72-6.54-2.26-9.05 6.77-2.42-3.07 1.1-5.19-7.29-4.77-11.23 9.98-4.22 0.9-5.86 4.45-2.21 4 1.42 3.56-1.96 2.09 6.98 6.34-0.21 2.6-9.45 6.64-5.69 0.08-1.49 2.97 10.54 5.46 0.91 5.87 3.89 3.28-0.05 5.4-2.75 2.55 0.4 4.74 6.95-0.21 4.28-3.24 3.48 0.18 5.73 2.44 0.58 3.22 13.24 2.19 3.61 2.66 1.51 4.76 13-2.34 3.35-1.58 0.61-2.43 4.06-0.98 0.9-5.53 6.86-3.9 5.11-6.31-8.96-9.71 10.8-5.59-6.62-10.36-8.64-4.79-1.49-2.59 14.1-5.69 2.69-11.53z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="eskisehir"
              data-cityid="26"
              d="m326.98 237.38l-2.03-6 0.82-2.63-3.93-5.44 2.15-2.67-7.16-4.62 1.05-3.24 3.81-1.25 0.42-1.64-5.29-2.55-0.78-3.4-1.66-0.15 0.78-2.34-2.38 0.44 0.45-2.25-2.43 1.69-5.42-1.24-2.49 1.38-0.45-2.26-7.54 0.76-2.91-1.27-6.9 2.14-3.33-1.85-0.67-3.85-5.91-2.13-5.21 3.23-2.95 4.99-3.83 1.13-0.53 2.92-2.36-0.68 0.09 5.57-6.8-0.36-4.82 6.12 1.71 3.27 6.48-0.99 1.51 5.03 3.48 2.33 2.16 13.09 3.93 2.45 2.33 4.72 6.69 2.92 7.57-3.24-0.77-3.83 5.03 1.84 0.07-1.59 2.44 0.01 0.24-2.85 8.32 0.28 0.5 2.75 3.99 1.94 5.71 6.24 10.34-4.52-1.16-2.04 7.57-6.13z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="gaziantep"
              data-cityid="27"
              d="m559.27 326.51l-16.71-3.13-5.41 5.93-6.06 3.29-4.35-0.38-1.48 2.67-5.59 3.32-2.04-0.76 0.69-1.08-2.87-3.39-2.33-0.53-0.92 2.67-11.21 8.37-4.67 8.2 10.2 4.37 3.33-4.12 3.03 0.6 4.38-6.15 3.92 6.1 7.57 1.99 0.64 4.47 2.28 2.02 2.16-4.71 5.61 5.78 3.56-2.19 4.68 0.05 9.38-3.91 0.59-2.38-3.11-5.45 0.72-2.47-4.35-1.33-1.21-6.52 1.78-0.17-1.59-2.41 1.35-3.73 3.86-4.39 3.77-0.34z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="giresun"
              data-cityid="28"
              d="m601.17 148.93l-9.47 1.37-8.15 4.6-3.36-1.1-4.02 2.77-6.55 0.36-9.23-2.34-3.87 4.87 0.75 4-1.67 2.67 3.12 7.8-1.21 3.19 4.66 1.75 0.48 12.15 9.74 4.71 3.86-1.49 2.64 3.66 13.19 1.96 0.38-3.01-2.64-3.19 6.08-8.76-0.03-2.32-2.08-3.37-6.76-2.52-0.94-1.91 2.63-4.98 2.98-0.59-0.47-3.98 8.61-3.21 1.31-12.75z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="gumushane"
              data-cityid="29"
              d="m637.2 173.4l-3.89-3.03 0.74 3.97-3.43 0.93-1.37-3.22-2.86-0.87-0.06-5.36-1.74 0.37-2.47 4.94-5.99 0.89-4.12-0.52-6.1-5.85-5.84-0.43-0.2-3.27-7.1 2.33-1.72 1.13 0.65 3.8-2.98 0.59-2.68 5.32 7.75 4.09 2.18 3.79-5.49 7.94-0.37 3.99 2.4 3.71 8.17 4.1 1.65 2.67 10.05 3.42 10.34-1.28 2.84-1.35-4.93-4.63-0.75-4.72 6.44-4.52-0.11-6.01 2.23-5.84 2.11-2.16 5.78-1.4 0.64-3.07z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="hakkari"
              data-cityid="30"
              d="m817.44 334.75l-3.59-4.75-5.43-3.36 1.34-7.4-1.78-2.1 1.99-3.96-2.15-2.23-4.31 0.3-1.21-1.87-7.92 3.58-4.37 0.12-1.57-3.61-21.36 3.2-2.72 6.32-4.3 3.56-0.9 10.4 2.86-0.64 5.03 3.85 11.48 0.86 1.47 1.43 3.09-1.14 3.85-4.83 8.36 2.2 2.02 4.96-3.37 3.67 2.85 5.75 13.45-10.12 6.04 1.41-1.04-3.25 2.17-2.32z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="hatay"
              data-cityid="31"
              d="m506.03 383.38l-1.5-3.87-2.06 0.35-2.35-7.29 1.8-5.03-0.65-3.38 3.71-8.05-20.41-9.06-3.27 0.72-4.21 5.4 3.32-2.01 4.42 2.99 2.63 4.53 0.21 5.83-0.77 2.51-5.81 2.98-9.84 10.19 7.55 14.13-2.53 4.18 3.34-0.45 0.38 2.77 3.75 0.73 1.87 2.32 0.95-5.85 3.99-0.67-0.23-2.57 3.42-0.05 0.19-9.7 3.4 0.71 2.46-1.95 3.21 1.27 3.03-1.63z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="isparta"
              data-cityid="32"
              d="m308.35 297.78l-1.62-6.69-7.72-5.92-0.89-3.24-2.39-0.72-3.35-5.26-4.15-0.12-2.07 3.84-6 2.9-1.03 2.67-11.81 5.88-5.39 6.76-2.42 0.53-0.75 2.08-2.2-0.2-4.36 9.89 5.1 0.41 7.37-2.23 3.32 6.35 9.89 2.21 1.12 6.03 5.1 7.01 4.66 0.59 12.66-2.94-0.73-11.17 3.52-2.17 0.13-7.21 3.87-8.89z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="mersin"
              data-cityid="33"
              d="m446.3 354.55l-1.82-4.95 1-3.55-4.1-1.16-3.32-6.77 1.69-2.82-7.29-3.06-0.12-4.46-12.27 0.69-10.91 10.92 0.57 4.13-2.62 2.26-18.74 2.22-13.71 4-1.49-0.98-3.78 3.53-3.94-0.82-1.33 2.31 0.84 4.73 6.75 8.08-5.31-0.6-0.84 5.54-19.07-3.98 2.67 12.06-1.87 8.66 9.36 3.8 5.44-3.93 5.41 1.33 2.27-2.98 7.91-0.78 1.82 1.27 2.4-1.52 3.78 1.6 3.34-3.21 1.62 2.78 6.85-8.53 2.51 1.1 1.14 2.84 1.92-3.72 2.26-0.32 1.7-6.1 17.69-15.6 9.1-1.42 3.85 3.59 6.13-3.19 2.28 0.5 0.19-2.36z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="istanbul"
              data-cityid="34"
              d="m215.16 140.53l-18.74-6.06-13.62-7.21-4.82-4.48-2.91 3.84 1.98 5.04-1.75 6.16-2.94 2.37 0.94 3.15-1.72 1.15-0.16 4.54 1.4-0.38-0.12 2.11 9.24-2.67 11.35 4.53 0.32-4.69 2.13 5.76 8.77 0.77 5.66-2.42-1.6-2.97 1.06 2.17 2.34-1.4 1.51-3.72-1.36-1.69 2.99-3.75z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>

            <path
              id="istanbul"
              data-cityid="34"
              d="m245.85 145.44l-28.76-4.43-3.49 2.96 0.83 2.4-3.44 5.6 5.14 5.19 5.88 2.66-0.71 1.51 3.32 0.23 0.18-2.31 5.32-6.28 0.1-2.73 3.42 0.62 0.37 2.43 2.69 0.85 1.02-3.53 4.58 1.06 2.16-1.38 1.37-4.66z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="izmir"
              data-cityid="35"
              d="m191.51 294l-1.67-1.79 0.93-4.21-13.12-5.08-1.9-2.38-7.17 0.16-0.84 4.09-4.01-0.89-2.49-1.4 0.11-2.98-3.56-3.81-2.89 2.45-3.53-4.87-8.61-0.64 1.37-1.36-2.48-4.29 1.11-1.39-4.96-4.28 6.58-5.46 6.97-1.1 2.67-4.68-4.63-17.62-10.67 0.89-13.5 9.86 4.55 4.28-3.42 2.39 0.05 3.58 2.05 2.16 4.46-1.92 1.32 1.99 1.62-1.36 0.67 3.11-3.65 1.61 0.11 1.96-1.11-1.47-1.82 0.57 1.64 2.82-1.71 1.58-2.74-1.39-3.09 1.04-0.76 4.38 4.71 2.26-0.41 2.37 2.03 2.21-2.63-1.23 5.15 4.67 2.97-1.41 4.89 0.05 0.33 1.3-3.11 2.04-2.52-0.78-8.36 2.88-3.63-3.8-1.31 5.93-3.47-5.61 0.13-2.07 2.05 0.03-0.62-2.99-3.9-5.8-4.07-1.49-2.34 1.55 1.45 9.62 1.74-1.27 2.82 2.77-2.32-0.4 1.2 2.5-4.27 3.3-3.33-3.31 0.69 2.72-2.66 1.27 4.16 3.12 1.87-1.67 1.3 2.93 0.32-1.04 3.38 1.74 0.47 2.83 1.44-0.64 0.81 1.74 1.67-4.83 4.74-0.72 4.36 9.12 4.11-2.09 6.23 4.38 3.67-0.13 2.15 4.98 3.91 3.25 2.3-0.7 0.03-4.72 3.8-3.85 2.08 0.19 1.11 2.19 13.81-0.77 6.05-3.21 4.66 0.2 2.36-3.84 3.24 1.33 2.63-1.42z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="kars"
              data-cityid="36"
              d="m776.55 168.48l-2.95-12.79-13.25-8.04-5.64 6.29-4.91 0.25-3.79 4.27-1.77-0.32-2.22 7.72-10.63 4.15-2.79 11.92-12.9 4.4-1.12 2.33 10.05 6.34 6.6 10.79 12.9-2.82 14.74-0.52 2.61-6.14 8.07 0.4 5.66-2.67-2.32-2.88 1.1-1.66-3.38-3.97 0.96-4.02-2.93-2.91 4.05-2.76 3.79-7.22z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/kastamonu"}>
            <path
              id="kastamonu"
              data-cityid="37"
              d="m425.69 119.14l-3.61-4.03-9.92-0.11 1.36-3.42-0.96-5.5-1.23-2.17-5.13-1.26-11.98 0.32-17.08-2.36-22.47 8.93 0.96 4.68 5.79 0.95-2.03 7 0.88 1.72 6.08-0.26-0.03 3.79 3.71 3.37-1.32 3.5-5.85 3.17-1.61 3.28 0.09 2.5 3.83 2.02 1.72 3.99 4.53-0.59 6.25 4.43 4.92-3.37 4.76 1.41 8.98-2.94-1.55 9.19 7.85 3.5 8.72-1.5 3.84-8.11-3.58-3.39-0.52-4.74 4.71-5.42 2.46 1.26 1.8-1.83 0.75-4.27-1.72-5.79 4.02-2.48 2.54-5.18z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="kayseri"
              data-cityid="38"
              d="m516.17 252.43l-2.48-4.18-7.27-4.21-6.42 2.41-16.98 0.52-9.82-10.24-6.4 4.88-8.25 2.19-1.13 1.96 1.18 2.44-5.76 5.07-6.21-3.33-2.58 0.35-4.09 10.71 4.82 4.98 0.66 2.9-1.67 0.84 3.51 6.05-10.41 4.51 5.68 11.65 7.82 1.73-0.27 13.19-5.51 10.05 14.4-3.66 2 2.01 0.48-3.85 3.13-2.78-0.78-6.55 2.94-1.08 2.64-3.79 8.2-3.15 3.69-7.92 5.7-5.55 2.75-0.93 3.24 2.49 1.28 6.17 11.12-3.88 1.12-8.98 2.69-1.31-0.42-11.32 7.3-9.74z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="kirklareli"
              data-cityid="39"
              d="m177.18 121.43l-5.59-11.41 1.09-3.15 1.84 0.42-1.06-4.79-7.52-0.79-0.7 2.85-4.51-1.84-4.66 2.09-0.17 1.71-11.5-10.27-2.72 2.306-5.05-1.633-4.09 4.827 0.36 8.01 1.68 3.04-1.64 5.05-2.84 1.47 0.4 2.13 2.53-1.41 1.21 2.11-5.11 9.51 2.04 4.93 3.5-2.3 2.76 1.31 0.97 3.05 0.23-2.63 4.18-0.14 7.5 5.03 6.5-2.75 1.57-7.97 9.92-3.21 2.1-2.96 6.3-2.22z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="kirsehir"
              data-cityid="40"
              d="m429.35 238.26l-3.98-2.51 1.99-4.96-4.72-3.68 0.29-3.98-3-2.75 0.52-1.82-8.49-2.24-3.22-3.93-7.66 3.37 0.49 2.44-9.03 7.08 0.56 3.04-2.9-1.28-5.44 3.63-5.81 0.22 6.98 10.95 7.75 6.35 7.42 1.53 4.03 4.43 0.48 3.51 3.52 2.73 3.56-0.31-0.22-4.06 4.57 2.11 2.63-5.3 5.46-3.38-1.06-3.43 5.25-7.52z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="kocaeli"
              data-cityid="41"
              d="m263.19 143.3l-3.72-1.54-4.4 3.56-9.33-0.16-1.28 5.13-2.16 1.38-4.58-1.06 0.55 1.57-2.09 2.1-2.17-0.99-0.33-2.4-3.46-0.65-2.01 6.5-3.51 2.67-0.23 4.68 9.84-1.2 12.69 2.03-1.12 1.77-3.97-0.81-9.76 2.18-2.32 2.39 0.19 5.69 4.54-0.88 3.12-3.52 7.8 2.21-0.03 2.09 7.91-3.38 1.33-6.07 4.12-0.94-1.46-4.27 2.89-6.81-2.05-1.4 0.26-5.13 4.68-4.68z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="konya"
              data-cityid="42"
              d="m418.93 329.43l-4.09-4.64 0.95-2.25-1.73-2.42 3.63-5.7-5.96-8.28-8.54-5.21-0.44-1.53 2.88-2.25-2.29-0.17-1.54-2.65-3.9-0.14-3.49 2.09-4.59-1.21-4.44 2.13 1.01 3.13-1.09 0.67-4.11 0.37-3.86-3.3-2.2 1.75 1.07-3.46-1.43-7.86-3.25-0.51-0.51-2.16 5.69-9.78 0.69-8.04 6.61-0.79-5.51-5.25 0.38-11.98-1.99-3.27-2.98 1.12-3.01-2.56-1.67-3.77 1.39-3.03-4.94 2.7-3.34-2.51-5.35-0.27 1.67 4.35-1.12 2.04-5.9 1.9-8.08 6.26-9.23-2.77-5.42 3.37-8.4-7.77-10.19 4.03 5.27 2.73-3.64 1.4-1.01 2.38-2.31 8.84 1.66 3.83-13.54 10.35 2.27 5.83 7.72 5.92 1.58 6.04-3.97 9.93-0.13 7.21-3.52 2.17 0.8 11.83 4.75 3.31 10.13-1.04 21.91 26.22 5.37 2.98-0.02 2.49 3.63-3.53-1.21-3.87 9.85 1.68 1.53-6.28-2.31-4.67 1.99-1.18-1.21-5.08 1.12-6.43 13.3-10.26 6.82 3.14 15.81-4.92 2.24 4.08 3.8 0.7 0.95 2.95 3.46 1.96 5.18 1.27 4.41 5.12 5.78-6.81z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="kutahya"
              data-cityid="43"
              d="m266.69 240.72l-2.58-2.06-1.87-5.21 1.6-2.25-1.92-1.22 0.03-4.41-3.48-2.33-1.51-5.03-8.69 0.85-6.52-2.15-2.8-2.09 0.11-5.25-10.58-1.6-10.12 13.52-8.75 1.51-1.76 4.08 0.98 4.23-5.3 5.41-6.48 0.76 1.68 6.77-2.85-0.56 11.13 5.42 0.5 3.97 4.11 5.53 2.91-0.65 6.92 3.28 4.42-3.51 11.99-2.47 6.77 1.05 6.45 4.24 8.02-8.27 0.2-6.34 7.29-5.16z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="malatya"
              data-cityid="44"
              d="m599.66 286.56l-1.89-3.24-4.53 1.37-6.28-5.65-4.43 0.88-8.86-3-3.49-2.91-0.34-3.75 3.97-3.99 0.1-2.52 6.38 0.13 1.26-4.67-4.11-4.06 4.78-5.9-7.12-4.67-4.03 1.39-2.6-1.85-6.61 3.55-14.03-0.26-1.83 5.37-9.07-1.94 1.64 11.63-11.37 13.64 15.41 8.89 2.67 8.04-4.31 4.86-0.39 2.88 8.01 4.36 9.51-0.05 3.63-3.37-0.5-4.79 4.28-4.55 6.39 2.13 0.9-2.15 3.51 0.25 11.36 7.02 7.81-3.27 3.73-5.19 0.45-4.54z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="manisa"
              data-cityid="45"
              d="m215.77 258.92l-5.26-0.92-3-4.92 0.63-2.58-4.61-4.22-8.81-2.78-4.71 2.65-11.21 1.37-8.69-8.29 0.87-2.79-1.87-2.02-3.68 1.78-3.14-2.3-4.96 0.92-5.85-3.62-2.52 3.09 3.07 4.34 1.35 13.68-2.03 2.46-10.46 2.9-3.11 4.05 4.98 3.89-1.11 1.39 2.48 4.29-1.37 1.36 8.61 0.64 3.53 4.87 2.89-2.45 3.56 3.81-0.11 2.98 2.49 1.4 4.01 0.89 0.84-4.09 7.17-0.16 1.9 2.38 12.64 4.7-0.13 5.19 6.72 3.09 3.15-1.44 0.68-3.29 3.71-0.08 1.32-2.64-4.34-5.6 2.41-4.5 1.37-11.98 6.84-2.06 3.21-5.39z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="kahramanmaras"
              data-cityid="46"
              d="m545.37 291.93l-2.76-6.93-15.41-8.89 2.57-2.27-0.83-1.43-9.69-0.98-12.08 3.51-1.79 9.49-11.12 3.88-1.51 9.33-3.27-0.14-2.29 4.62 3.79 4.98-3.53 6.56 2.07 2.47-1.15 7.02 1.7 8.63 8.07-1.81 6.29 0.5 0.33 4.7 1.76 1.82-1.16 2.16 6.84-4.03 0.92-2.67 2.33 0.53 2.87 3.39-0.69 1.08 2.04 0.76 5.59-3.32 1.48-2.67 4.35 0.38 6.06-3.29 3.54-4.95-1.9-4.58-3.05 0.85-0.58-5.49 7.21-12.49-1.79-4.08 4.74-6.04z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="mardin"
              data-cityid="47"
              d="m705.54 320.48l-2.97-4.84-3.46-0.42-8.42 8.39-12.4 0.74-2.34-2.19-3.02-9.34-6.94 4.39-7.24-4.57-0.42 8.45-8.44-1.81-6.5 3.93-6.46-1.63-7.76 2.81 4.15 13.1 3.12 2.8 5.11 12.08 7.04-5.13 4.46-0.58 9.07-4.76 5.63-0.19 11.73 3.24 17.16-2.58 0.15-4.62-5.04-4.36 1.6-0.93 1.07-5.28 9.93 1.25-1.11-5.29 2.27-2.58z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="mugla"
              data-cityid="48"
              d="m240.2 353.56l-0.96-3.49-3.72-1.42-9.47 8.15-6.78-4.09-3.91 1.59-0.74-7.63-2.72-0.62-3.3-4.64-4.12 0.13-3.83-6.42 0.13-2.5-3.86-2.43-1.4-6.16-1.99 1.39-5.75-2.18-4.31 0.31-0.59-2.77-1.78 3.63-2.62-0.38-1.29 2.11-8.58-1.71-2.88 1.22-7.82-4.23-4.12 0.21-3.43 3.94 2.53 3.11-4.21 3.08 1.18 1.65 3.08-1.25-1.44 3.44 2.39-0.83-0.37 2.2 3.98-2.34-1.3 3.03 1.39 1.38-3.3-0.29 1.99 2.76-4.24 2.63-5.51-3.76-0.52 2.08-2.25-0.52 1.47 0.95-2.59 2.31 2.27 4.53 1.97-2.71 3.4-0.76 2.36 2.24 5.48 0.37 26.79-3.51-6.3 5.04 0.2 2-2.66-1.62-2.65 0.9 1.2 2.38-1.92 1.64 2.09 0.48-1.27 1.88-15-1.12-1.09 2.23-5.57 0.11-4.01 3.51 4.49 1.69 3.6-1.55 3.79 1.12 1.99-4.71 7.89 0.87 7.26-3.09 0.46 2.56-1.68 0.19 1.57 1.13-6.11 2.15 2.73-0.61 2.11 2.24-1.26 1.92-3.76-0.01 0.68 2.27 1.95-0.28 3.07-1.74 1.94-4.01 4.87-1.7-2.22-4.01 1.38-2.4 4.89 3.31 1.16-2.35-1.84-0.67 3.02-1.57 0.12 3.13 2.76 1.37 0.67-2.28 1.75 1.74 0.96-1.06 0.2 5.6 0.87-1.08 5.24 1.87 2.56 2.32-0.16 2.27 2.31-2.7-2.23 0.19 0.53-2.51 2.51-2.82 6.4 3.49 1.08 2.35-3.08 0.51 0.27 2.81-1.36 0.7 4.35 0.01-0.91 7.47 6.63 4.81 2.36-6.85 5.58-1.48 6.94-7.5 3.84-11.71z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="mus"
              data-cityid="49"
              d="m736.63 245.17l-4.81-11.17-2.27-0.4-1.1-4.24-3.11-2.35-3.12 0.12-4.78 3.74-0.25 4.59-4.71 1.92-0.95 2.74-10.84 3.15-5.77 0.22-0.91-4.52-5.52-3.55-11.43-1.42-0.36 5.75 4.5 8.45-5.85 7.82 1.43 6.98 7.12 14.55 8.12-2.66 3.23-4.92 11.5 2.28 7.06-7.7-0.9-2.67 4.14-4.59 19.32-3.12 0.26-8.54z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="nevsehir"
              data-cityid="50"
              d="m447.28 275.77l-3.51-6.05 1.67-0.84-0.66-2.9-4.82-4.98 3.24-9.83-3.31-11.81-2.31-2.59-10.84-3.73-1.37 2.71 3.98 2.51-5.28 7.76 1.06 3.43-5.46 3.38-3.21 5.68-4.28-2.17 0.71 13.46 6.36 3.46-0.15 6 5.88 5.12 12.47-1.32-0.02-3.62 9.71-3.4z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="nigde"
              data-cityid="51"
              d="m450.83 302.9l-0.46-9.24-7.82-1.73-4.26-8.32-12.49 0.89-4.23-3.18-2.54 2.64 0.56 4.41-6.25 5.72-4.45 0.91-6.18 4.99 9.02 6.15 5.36 7.04 0.68 2.22-3.71 4.72 1.73 2.42-0.95 2.25 4.09 4.64 9.2-2.22 4.21 0.57 2.65-1.7 1.31-10.19 8.29 1.01 4.34-5.74 1.88-7.98z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="ordu"
              data-cityid="52"
              d="m551.51 153.05l-3.8-3.25 0.14-3.18-4-0.88-5.32 5.66-9.6-6.28-4.58-0.13-2.18 2.71-4.79-0.41-2.87 8.27-5.3 0.29-3.92 3.06 6.69 5-0.35 2.88 3.9-0.34 1.85 1.55 6.42-1.65 7.45 7.91 9.25 1.54 1.06 1.8-1.14 5.52 5.4 3.08 1.27-3.08 3.17 2.33 1.43-0.68 2.17-6.38 3.65-1.27 1.21-2.96-3.12-8.03 1.67-2.67-0.75-4 3.91-4.79-8.88-1.59z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="rize"
              data-cityid="53"
              d="m685.49 146.43l-4.99-10.31-7.35 6.07-8.76 1.67-5.12 4.59-13.81 4.31 5.54 6.93-2.19 2.32 3.27 5.23-2.94 4.38 1.83 4.52 4.84 0.01 5.86-4.45 4.22-0.9 11.23-9.98 8.18-13.74z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="sakarya"
              data-cityid="54"
              d="m287.75 149.96l-24.56-6.66-1.27 0.97-3.47 3.77-0.66 3.5 2.45 3.03-2.89 6.81 1.46 4.27-4.12 0.94-1.33 6.07-5.99 2.77 2.09 5.17 1.23 0.79 1.37-1.72 1.27 3.57 2.46 1.38 10.71-1.18 1.02 3.18 4.32 1.74-1.1-1.52 1.67-1.42-1.74-1.02 1.15-5.76 11.12-2.99-0.21-3.71 2.58-5.17-4.08-2.32 2.17-3.46-0.62-2.12-2.6-0.62 7.55-8.16z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="samsun"
              data-cityid="55"
              d="m524.35 144.99l-5.23-1.91-1.46-4.95-9.36-4.08-4.76-0.59-5.16 6.32-4.15-0.24-9.91-10.53-0.6-8.19-5.92-5.71-14.64 5.33-6.99-2.23-3.2 4.51 1.92 5.08 3.2 2.07 0.41 5.16-0.99 1.41-2.57-1.18 0.98 3.51-1.81 1.69-5.61-1.68-2.72-4.59-6.93 1.66 0.01 2.44-2.76 1.52 4.37 5.09-1.75 2.9 19.81 3.71 1.11 3.4 4.79 0.76 2.64 3.06 14.02 1.81 6.2-7.19 4.74 0.18 0.68 3.39 2.18 1.49 19.62-2.85 2.87-8.27 4.79 0.41 1.96-2.53z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="siirt"
              data-cityid="56"
              d="m743.51 311.28l-6.29-11.05-1.89-1.1-1.64 2.01-3.95-0.66-5.81-4.74 0.46-1.91-4.69-0.66-1.32-4.63-9.88 4.41-12.07-5.85-1.71 9.07-9.7 5.76 3.06 3.52 8.77 1.62 1.48 2.17-0.91 3.38 2.28 3.07 5.05 0.25 4.39 2.89 4.7-0.56 4.65 1.98 2.77-5.85 4.1 0.73 4.59-1.87 11.02 2.9 2.5-4.65z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="sinop"
              data-cityid="57"
              d="m458.5 135.03l-0.41-5.16-3.2-2.07-1.92-5.08 3.86-4.95-5.38-1.73-7.07-10.42 1.07-4.5 3.38-0.16-0.79-1.332-4.75 0.432-1.73-3.229-2.75-0.243-1.4 3.682-4.16 3.6-7.02 0.86-4.9-1.95-10 1.13 2.24 4.9-1.71 5.91 10.22 0.39 3.55 3.16-2.52 6.34-4.02 2.48 1.05 8.9 9.41 1.28 0.2 2.05 5.42 2.63 1.37-3.27 2.32-0.39-0.48-2.07 3.71-2.13 3.87 0.17 2.54 4.52 4.54 1.86 2.88-1.87-0.98-3.51 2.57 1.18 0.99-1.38z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="sivas"
              data-cityid="58"
              d="m585.61 198.74l-6.72-0.84-2.64-3.66-3.86 1.49-8.69-3.81-1.53-13.05-4.66-1.75-3.65 1.27-2.8 6.9-3.5-2.28-2.54 3.03-4.6-2.92-0.69 3.51-5.66 4.6 1.18 2.86-10.23-3.82-5.19 1.59-10.53-1.64-1.75 4.5-4.07 3.28-1.22 5.07-12.43 1.21-6.3 3.53-3.7-0.8 2.68 2.67-1.4 3.59 4.19 4.33-0.1 4.88-2.7 4.2-6.78 0.42-2.52 9.63 10.91 10.55 15.89-0.83 5.23-2.41 5.09 1.86 5.85 6.53-7.4 10.39 0.42 11.32 10.06-2.71 10.64 1.5 8.68-10.46-1.64-11.63 9.07 1.94 1.83-5.37 14.03 0.26 6.61-3.55 2.75-12.89-4.13-6.34 1.64-1.57 1.54-8.77 4.34-3.42-3.49-4.58 2.8-3.54 3.07 1.17 5.36-2.91 2.61 0.53 0.6-2.11z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="tekirdag"
              data-cityid="59"
              d="m177.99 122.4l-0.81-0.97-6.42 2.44-2.46 3.11-9.92 3.21-1.57 7.97-6.18 2.66-7.82-4.94-4.18 0.14-0.23 2.63-0.97-3.05-2.76-1.31-3.5 2.3 1.19 3.47-2.21 6.34-8.17 3.34 2.61 3.12-1.45 7.15 2.69 5.45 5.68-0.26 2.88 1.73-3.03 7.94 14.73-7.96 5.41-7.04 2.5-6.67 8.92-1.88 4.84 2.31 3.85-0.68 1.21-4.3-1.4 0.4 0.12-4.42 1.76-1.29-0.95-3.11 2.95-2.41 1.75-6.16-1.98-5.01 2.92-4.23z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="tokat"
              data-cityid="60"
              d="m541.56 177.6l-1.86-2.18-9.59-1.85-6.31-7.22-6.42 1.65-1.85-1.55-3.9 0.34 0.35-2.88-6.48-4.35-0.42-2.04-2.97-0.54-6.03 1.03-1.01 3.44 3.03 2.26-3.45 1.96-0.06 3.33-6.32 8.53-4.87 3.04-4.31-2.75-4.85 0.44-16.68 12.42 2.49 5 3.83 1.88 13.71-0.08-0.69 2.62 2.79 6.68 3.84 1.03 6.3-3.53 12.43-1.21 1.22-5.07 4.07-3.28 1.75-4.5 10.53 1.64 5.19-1.59 10.23 3.82-1.18-2.86 5.66-4.6 1.68-7.99z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="trabzon"
              data-cityid="61"
              d="m652.08 167.24l-3.27-5.03 2.13-2.69-5.52-6.72-7.51 4.15-5.39-2.71-4.42 0.58-4.14-2.57-4.8 0.29-8.45-5.52-5.65 2.92-3.95-1.32-1 2.77-0.04 13.83 5.84 0.43 6.1 5.85 9.77-0.18 4.32-5.53 0.29 5.39 2.86 0.87 1.84 3.43 2.96-1.14-1.38-2.87 0.98-1.1 3.34 2.53 0.14 2.47 3.29-1.69 2.15 2.35 8.4 0.11-1.83-4.52 2.84-4z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="tunceli"
              data-cityid="62"
              d="m647.97 223.98l-1.59-1.46-11.8 1.54-9.18-2.35-0.95 4.33-4.4 2.01-4.71-1.31-17.49 2.2-7.64 6.48 1.62 4.07-6.43 2.22-0.66 15.05 3.97 1.18 8.48-1.61 15.58 6.72 3.23-2.56 8.62-0.67 3.29-4.14 1.25-4.56-1.87-2.31 3.59-2.91 2.93-9.74 3.66 0.54 2.99-1.8 3.06-3.96 0.07-2.97 4.16-3.59z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="sanliurfa"
              data-cityid="63"
              d="m641.55 352.37l-5.11-12.08-3.12-2.8-4.09-13.57-2.46-2.57-1.05-14.36-6.03 2.83-6.55-5.28-14.26-5.59-5.58 5.72 0.08 3.6-2.96 1.07 2.14 0.17 0.19 2.24-2.52 0.12 1.08 2.05-4.55 0.55 1.92 2.44-6.62 1.72-8.35 6.41-5.66-0.37-4.42 1.8-0.79 1.93-3.27-1.85-4.22 0.46-5.46 8.25 1.59 2.16-1.78 0.42 1.21 6.52 1.76 1.62 2.51-0.5-0.64 2.68 3.11 5.45-0.59 2.38 5.47-3.79 8.41 0.42 7.6 3.6 6.08 5.68 10.79-0.14 7.05 1.94 11.02-1.54 12.33-2.59 15.49-6.94z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="usak"
              data-cityid="64"
              d="m249.89 260.39l-4-3.57-8.03-1.56-11.99 2.47-4.42 3.51-6.22-2.32-3.21 5.39-6.84 2.06-1.37 11.98-2.41 4.5 4.31 5.54 9.45-0.05 3.74 3.05 0.03-3 5.45-3.84 9.88 2.91 0.9-7.71 2.89-2.03 4.45-0.12 0.15-3.73 6.95-13.2z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="van"
              data-cityid="65"
              d="m805.61 283.37l-2.19-1.92-2.75 1.27-2.95-1.66 0.87-10.82-2.67-4.96 2.33-4.4-4.82-6.13-0.65-3 1.53-1.29-2.28-3.42 2.23-1.89-4.36-3.64 0.38-3.84-2.15-3.69-3.86-0.56-4.21 2.66-4.11 5.61-3.65 0.12-7.13-7.28-5.37-1.02-3.85 1.77-2.48 7.52-5.46 4.91 2.8 7.71 3.76 0.02 1.9 2.66-7.87 7.84-1.93 4.44-7.91 5.55 0.57 5.63-4.47 4.05 2.68 2.27-2.21 11.25 4.49 4.23 3.69 7.92 24.93 1.41 20-3.22 1.57 3.61 4.37-0.12 7.92-3.58-1.02-2.51-6.51-1.46 4.06-10.14 1.37-0.2-0.63-1.44 1.57-0.61 0.47-5.14 4.08-3.98z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="yozgat"
              data-cityid="66"
              d="m485.42 218.1l-4.31-4.83 1.4-3.59-4.77-6.55-0.15-5.65-13.71 0.08-3.56-1.59-0.33-2.68-3.46-3-6.07 2.24-3.55-1.67 0.29 3.16 1.76 0.6-2.96 2.52 2.04 3.19-5.48 2.34-5-0.28-1.35-2.34-2.75 2.48-5.54-1.21-2.04 3.49-6.71-2.5-3.1 3.37-10.72 1.89 0.32 3.65 3.74 1.38 2.55 3.72 8.49 2.24-0.52 1.82 3 2.75-0.29 3.98 4.41 2.95-0.1 2.89 11.44 4.43 4.81 13.79 3.43-1.23 4.98 3.4 6.99-5.14-1.18-2.44 1.13-1.96 8.25-2.19 6.4-4.88 2.52-9.63 7.46-0.87 2.23-8.02z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="zonguldak"
              data-cityid="67"
              d="m336.96 132.77l-2.71-7.81-1.81 1.34-2.76-3.87-2.73 0.87-24.26 13.25 0.54 4.95-4.48 4.99 1.3 3.96 11.54-0.68 11.43 1.99 8.33-11.07 3.92-1.2 1.62-6.23z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="aksaray"
              data-cityid="68"
              d="m421.57 281.32l-2.71-2.6 0.39-5.46-6.36-3.46-0.2-9.72-4.38-0.1-6.49-9.64-3.4-0.73-0.71 1.62-5.68 1.5 2.86 4.87-5.29 9.59-12.21 0.82-0.69 8.04-5.73 10.96 3.7 1.15 0.67 12.03 2.7-2.02 4.69 3.13 3.6-0.74-0.79-3.57 5.21-2.15 3.66 1.44 6.95-2.19 2.67 3.2 9.31-3.2 6.5-6.01-0.81-4.12 2.23-2.5z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="bayburt"
              data-cityid="69"
              d="m660.33 192.98l-7.27-7.18 1.96-2.09-1.42-3.56 2.21-4-11.7 0.22-3.69-2.69-11.98 6.8-2.23 5.84 0.59 5.07-7.16 7.1 0.99 3.08 4.93 4.63 8.42-6.67 16.4 1.85 8.17-5.16 1.73-2.97z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="karaman"
              data-cityid="70"
              d="m412.99 336.91l-4.41-5.12-5.18-1.27-3.46-1.96-0.95-2.95-3.8-0.7-2.24-4.08-15.81 4.92-6.82-3.14-12.79 9.27-1.63 7.42 1.21 5.08-1.99 1.18 2.31 4.67-1.53 6.28-9.85-1.68 1.21 3.87-3.63 3.53 1.81 6.39 2.45 1.79 17.67 3.38 0.84-5.54 5.31 0.6-6.75-8.08-0.84-4.73 1.33-2.31 3.94 0.82 2.87-3.24 2.4 0.69 13.71-4 16.38-1.22 4.51-2.23-0.36-4.68 3.51-2.47z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="kirikkale"
              data-cityid="71"
              d="m410.55 203.57l-9.39-14.75-4.59-0.84-1.63-1.98-6.22-0.46-1.56 11.55-4.48 2.37 0.75 3.04-3.39 2.17-0.68-1-0.83 2.38-2.66 1.18-0.8 8-2.13 0.66-1.57 3.55 4.65-0.04-0.47 5.09 2 1.85-1.41 0.21 3.01 1.59 0.22 2.54 5.39-0.01 5.44-3.63 2.9 1.28-0.56-3.04 9.03-7.08-0.49-2.44 6.25-3.09-1.96-2.06 0.05-3.23 4.81-1.68 0.29-1.84z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="batman"
              data-cityid="72"
              d="m699.11 315.22l-2.26-8.15-8.77-1.62-3.02-3.22 4.82-3.72 1.63 0.76 1.26-2.97 1.95-0.13 1.35-4.54 0.23-14.81-4.28-1.93-4.05 0.62-5.25 2.68-4.61 6.67 1.61 9.61-8.9 14.94 2.57 1.51 2.24 10.44 2.66 2.99 12.4-0.74 8.28-8.03z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="sirnak"
              data-cityid="73"
              d="m767.16 313.18l-23.09-2.03-3.1 5.01-10.51-2.87-5.1 1.84-4.1-0.73-2.77 5.85-4.65-1.98-5.73 0.38-5.54-3.01 2.97 4.69-2.3 2.81 1.37 5.03-10.19-0.99-2.74 6.63 4.55 2.98 0.57 5.62 14.08-3.16 6.82-6.48 0.23 1.97 2.85-0.15 2.4 2.55 0.28 5.41 8.33-1.51 4.06-7.75 3.94-3.47 6.66 3.02 7.8-2.7 4.91 2.81 0.96-10.64 4.24-3.32 2.74-4.93z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="bartin"
              data-cityid="74"
              d="m361.48 115.52l-5.85-1.3-1.05-4.89-5.76 0.94-7.06 4.63-5.03 0.99-7.09 6.75 2.45 3.52 2.34-0.98 2.8 11.49 5.42 0.1 3.55-3.17 4.43 1.81 1.8-3.39-1.96-4.46 5.88-0.88-0.75-1.48 4.51-1.87 1.37-7.73z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="ardahan"
              data-cityid="75"
              d="m761.89 142.14l-5.37 1.32-1.32-3.84-2.62-0.1 2.88-2.5-6.73-3.54-4.77-6.43-1.71 1.61-2.04-1.38 1.2-4.55-8.65-0.02-3.64 5.91 3.11 6.12-0.48 3.97-2.46 4.61-5.96 5.22 0.69 4.16-2.04 4.78 2.55 3.79 4.11 0.96 2.12 2.35 0.8 5.61 3.71-0.56 6.75-3.77 2.22-7.72 1.77 0.32 2.69-3.8 6.01-0.72 5.64-6.29 5.64 3.35-0.69-6.78-3.1-2.06z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="igdir"
              data-cityid="76"
              d="m817.58 219.57l-4.7-6.96-3.9-1.64-4.28-7.05-7.85-4.04-14.28 1.51-9.93-5.57-6.66 1.31-4.08-0.96-2.95 5.82 3.61 1.28-0.45 3.01 2.9 7.15 3.4-3.39 8.15 3.79 3.17-0.63 8.63 3.05 2.86-1.34 6.57 3.6 5.54 0.46 6.43-5.88 7.8 6.54z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="yalova"
              data-cityid="77"
              d="m232.14 168.09l-1.63-2.79-2.09 2.32-3.09-0.32-3.83 2.1-10.9 0.72-8.18 5.7 7.58 3.38 1.89-5.73 4.53 0.17 3.09 2.74 3.27-0.19 1.21-2.16 3.89 1.35 4.25-7.28z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="karabuk"
              data-cityid="78"
              d="m370.03 130.79l-3.71-3.37-0.22-3.96-8.33 0.27-2.17 1.47 0.75 1.48-5.88 0.88 1.96 4.46-1.23 3.07-5-1.49-3.55 3.17-5.93-0.67-1.45 3.39-3.28 0.69-3.86 4.08-3.09 5.73 4.24 1.93 3.04-2.13 5.65 1.02 1.35 0.94-1.18 2.93 2.34-0.71 0.39 1.39-3.13 3.32 3.97 0.59 3.73-1.57 2.5 3.76 3.72-1.51 4.66-5.63 4.55 0.35 5.24-2.37 0.3-5.52-5.04-3.51-0.12-2.53 1.61-3.28 5.85-3.17 1.29-2.97z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="kilis"
              data-cityid="79"
              d="m539.44 362.04l-5.12-5.74-2.65 4.67-2.28-2.02-0.64-4.47-7.57-1.99-3.92-6.1-4.38 6.15-3.03-0.6-3.32 3.97 10.98 3.27 2.36 2.06-1.16 2.61 2.21 1.49 2.13-2.06 12.72 1.88 3.18-2.87z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="osmaniye"
              data-cityid="80"
              d="m506.52 336.99l-2.29-6.6-14.16 1.39-1.7-8.63 1.15-7.02-2.07-2.42 1.83-1.57-4.37 0.86-6.75 7.85-3.62 18.33 3.73-1.69 4.04 1.08 0.64 5.38-1.65 3.82 3.27-0.72 4.75 3.2 7 1.44 10.14-13.92z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
          <NavLink to={"/" + id}>
            <path
              id="duzce"
              data-cityid="81"
              d="m320.21 151.84l-8.62-2.07-10.56 1.42-2.28-4.7-12.59 2.06 1.57 1.81-1.83 2.95-5.61 4.59 0.34 1.49 2.15-0.52 0.62 2.12-2.17 3.46 3.91 2.05-0.4 2.77 6.07-1.71 5.3 3.77 4.55-1.42 2.27-5.6 4.83-2.05-0.71-3.36 4.73-4.07 4.41-1.97 1.26 1.43 2.57-2.08z"
              stroke="#18122B"
              strokeWidth={1}
              fill="#F0F0F0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onTouchStart={getId}
            ></path>
          </NavLink>
        </svg>
      </div>
      <a className={styles.arrow} href="#lastupdated">
        <img className={styles.icon} src={arrow} alt="" />
      </a>
    </div>
  );
}

export default TurkeyMap;
