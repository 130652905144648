import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function KastamonuMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 550 400"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Kastamonu" transform="translate(0, 0)">
            <NavLink to={"/kastamonu/" + id2}>
              <g
                id="598"
                transform="translate(325.000000, 13.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="catalzeytin"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="44 9 8 0 6 16 16 50 0 65 13 84 53 80 58 50"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kastamonu/" + id2}>
              <g
                id="609"
                transform="translate(287.000000, 93.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="taskopru"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="91 0 96 7 97 19 109 31 117 52 134 65 130 101 136 138 98 152 73 189 65 189 59 196 51 183 57 155 50 142 19 133 3 105 14 92 2 78 0 59 33 36 51 4"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kastamonu/" + id2}>
              <g
                id="600"
                transform="translate(247.000000, 71.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="devrekani"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="91 26 78 7 66 0 47 2 36 8 23 8 19 24 0 34 6 56 2 85 18 94 40 81 73 58"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kastamonu/" + id2}>
              <g
                id="596"
                transform="translate(281.000000, 15.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bozkurt"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="13 58 20 48 6 37 0 2 25 0 50 14 60 48 44 63 32 56"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kastamonu/" + id2}>
              <g
                id="1"
                transform="translate(306.000000, 13.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="abana"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="27 -1.42108547e-14 0 2 25 16"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kastamonu/" + id2}>
              <g
                id="604"
                transform="translate(203.000000, 12.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="inebolu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="78 5 47 2 10 0 0 17 68 56 85 40"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kastamonu/" + id2}>
              <g
                id="601"
                transform="translate(143.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="doganyurt"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="70 12 5 0 0 14 2 32 7 39 42 41 60 29"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kastamonu/" + id2}>
              <g
                id="597"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cide"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="148 0 137 2 65 33 57 34 54 41 0 56 12 78 15 85 21 110 57 87 70 88 98 89 88 67 94 46 145 32 143 14"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kastamonu/" + id2}>
              <g
                id="608"
                transform="translate(88.000000, 32.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="senpazar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="62 7 51 17 47 39 28 55 10 57 0 35 6 14 57 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kastamonu/" + id2}>
              <g
                id="605"
                transform="translate(180.000000, 29.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kure"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="23 0 5 12 12 26 0 42 8 52 45 78 55 71 67 76 86 66 90 50 103 50 114 44 122 34 108 23 91 39"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kastamonu/" + id2}>
              <g
                id="593"
                transform="translate(174.000000, 81.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="agli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="14 0 11 38 0 52 15 59 26 56 51 26"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kastamonu/" + id2}>
              <g
                id="607"
                transform="translate(200.000000, 99.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="seydiler"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="35 1 25 8 0 38 4 48 49 57 53 28 47 6"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kastamonu/" + id2}>
              <g
                id="595"
                transform="translate(70.000000, 39.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="azdavay"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="104 94 36 108 36 78 0 49 27 50 46 48 65 32 69 10 80 0 115 2 122 16 110 32 118 42 115 80"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kastamonu/" + id2}>
              <g
                id="606"
                transform="translate(4.000000, 87.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="pinarbasi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="17 23 0 49 6 59 21 56 26 59 66 63 102 60 102 30 66 1 53 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kastamonu/" + id2}>
              <g
                id="599"
                transform="translate(70.000000, 133.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="daday"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 17 9 58 25 62 18 71 36 83 115 93 120 85 134 14 130 4 119 7 104 0 36 14"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kastamonu/" + id2}>
              <g
                id="591"
                transform="translate(190.000000, 147.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="148 128 144 145 95 152 66 179 40 172 33 128 20 112 15 82 0 71 14 0 59 9 75 18 97 5 99 24 111 38 100 51 116 79 147 88 154 101"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kastamonu/" + id2}>
              <g
                id="603"
                transform="translate(171.000000, 218.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ihsangazi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="59 101 31 103 20 85 0 69 9 59 14 8 19 0 34 11 39 41 52 57"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kastamonu/" + id2}>
              <g
                id="594"
                transform="translate(32.000000, 204.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="arac"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="170 117 141 120 124 132 111 145 95 116 78 112 52 114 38 111 19 82 0 76 6 46 14 15 56 0 74 12 153 22 148 73 139 83 159 99"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kastamonu/" + id2}>
              <g
                id="602"
                transform="translate(383.000000, 100.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="hanonu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="76 8 70 39 38 58 21 45 13 24 1 12 0 0 8 1"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kastamonu/" + id2}>
              <g
                id="610"
                transform="translate(248.000000, 276.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="tosya"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="112 6 105 33 122 63 129 101 108 111 48 119 1 115 0 75 8 50 37 23 86 16 90 0 98 13 104 6"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default KastamonuMap;
