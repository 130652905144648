import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function KarsMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 600 410"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Kars" transform="translate(0, 0)">
            <NavLink to={"/kars/" + id2}>
              <g
                id="584"
                transform="translate(228.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="arpacay"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="82 10 134 42 100 74 101 115 78 142 46 129 43 107 0 63 33 41 49 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kars/" + id2}>
              <g
                id="583"
                transform="translate(328.000000, 42.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="akyaka"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="34 0 45 31 62 51 58 114 40 93 40 74 1 73 0 32"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kars/" + id2}>
              <g
                id="585"
                transform="translate(268.000000, 181.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="digor"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="85 17 62 0 44 15 8 21 0 71 86 138 123 130 135 118 126 92 98 60"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kars/" + id2}>
              <g
                id="582"
                transform="translate(168.000000, 115.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="merkez"
                  d="M218,41 L185,83 L162,66 L144,81 L108,87 L100,137 L35,142 L43,116 L7,85 L0,37 L106,14 L138,27 L161,0 L200,1 L201,21 L218,41 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/kars/" + id2}>
              <g
                id="589"
                transform="translate(168.000000, 63.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="susuz"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="60 0 50 1 12 17 0 89 106 66 103 43"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kars/" + id2}>
              <g
                id="588"
                transform="translate(99.000000, 152.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="selim"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="69 0 0 10 0 77 42 87 57 114 50 140 75 135 104 105 112 79 76 48"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kars/" + id2}>
              <g
                id="587"
                transform="translate(0.000000, 229.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sarikamis"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="99 0 59 49 0 64 0 72 11 95 60 105 121 171 167 166 186 120 174 58 149 63 156 37 141 10"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kars/" + id2}>
              <g
                id="586"
                transform="translate(167.000000, 252.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kagizman"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="187 67 130 112 54 126 0 143 19 97 7 35 36 5 101 0"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default KarsMap;
