import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function EskisehirMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 600 400"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Eskişehir" transform="translate(0, 0)">
            <NavLink to={"/eskisehir/" + id2}>
              <g
                id="401"
                transform="translate(0.000000, 93.944752)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="inonu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="64.9708562 1.9988245 57.9739948 0 30.9861007 1.9988245 9.99551634 12.9923593 0 38.9770778 17.9919294 61.9635596 52.9762366 61.9635596 55.9748915 39.97649 68.9690627 18.9888328"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/eskisehir/" + id2}>
              <g
                id="409"
                transform="translate(52.976237, 19.988245)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="tepebasi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="11.9946196 75.9553311 18.991481 75.9553311 32.9852039 53.9682616 65.9704078 37.9776655 64.9708562 26.9841308 106.952025 42.9747268 123.944403 44.9735513 130.941264 36.9782533 172.922433 0 180.918846 17.9894205 202.908982 64.9617963 171.922881 98.9418129 131.940816 103.938874 112.949335 96.9429884 60.9726497 105.937699 41.9811686 136.919478 0 135.920066 2.9986549 113.932997 15.9928261 92.9453394"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/eskisehir/" + id2}>
              <g
                id="405"
                transform="translate(52.976237, 116.931233)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="odunpazari"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 38.9770778 3.99820654 65.9612086 39.9820654 101.94005 61.9722013 101.94005 77.9650274 94.9441639 92.958302 97.9424006 99.9551634 66.9606208 155.930055 62.9629718 177.920191 50.9700248 171.922881 1.9988245 131.940816 6.99588576 112.949335 0 60.9726497 8.99471026 41.9811686 39.97649"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/eskisehir/" + id2}>
              <g
                id="403"
                transform="translate(117.947093, 22.986482)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="nihalgazi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="65.9704078 33.9800165 58.9735464 41.9753145 41.9811686 39.97649 0 23.985894 15.9928261 0 39.9820654 18.9888328 47.9784784 29.9823675"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/eskisehir/" + id2}>
              <g
                id="406"
                transform="translate(133.939919, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="saricakaya"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="49.9775817 56.9664983 31.9856523 52.9688493 23.9892392 41.9753145 0 22.9864818 13.9937229 5.99647351 41.9811686 0 68.9690627 0 85.9614405 7.99529801 91.9587503 19.988245"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/eskisehir/" + id2}>
              <g
                id="396"
                transform="translate(224.899118, 37.977666)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="alpu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="8.99596471 0 45.9793752 3.997649 68.9690627 8.99471026 85.9614405 67.9600331 57.9739948 131.922417 5.9973098 129.923593 0 80.9523923 30.9861007 46.9723758"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/eskisehir/" + id2}>
              <g
                id="404"
                transform="translate(270.878493, 34.979429)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="mihalliccik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 6.99588576 26.9878941 0 40.981617 7.99529801 54.9753399 0 94.9574052 1.9988245 104.952922 4.99706126 98.9556118 6.99588576 99.9551634 14.9911838 153.930952 17.9894205 164.92602 13.9917715 158.92871 24.9853063 164.92602 25.9847185 176.920639 23.985894 184.917052 46.9723758 193.913017 65.9612086 213.90405 65.9612086 208.906291 75.9553311 224.899118 77.9541556 200.909878 109.935348 161.927365 123.927119 141.936332 107.936523 121.945299 135.920066 81.963234 134.920654 75.9659242 85.9494536 39.9820654 70.9582698 22.9896876 11.992947"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/eskisehir/" + id2}>
              <g
                id="397"
                transform="translate(282.873112, 105.937699)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="beylikova"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="27.9874457 0 0 63.9623841 21.9901359 90.9465149 69.9686144 63.9623841 63.9713046 14.9911838"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/eskisehir/" + id2}>
              <g
                id="408"
                transform="translate(288.870422, 142.915952)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sivrihisar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="123.944403 0 103.95337 27.983543 63.9713046 26.9841308 15.9928261 53.9682616 5.9973098 66.9606208 9.99551634 76.9547433 0 86.9488659 22.9896876 102.939462 28.9869974 123.927119 46.9789268 137.918891 40.981617 153.909487 52.9762366 186.890091 122.944851 212.87481 158.92871 221.86952 174.921536 205.878924 159.928261 194.885389 148.933193 144.914776 154.930503 63.9623841 161.927365 39.97649 143.935435 15.990596"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/eskisehir/" + id2}>
              <g
                id="399"
                transform="translate(432.805857, 144.914776)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gunyuzu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="38.9825137 0 0 13.9917715 17.9919294 37.9776655 10.995068 61.9635596 4.99775817 142.915952 15.9928261 192.886564 30.9861007 203.880099 68.9690627 173.897732 84.9618889 174.897144 99.9551634 153.909487 78.9645791 130.923005 90.9591987 91.9459271 80.9636823 78.9535678 60.9726497 23.985894"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/eskisehir/" + id2}>
              <g
                id="402"
                transform="translate(207.906740, 167.901258)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="mahmudiye"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="96.9565085 28.9829553 74.9663725 1.9988245 22.9896876 0 0.999551634 11.992947 8.99596471 33.9800165 0 77.9541556 31.9856523 83.9506291 80.9636823 61.9635596 90.9591987 51.9694371 86.9609922 41.9753145"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/eskisehir/" + id2}>
              <g
                id="398"
                transform="translate(203.908533, 229.864818)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cifteler"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="84.9618889 -5.67115316e-14 35.9838588 21.9870695 3.99820654 15.990596 0 53.9682616 26.9878941 88.9476904 38.9825137 90.9465149 53.9757882 89.9471026 97.9560601 64.9617963 125.943506 66.9606208 131.940816 50.9700248 113.948886 36.9782533 107.951576 15.990596"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/eskisehir/" + id2}>
              <g
                id="407"
                transform="translate(77.965027, 179.894205)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="seyitgazi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="14.9932745 38.9770778 13.9937229 64.9617963 0 80.9523923 26.9878941 125.925944 45.9793752 130.923005 59.973098 152.910074 87.9605438 149.911838 99.9551634 120.928882 125.943506 103.938874 129.941712 65.9612086 138.937677 21.9870695 130.941264 0 74.9663725 3.997649 67.9695111 34.9794288 52.9762366 31.981192 36.9834105 38.9770778"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/eskisehir/" + id2}>
              <g
                id="400"
                transform="translate(165.925571, 283.833079)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="han"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="37.9829621 0 11.9946196 16.9900083 0 45.9729636 17.9919294 55.9670861 52.9762366 51.9694371 64.9708562 34.9794288"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default EskisehirMap;
