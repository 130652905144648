import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function NevsehirMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 500 380"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Nevşehir" transform="translate(0, 0)">
            <NavLink to={"/nevsehir/" + id2}>
              <g
                id="801"
                transform="translate(80.000000, 293.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="derinkuyu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="108 65 76 77 19 73 1.11022302e-16 59 42 29 74 28 91 0 99 9 91 36 108 53"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/nevsehir/" + id2}>
              <g
                id="805"
                transform="translate(171.000000, 215.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="urgup"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="17 131 18 113 62 77 68 57 55 42 55 13 40 0 5 44 0 78 8 87 0 114"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/nevsehir/" + id2}>
              <g
                id="800"
                transform="translate(140.000000, 102.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="avanos"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="71 113 36 157 6 135 0 84 24 32 15 18 80 0 86 8 86 35"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/nevsehir/" + id2}>
              <g
                id="804"
                transform="translate(106.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kozakli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="114 102 85 74 88 44 63 32 18 0 6 18 0 45 35 43 35 53 17 82 49 120"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/nevsehir/" + id2}>
              <g
                id="803"
                transform="translate(55.000000, 82.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="hacibektas"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="68 0 51 12 54 40 34 39 32 48 38 60 19 66 13 83 0 100 17 105 57 109 71 98 85 104 109 52 100 38"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/nevsehir/" + id2}>
              <g
                id="802"
                transform="translate(0.000000, 175.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gulsehir"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="140 11 126 5 112 16 72 12 55 7 21 0 0 27 21 37 20 88 52 104 66 87 55 76 58 70 146 62"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/nevsehir/" + id2}>
              <g
                id="799"
                transform="translate(52.000000, 243.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="acigol"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="28 109 4 91 17 65 14 48 0 36 14 19 3 8 6 2 26 2.22044605e-16 64 38 60 62 70 79"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/nevsehir/" + id2}>
              <g
                id="798"
                transform="translate(78.000000, 237.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="44 85 34 68 38 44 0 6 68 0 98 22 93 56 76 84"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default NevsehirMap;
