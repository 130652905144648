import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function BurdurMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 550 320"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Burdur" transform="translate(0, 0)">
            <NavLink to={"/burdur/" + id2}>
              <g
                id="227"
                transform="translate(0.000000, 237.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="altinyayla"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 26 15 0 70 9 78 39 62 47 26 74 11 76 8 68 21 46 10 37 8 31"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/burdur/" + id2}>
              <g
                id="231"
                transform="translate(15.000000, 185.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="golhisar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 52 15 25 33 0 43 6 55 61"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/burdur/" + id2}>
              <g
                id="229"
                transform="translate(48.000000, 169.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cavdir"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="30 107 52 117 80 102 83 59 42 42 10 0 0 16 10 22 22 77"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/burdur/" + id2}>
              <g
                id="234"
                transform="translate(58.000000, 140.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="tefenni"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="73 88 118 65 113 54 85 41 48 1 22 0 0 29 32 71"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/burdur/" + id2}>
              <g
                id="232"
                transform="translate(79.000000, 121.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="karamanli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="1 19 0 10 92 0 96 5 87 47 92 73 64 60 27 20"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/burdur/" + id2}>
              <g
                id="235"
                transform="translate(52.000000, 16.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yesilova"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="27 115 3 99 8 71 0 48 7 43 19 40 99 6 125 0 142 3 125 37 133 59 134 84 119 105"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/burdur/" + id2}>
              <g
                id="225"
                transform="translate(171.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 121 4 126 19 113 52 118 61 141 72 157 86 144 107 129 109 102 136 83 145 49 122 9 119 4 110 0 78 10 46 18 40 34 32 34 28 25 23 19 6 53 14 76 15 100"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/burdur/" + id2}>
              <g
                id="133"
                transform="translate(307.000000, 49.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="aglasun"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="9 0 33 14 73 29 77 54 25 59 17 43 0 34"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/burdur/" + id2}>
              <g
                id="230"
                transform="translate(278.000000, 83.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="celtikci"
                  d="M29,0 C29,0 2,18 2,19 C2,20 0,46 0,46 L15,46 L54,25 L46,9 L29,0 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/burdur/" + id2}>
              <g
                id="228"
                transform="translate(240.000000, 103.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bucak"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="3 54 17 41 38 26 53 26 92 5 144 0 159 24 162 31 159 37 164 52 162 59 165 61 171 57 181 62 183 66 155 102 135 104 98 94 76 99 51 93 0 71"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/burdur/" + id2}>
              <g
                id="233"
                transform="translate(166.000000, 113.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kemer"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="74 61 10 92 5 81 0 55 9 13 24 0 57 5 66 27 77 44"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default BurdurMap;
