import React, { useState, useEffect } from "react";

import MainPopup from "../popups/MainPopup";
import styles from "../../css/cookies/cookies.module.css";
import { NavLink } from "react-router-dom";

function Cookies() {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const [showPopup, setShowPopup] = useState(
    sessionStorage.getItem("ShowPopup") !== "false"
  );

  const handleCookiesAccept = () => {
    setCookiesAccepted(true);
    document.cookie =
      "CookieAccept=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
  };

  const handleCookiesDecline = () => {
    setCookiesAccepted(true);
  };

  useEffect(() => {
    // State değeri çerezden alınan değer

    const showCookie = document.cookie
      .split("; ")
      .find((row) => row.startsWith("CookieAccept="))
      ? document.cookie
          .split("; ")
          .find((row) => row.startsWith("CookieAccept="))
          .split("=")[1] === "true"
      : false;

    setCookiesAccepted(showCookie);
  }, []);

  return (
    <div>
      {showPopup && <MainPopup onClose={() => setShowPopup(false)} />}
      {!cookiesAccepted && (
        <div className={styles.cookies_container}>
          <p className={styles.info}>
            Size daha iyi hizmet sunabilmek için çerezler kullanıyoruz. Detaylı
            bilgiye  <NavLink style={{color:"#00abcd",}} to={"/cerez-politasi"}>Çerez Politikası </NavLink> sayfamızdan erişebilirsiniz.
          </p>
          <div className={styles.buttons}>
            <button className={styles.accept} onClick={handleCookiesAccept}>
              <span className={styles.text}> Kabul Et</span>
            </button>
            <button onClick={handleCookiesDecline} className={styles.reject}>
              <span className={styles.text}> Reddet</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Cookies;
