import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function VanMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 900 580"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Van" transform="translate(0, 0)">
            <NavLink to={"/van/" + id2}>
              <g
                id="1014"
                transform="translate(0.000000, 324.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gevas"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="7 21 30 0 50 18 53 53 108 52 170 41 165 70 74 108 20 118 0 97"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/van/" + id2}>
              <g
                id="1008"
                transform="translate(20.000000, 432.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bahcesaray"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="54 0 48 24 66 40 58 71 42 84 4 87 0 10"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/van/" + id2}>
              <g
                id="1011"
                transform="translate(62.000000, 394.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="catak"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="103 0 122 62 95 63 96 117 145 176 21 170 0 122 16 109 24 78 6 62 12 38"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/van/" + id2}>
              <g
                id="1015"
                transform="translate(157.000000, 293.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gurpinar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="50 277 69 274 157 253 165 95 208 45 173 10 111 0 41 44 13 72 8 101 27 163 0 164 1 218"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/van/" + id2}>
              <g
                id="172"
                transform="translate(314.000000, 323.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="baskale"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 223 62 185 74 227 158 183 153 166 109 167 159 11 115 0 51 15 8 65"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/van/" + id2}>
              <g
                id="1019"
                transform="translate(330.000000, 178.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="saray"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 125 74 0 99 145 35 160"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/van/" + id2}>
              <g
                id="1018"
                transform="translate(264.000000, 129.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ozalp"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="4 164 0 88 26 36 101 0 140 49 66 174"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/van/" + id2}>
              <g
                id="1016"
                transform="translate(149.000000, 205.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ipekyolu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="115 12 83 17 60 0 28 102 0 103 1 116 49 132 119 88"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/van/" + id2}>
              <g
                id="1020"
                transform="translate(89.000000, 167.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="tusba"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="120 38 113 0 96 11 66 35 54 31 42 35 41 52 21 65 0 109 7 112 17 100 25 103 18 107 57 128 60 141 88 140"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/van/" + id2}>
              <g
                id="1012"
                transform="translate(108.000000, 321.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="edremit"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="42 0 40 17 24 19 3 40 0 55 62 44 90 16"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/van/" + id2}>
              <g
                id="1010"
                transform="translate(242.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="caldiran"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="123 129 123 64 73 0 11 7 0 35 48 165"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/van/" + id2}>
              <g
                id="1017"
                transform="translate(197.000000, 35.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="muradiye"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="45 0 18 27 0 107 29 119 5 132 12 170 35 187 67 182 93 130"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/van/" + id2}>
              <g
                id="1013"
                transform="translate(41.000000, 10.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ercis"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="174 52 86 0 55 24 31 39 49 49 43 90 0 139 37 156 71 150 87 150 104 133 133 130 156 132"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default VanMap;
