import React, { useContext, useEffect } from "react";
import styles from "../../css/blog/blog.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../context/Context";
import { Link } from "react-router-dom";
import slugify from "slugify"; 

function Blog() {
  const { articles, dispatch } = useContext(Context);
  const myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    "Token 8f6c14510d09a853e6145cc52567a9bebe86c172"
  );

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  useEffect(() => {
    fetch("https://api.ibrahimaluc.com/api/latestarticles/", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: "GET_ARTİCLE",
          payload: data.articles,
        });
      })
      .catch((error) => {
        console.error("Hata:", error);
      });
  }, []);

  let url = "https://api.ibrahimaluc.com";
  return (
    <div id="blog" className={styles.container}>
      <h1 className={styles.header}>Blog</h1>
      <hr className={styles.hr} />
      <div className={styles.grid_container}>
        {articles.map((article, index) => {
          const cardClassName =
            index === 0 || index === 2 ? styles.cardRight : "";

            const encodedTitle = slugify(article.title, {
              remove: /[*+~.()'"!:@]/g, // Remove special characters
              lower: true, // Convert to lowercase
            });
            const articleUrl = `/blog/${encodedTitle}`;

          return (
            <Link
              to={{ pathname: articleUrl, state: { article } }}
              key={index}
              className={`${styles.card} ${cardClassName}`}
            >
              <div className={styles.upper_part}>
                <div className={styles.author} >
                  <img
                    className={styles.author_picture}
                    src={url + article.author.photo}
                    alt=""
                  />
                  <p className={styles.author_name}> {article.author.name}</p>
                </div>
                <div className={styles.time_area}>
                  <span className={styles.time}>{article.updated_at} |</span>                  
                  <span className={styles.read_time}>
                  <FontAwesomeIcon className={styles.icon} icon={faClock} />
                    {article.avg_read_minute} dk
                  </span>
                </div>
              </div>

              <h2 className={styles.blog_header}>{article.title}</h2>

              <div
                  className={styles.blog}
                  dangerouslySetInnerHTML={{
                    __html: article.html,
                  }}
                />
              <button className={styles.button}>
                Devamını Oku{"     "}
                <FontAwesomeIcon icon={faArrowRight} fade size="xl" />
              </button>
            </Link>
          );
        })}

        {articles.length === 2 && (
          <div
            className={`${styles.card} ${styles.blurEffect} ${styles.centerLastCard}`}
          >
            <div className={styles.upper_part}>
              <div className={styles.author}>
                <img
                  className={styles.author_picture}
                  src="../../assets/tick.svg"
                  alt="Author"
                />
                <p className={styles.author_name}>| Mustafa Kemal Atatürk</p>
              </div>
              <div>
                <span className={styles.time}>29.10.2023 |</span>
                <FontAwesomeIcon className={styles.icon} icon={faClock} />
                <span className={styles.read_time}>23 dk</span>
              </div>
            </div>

            <h2 className={styles.blog_header}>Büyük Cumhuriyet Tarihi</h2>

            <div className={styles.blog1}>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Velit
              exercitationem iure earum repellat sapiente ipsa voluptatibus
              dolor vel alias? Adipisci quae ea at, ex deleniti tempore
              voluptates, quis autem sint rem excepturi. Expedita facere dolore
              quia? Odio modi impedit, eos fugit quod adipisci molestiae
              mollitia eveniet vel maxime atque aliquam quisquam expedita
              perspiciatis, aliquid illum earum nobis eius? Deleniti, nisi nobis
              corporis quos aperiam pariatur numquam voluptatem est facilis eos
              quo explicabo eaque ipsum temporibus quibusdam veniam dolore
             
            </div>
            <button className={styles.button_all}>
              <Link to="/blog" className={styles.link}>
                Tümünü Gör{"        "}
                <FontAwesomeIcon icon={faArrowRight} fade size="xl" />
              </Link>
            </button>
          </div>
        )}
        {articles.length === 3 && (
          <div className={`${styles.card} ${styles.blurEffect} `}>
            <div className={styles.upper_part}>
              <div className={styles.author}>
                <img
                  className={styles.author_picture}
                  src="../../assets/tick.svg"
                  alt="Author"
                />
                <p className={styles.author_name}>| Mustafa Kemal Atatürk</p>
              </div>
              <div>
                <span className={styles.time}>29.10.2023 |</span>
                <FontAwesomeIcon className={styles.icon} icon={faClock} />
                <span className={styles.read_time}>23 dk</span>
              </div>
            </div>

            <h2 className={styles.blog_header}>Büyük Cumhuriyet Tarihi</h2>

            <div className={styles.blog1}>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Velit
              exercitationem iure earum repellat sapiente ipsa voluptatibus
              dolor vel alias? Adipisci quae ea at, ex deleniti tempore
              voluptates, quis autem sint rem excepturi. Expedita facere dolore
              quia? Odio modi impedit, eos fugit quod adipisci molestiae
              mollitia eveniet vel maxime atque aliquam quisquam expedita
              perspiciatis, aliquid illum earum nobis eius? Deleniti, nisi nobis
              corporis quos aperiam pariatur numquam voluptatem est facilis eos
              quo explicabo eaque ipsum temporibus quibusdam veniam dolore
              
            </div>
            <button className={styles.button_all}>
              <Link to="/blog" className={styles.link}>
                Tümünü Gör{"        "}
                <FontAwesomeIcon icon={faArrowRight} fade size="xl" />
              </Link>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Blog;
