import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function BatmanMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 600 370"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Batman" transform="translate(0, 0)">
            <NavLink to={"/batman/" + id2}>
              <g
                id="181"
                transform="translate(54.000000, 268.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="hasankeyf"
                  d="M175,25 L161,4 L128,0 L63,15 L0,15 L10,43 L95,41 L109,32 L128,48 L175,25 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/batman/" + id2}>
              <g
                id="180"
                transform="translate(12.000000, 278.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gercus"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="170 38 133 74 34 86 0 18 11 0 42 5 52 32 136 31 151 22"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/batman/" + id2}>
              <g
                id="178"
                transform="translate(0.000000, 208.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="23 70 0 57 31 0 65 6 61 40 117 75 54 75"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/batman/" + id2}>
              <g
                id="179"
                transform="translate(31.000000, 171.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="besiri"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="86 112 152 97 157 63 86 50 67 29 86 11 18 0 0 37 34 43 30 77"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/batman/" + id2}>
              <g
                id="182"
                transform="translate(35.000000, 41.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kozluk"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="14 130 25 104 1 65 66 52 118 0 136 64 111 118 82 141"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/batman/" + id2}>
              <g
                id="183"
                transform="translate(35.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="sason"
                  d="M1,106 C0.999998152,106.000002 27,41 27,41 L47,26 L79,0 L103,9 L118,41 L66,93 C66,93 1.00000185,105.999998 1,106 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default BatmanMap;
