import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function BursaMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 550 350"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Bursa" transform="translate(0, 0)">
            <NavLink to={"/bursa/" + id2}>
              <g
                id="243"
                transform="translate(0.000000, 46.281356)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="karacabey"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="25 0.756421457 24 12.7660667 0 43.7909837 8 62.8062555 6 78.8191158 9 91.8295649 10 96.8335838 20 105.840818 56 113.847248 96 74.8159008 92 65.8086668 138 78.8191158 146 49.7958064 124 32.7821422 129 17.7700856"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bursa/" + id2}>
              <g
                id="246"
                transform="translate(122.941860, 56.440678)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="mudanya"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="6.05813953 7.61076359 57.0581395 0.605137166 107.05814 18.6196051 95.0581395 36.634073 23.0581395 39.6364844 1.05813953 22.6228202"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bursa/" + id2}>
              <g
                id="238"
                transform="translate(228.965116, 13.545763)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gemlik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="1.03488372 61.5145204 67.0348837 75.5257732 97.0348837 62.5153241 68.0348837 49.5048751 67.0348837 6.47031277 33.0348837 0.465490128 11.0348837 21.4823694 44.0348837 39.4968373"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bursa/" + id2}>
              <g
                id="250"
                transform="translate(295.511628, 16.932203)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="orhangazi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0.488372093 3.0838721 45.4883721 1.08226455 66.4883721 1.08226455 72.4883721 21.09834 44.4883721 15.0935174 26.4883721 22.0991438 35.4883721 43.1160231 73.4883721 54.1248646 72.4883721 67.1353136 30.4883721 59.1288835 1.48837209 46.1184344"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bursa/" + id2}>
              <g
                id="242"
                transform="translate(360.930233, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="iznik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="1.06976744 18.0144679 30.0697674 19.0152717 66.0697674 0 96.0697674 16.0128604 114.069767 26.0208981 124.069767 32.0257208 80.0697674 77.0618906 7.06976744 84.067517 8.06976744 71.057068 45.0697674 70.0562642 58.0697674 54.0434038 35.0697674 38.0305434 7.06976744 38.0305434"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bursa/" + id2}>
              <g
                id="252"
                transform="translate(315.813953, 75.630508)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yenisehir"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="125.186047 1.43138214 52.1860465 8.43700856 10.1860465 0.430578368 0.186046512 4.43379346 25.1860465 59.478001 95.1860465 85.4988992 129.186047 40.4627293 130.186047 23.4490652"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bursa/" + id2}>
              <g
                id="241"
                transform="translate(302.279070, 134.328814)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="inegol"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="38.7209302 0.779695964 0.720930233 49.8190809 28.7209302 96.8568583 116.72093 112.869719 108.72093 26.8005941"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bursa/" + id2}>
              <g
                id="245"
                transform="translate(256.034884, 79.016949)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kestel"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="59.9651163 1.04735279 50.9651163 5.05056788 51.9651163 22.064232 34.9651163 43.0811113 13.9651163 47.0843264 0.965116279 76.1076359 46.9651163 105.130945 84.9651163 56.0915604"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bursa/" + id2}>
              <g
                id="239"
                transform="translate(258.290698, 83.532203)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gursu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="48.7093023 0.535313647 37.7093023 5.53933252 0.709302326 32.5610344 11.7093023 42.5690722 32.7093023 38.5658571 49.7093023 17.5489778"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bursa/" + id2}>
              <g
                id="248"
                transform="translate(137.604651, 74.508495)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="nulufer"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="105.395349 51.8710231 121.395349 41.8409018 158.395349 14.7595775 92.3953488 0.717408668 80.3953488 18.566256 8.39534884 21.5686673 0.395348837 50.5919768 21.3953488 51.5927806 16.3953488 63.6024258 27.3953488 85.6201089 63.3953488 87.6217164 80.3953472 51.8710231"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bursa/" + id2}>
              <g
                id="247"
                transform="translate(19.174419, 120.783051)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="mustafakemalpasa"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="135.825581 17.32787 146.825581 40.3463568 155.825581 78.3769002 139.825581 119.409855 128.825581 148.433164 115.825581 143.429146 76.8255814 148.433164 58.8255814 120.410659 23.8255814 103.396995 2.8255814 54.3576096 0.825581395 31.3391228 36.8255814 39.345553 76.8255814 0.314205836 95.8255814 27.3359077 121.825581 24.3334964"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bursa/" + id2}>
              <g
                id="249"
                transform="translate(157.906977, 159.162712)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="orhaneli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="43.0930233 2.96749956 8.09302326 0.965892015 17.0930233 39.9972392 1.09302326 81.030194 50.0930233 84.0326053 82.0930233 126.066364 105.093023 109.0527 86.0930233 33.9924166"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bursa/" + id2}>
              <g
                id="253"
                transform="translate(242.500000, 115.138983)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yildirim"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="16.5 0.954254761 0.5 10.9622925 14.5 39.985602 27.5 10.9622925"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bursa/" + id2}>
              <g
                id="251"
                transform="translate(200.767442, 125.655309)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="osmangazi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="17.4943175 0.352146956 0.23255814 36.4749021 43.2325581 67.4998191 102.232558 58.4925851 56.2325581 29.4692756 42.136478 0.0866301504"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bursa/" + id2}>
              <g
                id="244"
                transform="translate(243.627907, 183.996610)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="keles"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="59.372093 0.151284291 0.372093023 9.15851826 19.372093 84.2188013 53.372093 102.233269 78.372093 86.2204089 87.372093 47.1890617"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bursa/" + id2}>
              <g
                id="240"
                transform="translate(201.895349, 267.528814)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="harmancik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="61.1046512 0.686597938 38.1046512 17.7002621 0.104651163 43.7211602 15.1046512 56.7316093 61.1046512 64.7380395 80.1046512 49.7259829 95.1046512 18.7010659"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bursa/" + id2}>
              <g
                id="237"
                transform="translate(147.755814, 239.308475)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="buyukorhan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="60.244186 3.88684257 11.244186 0.884431242 0.244186047 29.9077407 7.24418605 59.9318539 28.244186 76.9455181 54.244186 71.9414992 92.244186 45.9206011"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default BursaMap;
