import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function BilecikMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 400 290"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Bilecik" transform="translate(0, 0)">
            <NavLink to={"/bilecik/" + id2}>
              <g
                id="190"
                transform="translate(6.000000, 175.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bozuyuk"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="84 4 74 0 63 18 0 20 2 40 5 93 19 99 64 115 81 114 63 91 73 65 94 54 121 52 116 34 95 24"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bilecik/" + id2}>
              <g
                id="193"
                transform="translate(30.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="osmaneli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="44 5.68434189e-14 0 45 5 67 63 78 83 82 112 56 94 55 76 35 59 35 47 21"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bilecik/" + id2}>
              <g
                id="189"
                transform="translate(0.000000, 67.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="35 0 34 17 0 62 2 84 32 74 80 108 90 112 92 94 105 83 120 60 94 29 93 11"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bilecik/" + id2}>
              <g
                id="194"
                transform="translate(2.000000, 141.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="pazaryeri"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="78 34 30 0 0 10 4 54 67 52"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bilecik/" + id2}>
              <g
                id="195"
                transform="translate(90.000000, 122.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sogut"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="74 10 53 0 30 5 15 28 2 39 0 57 11 77 32 87 37 105 44 107 51 107 65 85 56 65 58 37"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bilecik/" + id2}>
              <g
                id="192"
                transform="translate(146.000000, 132.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="inhisar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="18 0 2 27 0 55 9 75 42 59 41 48 57 24 71 7"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bilecik/" + id2}>
              <g
                id="196"
                transform="translate(164.000000, 78.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yenipazar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="43 5.68434189e-14 29 20 4 30 0 54 53 61 81 55 70 38 67 17 51 4"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bilecik/" + id2}>
              <g
                id="191"
                transform="translate(93.000000, 50.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="golpazari"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="49 6 20 32 0 28 1 46 27 77 50 72 71 82 75 58 100 48 114 28 97 20 99 4 85 0"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default BilecikMap;
