import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function MalatyaMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 600 430"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Malatya" transform="translate(0, 0)">
            <NavLink to={"/malatya/" + id2}>
              <g
                id="730"
                transform="translate(328.000000, 253.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="puturge"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="167 62 160 52 99 40 94 37 114 23 95 0 71 33 0 75 76 109 88 121 147 94"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/malatya/" + id2}>
              <g
                id="726"
                transform="translate(427.000000, 264.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="doganyol"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="61 41 69 33 65 1 51 0 21 13 0 29"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/malatya/" + id2}>
              <g
                id="728"
                transform="translate(373.000000, 241.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kale"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="50 12 36 0 17 8 0 4 26 45"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/malatya/" + id2}>
              <g
                id="723"
                transform="translate(253.000000, 226.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="battalgazi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0.486776723 0 45 0 60 9.64285714 91 9.64285714 97 18.3214286 120.603164 19.7326418 146.456886 60.0072656 75.3207564 101.825245 47.6553118 87.6319728"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/malatya/" + id2}>
              <g
                id="214"
                transform="translate(104.000000, 216.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="akcadag"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="149 9 143 10 143 3 91 0 29 12 0 61 33 107 95 107 121 70 125 40"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/malatya/" + id2}>
              <g
                id="732"
                transform="translate(199.000000, 225.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yesilyurt"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="30 31 26 61 0 98 37 123 102 89 54.1168816 0.0563263571"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/malatya/" + id2}>
              <g
                id="725"
                transform="translate(110.000000, 323.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="dogansehir"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="126 25 116 36 133 58 132 70 123 79 42 105 0 96 2 43 27 0 89 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/malatya/" + id2}>
              <g
                id="724"
                transform="translate(0.000000, 134.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="darende"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="104 143 0 102 21 65 80 0 149 19 122 60 133 94"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/malatya/" + id2}>
              <g
                id="731"
                transform="translate(195.000000, 162.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yazihan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 54 1 32 35 0 90 21 87 31 74 34 87 40 100 54 89 53 89 58 52 57"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/malatya/" + id2}>
              <g
                id="729"
                transform="translate(61.000000, 49.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="kuluncak"
                  d="M88,104 C87.9999986,103.999998 62,18 62,18 L0,0 L0,19 L19,85 C19,85 88.0000014,104.000002 88,104 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/malatya/" + id2}>
              <g
                id="727"
                transform="translate(122.000000, 24.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="hekimhan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="1 43 17 5 49 4 77 0 94 19 125 108 108 138 74 170 73 192 11 204 0 170 27 129"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/malatya/" + id2}>
              <g
                id="722"
                transform="translate(199.000000, 18.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="arguvan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 6 42 0 73 12 76 51 94 51 93 69 125 110 121 113 113 114 111 129 86 165 31 144 48 114 17 25"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/malatya/" + id2}>
              <g
                id="673"
                transform="translate(241.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="arapgir"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 18 20 0 36 3 51 10 91 5 119 32 112 32 122 44 108 57 103 51 101 53 103 59 101 68 120 99 124 127 83 128 51 87 52 69 34 69 31 30"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default MalatyaMap;
