import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function MusMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 550 350"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Muş" transform="translate(0, 0)">
            <NavLink to={"/mus/" + id2}>
              <g
                id="796"
                transform="translate(0.000000, 63.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="varto"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="150 63 110 9 75 0 27 4 0 24 22 64 70 103 214 90 186 62"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mus/" + id2}>
              <g
                id="792"
                transform="translate(186.000000, 67.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="bulanik"
                  d="M0,58 C0,58 25,43 25,43 C25,43 76,30 76,30 L94,0 L141,29 L179,81 L179,111 L118,131 L104,155 L32,130 L28,86 L0,58 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/mus/" + id2}>
              <g
                id="794"
                transform="translate(173.000000, 197.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="korkut"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="45 0.105702639 38 32.0559602 6 13.0854948 0 30.0590691 12 52.0248712 1 66.0031089 86 68 97 58.0155445 77 48.031089 78 35.0512969 117 25.0668414"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mus/" + id2}>
              <g
                id="793"
                transform="translate(164.000000, 263.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="haskoy"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="10 0 0 45 67 41 88 36 95 2"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mus/" + id2}>
              <g
                id="791"
                transform="translate(24.000000, 153.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="140 155 104 168 72 194 41 127 13 125 0 95 15 35 46 13 190 0 194 43 187 76 155 57 149 74 161 96 150 110"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mus/" + id2}>
              <g
                id="795"
                transform="translate(280.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="malazgirt"
                  d="M0,67 L12,46 L51,0 L115,61 L159,173 L111,183 L85,178 L85,148 L47,96 L0,67 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default MusMap;
