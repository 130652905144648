import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function IstanbulMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <svg
        width="100%"
        height="100%"
        style={{ position: "relative" }}
        viewBox="0 0 480 270"
        preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0, 0)">
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="494"
              transform="translate(256.000000, 164.000000)"
              fill="#f0f0f0"
              stroke="#000000"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="besiktas"
                points="17 8 14 9 11 18 1 22 0 21 4 12 6 13 7 10 4 5 6 1.42108547e-13 13 6"
                fill="#f0f0f0"
              ></polygon>
            </g>
          </NavLink>

          <NavLink to={"/istanbul/" + id2}>
            <g
              id="508"
              transform="translate(247.000000, 161.000000)"
              fill="#f0f0f0"
              stroke="#000000"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="kagithane"
                points="12 5 1 1.42108547e-13 0 4 4 8 0 17 2 19 6 14 9 12 12 12 11 10 11 8"
                fill="#f0f0f0"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="497"
              transform="translate(243.000000, 178.000000)"
              fill="#f0f0f0"
              stroke="#000000"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="beyoglu"
                points="14 8 10 14 8 12 4 9 2 7 0 3 3 2 4 1.42108547e-13 6 2"
                fill="#f0f0f0"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="504"
              transform="translate(239.000000, 185.000000)"
              fill="#f0f0f0"
              stroke="#000000"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="fatih"
                points="6 1.42108547e-13 7 3 9 5 13 8 14 9 16 8 16 11 14 13 4 13 2 17 0 17 0 12 2 5"
                fill="#f0f0f0"
              ></polygon>
            </g>
          </NavLink>

          <NavLink to={"/istanbul/" + id2}>
            <g
              id="523"
              transform="translate(231.000000, 188.000000)"
              fill="#f0f0f0"
              stroke="#000000"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="zeytinburnu"
                points="9 1 8 2 6 1.42108547e-13 3 1 3 4 2 8 0 16 3 17 8 14 8 9 10 2"
                fill="#f0f0f0"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="493"
              transform="translate(229.000000, 173.000000)"
              fill="#f0f0f0"
              stroke="#000000"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="bayrampasa"
                points="8 8 4 2 0 1.42108547e-13 0 2 2 6 1 10 2 13 5 16 8 15 10 17 11 16"
                fill="#f0f0f0"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="505"
              transform="translate(228.000000, 167.000000)"
              fill="#f0f0f0"
              stroke="#000000"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="gaziosmanpasa"
                points="11 1.42108547e-13 8 1 4 1 0 4 1 6 5 8 9 14 15 12 14 9 10 8"
                fill="#f0f0f0"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="501"
              transform="translate(218.000000, 163.000000)"
              fill="#f0f0f0"
              stroke="#000000"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="esenler"
                points="10 8 4 1.42108547e-13 0 12 0 13 3 13 5 11 7 11 5 17 6 21 9 23 10 24 12 24 13 25 12 28 13 29 16 29 16 26 13 23 12 20 13 16 11 12 11 10"
                fill="#f0f0f0"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="506"
              transform="translate(224.000000, 186.000000)"
              fill="#f0f0f0"
              stroke="#000000"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="gungoren"
                points="9 10 6 11 5 7 3 10 0 6 0 2 3 3 3 1.42108547e-13 4 1 6 1 7 2 6 5 7 6 10 6"
                fill="#f0f0f0"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="490"
              transform="translate(211.000000, 189.000000)"
              fill="#f0f0f0"
              stroke="#000000"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="bahcelievler"
                points="13 2 0 1.42108547e-13 0 4 3 11 11 12 19 8 18 4 16 7"
                fill="#f0f0f0"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="489"
              transform="translate(211.000000, 174.000000)"
              fill="#f0f0f0"
              stroke="#000000"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="bagcilar"
                points="7 1 2 1.42108547e-13 0 6 0 15 13 17 13 14 16 15 16 12 13 10 12 6 14 1.42108547e-13 12 1.42108547e-13 10 2 7 2"
                fill="#f0f0f0"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="491"
              transform="translate(203.000000, 196.000000)"
              fill="#f0f0f0"
              stroke="#000000"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="bakirkoy"
                points="28 8 24 12 18 11 15 16 10 16 0 9 0 6 6 5 6 2 8 2 11 4 19 5 27 1 30 1.42108547e-13"
                fill="#f0f0f0"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="510"
              transform="translate(195.000000, 177.000000)"
              fill="#f0f0f0"
              stroke="#000000"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="kucukcekmece"
                points="17 1 1 1.42108547e-13 0 4 3 8 3 17 7 17 8 19 5 21 6 23 7 24 4 26 4 27 8 28 8 25 14 24 14 21 16 21 19 23 16 16 16 12 16 3"
                fill="#f0f0f0"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="488"
              transform="translate(181.000000, 163.000000)"
              fill="#f0f0f0"
              stroke="#000000"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="avcilar"
                points="3 43 12 44 17 41 17 40 12 40 9 31 9 28 13 30 11 20 12 17 14 18 15 14 12 14 2 1.42108547e-13 1 5 5 15 2 15 0 16 4 25 3 34 0 33"
                fill="#f0f0f0"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="496"
              transform="translate(159.000000, 191.000000)"
              fill="#f0f0f0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="beylikduzu"
                stroke="#000000"
                fill="#f0f0f0"
                points="22 5 25 15 17 18 6 18 0 15 0 7 4 8 9 1.42108547e-13 17 4"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="502"
              transform="translate(165.000000, 166.000000)"
              fill="#f0f0f0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="esenyurt"
                stroke="#000000"
                fill="#f0f0f0"
                points="3 25 11 29 19 31 20 22 16 13 18 12 9 7 6 1.42108547e-13 4 1 0 8"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="498"
              transform="translate(109.000000, 155.000000)"
              fill="#f0f0f0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="buyukcekmece"
                stroke="#000000"
                fill="#f0f0f0"
                points="0 25 37 46 42 36 50 38 50 43 54 44 59 36 56 19 60 12 48 1.42108547e-13 41 7 36 7 22 4 21 19 1 14"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="492"
              transform="translate(171.000000, 150.000000)"
              fill="#f0f0f0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="basaksehir"
                stroke="#000000"
                fill="#f0f0f0"
                points="0 16 3 23 12 28 15 28 11 18 12 13 22 27 25 27 41 28 42 24 47 25 51 13 50 10 44 3 33 1.42108547e-13 23 5 20 10 11 7"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="517"
              transform="translate(215.000000, 146.000000)"
              fill="#f0f0f0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="sultangazi"
                stroke="#000000"
                fill="#f0f0f0"
                points="0 7 6 14 7 17 13 25 17 22 21 22 24 21 26 14 10 1.42108547e-13 5 2 3 8"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="519"
              transform="translate(248.000000, 150.000000)"
              fill="#f0f0f0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="sisli"
                stroke="#000000"
                fill="#f0f0f0"
                points="1 30 8 35 12 26 14 27 15 24 12 19 14 14 17 12 17 3 15 1.42108547e-13 5 1.42108547e-13 1 8 0 11 11 16 10 19 10 21 11 23 8 23 5 25"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="514"
              transform="translate(244.000000, 112.000000)"
              fill="#f0f0f0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="sariyer"
                stroke="#000000"
                fill="#f0f0f0"
                points="2 1.42108547e-13 21 7 25 3 43 10 43 17 33 28 24 35 32 45 28 55 29 60 25 58 18 52 21 50 21 41 19 38 9 38 0 12"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="503"
              transform="translate(204.000000, 94.000000)"
              fill="#f0f0f0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="eyupsultan"
                stroke="#000000"
                fill="#f0f0f0"
                points="5 1.42108547e-13 42 18 40 30 49 56 45 64 43 71 47 75 43 84 42 86 39 87 41 91 37 96 36 95 33 87 39 85 38 82 34 81 35 73 37 66 30 60 21 52 16 54 14 60 11 59 10 39 0 18"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="486"
              transform="translate(143.000000, 74.000000)"
              fill="#f0f0f0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="arnavutkoy"
                stroke="#000000"
                fill="#f0f0f0"
                points="25 1.41220369e-13 66 20 61 38 71 59 72 79 61 76 51 81 48 86 39 83 28 92 26 93 14 81 7 88 2 88 0 56 13 44 3 35 22 14"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="515"
              transform="translate(0.000000, 60.000000)"
              fill="#f0f0f0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="silivri"
                stroke="#000000"
                fill="#f0f0f0"
                points="9 107 47 101 76 107 109 120 110 109 99 58 87 46 94 34 75 21 41 1.42108547e-13 21 36 0 84"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="499"
              transform="translate(41.000000, 0.000000)"
              fill="#f0f0f0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="catalca"
                stroke="#000000"
                fill="#f0f0f0"
                points="22 1.42108547e-13 127 74 124 88 105 109 115 118 102 130 104 162 90 159 89 174 69 169 58 118 46 106 53 94 34 81 0 60 10 37 12 27 2 15"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="487"
              transform="translate(276.000000, 195.000000)"
              fill="#f0f0f0"
              stroke="#000000"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="atasehir"
                points="34 12 22 11 10 17 5 8 0 5 0 2 4 0 11 5 30 7"
                fill="#f0f0f0"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="511"
              transform="translate(279.000000, 206.000000)"
              fill="#f0f0f0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="maltepe"
                stroke="#000000"
                fill="#f0f0f0"
                points="0 9 12 22 29 18 32 9 31 1 19 0 7 6"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="521"
              transform="translate(279.000000, 178.000000)"
              fill="#f0f0f0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="umraniye"
                stroke="#000000"
                fill="#f0f0f0"
                points="0 0 1 17 8 22 27 24 27 17 18 9 14 9 14 6"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="500"
              transform="translate(293.000000, 157.000000)"
              fill="#f0f0f0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="cekmekoy"
                stroke="#000000"
                fill="#f0f0f0"
                points="55 1 35 0 16 16 17 24 0 27 0 30 4 30 13 38 36 25 51 26 53 31 58 30 64 15 60 3"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="520"
              transform="translate(320.000000, 207.000000)"
              fill="#f0f0f0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="tuzla"
                stroke="#000000"
                fill="#f0f0f0"
                points="51 3 33 31 21 54 17 57 11 54 0 56 5 47 3 42 22 15 38 2 49 0"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="522"
              transform="translate(260.000000, 173.000000)"
              fill="#f0f0f0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="uskudar"
                stroke="#000000"
                fill="#f0f0f0"
                points="15 0 13 2 12 10 0 17 2 26 8 22 10 22 10 25 17 27 16 24 20 22 19 5"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="509"
              transform="translate(291.000000, 215.000000)"
              fill="#f0f0f0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="kartal"
                stroke="#000000"
                fill="#f0f0f0"
                points="30 6 28 0 20 0 17 9 0 13 17 25"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="507"
              transform="translate(262.000000, 195.000000)"
              fill="#f0f0f0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="kadikoy"
                stroke="#000000"
                fill="#f0f0f0"
                points="0 4 2 11 17 20 24 17 19 8 14 5 8 3 8 0 6 0"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="513"
              transform="translate(306.000000, 182.000000)"
              fill="#f0f0f0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="sancaktepe"
                stroke="#000000"
                fill="#f0f0f0"
                points="40 6 38 1 23 0 0 13 0 20 4 25 5 33 13 33 17 16 24 15"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="516"
              transform="translate(319.000000, 197.000000)"
              fill="#f0f0f0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="sultanbeyli"
                stroke="#000000"
                fill="#f0f0f0"
                points="11 0 4 1 0 18 2 24 15 20"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="512"
              transform="translate(308.000000, 186.000000)"
              fill="#f0f0f0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="pendik"
                stroke="#000000"
                fill="#f0f0f0"
                points="63 24 61 21 50 23 34 36 15 63 0 54 13 35 26 31 22 11 38 2 43 1 58 8 59 13 77 0 90 13 73 15"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="518"
              transform="translate(338.000000, 129.000000)"
              fill="#f0f0f0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="sile"
                stroke="#000000"
                fill="#f0f0f0"
                points="143 34 97 22 54 15 2 0 0 14 10 29 14 31 19 43 13 58 27 65 29 71 39 65 47 57 60 70 80 82 96 76 115 65 132 60"
              ></polygon>
            </g>
          </NavLink>
          <NavLink to={"/istanbul/" + id2}>
            <g
              id="495"
              transform="translate(275.000000, 123.000000)"
              fill="#f0f0f0"
              opacity="1"
              onMouseEnter={getId}
              onMouseLeave={handleMouseLeave}
              onClick={onClick}
            >
              <polygon
                id="beykoz"
                stroke="#000000"
                fill="#f0f0f0"
                points="65 6 45 0 19 6 3 22 2 29 9 35 1 43 0 50 4 55 18 61 35 58 34 50 53 34 73 35 63 20"
              ></polygon>
            </g>
          </NavLink>
        </g>
      </svg>
    </div>
  );
}

export default IstanbulMap;
