import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import styles from "../css/province/province.module.css";
import DistrictCard from "../components/cards/DistrictCard";
import CityHallDetail from "../components/details/CityHallDetail";
import Ad2 from "../components/ads/Ad2";

import { Context } from "../context/Context";
import { useContext } from "react";

import AdanaMap from "../components/maps/AdanaMap";
import AfyonMap from "../components/maps/AfyonMap";
import AgriMap from "../components/maps/AgriMap";
import AdiyamanMap from "../components/maps/AdiyamanMap";
import AmasyaMap from "../components/maps/AmasyaMap";
import AntalyaMap from "../components/maps/AntalyaMap";
import ArtvinMap from "../components/maps/ArtvinMap";
import AydinMap from "../components/maps/AydinMap";
import BalikesirMap from "../components/maps/BalikesirMap";
import BilecikMap from "../components/maps/BilecikMap";
import BingolMap from "../components/maps/BingolMap";
import BitlisMap from "../components/maps/BitlisMap";
import BoluMap from "../components/maps/BoluMap";
import BurdurMap from "../components/maps/BurdurMap";
import BursaMap from "../components/maps/BursaMap";
import CanakkaleMap from "../components/maps/CanakkaleMap";
import CankiriMap from "../components/maps/CankiriMap";
import CorumMap from "../components/maps/CorumMap";
import DenizliMap from "../components/maps/DenizliMap";
import DiyarbakirMap from "../components/maps/DiyarbakirMap";
import EdirneMap from "../components/maps/EdirneMap";
import ElazigMap from "../components/maps/ElazigMap";
import ErzincanMap from "../components/maps/ErzincanMap";
import ErzurumMap from "../components/maps/ErzurumMap";
import EskisehirMap from "../components/maps/EskisehirMap";
import GaziantepMap from "../components/maps/GaziantepMap";
import GiresunMap from "../components/maps/GiresunMap";
import GumushaneMap from "../components/maps/GumushaneMap";
import HakkariMap from "../components/maps/HakkariMap";
import HatayMap from "../components/maps/HatayMap";
import MersinMap from "../components/maps/MersinMap";
import IzmirMap from "../components/maps/IzmirMap";
import KarsMap from "../components/maps/KarsMap";
import KastamonuMap from "../components/maps/KastamonuMap";
import KayseriMap from "../components/maps/KayseriMap";
import KirklareliMap from "../components/maps/KirklareliMap";
import KirsehirMap from "../components/maps/KirsehirMap";
import KocaeliMap from "../components/maps/KocaeliMap";
import KonyaMap from "../components/maps/KonyaMap";
import KutahyaMap from "../components/maps/KutahyaMap";
import MalatyaMap from "../components/maps/MalatyaMap";
import ManisaMap from "../components/maps/ManisaMap";
import KahramanmarasMap from "../components/maps/KahramanmarasMap";
import MardinMap from "../components/maps/MardinMap";
import MuglaMap from "../components/maps/MuglaMap";
import MusMap from "../components/maps/MusMap";
import NevsehirMap from "../components/maps/NevsehirMap";
import NigdeMap from "../components/maps/NigdeMap";
import OrduMap from "../components/maps/OrduMap";
import RizeMap from "../components/maps/RizeMap";
import SakaryaMap from "../components/maps/SakaryaMap";
import SamsunMap from "../components/maps/SamsunMap";
import SiirtMap from "../components/maps/SiirtMap";
import SinopMap from "../components/maps/SinopMap";
import SivasMap from "../components/maps/SivasMap";
import TekirdagMap from "../components/maps/TekirdagMap";
import TokatMap from "../components/maps/TokatMap";
import TrabzonMap from "../components/maps/TrabzonMap";
import TunceliMap from "../components/maps/TunceliMap";
import SanliurfaMap from "../components/maps/SanliurfaMap";
import UsakMap from "../components/maps/UsakMap";
import VanMap from "../components/maps/VanMap";
import YozgatMap from "../components/maps/YozgatMap";
import ZonguldakMap from "../components/maps/ZonguldakMap";
import AksarayMap from "../components/maps/AksarayMap";
import BayburtMap from "../components/maps/BayburtMap";
import KaramanMap from "../components/maps/KaramanMap";
import KirikkaleMap from "../components/maps/KirikkaleMap";
import BatmanMap from "../components/maps/BatmanMap";
import SirnakMap from "../components/maps/SirnakMap";
import BartinMap from "../components/maps/BartinMap";
import ArdahanMap from "../components/maps/ArdahanMap";
import IgdirMap from "../components/maps/IgdirMap";
import YalovaMap from "../components/maps/YalovaMap";
import KarabukMap from "../components/maps/KarabukMap";
import KilisMap from "../components/maps/KilisMap";
import OsmaniyeMap from "../components/maps/OsmaniyeMap";
import DuzceMap from "../components/maps/DuzceMap";
import AnkaraMap from "../components/maps/AnkaraMap";
import IstanbulMap from "../components/maps/IstanbulMap";
import IspartaMap from "../components/maps/IspartaMap";
import ProvincePromises from "../components/promises/ProvincePromises";
import { useLocation } from "react-router-dom";
import Arrow from "../assets/arrows.svg";
import Footer from "../components/Footer";

function ProvinceDetailPage() {
  const { dispatch, isHovered2, mouseX, mouseY } = useContext(Context);
  const screenWidth = window.innerWidth;

  const location = useLocation();
  const { pathname } = location;
  const words = pathname.split("/").filter((word) => word.trim() !== "");

  const maps = {
    ankara: <AnkaraMap />,
    istanbul: <IstanbulMap />,
    adana: <AdanaMap />,
    adiyaman: <AdiyamanMap />,
    afyonkarahisar: <AfyonMap />,
    agri: <AgriMap />,
    amasya: <AmasyaMap />,
    antalya: <AntalyaMap />,
    artvin: <ArtvinMap />,
    aydin: <AydinMap />,
    balikesir: <BalikesirMap />,
    bilecik: <BilecikMap />,
    bingol: <BingolMap />,
    bitlis: <BitlisMap />,
    bolu: <BoluMap />,
    burdur: <BurdurMap />,
    bursa: <BursaMap />,
    canakkale: <CanakkaleMap />,
    cankiri: <CankiriMap />,
    corum: <CorumMap />,
    denizli: <DenizliMap />,
    diyarbakir: <DiyarbakirMap />,
    edirne: <EdirneMap />,
    elazig: <ElazigMap />,
    erzincan: <ErzincanMap />,
    erzurum: <ErzurumMap />,
    eskisehir: <EskisehirMap />,
    gaziantep: <GaziantepMap />,
    giresun: <GiresunMap />,
    gumushane: <GumushaneMap />,
    hakkari: <HakkariMap />,
    hatay: <HatayMap />,
    mersin: <MersinMap />,
    izmir: <IzmirMap />,
    kars: <KarsMap />,
    kastamonu: <KastamonuMap />,
    kayseri: <KayseriMap />,
    kirklareli: <KirklareliMap />,
    kirsehir: <KirsehirMap />,
    kocaeli: <KocaeliMap />,
    konya: <KonyaMap />,
    kutahya: <KutahyaMap />,
    malatya: <MalatyaMap />,
    manisa: <ManisaMap />,
    kahramanmaras: <KahramanmarasMap />,
    mardin: <MardinMap />,
    mugla: <MuglaMap />,
    mus: <MusMap />,
    nevsehir: <NevsehirMap />,
    nigde: <NigdeMap />,
    ordu: <OrduMap />,
    rize: <RizeMap />,
    sakarya: <SakaryaMap />,
    samsun: <SamsunMap />,
    siirt: <SiirtMap />,
    sinop: <SinopMap />,
    sivas: <SivasMap />,
    tekirdag: <TekirdagMap />,
    tokat: <TokatMap />,
    trabzon: <TrabzonMap />,
    tunceli: <TunceliMap />,
    sanliurfa: <SanliurfaMap />,
    usak: <UsakMap />,
    van: <VanMap />,
    yozgat: <YozgatMap />,
    zonguldak: <ZonguldakMap />,
    aksaray: <AksarayMap />,
    bayburt: <BayburtMap />,
    karaman: <KaramanMap />,
    kirikkale: <KirikkaleMap />,
    batman: <BatmanMap />,
    sirnak: <SirnakMap />,
    bartin: <BartinMap />,
    ardahan: <ArdahanMap />,
    igdir: <IgdirMap />,
    yalova: <YalovaMap />,
    karabuk: <KarabukMap />,
    kilis: <KilisMap />,
    osmaniye: <OsmaniyeMap />,
    duzce: <DuzceMap />,
    isparta: <IspartaMap />,
  };
  const myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    "Token 8f6c14510d09a853e6145cc52567a9bebe86c172"
  );

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  useEffect(() => {
    fetch("https://api.ibrahimaluc.com/api/provinces/", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: "GET_DATA",
          payload: data.provinces,
        });
      })
      .catch((error) => {
        console.error("Hata:", error);
      });
    fetch("https://api.ibrahimaluc.com/api/districts/", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: "GET_DISTRICT",
          payload: data.districts,
        });
      })
      .catch((error) => {
        console.error("Hata:", error);
      });
    window.scrollTo(0, 0);
    dispatch({ type: "SET_HOVER", payload: false });
  }, []);
  const mapComponent = maps[words[0]];
  

  return (
    <div>
      <Navbar />
      <div className={styles.container}>
       <div className={styles.map}>
       <div className={` ${styles[words[0]]} ${styles.all}`}>
          {mapComponent}
          {words[0] && isHovered2 && (
            <div
              style={{
                position: "absolute",
                top: mouseY + (screenWidth / 2 > mouseX ? -500 : -400),
                left: mouseX + (screenWidth / 2 > mouseX ? -450 : -400),
              }}
            >
              <DistrictCard />
            </div>
          )}
        </div>
       </div>
        <div className={styles.city}>
          <CityHallDetail />
        </div>
      </div>
      <a className={styles.icon} href="#promises">
        <img className={styles.arrow} src={Arrow} alt="" />
      </a>
      <Ad2 />
      <ProvincePromises />
      <Footer />
    </div>
  );
}

export default ProvinceDetailPage;
