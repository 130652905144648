import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function DuzceMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 270 170"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Düzce" transform="translate(0, 0)">
            <NavLink to={"/duzce/" + id2}>
              <g
                id="236"
                transform="translate(34.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="akcakoca"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="88 0 38 6 8 12 12 29 0 55 9 56 35 52 56 42 86 44 121 33 110 29 100 12"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/duzce/" + id2}>
              <g
                id="341"
                transform="translate(115.000000, 33.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yigilca"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="40 0 49 0 121 9 114 40 92 55 40 63 14 48 7 40 22 30 28 36 30 31 38 31 35 25 19 25 3 33 0 23 5 11"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/duzce/" + id2}>
              <g
                id="334"
                transform="translate(55.000000, 42.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="65 2 35 0 14 10 14 27 24 34 -1.77635684e-15 53 10 63 8 75 34 76 53 61 87 74 100 54 74 39 67 31 61 32 57 27 63 24 60 14"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/duzce/" + id2}>
              <g
                id="337"
                transform="translate(43.000000, 52.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cilimli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="26 0 0 4 1 18 3 33 12 43 36 24 26 17"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/duzce/" + id2}>
              <g
                id="336"
                transform="translate(6.000000, 55.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cumayeri"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="28 0 15 10 0 21 22 21 38 15 37 1"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/duzce/" + id2}>
              <g
                id="339"
                transform="translate(0.000000, 70.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gumusova"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="44 0 27 6 6 6 4 20 0 37 3 45 16 47 55 25 46 15"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/duzce/" + id2}>
              <g
                id="338"
                transform="translate(15.000000, 95.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="golyaka"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="40 0 0 22 9 42 0 56 8 62 32 61 48 22 50 10"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/duzce/" + id2}>
              <g
                id="340"
                transform="translate(47.000000, 103.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kaynasli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 53 28 50 44 63 90 54 95 13 61 0 42 15 16 14"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default DuzceMap;
