import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function AdanaMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative"}}
          viewBox="0 0 700 700"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="adana" transform="translate(0, 0)">
            <NavLink to={"/adana/" + id2}>
              <g
                id="10"
                transform="translate(0.000000, 224.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
                
              >
                <polygon
                  id="pozanti"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="110 0 73 18 36 19 36 71 0 107 14 139 48 141 65 112 63 82 81 77"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/adana/" + id2}>
              <g
                id="2"
                transform="translate(81.000000, 210.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="aladag"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="29 14 45 0 136 9 143 21 135 27 136 40 124 44 119 65 121 71 126 69 127 53 138 44 139 53 131 66 126 84 121 84 112 98 105 105 107 116 111 124 116 137 112 144 103 151 58 97 32 100 0 91"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/adana/" + id2}>
              <g
                id="5"
                transform="translate(217.000000, 120.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="fekke"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 99 2 34 12 20 53 0 105 30 143 84 123 106 87 110 53 105 24 115 7 111"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/adana/" + id2}>
              <g
                id="11"
                transform="translate(270.000000, 79.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="saimbeyli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 41 41 23 55 0 80 31 114 23 155 30 156 54 143 79 152 92 142 127 90 125 52 71"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/adana/" + id2}>
              <g
                id="14"
                transform="translate(325.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="tufanbeyli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 79 14 50 63 0 103 18 106 44 119 53 101 85 100 109 59 102 25 110"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/adana/" + id2}>
              <g
                id="9"
                transform="translate(194.000000, 204.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kozan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="218 2 166 0 147 21 109 26 76 21 47 31 30 27 22 33 28 44 32 35 39 33 35 49 25 50 26 59 18 72 13 90 13 98 10 106 0 109 0 112 23 118 37 151 68 146 88 174 110 183 114 157 108 139 122 127 130 77 167 21 217 11"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/adana/" + id2}>
              <g
                id="6"
                transform="translate(176.000000, 316.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="imamoglu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="18 0 19 8 12 10 15 17 25 19 26 30 24 43 13 46 7 56 0 64 6 69 29 62 46 69 82 71 108 63 86 34 56 39 41 6"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/adana/" + id2}>
              <g
                id="3"
                transform="translate(200.000000, 379.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ceyhan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="104 8 84 0 58 7 21 6 23 27 6 29 13 40 34 47 42 93 46 135 23 150 7 148 0 159 17 164 56 139 59 126 83 127 129 103 151 75 154 26 138 16 105 27 96 26"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/adana/" + id2}>
              <g
                id="15"
                transform="translate(200.000000, 482.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yumurtalik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="129 0 128 16 84 65 46 67 20 84 21 88 42 79 63 81 64 89 53 91 41 123 29 127 45 88 27 104 23 127 1 126 0 56 17 61 56 36 59 23 83 24"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/adana/" + id2}>
              <g
                id="8"
                transform="translate(39.000000, 534.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="karatas"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="161 4 96 15 74 2 68 0 5 20 0 31 18 28 57 47 115 80 118 72 84 52 83 42 124 62 122 86 130 85 145 73 162 74"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/adana/" + id2}>
              <g
                id="16"
                transform="translate(113.000000, 470.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yuregir"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="87 68 22 79 0 66 44 32 44 0 129 2 133 44 110 59 94 57"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/adana/" + id2}>
              <g
                id="13"
                transform="translate(90.000000, 446.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="seyhan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="7 6 27 0 57 10 67 25 67 56 23 90 17 88 24 73 0 41"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/adana/" + id2}>
              <g
                id="12"
                transform="translate(134.000000, 378.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="saricam"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="42 2 37 7 28 2 20 10 6 16 0 19 5 38 18 37 17 46 28 52 29 58 12 56 12 63 20 63 21 77 13 78 23 93 108 94 100 48 79 40 72 30 89 28 88 7 71 0 48 7"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/adana/" + id2}>
              <g
                id="4"
                transform="translate(65.000000, 397.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cukurova"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="69 0 27 19 13 12 0 46 32 55 52 49 82 59 80 49 63 48 49 37 54 26 66 39 75 32 66 22 63 8"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/adana/" + id2}>
              <g
                id="7"
                transform="translate(48.000000, 301.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="karaisali"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="17 142 7 146 1 107 16 82 0 64 17 35 15 5 33 0 66 9 91 6 136 60 126 67 127 73 121 74 118 65 110 70 105 75 99 71 97 82 93 82 92 86 86 81 87 65 82 60 79 68 82 79 86 90 92 93 86 96 44 115 30 108"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default AdanaMap;
