import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function YalovaMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 200 80"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Yalova" transform="translate(0, 0)">
            <NavLink to={"/yalova/" + id2}>
              <g
                id="1024"
                transform="translate(0.000000, 36.585366)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="armutlu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="56.936124 42.9430124 30.9652604 41.9489076 0 22.0668131 4.99439684 9.14345162 34.9607779 0.196509074 78.9114701 22.0668131"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/yalova/" + id2}>
              <g
                id="1025"
                transform="translate(34.800493, 19.512195)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cinarcik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0.160285294 17.2696798 26.1311489 0.369899434 69.0829617 1.36400416 56.0975299 41.1281933 44.1109775 39.1399838"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/yalova/" + id2}>
              <g
                id="1027"
                transform="translate(90.677340, 20.487805)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="termal"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="13.2061144 0.388394405 26.1915462 0.388394405 10.2094763 42.140793 0.220682651 40.1525835"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/yalova/" + id2}>
              <g
                id="1022"
                transform="translate(100.480296, 20.487805)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="16.3885906 0.388394405 37.3650573 0.388394405 44.3572129 43.1348977 12.3930731 44.1290024 0.406520673 42.140793"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/yalova/" + id2}>
              <g
                id="1026"
                transform="translate(137.731527, 7.804878)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ciftlikkoy"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0.113825789 13.0713212 17.0947751 0.147959773 31.0790862 5.11848341 20.0914132 54.8237198 7.10598137 55.8178245"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/yalova/" + id2}>
              <g
                id="1023"
                transform="translate(157.337438, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="altinova"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="11.4731749 12.9233615 29.4530035 0 41.4395559 15.9056756 24.4586067 23.8585135 8.47653678 62.6285978 0.485501833 62.6285978"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default YalovaMap;
