import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function RizeMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 400 280"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Rize" transform="translate(0, 0)">
            <NavLink to={"/rize/" + id2}>
              <g
                id="851"
                transform="translate(0.000000, 122.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="iyidere"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 11 11 0 21 10 12 20"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/rize/" + id2}>
              <g
                id="846"
                transform="translate(11.000000, 112.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="derepazari"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 10 14 0 20 20 10 20"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/rize/" + id2}>
              <g
                id="842"
                transform="translate(25.000000, 95.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 17 12 14 21 17 51 0 52 15 33 27 48 58 67 53 73 67 71 79 32 72 6 37"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/rize/" + id2}>
              <g
                id="848"
                transform="translate(58.000000, 110.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="guneysu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="19 0 19 14 34 38 15 43 0 12"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/rize/" + id2}>
              <g
                id="845"
                transform="translate(76.000000, 73.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cayeli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 22 18 16 28 0 45 8 47 62 59 82 63 101 50 116 19 101 22 89 16 75 1 51 1 37"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/rize/" + id2}>
              <g
                id="853"
                transform="translate(104.000000, 50.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="pazar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 23 20 6 49 0 52 16 43 22 17 31"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/rize/" + id2}>
              <g
                id="849"
                transform="translate(121.000000, 72.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="hemsin"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="26 0 29 23 14 83 2 63 0 9"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/rize/" + id2}>
              <g
                id="844"
                transform="translate(126.000000, 66.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="camlihemsin"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="30 0 56 31 58 53 79 101 71 120 15 146 13 133 0 123 13 108 9 89 24 29 21 6"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/rize/" + id2}>
              <g
                id="733"
                transform="translate(153.000000, 31.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ardesen"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 19 35 0 74 43 104 55 66 102 57 134 52 136 31 88 29 66 3 35"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/rize/" + id2}>
              <g
                id="847"
                transform="translate(188.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="findikli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 31 7 20 13 11 35 0 47 13 56 39 83 56 86 63 69 86 39 74"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/rize/" + id2}>
              <g
                id="852"
                transform="translate(12.000000, 132.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kalkandere"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="45 35 18 38 0 10 9 0 19 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/rize/" + id2}>
              <g
                id="850"
                transform="translate(30.000000, 167.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ikizdere"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 3 15 55 4 81 10 112 46 109 86 85 111 45 109 32 96 22 65 7 27 0"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default RizeMap;
