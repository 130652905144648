import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function ErzincanMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 650 350"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Erzincan" transform="translate(0, 0)">
            <NavLink to={"/erzincan/" + id2}>
              <g
                id="369"
                transform="translate(11.000000, 237.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="kemaliye"
                  d="M4,12 L13,31 L35,49 L37,60 L61,68 L70,82 L99,85 L100,125 L85,116 L58,90 L18,94 L3,87 L0,14 L4,12 Z M103.905335,88 L147.862394,50 L114.8946,33 L75.9326609,12 L50.9570591,16 L13.9931685,0 L7,9 L17.9892648,25 L41.9658425,45 L67.9404683,66 L74.9336368,76 L96.9121664,75 L103.905335,88 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/erzincan/" + id2}>
              <g
                id="367"
                transform="translate(0.000000, 117.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ilic"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="126 153 142 133 92 82 92 17 20 0 0 37 26 111 34 110 35 115 25 120 62 136 87 132"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/erzincan/" + id2}>
              <g
                id="368"
                transform="translate(92.000000, 67.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="kemah"
                  d="M50,183 L187,128 L196,76 L166,72 L116,27 L132,11 L104,8 L86,0 L40,67 L0,67 L0,132 L50,183 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/erzincan/" + id2}>
              <g
                id="371"
                transform="translate(5.000000, 7.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="refahiye"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="191 68 193 23 147 2 141 10 128 10 104 0 101 27 65 30 52 44 29 34 0 52 29 82 15 110 87 127 127 127 173 60"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/erzincan/" + id2}>
              <g
                id="365"
                transform="translate(196.000000, 30.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="2 0 127 38 153 60 133 105 188 121 181 142 140 176 83 165 92 113 62 109 12 64 27 48 0 45"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/erzincan/" + id2}>
              <g
                id="373"
                transform="translate(329.000000, 90.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="uzumlu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="20 0 108 17 107 50 107 74 55 61 1.11022302e-16 45"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/erzincan/" + id2}>
              <g
                id="366"
                transform="translate(323.000000, 22.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="cayirli"
                  d="M113,117 C112.999999,117 139,115 139,115 L172,82 L126,35 L138,23 L57,0 L45,17 L0,46 L26,68 L114,85 C114,85 113.000001,117 113,117 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/erzincan/" + id2}>
              <g
                id="372"
                transform="translate(436.000000, 34.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="tercan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="25 11 72 0 124 11 154 73 197 121 164 130 111 131 89 113 32 116 0 130 0 105 26 103 59 70 13 23"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/erzincan/" + id2}>
              <g
                id="370"
                transform="translate(380.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="otlukbeli"
                  d="M129,34 C129.000001,33.9999992 112,19 112,19 L132,1 L35,0 L0,22 L81,45 C81,45 128.999999,34.0000008 129,34 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default ErzincanMap;
