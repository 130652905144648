import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function KullanimKosullari() {
  return (
    <>
    
      <div>KullanimKosullari</div>
      
    </>
  );
}

export default KullanimKosullari;
