import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function KaramanMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 650 430"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Karaman" transform="translate(0, 0)">
            <NavLink to={"/karaman/" + id2}>
              <g
                id="578"
                transform="translate(6.000000, 255.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ermenek"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="20 175 135 147 170 111 161 108 163 104 170 104 178 113 190 117 186 124 195 128 202 119 140 70 126 17 86 0 80 3 60 17 83 78 104 95 124 109 116 116 118 132 109 118 105 118 89 104 59 107 9 121 0 163"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/karaman/" + id2}>
              <g
                id="577"
                transform="translate(27.000000, 272.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="basyayla"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="39 0 0 20 27 72 40 81 38 90 64 87 67 81 55 60 62 61"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/karaman/" + id2}>
              <g
                id="580"
                transform="translate(0.000000, 292.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sariveliler"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="27 0 0 9 12 67 15 84 65 70 67 61 54 52"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/karaman/" + id2}>
              <g
                id="575"
                transform="translate(91.000000, 13.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="1 242 19 217 54 196 57 171 73 168 64 146 75 132 64 99 43 89 102 29 112 47 131 43 150 54 203 0 227 135 232 200 209 219 109 238 94 266 82 254 41 259"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/karaman/" + id2}>
              <g
                id="579"
                transform="translate(59.000000, 102.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kazimkarabekir"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="75 0 62 0 36 4 35 33 18 46 0 74 11 102 51 128 86 107 89 82 105 79 96 57 107 43 96 10"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/karaman/" + id2}>
              <g
                id="576"
                transform="translate(294.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ayranci"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 13 44 0 77 81 126 107 121 186 74 220 59 223 29 213 24 148"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default KaramanMap;
