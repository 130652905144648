import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function AntalyaMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 1000 450"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Antalya" transform="translate(0, 0)">
            <NavLink to={"/antalya/" + id2}>
              <g
                id="108"
                transform="translate(0.000000, 249.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kas"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="93 0 22 43 0 98 20 122 29 110 40 112 34 125 91 131 85 140 97 135 105 141 95 142 108 155 134 151 179 75 163 61 162 24"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/antalya/" + id2}>
              <g
                id="103"
                transform="translate(93.000000, 145.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="elmali"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="3.41060513e-13 104 20 78 34 30 54 0 128 0 168 49 161 86 139 118 69 128"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/antalya/" + id2}>
              <g
                id="112"
                transform="translate(137.000000, 19.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="korkuteli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="10 126 0 112 28 97 31 54 76 31 140 0 192 22 188 66 208 75 214 96 190 137 163 173 124 175 84 126"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/antalya/" + id2}>
              <g
                id="113"
                transform="translate(232.000000, 192.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kumluca"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="29 2 68 0 68 37 81 50 74 85 81 113 81 140 108 163 97 168 103 176 78 200 77 179 66 179 29 162 28 153 13 134 0 71 22 39"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/antalya/" + id2}>
              <g
                id="111"
                transform="translate(300.000000, 150.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="konyaalti"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="27 6 77 0 73 15 83 16 83 27 68 40 60 79 13 92 0 79 0 42"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/antalya/" + id2}>
              <g
                id="102"
                transform="translate(325.000000, 41.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="dosemealti"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="4 0 28 6 50 1 87 11 88 24 93 40 68 40 69 51 61 48 53 58 63 92 52 109 2 115 26 74 20 53 0 44"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/antalya/" + id2}>
              <g
                id="99"
                transform="translate(409.000000, 50.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="aksu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="3 2 23 0 27 34 48 56 50 91 41 111 48 121 49 132 35 133 30 125 19 121 23 109 15 104 8 92 23 85 13 76 12 58 0 41 9 31 4 15"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/antalya/" + id2}>
              <g
                id="115"
                transform="translate(383.000000, 154.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="muratpasa"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="61 29 56 21 45 17 49 5 41 0 33 9 0 12 0 23 11 18 20 23 29 30"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/antalya/" + id2}>
              <g
                id="116"
                transform="translate(432.000000, 14.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="serik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="26 168 57 168 70 177 89 178 89 154 109 115 103 83 67 73 72 46 61 2 39 12 28 0 0 36 4 70 25 92 27 127 18 147 25 157"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/antalya/" + id2}>
              <g
                id="114"
                transform="translate(493.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="manavgat"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 16 74 0 84 6 85 20 90 45 83 70 104 112 115 155 128 162 124 173 128 174 132 171 138 177 142 175 155 177 189 216 187 240 160 257 144 245 135 236 128 236 107 222 87 220 85 208 51 192 46 196 28 192 28 168 48 129 42 97 6 87 11 60"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/antalya/" + id2}>
              <g
                id="107"
                transform="translate(576.000000, 6.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ibradi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="1 0 43 24 82 22 91 60 108 78 108 125 72 171 60 169 54 160 59 154 58 149 52 151 50 156 45 156 32 149 21 106 1.11022302e-16 64 7 39 2 13"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/antalya/" + id2}>
              <g
                id="98"
                transform="translate(648.000000, 18.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="akseki"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="10 10 23 0 36 1 69 43 89 84 116 112 104 120 111 141 34 198 0 159 36 113 36 66 19 48"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/antalya/" + id2}>
              <g
                id="106"
                transform="translate(680.000000, 130.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gundogmus"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="84 0 150 42 150 49 143 58 155 102 146 134 77 121 34 128 0 110 2 86 79 29 72 8"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/antalya/" + id2}>
              <g
                id="100"
                transform="translate(653.000000, 179.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="alanya"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="177 0 189 19 219 51 231 108 202 132 161 195 126 152 128 143 98 119 92 123 85 114 32 97 30 90 13 82 0 78 27 61 61 79 105 72 173 85 182 53 170 9"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/antalya/" + id2}>
              <g
                id="105"
                transform="translate(814.000000, 287.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="gazipasa"
                  d="M0.0175746367,87 C1.01739157,88 10.0157439,116 10.0157439,116 L15.0148286,116 L33.0115334,141 L85.0020138,160 L96,143 L94.0003661,98 L84.0021968,72 L64.0058582,60 L73.0042106,17 L70.0047598,0 L41.0100688,24 C41.0100688,24 -0.982242294,86 0.0175746367,87 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/antalya/" + id2}>
              <g
                id="101"
                transform="translate(132.000000, 324.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="demre"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="2 76 0 85 18 79 15 71 22 69 19 62 32 65 47 52 59 59 81 52 81 43 93 52 99 49 76 13 47 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/antalya/" + id2}>
              <g
                id="104"
                transform="translate(162.000000, 263.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="finike"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="69 110 79 106 80 94 99 91 98 82 83 63 70 0 0 10 1 47 17 61 46 74"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/antalya/" + id2}>
              <g
                id="109"
                transform="translate(306.000000, 229.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kemer"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="54 0 7 13 0 48 7 77 7 103 34 126 40 122 31 106 25 104 29 89 37 88 37 75 60 38 50 29 56 9"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/antalya/" + id2}>
              <g
                id="110"
                transform="translate(373.000000, 81.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kepez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="4 69 0 84 10 85 43 82 51 73 44 61 59 54 49 45 48 27 36 10 45 0 20 0 21 11 13 8 5 18 15 52"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default AntalyaMap;
