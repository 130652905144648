import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function SinopMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 500 290"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Sinop" transform="translate(0, 0)">
            <NavLink to={"/sinop/" + id2}>
              <g
                id="901"
                transform="translate(210.000000, 132.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="dikmen"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="109 20 69 0 40 7 6 29 0 35 15 51 46 67 70 63 86 72 91 53"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sinop/" + id2}>
              <g
                id="902"
                transform="translate(171.000000, 161.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="duragan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="125 43 121 60 132 82 127 95 120 105 108 116 92 119 79 115 74 109 73 102 63 92 55 79 55 72 45 68 42 68 44 72 6 97 0 75 6 23 0 8 18 0 39 6 54 22 85 38 109 34"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sinop/" + id2}>
              <g
                id="905"
                transform="translate(101.000000, 236.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sarayduzu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="70 0 41 3 0 28 48 53 51 43 62 38 61 29 76 22"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sinop/" + id2}>
              <g
                id="900"
                transform="translate(48.000000, 123.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="boyabat"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="123 113 94 116 53 141 6 134 0 98 4 62 36 43 42 12 49 0 64 16 93 13 102 12 111 17 153 25 168 38 162 44 141 38 123 46 129 61"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sinop/" + id2}>
              <g
                id="904"
                transform="translate(159.000000, 73.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gerze"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="120 59 106 54 85 35 83 23 64 0 43 23 4 37 0 67 42 75 57 88 91 66"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sinop/" + id2}>
              <g
                id="898"
                transform="translate(150.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="73 73 67 59 69 38 71 33 91 29 94 22 84 19 68 26 56 19 51 6 37 0 25 3 20 24 17 29 29 41 38 68 30 85 15 96 5 112 0 135 9 140 13 110 52 96"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sinop/" + id2}>
              <g
                id="903"
                transform="translate(133.000000, 29.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="erfelek"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="34 0 6 20 6 47 0 68 9 81 8 107 17 106 22 83 32 67 47 56 55 39 46 12"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sinop/" + id2}>
              <g
                id="871"
                transform="translate(22.000000, 44.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ayancik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="117 5 68 9 44 0 36 3 24 4 24 24 29 35 13 49 0 84 68 91 75 79 90 95 119 92 120 66 111 53 117 32"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sinop/" + id2}>
              <g
                id="906"
                transform="translate(0.000000, 48.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="turkeli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="46 0 31 3 14 6 0 1 13 42 9 72 14 79 22 80 35 45 51 31 46 20"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default SinopMap;
