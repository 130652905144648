import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function KarabukMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 400 260"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Karabük" transform="translate(0, 0)">
            <NavLink to={"/karabuk/" + id2}>
              <g
                id="569"
                transform="translate(171.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="eflani"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="68 4 28 0 10 5 21 22 8 35 0 58 16 79 23 98 36 104 44 73 86 58 93 49 77 45"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/karabuk/" + id2}>
              <g
                id="572"
                transform="translate(82.000000, 5.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="safranbolu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="99 0 87 2 60 19 36 51 4 65 9 92 0 115 11 118 42 96 57 100 58 117 90 137 120 129 125 99 112 93 105 73 89 53 97 30 110 17"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/karabuk/" + id2}>
              <g
                id="573"
                transform="translate(0.000000, 70.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yenice"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="86 0 53 16 15 37 12 72 0 85 8 107 24 104 41 109 57 110 51 91 82 50 91 27"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/karabuk/" + id2}>
              <g
                id="568"
                transform="translate(51.000000, 101.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="121 41 89 21 88 4 73 0 42 22 31 19 0 60 6 79 25 82 48 67 65 84 85 79 103 89 117 88"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/karabuk/" + id2}>
              <g
                id="571"
                transform="translate(168.000000, 134.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ovacik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="34 0 52 6 71 35 60 61 34 67 9 61 0 55 4 8"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/karabuk/" + id2}>
              <g
                id="570"
                transform="translate(41.000000, 168.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="eskipazar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="136 27 127 44 102 70 75 86 62 73 23 64 11 52 6 21 0 11 16 12 35 15 58 0 75 17 95 12 113 22 127 21"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default KarabukMap;
