import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function ZonguldakMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 400 190"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Zonguldak" transform="translate(0, 0)">
            <NavLink to={"/zonguldak/" + id2}>
              <g
                id="1046"
                transform="translate(175.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="caycuma"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="19 -8.52651283e-14 9 8 11 19 0 49 17 78 40 76 69 60 58 37 59 18"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/zonguldak/" + id2}>
              <g
                id="1050"
                transform="translate(130.000000, 8.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kilimli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="54 0 17 13 0 25 11 38 25 44 45 41 56 11"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/zonguldak/" + id2}>
              <g
                id="1044"
                transform="translate(115.000000, 33.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="60 16 40 19 26 13 15 0 0 12 15 25 12 43 23 47 15 68 28 60 40 52 73 62 77 45"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/zonguldak/" + id2}>
              <g
                id="1049"
                transform="translate(188.000000, 60.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gokcebey"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="4 18 0 35 27 53 65 32 54 19 56 0 26 16"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/zonguldak/" + id2}>
              <g
                id="1047"
                transform="translate(105.000000, 85.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="devrek"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="110 28 107 63 96 76 103 98 9 104 0 71 20 42 37 26 38 8 50 0 83 10"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/zonguldak/" + id2}>
              <g
                id="1048"
                transform="translate(25.000000, 56.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="eregli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="118 37 105 45 87 43 70 23 69 0 56 7 30 11 20 23 13 23 0 29 1 37 7 41 0 65 18 80 24 97 17 124 89 133 80 100 100 71 117 55"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/zonguldak/" + id2}>
              <g
                id="1045"
                transform="translate(0.000000, 121.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="alapli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="25 0 14 15 0 26 12 38 22 55 33 59 42 59 49 32 43 15"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/zonguldak/" + id2}>
              <g
                id="1051"
                transform="translate(94.000000, 45.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kozlu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="21 0 0 11 1 34 18 54 36 56 44 35 33 31 36 13"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default ZonguldakMap;
