import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function TrabzonMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 400 180"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Trabzon" transform="translate(0, 0)">
            <NavLink to={"/trabzon/" + id2}>
              <g
                id="987"
                transform="translate(0.000000, 50.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="salpazari"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="15 0 22 61 15 56 0 22 1 2"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/trabzon/" + id2}>
              <g
                id="976"
                transform="translate(1.000000, 15.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="besikduzu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="1 21 9 0 27 11 26 35 13 35 0 37"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/trabzon/" + id2}>
              <g
                id="989"
                transform="translate(27.000000, 17.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="vakfikebir"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="1 9 9 7 18 0 42 25 38 42 30 48 17 39 4 39 0 33"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/trabzon/" + id2}>
              <g
                id="977"
                transform="translate(45.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="carsibasi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 17 13 1 22 0 24 42"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/trabzon/" + id2}>
              <g
                id="206"
                transform="translate(65.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="akcabaat"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="2 0 19 2 36 17 44 29 56 31 54 71 22 88 21 69 0 59 4 42"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/trabzon/" + id2}>
              <g
                id="980"
                transform="translate(53.000000, 59.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="duzkoy"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="34 29 15 35 0 15 4 6 12 0 33 10"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/trabzon/" + id2}>
              <g
                id="988"
                transform="translate(15.000000, 49.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="tonya"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="53 45 24 73 7 62 0 1 12 1 16 7 29 7 42 16 38 25"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/trabzon/" + id2}>
              <g
                id="983"
                transform="translate(39.000000, 66.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="macka"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 56 73 91 96 111 110 109 127 89 129 60 118 47 121 20 108 0 80 5 48 22 29 28"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/trabzon/" + id2}>
              <g
                id="985"
                transform="translate(119.000000, 25.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ortahisar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="41 61 49 57 41 33 53 10 44 4 25 0 2 6 0 46 28 41"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/trabzon/" + id2}>
              <g
                id="990"
                transform="translate(157.000000, 35.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yomra"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="11 91 18 85 33 26 29 7 20 6 15 0 3 23 11 47 3 51 0 78"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/trabzon/" + id2}>
              <g
                id="790"
                transform="translate(175.000000, 36.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="arsin"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 84 8 89 30 50 31 35 44 0 26 0 11 6 15 25"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/trabzon/" + id2}>
              <g
                id="647"
                transform="translate(180.000000, 36.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="arakli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="3 89 6 99 0 114 9 118 21 117 29 134 41 130 42 119 37 109 44 104 38 80 57 11 46 1 39 0 26 35 25 50"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/trabzon/" + id2}>
              <g
                id="986"
                transform="translate(218.000000, 46.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="surmene"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="19 1 30 5 52 0 49 32 28 33 20 51 17 97 6 94 0 70"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/trabzon/" + id2}>
              <g
                id="984"
                transform="translate(267.000000, 28.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="of"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="3 18 37 0 49 9 35 30 39 48 0 50"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/trabzon/" + id2}>
              <g
                id="979"
                transform="translate(265.000000, 76.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="dernekpazari"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="2 2 0 22 40 20 41 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/trabzon/" + id2}>
              <g
                id="982"
                transform="translate(235.000000, 78.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="koprubasi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="30 20 25 73 15 88 7 81 0 65 3 19 11 1 32 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/trabzon/" + id2}>
              <g
                id="978"
                transform="translate(250.000000, 96.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="caykara"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="55 0 57 21 71 40 88 47 94 78 45 82 19 79 0 70 10 55 15 2"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/trabzon/" + id2}>
              <g
                id="981"
                transform="translate(302.000000, 37.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="hayrat"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="14 0 32 28 47 80 36 106 19 99 5 80 3 60 4 39 0 21"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default TrabzonMap;
