import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../context/Context";
import styles from "../../css/cards/province_card.module.css";

function ProvinceCard() {
  const { infos, id } = useContext(Context);
  const [electionRate, setElectionRate] = useState(0);
  useEffect(() => {
    sessionStorage.setItem("myData", id);
  }, [id]);

  useEffect(() => {
   
    const provinceInfo = infos.find((info) => info.url_name === id);

    if (provinceInfo) {
      
      const animationDuration = 1000;
      const step = 5; 

      let currentPercentage = 0;

      const interval = setInterval(() => {
        currentPercentage += step;
        if (currentPercentage >= provinceInfo.mayor.election_rate) {
          currentPercentage = provinceInfo.mayor.election_rate;
          clearInterval(interval);
        }
        setElectionRate(currentPercentage);
      }, animationDuration / (provinceInfo.mayor.election_rate / step));

      
      
    }
  }, [id, infos]);

  let url = "https://api.ibrahimaluc.com";

  return (
    <>
      {infos.map((info, index) => {
        if (info.url_name === id) {
          return (
            <div key={index} className={styles.card}>
              <img
                className={styles.mayor_photo}
                src={url + info.mayor.mayor_photo}
                alt="Mayor "
              />
              <div className={styles.card_content}>
                <h3 className={styles.header}>Aldığı Oy Oranı</h3>
                <div className={styles.progress_bar}>
                  <div
                    className={styles.progress_bar_inner}
                    style={{ width: `${electionRate}%` }} // Use the animated electionRate value
                  >
                    {electionRate}%
                  </div>
                </div>
                <h3 className={styles.city_type}>{info.mayor.city_type_mayor}</h3>
                <h2 className={styles.mayor_name}>{info.mayor.mayor_name}</h2>
                <h3 className={styles.mayor_party}>{info.mayor.mayor_party}</h3>
                <div className={styles.lover}>
                  <img
                    src={url + info.mayor.party_logo}
                    alt="Party Logo"
                    className={styles.party_logo}
                  />
                  <div className={styles.election_year}>
                    <span className={styles.year_info}>Seçilme Yılı</span>
                    <p className={styles.year}>{info.mayor.election_year}</p>
                  </div>
                </div>
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
    </>
  );
}

export default ProvinceCard;
