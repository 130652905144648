import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function ManisaMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 550 460"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Manisa" transform="translate(0, 0)">
            <NavLink to={"/manisa/" + id2}>
              <g
                id="737"
                transform="translate(330.834606, 106.055556)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="demirci"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="90.0256666 27.9444444 80.0289855 0.944444444 36.0435889 4.94444444 0.0555371169 98.9444444 13.0512225 120.944444 11.0518863 131.944444 23.0479035 125.944444 25.0472397 128.944444 8.05288196 146.944444 9.05255006 151.944444 26.0469078 148.944444 25.0472397 151.944444 41.0419294 148.944444 63.0346277 155.944444 71.0319726 113.944444 117.016705 89.9444444 155.004093 90.9444444 157.00343 81.9444444 140.009072 70.9444444 145.007412 56.9444444 116.017037 25.9444444"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/manisa/" + id2}>
              <g
                id="746"
                transform="translate(393.424936, 195.500000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="selendi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="92.4137626 1.5 54.4263746 0.5 8.44164177 24.5 0.444296936 66.5 28.4350039 71.5 49.4280341 79.5 87.4154221 67.5 108.408452 45.5 108.408452 39.5 103.410112 22.5"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/manisa/" + id2}>
              <g
                id="742"
                transform="translate(333.389313, 254.277778)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kula"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="109.463657 20.7222222 86.4712911 112.722222 57.480916 92.7222222 48.4839031 100.722222 0.499834052 79.7222222 10.4965151 68.7222222 6.49784268 50.7222222 7.49751079 30.7222222 1.49950216 20.7222222 10.4965151 8.72222222 22.4925324 3.72222222 38.487222 0.722222222 60.4799203 7.72222222 88.4706273 12.7222222"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/manisa/" + id2}>
              <g
                id="743"
                transform="translate(196.712468, 238.944444)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="salihli"
                  d="M147.17336,24.0555556 L135.177343,21.0555556 L132.178338,29.0555556 L109.185972,47.0555556 L107.186636,42.0555556 L114.184312,37.0555556 L99.1892909,27.0555556 L94.1909503,3.05555556 L59.2025667,0.0555555556 L36.2102002,26.0555556 L46.2068813,32.0555556 L39.2092046,45.0555556 L28.2128554,41.0555556 L21.2151787,42.0555556 L15.21717,35.0555556 L11.2184976,34.0555556 L9.21916141,47.0555556 C9.21916141,47.0555556 18.2496181,65.0555556 18.2161744,65.0555556 C18.2496181,65.0555556 -0.739150118,101.055556 0.222148468,101.055556 C1.25966758,101.055556 41.2085408,109.055556 41.2085408,109.055556 L49.2058856,128.055556 L58.2028986,137.055556 L73.1979201,135.055556 L103.187963,93.0555556 L126.18033,85.0555556 L143.174687,66.0555556 L144.174356,46.0555556 L138.176347,36.0555556 L147.17336,24.0555556 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/manisa/" + id2}>
              <g
                id="738"
                transform="translate(180.106870, 223.611111)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="golmarmara"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="27.8240956 49.3888889 8.83040159 38.3888889 0.833056754 25.3888889 8.83040159 11.3888889 7.83073349 0.388888889 33.8221042 0.388888889 60.813143 8.38888889 75.8081646 15.3888889 52.8157982 41.3888889 42.8191172 40.3888889 37.8207766 45.3888889 32.8224361 47.3888889 31.822768 50.3888889"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/manisa/" + id2}>
              <g
                id="741"
                transform="translate(287.404580, 201.888889)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="koprubasi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="54.4819117 36.1111111 47.484235 37.1111111 39.4868901 56.1111111 34.4885496 58.1111111 31.4895453 69.1111111 23.4922005 74.1111111 8.49717889 64.1111111 3.49883837 40.1111111 0.499834052 19.1111111 25.4915367 0.111111111 43.4855626 3.11111111 56.4812479 25.1111111"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/manisa/" + id2}>
              <g
                id="739"
                transform="translate(240.142494, 109.888889)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gordes"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="126.735701 1.11111111 85.7493086 5.11111111 71.7539551 16.1111111 38.7649076 19.1111111 30.7675628 33.1111111 28.7682266 75.1111111 0.777519637 100.111111 0.777519637 122.111111 15.7725412 129.111111 50.7609249 132.111111 47.7619206 111.111111 72.7536232 92.1111111 90.7476491 95.1111111"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/manisa/" + id2}>
              <g
                id="267"
                transform="translate(97.078880, 71.555556)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="akhisar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="181.828521 57.4444444 168.832835 34.4444444 141.841797 14.4444444 135.843788 0.444444444 109.852417 26.4444444 109.852417 39.4444444 65.8670207 61.4444444 37.8763138 51.4444444 7.88627061 63.4444444 0.888593871 70.4444444 5.8869344 99.4444444 49.872331 108.444444 61.8683483 138.444444 90.8587233 152.444444 116.850094 152.444444 143.841133 160.444444 143.841133 138.444444 171.83184 113.444444 173.831176 71.4444444"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/manisa/" + id2}>
              <g
                id="740"
                transform="translate(134.122137, 25.555556)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kirkagac"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="98.800531 46.4444444 88.80385 27.4444444 88.80385 4.44444444 82.8058414 0.444444444 61.8128112 10.4444444 48.8171258 9.44444444 47.8174577 51.4444444 12.829074 62.4444444 0.833056754 97.4444444 28.8237637 107.444444 72.8091603 85.4444444 72.8091603 72.4444444"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/manisa/" + id2}>
              <g
                id="747"
                transform="translate(85.582697, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="soma"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="97.356566 35 85.3605487 25 56.3701737 25 24.3807943 4 9.38577276 0 0.388759819 15 15.3837814 48 14.3841133 83 21.38179 115 19.3824538 135 49.372497 123 61.3685142 88 96.3568979 77"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/manisa/" + id2}>
              <g
                id="745"
                transform="translate(63.867684, 141.833333)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="saruhanli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="124.069919 82.1666667 95.0795442 68.1666667 83.0835269 38.1666667 39.0981303 29.1666667 34.0997898 0.166666667 1.11074234 18.1666667 6.10908286 36.1666667 27.1021131 61.1666667 17.105432 75.1666667 48.0951433 101.166667 70.0878416 131.166667 91.0808718 124.166667 104.076557 125.166667 125.069587 120.166667 117.072243 107.166667 125.069587 93.1666667"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/manisa/" + id2}>
              <g
                id="750"
                transform="translate(0.000000, 159.722222)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yunusemre"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="64.9784268 0.277777778 21.9926983 1.27777778 24.9917026 14.2777778 10.9963492 8.27777778 0 50.2777778 21.9926983 74.2777778 31.9893794 120.277778 53.9820777 126.277778 68.9770992 129.277778 73.9754398 80.2777778 71.9761036 66.2777778 80.9731165 57.2777778 90.9697975 43.2777778 69.9767673 18.2777778"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/manisa/" + id2}>
              <g
                id="748"
                transform="translate(68.977099, 215.944444)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="11.9960173 1.05555556 2.99900431 10.0555556 4.99834052 24.0555556 0 73.0555556 37.987388 100.055556 50.9830733 80.0555556 64.9784268 57.0555556 42.9857285 27.0555556"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/manisa/" + id2}>
              <g
                id="734"
                transform="translate(166.055980, 261.944444)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ahmetli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="30.878637 78.0555556 48.8726629 42.0555556 39.87565 24.0555556 41.8749862 11.0555556 22.8812922 0.0555555556 1.88826198 5.05555556 0.888593871 22.0555556 10.8852749 44.0555556 5.8869344 70.0555556 17.8829517 89.0555556 20.881956 80.0555556"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/manisa/" + id2}>
              <g
                id="749"
                transform="translate(106.020356, 265.777778)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="turgutlu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="77.9185751 85.2222222 76.918907 104.222222 68.9215621 112.222222 56.9255449 105.222222 39.9311871 74.2222222 0.944130988 50.2222222 27.9351698 7.22222222 48.9282 0.222222222 61.9238854 1.22222222 60.9242173 17.2222222 70.9208983 40.2222222 65.9225578 66.2222222"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/manisa/" + id2}>
              <g
                id="410"
                transform="translate(269.521628, 304.111111)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="alasehir"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="150.338976 62.8888889 149.339307 70.8888889 105.353911 96.8888889 102.354907 105.888889 115.350592 124.888889 98.3562341 131.888889 84.3608806 148.888889 63.3678504 146.888889 69.3658591 108.888889 23.3811262 70.8888889 0.388759819 69.8888889 30.378803 27.8888889 53.3711694 19.8888889 70.3655272 0.888888889 74.3641996 18.8888889 64.3675185 29.8888889 112.351588 50.8888889 121.348601 42.8888889"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/manisa/" + id2}>
              <g
                id="744"
                transform="translate(353.826972, 374.388889)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sarigol"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="65.0339639 0.611111111 21.0485673 26.6111111 18.049563 35.6111111 31.0452484 54.6111111 14.0508906 61.6111111 0.0555371169 78.6111111 9.05255006 81.6111111 24.0475716 85.6111111 36.0435889 83.6111111 54.0376148 56.6111111 87.0266622 38.6111111 84.0276579 21.6111111"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default ManisaMap;
