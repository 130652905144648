import SiteProvider from "./context/Context";
import HomeLayout from "./layouts/HomeLayout";
import React from "react";
import { StrictMode } from "react";

function App() {
  return (
    <>
      <StrictMode>
        <SiteProvider>
          <HomeLayout />
        </SiteProvider>
      </StrictMode>
    </>
  );
}

export default App;
