import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function ErzurumMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 850 620"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Erzurum" transform="translate(0, 0)">
            <NavLink to={"/erzurum/" + id2}>
              <g
                id="389"
                transform="translate(48.000000, 144.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="pazaryolu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="62 1.11022302e-16 98 76 26 117 0 94 24 79 6 58 22 24"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/erzurum/" + id2}>
              <g
                id="380"
                transform="translate(75.000000, 57.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="ispir"
                  d="M0,204 L40,209 L144,168 L139,116 L186,82 L176,24 L129,0 L124,2 L116,21 L60,47 L35,87 L71,162 L0,204 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/erzurum/" + id2}>
              <g
                id="392"
                transform="translate(214.000000, 139.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="tortum"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="47 0 117 26 137 50 129 89 148 98 133 121 118 121 67 103 43 131 26 125 5 86 0 34"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/erzurum/" + id2}>
              <g
                id="393"
                transform="translate(261.000000, 97.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="uzundere"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="70 68 95 6 58 0 0 42"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/erzurum/" + id2}>
              <g
                id="385"
                transform="translate(331.000000, 63.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="oltu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="25 40 55 0 131 10 144 121 20 126 0 102"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/erzurum/" + id2}>
              <g
                id="384"
                transform="translate(343.000000, 184.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="narman"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="132 0 125 25 125 33 75 77 4 76 19 53 0 44 8 5"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/erzurum/" + id2}>
              <g
                id="388"
                transform="translate(298.000000, 260.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="pasinler"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="120 1 121 20 98 111 68 124 4 122 4 84 0 44 35 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/erzurum/" + id2}>
              <g
                id="383"
                transform="translate(395.000000, 280.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="koprukoy"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="24 0 81 81 1 91"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/erzurum/" + id2}>
              <g
                id="379"
                transform="translate(418.000000, 217.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="horasan"
                  d="M50,0 L61,23 L110,33 L171,99 L105,144 L58,144 L1,63 L0,44 L50,0 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/erzurum/" + id2}>
              <g
                id="382"
                transform="translate(366.000000, 361.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="karayazi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 23 43 122 70 132 155 166 194 120 218 94 210 50 157 0 110 0 30 10"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/erzurum/" + id2}>
              <g
                id="381"
                transform="translate(436.000000, 493.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="karacoban"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 0 4 99 55 85 85 34"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/erzurum/" + id2}>
              <g
                id="378"
                transform="translate(304.000000, 483.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="hinis"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="105 0 57 4 1 31 0 61 35 71 75 125 111 123 136 109 132 10"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/erzurum/" + id2}>
              <g
                id="391"
                transform="translate(211.000000, 382.000000)"
                fill="#f0f0f0"
                opacity="1"
              >
                <polygon
                  id="tekman"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="93 162 45 166 0 136 15 80 56 42 48 16 64 0 91 0 155 2 198 101 150 105 94 132"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/erzurum/" + id2}>
              <g
                id="377"
                transform="translate(98.000000, 398.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cat"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="113 120 47 135 16 139 0 128 10 106 43 97 0 49 75 20 161 0 169 26 128 64"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/erzurum/" + id2}>
              <g
                id="387"
                transform="translate(155.000000, 344.000000)"
                fill="#f0f0f0"
                stroke="#000000"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="palandoken"
                  points="147 0 69 19 56 48 38 50 16 30 0 51 18 74 104 54 120 38 147 38"
                  fill="#f0f0f0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/erzurum/" + id2}>
              <g
                id="375"
                transform="translate(0.000000, 308.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="askale"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="85 4 98 16 134 0 171 66 155 87 173 110 98 139 68 77 17 66 0 51 19 33"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/erzurum/" + id2}>
              <g
                id="376"
                transform="translate(85.000000, 225.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="aziziye"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 87 30 41 134 0 155 39 129 52 139 138 126 167 108 169 86 149 49 83 13 99"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/erzurum/" + id2}>
              <g
                id="386"
                transform="translate(386.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="olur"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 63 4 9 15 0 101 3 130 29 89 46 76 73"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/erzurum/" + id2}>
              <g
                id="390"
                transform="translate(462.000000, 29.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="senkaya"
                  d="M54,0 L105,49 L105,116 L65,165 L6,180 L13,155 L0,44 L13,17 L54,0 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/erzurum/" + id2}>
              <g
                id="394"
                transform="translate(214.000000, 242.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yakutiye"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="119 18 67 0 43 28 26 22 0 35 10 121 88 102 84 62"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default ErzurumMap;
