import React, { useContext, useState, useEffect, useRef } from "react";
import styles from "../../css/promises/provincepromises.module.css";
import ProvinceEditor from "../../components/cards/ProvinceEditor";
import { Context } from "../../context/Context";
import cross from "../../assets/cross.svg";
import tick from "../../assets/tick.svg";
import megafon from "../../assets/megafon.svg";
import cont from "../../assets/cont.svg";
import ProvinceHallExplanation from "../cards/ProvinceHallExplanation";
import { useLocation } from "react-router-dom";

function ProvincePromises() {
  const { infos } = useContext(Context);
  const [isPencereOpen, setIsPencereOpen] = useState(false);
  const [selectedPromise, setSelectedPromise] = useState(null);
  const [isWindowOpen, setIsWindowOpen] = useState(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
 
  const location = useLocation();
  const { pathname } = location;
  const words = pathname.split("/").filter((word) => word.trim() !== "");
  const provinceEditorRef = useRef();

  useEffect(() => {
    const data = infos.find((item) => item.url_name === words[0]);
    if (data) {
      setFileUrl(data.province_document);
    }
  }, [infos, words[0]]);

  const itemsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(1);

  const info_length = infos
    .filter((data) => data.url_name === words[0])
    .map((data) => data.promises.length);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, info_length);

  // Render the items within the current page's range
  const filteredItems = infos
    .filter((data) => data.url_name === words[0])
    .map((data) => data.promises)
    .flat();
  const visibleItems = filteredItems.slice(startIndex, endIndex);

  // Handle pagination button click
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleButtonClicked = (event) => {
    setIsPencereOpen(!isPencereOpen);
    const promiseValue = event.currentTarget.querySelector("p").getAttribute("index");
    console.log(promiseValue)
    setSelectedPromise(promiseValue);
    event.stopPropagation();
  };

  const handleCloseEditor = () => {
    setIsWindowOpen(false);
  };

  const onAnnouncementClick = (event) => {
    setIsWindowOpen(!isWindowOpen);
    const parentDiv = event.currentTarget.parentElement;
    const pElement = parentDiv.querySelector("p").getAttribute("index");
   
    setSelectedAnnouncement(pElement);
    event.stopPropagation();
  };

  const icons = {
    Tamamlandı: tick,
    DevamEdiyor: cont,
    Yapılmadı: cross,
  };

  const handleDownload = () => {
    let mainurl = "https://api.ibrahimaluc.com";
    let download = mainurl + fileUrl;

    fetch(download)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const a = document.createElement("a");
        a.href = url;
        a.download = fileUrl;
        a.style.display = "none";
        document.body.appendChild(a);

        a.click();

        document.body.removeChild(a);

        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        alert("Resim indirilemedi.", error);
      });
  };

  return (
    <>
      <div id="promises" className={styles.container}>
        <h2 className={styles.header}>Vaatler</h2>

        {visibleItems.length > 0 ? (
          <ul className={styles.list}>
            {visibleItems.map((promise, index) => (
              <div className={styles.element} key={startIndex + index}>
                <li onClick={handleButtonClicked} className={styles.promises}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h3 className={styles.index}> {startIndex + index + 1} </h3>
                    <p index={promise.id} className={styles.vaat}>{promise.promise}</p>
                  </div>
                  <img className={styles.situation} src={icons[promise.progress]} alt="" />
                </li>
                {promise.statement && (
                  <div
                    onClick={onAnnouncementClick}
                    className={styles.announcement_container}
                  >
                    <img
                      className={styles.announcement_image}
                      src={megafon}
                      alt="announcement"
                    />
                    <p className={styles.announcement}>Başkanın Mesajını Oku</p>
                  </div>
                )}
              </div>
            ))}
          </ul>
        ) : (
          <ul className={styles.list}>
            <div className={styles.element}>
              <li className={styles.promises}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h3 className={styles.index}> - </h3>
                  <p className={styles.vaat}>Vaatler Daha Sonra Girilecektir</p>
                </div>
                <img  className={styles.situation} src={cross} alt="" />
              </li>
            </div>
          </ul>
        )}

        <div>
          {info_length > itemsPerPage && (
            <div className={styles.pagination}>
              {Array.from({
                length: Math.ceil(info_length / itemsPerPage),
              }).map((_, index) => (
                <button
                  key={index}
                  className={currentPage === index + 1 ? styles.active : ""}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
            </div>
          )}
        </div>
        {fileUrl && (
          <div className={styles.button_container}>
            <button className={styles.download_button} onClick={handleDownload}>
              Dökümanı İndir
            </button>
          </div>
        )}
      </div>
      {/* Pagination buttons */}

      {/* Show the ProvinceEditor and ProvinceHallExplanation components */}
      {isPencereOpen && (
        <div ref={provinceEditorRef}>
          <ProvinceEditor
            selectedPromise={selectedPromise}
            onClose={() => setIsPencereOpen(false)}
          />
        </div>
      )}
      {isWindowOpen && (
        <ProvinceHallExplanation
          selectedAnnouncement={selectedAnnouncement}
          onClose={handleCloseEditor}
        />
      )}
    </>
  );
}

export default ProvincePromises;
