import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function BingolMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 500 360"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Bingöl" transform="translate(0, 0)">
            <NavLink to={"/bingol/" + id2}>
              <g
                id="205"
                transform="translate(69.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yedisu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="104 0 51 1 0 37 8 48 31 54 45 40 135 54 141 29 110 33 94 22"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bingol/" + id2}>
              <g
                id="201"
                transform="translate(196.000000, 14.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="karliova"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="14 15 80 0 125 30 98 50 120 90 112 121 62.5 111 35 141 26 123 0 122 23 56 8 40"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bingol/" + id2}>
              <g
                id="202"
                transform="translate(44.000000, 48.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kigi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="56 6 76 28 66 85 48 89 30 70 0 30 33 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bingol/" + id2}>
              <g
                id="204"
                transform="translate(0.000000, 78.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yayladere"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="44 0 11 1 0 69 33 75 74 40"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bingol/" + id2}>
              <g
                id="198"
                transform="translate(87.000000, 133.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="merkez"
                  d="M23,0 C24.9999983,1.49075845e-06 108,3 108,3 L135,4 L144,22 L162,77 L54,139 L13,133 L28,109 L0,90 L2,83 L5,4 C5,4 21.0000017,-1.49075845e-06 23,0 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/bingol/" + id2}>
              <g
                id="200"
                transform="translate(67.000000, 210.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="genc"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="33 56 0 99 43 148 64 141 79 92 143 96 176 98 243 56 182 0 74 62"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bingol/" + id2}>
              <g
                id="203"
                transform="translate(231.000000, 104.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="solhan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="79 162 100 151 87 121 102 61 133 39 85 0 77 31 27 21 0 51 18 106"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bingol/" + id2}>
              <g
                id="199"
                transform="translate(100.000000, 40.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="adakli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="104 14 119 30 96 96 10 93 20 36 0 14 14 0"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default BingolMap;
