import React from "react";
import styles from "../css/footer/footer.module.css";
import { Link } from "react-router-dom";
import Logo from "../assets/Logo-Beyaz.svg";
import facebook from "../assets/facebookf.svg"
import instagram from "../assets/instaf.svg"
import spotify from "../assets/Spotify.svg"
import threads from "../assets/Threadsf.svg"
import twitter from "../assets/xf.svg"
import youtube from "../assets/youtube.svg"

const handleClick =() =>{
  window.scrollTo(0, 0);
}

function Footer() {
  return (
    <>
      <div className={styles.container}>
        <img onClick={handleClick} className={styles.logo} src={Logo} alt="Logo" />
        <div className={styles.links}>
        <div className={styles.div}>
          <h3 className={styles.header}>Hakkımızda</h3>
          <Link className={styles.link} to="/biz-kimiz">
            Biz Kimiz?
          </Link>             
          <Link className={styles.link} to="/iletisim">
            İletişim
          </Link>
        </div>
        <div className={styles.div}>
          <h3 className={styles.header}>Hukuk</h3>
          <Link className={styles.link} to="/kullanim-kosullari">
            Kullanım Koşulları
          </Link>
          <Link className={styles.link} to="/gizlilik-sozlesmesi">
            Gizlilik Sözleşmesi
          </Link>
          <Link className={styles.link} to="/cerez-politikasi">
            Çerez Politikası
          </Link>
        </div>
        <div className={styles.div}>
          <h3 className={styles.header}>Yayın</h3>
          <Link className={styles.link} to="/yayin-ilkeleri">
            Yayın İlkeleri
          </Link>
          <Link className={styles.link} to="/metodoloji">
            Metodoloji
          </Link>
          <Link className={styles.link} to="/degerlerimiz">
            Değerlerimiz
          </Link>
        </div>
        <div className={styles.div}>
          <h3 className={styles.header}>Takip et</h3>
          <div className={styles.icons}>
            <img className={styles.icon} src={twitter} alt="Twitter" />
            <img className={styles.icon} src={instagram} alt="Instagram" />
            <img className={styles.icon} src={facebook} alt="Facebook" />
            <img className={styles.icon} src={threads} alt="Threads" />
            <img className={styles.icon} src={spotify} alt="Spotify" />
            <img className={styles.icon} src={youtube} alt="Youtube" />
          </div>
        </div>
        </div>
    
      </div>
      <p className={styles.info}>Copyright © 2023 Tüm Hakları Saklıdır.</p>
    </>
  );
}

export default Footer;
