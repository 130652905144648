import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function NigdeMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 600 370"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Niğde" transform="translate(0, 0)">
            <NavLink to={"/nigde/" + id2}>
              <g
                id="808"
                transform="translate(0.000000, 76.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="altunhisar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 60 68 0 92 18 119 58 84 79 21 67"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/nigde/" + id2}>
              <g
                id="809"
                transform="translate(20.000000, 134.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bor"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 9 4 17 77 90 74 123 162 115 179 97 201 97 209 72 165 64 99 0 64 21"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/nigde/" + id2}>
              <g
                id="811"
                transform="translate(68.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ciftlik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 76 35 55 21 16 22 0 65 32 74 76 56 99 24 94"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/nigde/" + id2}>
              <g
                id="807"
                transform="translate(92.000000, 24.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 70 27 110 93 174 137 182 127 153 158 104 231 79 221 68 171 55 154 2 129 0 98 12 41 8 50 52 32 75"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/nigde/" + id2}>
              <g
                id="810"
                transform="translate(219.000000, 103.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="camardi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="104 0 112 71 95 114 79 127 42 145 4 146 2 128 10 103 0 74 31 25"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/nigde/" + id2}>
              <g
                id="812"
                transform="translate(77.000000, 231.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ulukisla"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="144 0 147 18 147 70 111 106 67 121 28 135 2 87 0 40 17 26 105 18 122 0"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default NigdeMap;
