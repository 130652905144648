import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function KahramanmarasMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 600 480"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Kahramanmaraş" transform="translate(0, 0)">
            <NavLink to={"/kahramanmaras/" + id2}>
              <g
                id="561"
                transform="translate(215.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="elbistan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="76 0 8 10 0 156 83 164 167 167 192 124 159 78 55 37"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kahramanmaras/" + id2}>
              <g
                id="97"
                transform="translate(106.000000, 10.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="afsin"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="117 0 35 13 19 22 26 48 0 55 50 149 109 146"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kahramanmaras/" + id2}>
              <g
                id="560"
                transform="translate(191.000000, 156.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ekinozu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="24 0 0 49 20 50 18 95 70 66 86 17 109 8"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kahramanmaras/" + id2}>
              <g
                id="563"
                transform="translate(209.000000, 164.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="nurhak"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="173 3 171 56 134 106 32 95 0 87 52 58 68 9 90 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kahramanmaras/" + id2}>
              <g
                id="562"
                transform="translate(28.000000, 65.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="goksun"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="187 91 129 94 78 0 31 68 13 100 12 124 13 148 0 173 9 186 38 192 73 221 79 196 57 162 83 142 131 159 163 140"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kahramanmaras/" + id2}>
              <g
                id="564"
                transform="translate(70.000000, 207.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="onikisubat"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="89 17 113 63 106 82 99 88 90 85 82 80 73 74 72 79 79 82 94 98 99 94 106 93 117 78 128 115 115 178 70 178 53 190 21 194 12 203 0 207 0 201 5 200 1 188 11 174 25 170 23 165 14 169 28 122 31 79 37 54 15 20 41 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kahramanmaras/" + id2}>
              <g
                id="559"
                transform="translate(159.000000, 205.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="dulkadiroglu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="52 1 32 0 0 19 24 65 30 57 33 61 28 80 39 117 26 180 48 196 67 195 110 139 109 96 85 82 82 54 50 46"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kahramanmaras/" + id2}>
              <g
                id="558"
                transform="translate(241.000000, 259.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="caglayancerit"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 0 102 11 87 49 27 42 3 28"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kahramanmaras/" + id2}>
              <g
                id="565"
                transform="translate(192.000000, 301.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="pazarcik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="76 0 77 43 34 99 15 100 0 122 13 126 18 148 32 169 52 170 144 107 165 104 188 57 180 47 170 32 152 34 136 7"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kahramanmaras/" + id2}>
              <g
                id="566"
                transform="translate(82.000000, 385.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="turkoglu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="103 0 58 0 41 12 9 16 0 25 20 28 37 51 54 81 86 68 110 38 125 16"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kahramanmaras/" + id2}>
              <g
                id="557"
                transform="translate(0.000000, 251.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="andirin"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="37 0 27 35 26 44 23 59 0 71 1 79 19 84 18 170 23 167 23 160 28 159 29 164 51 171 58 165 66 165 70 163 70 157 66 151 66 141 75 129 84 125 98 78 101 35 66 6"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default KahramanmarasMap;
