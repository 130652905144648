import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function HatayMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 550 410"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Hatay" transform="translate(0, 0)">
            <NavLink to={"/hatay/" + id2}>
              <g
                id="456"
                transform="translate(48.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="erzin"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="23 0 1 28 0 44 9 41 39 57 90 22 70 3"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/hatay/" + id2}>
              <g
                id="455"
                transform="translate(87.000000, 22.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="dortyol"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="51 0 94 2 75 60 20 56 0 35"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/hatay/" + id2}>
              <g
                id="457"
                transform="translate(151.000000, 24.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="hassa"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="30 0 46 23 86 39 66 96 0 95 11 58"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/hatay/" + id2}>
              <g
                id="461"
                transform="translate(107.000000, 78.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="payas"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="55 4 0 0 2 25 44 41"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/hatay/" + id2}>
              <g
                id="458"
                transform="translate(99.000000, 103.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="iskenderun"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="52 16 29 58 10 66 0 43 10 42 13 23 10 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/hatay/" + id2}>
              <g
                id="459"
                transform="translate(127.000000, 119.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kirikhan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="24 0 1 43 0 81 13 94 4 116 22 116 50 106 56 75 86 62 90 1"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/hatay/" + id2}>
              <g
                id="460"
                transform="translate(177.000000, 181.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kumlu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="36 0 53 45 43 62 0 44 6 13"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/hatay/" + id2}>
              <g
                id="462"
                transform="translate(122.000000, 225.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="reyhanli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="108 1 117 2 130 31 107 42 53 45 44 53 35 30 0 20 9 10 27 10 55 0 98 18"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/hatay/" + id2}>
              <g
                id="449"
                transform="translate(103.000000, 255.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="altinozu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="63 23 59 89 42 85 39 103 17 107 7 83 10 73 0 60 21 18 46 16 54 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/hatay/" + id2}>
              <g
                id="464"
                transform="translate(40.000000, 332.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yayladagi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="70 6 40 0 14 4 0 33 15 38 30 33 35 49 72 71 80 30"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/hatay/" + id2}>
              <g
                id="451"
                transform="translate(43.000000, 200.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="antakya"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="67 138 37 132 64 80 54 69 44 68 36 79 0 37 45 5 84 0 97 13 88 35 79 45 114 55 106 71 81 73 60 115 70 128"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/hatay/" + id2}>
              <g
                id="454"
                transform="translate(66.000000, 268.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="defne"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="13 11 23 26 0 53 14 64 41 12 31 1 21 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/hatay/" + id2}>
              <g
                id="452"
                transform="translate(0.000000, 146.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="arsuz"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="43 91 7 115 0 93 23 64 37 44 99 0 109 23 87 38 88 59"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/hatay/" + id2}>
              <g
                id="453"
                transform="translate(87.000000, 161.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="belen"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 23 1 44 40 39 41 0 22 8"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/hatay/" + id2}>
              <g
                id="463"
                transform="translate(7.000000, 237.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="samandag"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 24 47 99 73 95 59 84 82 57 72 42 36 0"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default HatayMap;
