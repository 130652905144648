import React, { useContext } from "react";
import "@splidejs/react-splide/css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import styles from "../../css/youtube/youtube.module.css";
import { Context } from "../../context/Context";
import Ad from "../ads/Ad";
function Youtube() {
  const { youtube } = useContext(Context);

  return (
    <div id="youtube" className={styles.container}>
      <h1 className={styles.header}>Youtube</h1>
      <hr className={styles.hr} />
      <div className={styles.main_container}>
        <Ad />
        <Splide
          className={styles.splide_main}
          options={{
            type: "loop",
            perPage: 1,
            autoplay: true,
            interval: 5000,
            speed: 4000,
            pagination: true,
            focus: "center",
            arrows: false,
          }}
        >
          {youtube.map((data, index) => (
            <SplideSlide className={styles.splide} key={index}>
              <iframe
                className={styles.iframe}
                src={`https://www.youtube.com/embed/${data.watch_id}`}
                title="YouTube Video Player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </SplideSlide>
          ))}
        </Splide>
        <Ad />
      </div>
    </div>
  );
}

export default Youtube;
