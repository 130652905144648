import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function OrduMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 400 270"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Ordu" transform="translate(0, 0)">
            <NavLink to={"/ordu/" + id2}>
              <g
                id="280"
                transform="translate(0.000000, 71.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="akkus"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="1 16 0 28 17 38 53 72 119 78 129 12 95 0 51 2 10 17"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ordu/" + id2}>
              <g
                id="819"
                transform="translate(51.000000, 29.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="caybasi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 44 22 14 66 0 65 11 44 42"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ordu/" + id2}>
              <g
                id="824"
                transform="translate(73.000000, 16.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ikizce"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 27 5 8 35 0 44 13"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ordu/" + id2}>
              <g
                id="832"
                transform="translate(95.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="unye"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="13 16 25 4 47 5 53 0 60 4 60 10 101 28 90 37 80 65 64 59 53 64 34 83 0 71 21 40 22 29"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ordu/" + id2}>
              <g
                id="820"
                transform="translate(175.000000, 28.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="fatsa"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="21 0 32 12 50 12 62 1 69 13 74 45 66 53 44 44 28 44 12 53 0 37 10 9"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ordu/" + id2}>
              <g
                id="830"
                transform="translate(237.000000, 5.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="persembe"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 24 12 0 24 3 38 1 40 18 51 36 60 36 49 51 27 54 12 68 7 36"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ordu/" + id2}>
              <g
                id="815"
                transform="translate(249.000000, 41.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="atinordu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="48 0 56 10 83 11 81 29 88 41 102 41 96 53 48 52 29 37 11 37 1 41 0 32 15 18 37 15"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ordu/" + id2}>
              <g
                id="825"
                transform="translate(297.000000, 93.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kabaduz"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="48 1 44 23 36 37 37 48 51 57 57 87 44 96 23 94 15 84 21 51 5 41 0 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ordu/" + id2}>
              <g
                id="829"
                transform="translate(235.000000, 134.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="mesudiye"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="67 0 56 5 0 67 13 71 2 108 23 130 43 137 46 115 55 113 68 125 97 115 102 82 144 69 146 64 147 52 139 44 119 46 106 55 85 53 77 42 83 10"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ordu/" + id2}>
              <g
                id="831"
                transform="translate(244.000000, 78.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ulubey"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="47 61 4 44 0 25 6 4 16 0 34 0 53 15 58 56"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ordu/" + id2}>
              <g
                id="821"
                transform="translate(218.000000, 122.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="golkoy"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="30 0 19 0 0 7 1 20 1 81 17 79 73 17"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ordu/" + id2}>
              <g
                id="817"
                transform="translate(198.000000, 72.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="camas"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="43 9 27 32 13 26 0 3 5 0 21 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ordu/" + id2}>
              <g
                id="818"
                transform="translate(195.000000, 75.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="catalpinar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="16 23 14 35 6 41 0 31 3 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ordu/" + id2}>
              <g
                id="823"
                transform="translate(201.000000, 73.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gurguntepe"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 43 17 56 36 49 47 49 43 30 49 9 48 0 40 8 24 31 10 25 8 37"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ordu/" + id2}>
              <g
                id="826"
                transform="translate(175.000000, 116.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kabatas"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="26 0 0 19 44 26 43 13"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ordu/" + id2}>
              <g
                id="816"
                transform="translate(145.000000, 135.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="aybasti"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="30 0 0 28 5 42 15 60 54 70 74 68 74 7"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ordu/" + id2}>
              <g
                id="827"
                transform="translate(136.000000, 75.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="korgan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="9 88 0 77 32 22 51 6 62 0 59 31 65 41 39 60"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ordu/" + id2}>
              <g
                id="828"
                transform="translate(119.000000, 59.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kumru"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="17 93 0 90 10 24 29 5 40 0 56 6 68 22 49 38"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ordu/" + id2}>
              <g
                id="822"
                transform="translate(330.000000, 52.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gulyali"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="2 0 32 7 21 30 7 30 0 18"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default OrduMap;
