import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function SiirtMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 550 220"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Siirt" transform="translate(0, 0)">
            <NavLink to={"/siirt/" + id2}>
              <g
                id="894"
                transform="translate(197.000000, 59.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="pervari"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="227 67 206 19 168 22 145 41 99 0 72 33 0 36 74 113 127 82 201 112"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/siirt/" + id2}>
              <g
                id="895"
                transform="translate(138.000000, 4.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sirvan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="158 55 97 19 59 0 24 23 0 65 20 73 49 82 59 91 131 88"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/siirt/" + id2}>
              <g
                id="892"
                transform="translate(117.000000, 86.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="eruh"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="80 9 154 86 128 117 87 119 40 119 14 102 0 81 7 58 46 22 70 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/siirt/" + id2}>
              <g
                id="896"
                transform="translate(158.000000, 77.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="tillo"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="29 9 0 0 6 30"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/siirt/" + id2}>
              <g
                id="890"
                transform="translate(99.000000, 66.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="59 11 39 3 0 0 25 78 65 41"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/siirt/" + id2}>
              <g
                id="893"
                transform="translate(0.000000, 54.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kurtalan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="99 13 48 0 19 23 0 41 19 62 90 75 85 109 117 113 124 90"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/siirt/" + id2}>
              <g
                id="567"
                transform="translate(48.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="baykan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 54 25 0 52 9 114 27 90 69 51 67"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default SiirtMap;
