import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import styles from "../css/navbar/navbar.module.css";
import Logo from "../assets/Logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faBars,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";


function getClassNameForResultType(resultType) {
  switch (resultType) {
    case "Vaat":
      return styles.type1;
    case "İl":
      return styles.type2;
    case "İlçe":
      return styles.type3;
    // Add more cases for other result types if needed
    default:
      return styles.type4;
  }
}

function Navbar() {
  const navRef = useRef();
  const navRef1 = useRef();
  const [scrolled, setScrolled] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const openMenu = () => {
    navRef.current.classList.toggle(styles.responsive_nav);
    navRef1.current.classList.toggle(styles.responsive_nav);
  };

  const handleSearch = async (inputValue) => {
    if (inputValue.length > 0) {
      const token = "8f6c14510d09a853e6145cc52567a9bebe86c172";
      const apiUrl = "https://api.ibrahimaluc.com/api/search/";

      const requestData = {
        params: {
          query: inputValue,
        },
        headers: {
          Authorization: `Token ${token}`,
        },
      };

      try {
        const response = await axios.post(apiUrl, null, requestData);
        const data = response.data;

        if (Array.isArray(data.search_results)) {
          setSearchResults(data.search_results);
        } else {
          setSearchResults([]);
        }
      } catch (error) {
        console.error("API isteği başarısız:", error);
        console.log("Status code:", error.response?.status);
        console.log("Response data:", error.response?.data);
        setSearchResults([]);
      }
    } else {
      setSearchResults([]);
    }
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchQuery(inputValue);
    handleSearch(inputValue);
  };

  return (
    <nav className={`${styles.navbar} ${scrolled && styles.scrolled}`}>
      <div className={styles.icon}>
      <a href="/">  <img className={styles.logo} src={Logo} alt="Logo" /></a>
      
      </div>
      <div ref={navRef} className={styles.link_area}>
        <ul className={styles.links}>
          <li className={styles.link}>
            <a href="/">Home</a>
          </li>
          <li className={styles.link}>
            <a href="/#lastupdated">Son Güncellenenler</a>
          </li>
          <li className={styles.link}>
            <a href="/blog">Blog</a>
          </li>
          <li className={styles.link}>
            <a href="/#youtube">Youtube</a>
          </li>
          <li className={styles.link}>
            <a href="/#podcast">Podcast</a>
          </li>
        </ul>
      </div>
      <div ref={navRef1} className={styles.search_area}>
        <input
          className={styles.search_bar}
          type="text"
          placeholder="Ara"          
          value={searchQuery}
          onChange={handleInputChange}
        />
        <FontAwesomeIcon
          className={styles.search_icon}
          icon={faMagnifyingGlass}
          onClick={() => handleSearch(searchQuery)}
        />
        {searchQuery.length > 0 && (
          <div className={styles.search_results_container}>
            <div className={styles.search_results}>
              <ul>
                {searchResults.map((result, index) => (
                  <li key={index}>
                    <a href={result.url}>
                      <div className={styles.result_container}>
                        {result.type && (
                          <p
                            className={`${getClassNameForResultType(
                              result.type
                            )}`}
                          >
                            {result.type}
                          </p>
                        )}
                        <p className={styles.result}>{result.result}</p>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
      <div className={styles.menu}>
        <FontAwesomeIcon
          className={styles.bar}
          icon={faBars}
          size="xl"
          onClick={openMenu}
        />
        <FontAwesomeIcon
          className={styles.cross}
          icon={faTimes}
          size="xl"
          onClick={openMenu}
        />
      </div>
    </nav>
  );
}

export default Navbar;
