import React, { useContext } from "react";
import styles from "../../css/lastUpdate/lastUpdate.module.css";
import { Context } from "../../context/Context";
import "@splidejs/react-splide/css";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import AdanaMap from "../maps/AdanaMap";
import AfyonMap from "../maps/AfyonMap";
import AgriMap from "../maps/AgriMap";
import AdiyamanMap from "../maps/AdiyamanMap";
import AmasyaMap from "../maps/AmasyaMap";
import AntalyaMap from "../maps/AntalyaMap";
import ArtvinMap from "../maps/ArtvinMap";
import AydinMap from "../maps/AydinMap";
import BalikesirMap from "../maps/BalikesirMap";
import BilecikMap from "../maps/BilecikMap";
import BingolMap from "../maps/BingolMap";
import BitlisMap from "../maps/BitlisMap";
import BoluMap from "../maps/BoluMap";
import BurdurMap from "../maps/BurdurMap";
import BursaMap from "../maps/BursaMap";
import CanakkaleMap from "../maps/CanakkaleMap";
import CankiriMap from "../maps/CankiriMap";
import CorumMap from "../maps/CorumMap";
import DenizliMap from "../maps/DenizliMap";
import DiyarbakirMap from "../maps/DiyarbakirMap";
import EdirneMap from "../maps/EdirneMap";
import ElazigMap from "../maps/ElazigMap";
import ErzincanMap from "../maps/ErzincanMap";
import ErzurumMap from "../maps/ErzurumMap";
import EskisehirMap from "../maps/EskisehirMap";
import GaziantepMap from "../maps/GaziantepMap";
import GiresunMap from "../maps/GiresunMap";
import GumushaneMap from "../maps/GumushaneMap";
import HakkariMap from "../maps/HakkariMap";
import HatayMap from "../maps/HatayMap";
import MersinMap from "../maps/MersinMap";
import IzmirMap from "../maps/IzmirMap";
import KarsMap from "../maps/KarsMap";
import KastamonuMap from "../maps/KastamonuMap";
import KayseriMap from "../maps/KayseriMap";
import KirklareliMap from "../maps/KirklareliMap";
import KirsehirMap from "../maps/KirsehirMap";
import KocaeliMap from "../maps/KocaeliMap";
import KonyaMap from "../maps/KonyaMap";
import KutahyaMap from "../maps/KutahyaMap";
import MalatyaMap from "../maps/MalatyaMap";
import ManisaMap from "../maps/ManisaMap";
import KahramanmarasMap from "../maps/KahramanmarasMap";
import MardinMap from "../maps/MardinMap";
import MuglaMap from "../maps/MuglaMap";
import MusMap from "../maps/MusMap";
import NevsehirMap from "../maps/NevsehirMap";
import NigdeMap from "../maps/NigdeMap";
import OrduMap from "../maps/OrduMap";
import RizeMap from "../maps/RizeMap";
import SakaryaMap from "../maps/SakaryaMap";
import SamsunMap from "../maps/SamsunMap";
import SiirtMap from "../maps/SiirtMap";
import SinopMap from "../maps/SinopMap";
import SivasMap from "../maps/SivasMap";
import TekirdagMap from "../maps/TekirdagMap";
import TokatMap from "../maps/TokatMap";
import TrabzonMap from "../maps/TrabzonMap";
import TunceliMap from "../maps/TunceliMap";
import SanliurfaMap from "../maps/SanliurfaMap";
import UsakMap from "../maps/UsakMap";
import VanMap from "../maps/VanMap";
import YozgatMap from "../maps/YozgatMap";
import ZonguldakMap from "../maps/ZonguldakMap";
import AksarayMap from "../maps/AksarayMap";
import BayburtMap from "../maps/BayburtMap";
import KaramanMap from "../maps/KaramanMap";
import KirikkaleMap from "../maps/KirikkaleMap";
import BatmanMap from "../maps/BatmanMap";
import SirnakMap from "../maps/SirnakMap";
import BartinMap from "../maps/BartinMap";
import ArdahanMap from "../maps/ArdahanMap";
import IgdirMap from "../maps/IgdirMap";
import YalovaMap from "../maps/YalovaMap";
import KarabukMap from "../maps/KarabukMap";
import KilisMap from "../maps/KilisMap";
import OsmaniyeMap from "../maps/OsmaniyeMap";
import DuzceMap from "../maps/DuzceMap";
import AnkaraMap from "../maps/AnkaraMap";
import IstanbulMap from "../maps/IstanbulMap";
import IspartaMap from "../maps/IspartaMap";
import { NavLink } from "react-router-dom";
import Pin from "../../assets/pin.svg"

function LastUpdate() {
  const { latestUpdate } = useContext(Context);
  const maps = {
    ankara: <AnkaraMap />,
    istanbul: <IstanbulMap />,
    adana: <AdanaMap />,
    adiyaman: <AdiyamanMap />,
    afyonkarahisar: <AfyonMap />,
    agri: <AgriMap />,
    amasya: <AmasyaMap />,
    antalya: <AntalyaMap />,
    artvin: <ArtvinMap />,
    aydin: <AydinMap />,
    balikesir: <BalikesirMap />,
    bilecik: <BilecikMap />,
    bingol: <BingolMap />,
    bitlis: <BitlisMap />,
    bolu: <BoluMap />,
    burdur: <BurdurMap />,
    bursa: <BursaMap />,
    canakkale: <CanakkaleMap />,
    cankiri: <CankiriMap />,
    corum: <CorumMap />,
    denizli: <DenizliMap />,
    diyarbakir: <DiyarbakirMap />,
    edirne: <EdirneMap />,
    elazig: <ElazigMap />,
    erzincan: <ErzincanMap />,
    erzurum: <ErzurumMap />,
    eskisehir: <EskisehirMap />,
    gaziantep: <GaziantepMap />,
    giresun: <GiresunMap />,
    gumushane: <GumushaneMap />,
    hakkari: <HakkariMap />,
    hatay: <HatayMap />,
    mersin: <MersinMap />,
    izmir: <IzmirMap />,
    kars: <KarsMap />,
    kastamonu: <KastamonuMap />,
    kayseri: <KayseriMap />,
    kirklareli: <KirklareliMap />,
    kirsehir: <KirsehirMap />,
    kocaeli: <KocaeliMap />,
    konya: <KonyaMap />,
    kutahya: <KutahyaMap />,
    malatya: <MalatyaMap />,
    manisa: <ManisaMap />,
    kahramanmaras: <KahramanmarasMap />,
    mardin: <MardinMap />,
    mugla: <MuglaMap />,
    mus: <MusMap />,
    nevsehir: <NevsehirMap />,
    nigde: <NigdeMap />,
    ordu: <OrduMap />,
    rize: <RizeMap />,
    sakarya: <SakaryaMap />,
    samsun: <SamsunMap />,
    siirt: <SiirtMap />,
    sinop: <SinopMap />,
    sivas: <SivasMap />,
    tekirdag: <TekirdagMap />,
    tokat: <TokatMap />,
    trabzon: <TrabzonMap />,
    tunceli: <TunceliMap />,
    sanliurfa: <SanliurfaMap />,
    usak: <UsakMap />,
    van: <VanMap />,
    yozgat: <YozgatMap />,
    zonguldak: <ZonguldakMap />,
    aksaray: <AksarayMap />,
    bayburt: <BayburtMap />,
    karaman: <KaramanMap />,
    kirikkale: <KirikkaleMap />,
    batman: <BatmanMap />,
    sirnak: <SirnakMap />,
    bartin: <BartinMap />,
    ardahan: <ArdahanMap />,
    igdir: <IgdirMap />,
    yalova: <YalovaMap />,
    karabuk: <KarabukMap />,
    kilis: <KilisMap />,
    osmaniye: <OsmaniyeMap />,
    duzce: <DuzceMap />,
    isparta: <IspartaMap />,
  };
  const handleCardClick = (urlProvinceName) => {
   
    sessionStorage.setItem("myData", urlProvinceName);
  };

  return (
    <div id="lastupdated" className={styles.content_container}>
      <h1 className={styles.header}>Son Güncellenenler</h1>
      <hr className={styles.hr} />
      <Splide
        options={{
          type: "loop",
          perPage: 1,
          autoplay: true,
          interval: 10000,
          speed: 2000,
          pagination: false,
        }}
      >
        {latestUpdate.map((data, index) => {
          const mapComponent = maps[data.url_province_name];
          
          const linkTo = data.url_district_name
          ? `/${data.url_province_name}/${data.url_district_name}`
          : `/${data.url_province_name}`;
          return (
            <SplideSlide className={styles.splide} key={index}>
              <NavLink onClick={() => handleCardClick(data.url_province_name)} className={styles.link} to={linkTo}>
                
                <div className={styles.container}>
                <div className={`${styles.map} ${styles[data.url_province_name]}`}>{mapComponent}</div>
                  <div className={styles.notification}>
                    <div className={styles.card}>
                      <div className={styles.province_area}>
                        <div className={styles.district_name}>
                          {data.province}
                        </div>
                        <div className={styles.province_name}>
                          {data.district}
                        </div>
                      </div>
                      <div className={styles.location}>
                      <img className={styles.pin} src={Pin} alt="" />
                      </div>
                      <p className={styles.information}>{data.description}</p>
                      <button className={styles.tag}>{data.tag}</button>
                    </div>
                  </div>
                </div>
              </NavLink>
            </SplideSlide>
          );
        })}
      </Splide>
    </div>
  );
}

export default LastUpdate;
