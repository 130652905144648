import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function SirnakMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 500 260"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Şırnak" transform="translate(0, 0)">
            <NavLink to={"/sirnak/" + id2}>
              <g
                id="943"
                transform="translate(59.000000, 62.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="guclukonak"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 0 25 17 73 16 68 82 3 66 12 32"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sirnak/" + id2}>
              <g
                id="940"
                transform="translate(127.000000, 15.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="5 63 46 62 72 31 125 0 199 30 161 75 164 109 89 127 0 129"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sirnak/" + id2}>
              <g
                id="942"
                transform="translate(116.000000, 142.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cizre"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="99 0 81 42 57 32 0 84 11 2"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sirnak/" + id2}>
              <g
                id="945"
                transform="translate(197.000000, 124.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="silopi"
                  d="M0,60 L15,108 C15,108 71,90 71,89 C71,88 114,24 114,24 L94,0 L18,18 L0,60 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/sirnak/" + id2}>
              <g
                id="944"
                transform="translate(0.000000, 128.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="idil"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="62 0 8 19 0 70 0 89 26 87 23 129 116 98 127 16"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sirnak/" + id2}>
              <g
                id="946"
                transform="translate(288.000000, 90.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="uludere"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 0 166 20 187 46 178 56 130 39 82 60 23 58 3 34"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sirnak/" + id2}>
              <g
                id="941"
                transform="translate(288.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="beytussebap"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="166 110 207 3 188 6 64 0 38 45 0 90"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default SirnakMap;
