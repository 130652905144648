import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function KocaeliMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 400 230"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Kocaeli" transform="translate(0, 0)">
            <NavLink to={"/kocaeli/" + id2}>
              <g
                id="670"
                transform="translate(21.844548, 157.896431)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="karamursel"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="33.23259 4.32210159 69.28308 0.31670572 70.2844825 37.3666175 42.2452125 52.386852 13.2045399 51.3855031 0.186307442 51.3855031 16.2087474 12.3328933"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kocaeli/" + id2}>
              <g
                id="667"
                transform="translate(91.122970, 151.655466)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="golcuk"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0.00465768605 6.55767138 19.0313052 0.549577573 46.0691727 3.55362448 39.0593552 55.6237708 9.01728019 39.6021873 1.00606019 43.6075832"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kocaeli/" + id2}>
              <g
                id="661"
                transform="translate(129.819026, 144.166307)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="basiskele"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="7.37311701 11.0427833 21.392752 13.0454812 25.398362 5.03468946 34.4109845 0.0279446224 44.4250095 53.0994399 18.3885445 73.1264193 0.363299512 63.1129296"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kocaeli/" + id2}>
              <g
                id="671"
                transform="translate(164.146172, 139.797631)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kartepe"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0.0838383489 4.39662058 69.1806109 0.391224713 67.1778059 17.4141572 45.1469509 21.419553 45.1469509 27.4276468 60.1679884 29.4303448 45.1469509 49.4573241 10.0978634 57.4681159"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kocaeli/" + id2}>
              <g
                id="668"
                transform="translate(136.060325, 73.019298)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="izmit"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="19.1570627 76.181698 17.1542577 70.1736042 0.130415209 73.1776511 1.13181771 47.1425779 14.1500502 42.1358331 21.1598677 25.1129006 14.1500502 0.07917643 50.2005402 15.0994109 61.2159677 30.1196455 87.2524327 19.1048068 109.283288 39.1317862 97.2664577 67.1695573 28.1696852 71.1749531"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kocaeli/" + id2}>
              <g
                id="669"
                transform="translate(134.812065, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kandira"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="110.531548 112.151084 114.537158 84.1133133 133.563805 7.00944277 109.530145 -3.25886274e-18 85.4964851 19.0256304 11.3927001 17.0229324 0.37727257 43.0580056 15.3983101 73.0984746 51.4488001 88.1187092 62.4642276 103.138944 88.5006926 92.124105"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kocaeli/" + id2}>
              <g
                id="664"
                transform="translate(109.846868, 42.438566)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="derince"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="25.3424698 0.619439129 8.31862728 5.62618397 15.3284448 58.6976793 0.307407279 101.755685 9.32002978 104.759732 26.3438723 103.758383 27.3452748 77.7233096 40.3635073 72.7165648 47.3733248 55.6936323 40.3635073 30.6599082"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kocaeli/" + id2}>
              <g
                id="672"
                transform="translate(64.909513, 48.055436)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="korfez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="53.2559823 0.00931487412 34.2293348 11.0241535 22.2125048 59.088904 0.181649756 85.1239771 20.2096998 87.1266751 37.2335423 89.129373 45.2447623 96.1388158 60.2657998 53.0808102"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kocaeli/" + id2}>
              <g
                id="666"
                transform="translate(11.858469, 53.048208)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gebze"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="87.2803788 6.03138099 71.2579388 12.0394748 51.2298888 0.0232871853 34.2060463 2.02598512 25.1934238 10.0367769 6.16677633 39.0758969 19.1850088 54.0961314 10.1723863 66.112319 0.158361326 68.115017 8.16958133 74.1231108 8.16958133 82.1339025 29.1990338 79.1298556 42.2172663 46.0853397 75.2635488 54.0961314"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kocaeli/" + id2}>
              <g
                id="665"
                transform="translate(40.568445, 98.607257)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="dilovasi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="24.522717 34.5721553 0.489057035 33.5708063 13.5072895 0.526290388 46.553572 8.53708213"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kocaeli/" + id2}>
              <g
                id="663"
                transform="translate(0.000000, 114.833768)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="darica"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="20.02805 20.3483425 12.01683 23.3523894 5.0070125 21.3496915 0 12.3375508 2.002805 3.32541006 6.008415 0.321363157 12.01683 6.32945696 20.02805 12.3375508"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kocaeli/" + id2}>
              <g
                id="662"
                transform="translate(5.617169, 91.742195)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cayiorva"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0.391245628 23.4129361 12.4080756 0.381909839 25.4263081 15.4021444 16.4136856 27.418332 6.39966063 29.4210299"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default KocaeliMap;
