import React, { useContext } from "react";
import styles from "../../css/popups/mainpopup.module.css";
import { Context } from "../../context/Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

function MainPopup({ onClose }) {
  const { popup } = useContext(Context);
  let url = "https://api.ibrahimaluc.com";

  return (
    <div className={styles.container}>
      {popup && (
        <div className={styles.card}>
          <FontAwesomeIcon
            onClick={() => {
              onClose();
              sessionStorage.setItem("ShowPopup", false); // Store the value in sessionStorage
            }}
            className={styles.icon}
            icon={faTimes}
            
          />
          <img className={styles.popup_image} src={url + popup.image} alt="" />
        </div>
      )}
    </div>
  );
}

export default MainPopup;
