import React, { useState } from "react";
import styles from "../../css/blogPage/mailSubscribe.module.css";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function MailSubscribe() {
  const [email, setEmail] = useState("");

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = () => {
    // E-posta kontrolü yapın
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    if (!isValidEmail) {
      toast.error("Lütfen geçerli e-posta adresi giriniz", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
      return;
    }

    // Abone olma isteğini Django API'ye gönderin
    axios
      .post("https://api.ibrahimaluc.com/api/subscribe/", { email })
      .then((response) => {
        // Başarılı bir şekilde kaydedildiğinde geri bildirim gösterebilirsiniz
        console.log("Abone olundu:", response.data);
        setEmail("");
        toast.success(response.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      })
      .catch((error) => {
        // Hata durumunda hata mesajını gösterebilirsiniz
        console.error("Hata:", error);
        setEmail("");
        toast.error(error.response.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.header_area}>
        <p className={styles.header1}>
          E-Posta Bültenimize Abone Olarak Güncel Kalın
        </p>
        <p className={styles.header}>
          Her Hafta Güncellenen İçeriklerimizi Takip Edebilirsiniz
        </p>
      </div>

      <div className={styles.mail_area}>
        <input
          className={styles.mail}
          type="email"
          name=""
          id=""
          value={email}
          onChange={handleInputChange}
          placeholder="E-Posta Adresinizi Giriniz"
        />

        <button onClick={handleSubscribe} className={styles.button}>
          Abone Ol
        </button>
      </div>
    </div>
  );
}

export default MailSubscribe;
