import React, { useContext, useEffect } from "react";
import Navbar from "../components/Navbar";
import TurkeyMap from "../components/maps/TurkeyMap";
import LastUpdate from "../components/lastUpdate/LastUpdate";
import { Context } from "../context/Context";
import Blog from "../components/blog/Blog";
import Podcast from "../components/podcast/Podcast";
import ProvinceCard from "../components/cards/ProvinceCard";
import Youtube from "../components/youtube/Youtube";
import Ad2 from "../components/ads/Ad2";

import Footer from "../components/Footer";
import Cookies from "../components/cookies/Cookies";

function HomePage() {
  const { mouseX, mouseY, id, isHovered, dispatch } = useContext(Context);
  

  const screenWidth = window.innerWidth;
  const myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    "Token 8f6c14510d09a853e6145cc52567a9bebe86c172"
  );

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  useEffect(() => {
    fetch("https://api.ibrahimaluc.com/api/generalpopup/", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: "GET_POPUP",
          payload: data.popup,
        });
      })
      .catch((error) => {
        console.error("Hata:", error);
      });
    fetch("https://api.ibrahimaluc.com/api/latestUpdate/", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: "GET_LATESTUPDATE",
          payload: data.latestUpdated,
        });
      })
      .catch((error) => {
        console.error("Hata:", error);
      });

    fetch("https://api.ibrahimaluc.com/api/provinces/", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: "GET_DATA",
          payload: data.provinces,
        });
      })
      .catch((error) => {
        console.error("Hata:", error);
      });
    fetch("https://api.ibrahimaluc.com/api/spotify/", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: "GET_SPOTİFY",
          payload: data.spofity,
        });
      })
      .catch((error) => {
        console.error("Hata:", error);
      });
    fetch("https://api.ibrahimaluc.com/api/youtube/", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: "GET_YOUTUBE",
          payload: data.youtube,
        });
      })
      .catch((error) => {
        console.error("Hata:", error);
      });
     
  }, []);

  return (
    <>
    
      <Navbar />
      <TurkeyMap />
      {id && isHovered && (
        <div
          style={{
            position: "absolute",
            top: mouseY + (screenWidth / 2 > mouseX ? +-50 : -70),
            left: mouseX + (screenWidth / 2 > mouseX ? 50 : -450),
          }}
        >
          <ProvinceCard />
        </div>
      )}
      <LastUpdate />
      {/* <Ad2 /> */}
      <Blog />
      <Podcast />
      <Youtube />
      <Footer/>
      <Cookies/>
    </>
  );
}

export default HomePage;
