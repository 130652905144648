import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function KirklareliMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 410 285"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Kırklareli" transform="translate(0, 0)">
            <NavLink to={"/kirklareli/" + id2}>
              <g
                id="641"
                transform="translate(167.558824, 41.311111)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="demirkoy"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="11.3675442 22.6888889 0.372069107 30.6888889 10.3679556 54.6888889 36.3572604 73.6888889 88.33587 78.6888889 135.316536 101.688889 118.323529 51.6888889 137.315714 41.6888889 133.317359 10.6888889 102.330111 10.6888889 91.334636 0.688888889 78.3399835 18.6888889 54.349856 7.68888889"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kirklareli/" + id2}>
              <g
                id="639"
                transform="translate(13.500000, 14.300000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="165.426368 49.7 132.439942 23.7 116.446524 0.7 101.452694 24.7 85.459276 51.7 40.4777869 37.7 24.4843686 37.7 27.4831345 84.7 12.4893048 117.7 0.494241053 128.7 20.486014 139.7 35.4798437 136.7 93.4559852 157.7 111.448581 122.7 172.423488 87.7 164.426779 81.7 154.430893 57.7"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kirklareli/" + id2}>
              <g
                id="645"
                transform="translate(106.411765, 101.688889)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="pinarhisar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="79.5117236 0.311111111 97.5043192 13.3111111 86.5088441 56.3111111 76.5129576 75.3111111 79.5117236 83.3111111 80.5113122 113.311111 61.5191279 101.311111 57.5207733 80.3111111 37.5290004 71.3111111 0.544220485 70.3111111 18.5368161 35.3111111"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kirklareli/" + id2}>
              <g
                id="646"
                transform="translate(182.647059, 114.400000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="vize"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="120.228301 28.6 73.2476347 5.6 21.2690251 0.6 10.27355 43.6 0.277663513 62.6 3.27642945 70.6 4.2760181 100.6 9.27396133 112.6 102.235705 78.6 141.219663 73.6 126.225833 53.6 129.224599 42.6"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kirklareli/" + id2}>
              <g
                id="643"
                transform="translate(79.411765, 171.600000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="luleburgaz"
                  d="M112.509255,55.4 L107.511312,43.4 L88.5191279,31.4 L84.5207733,10.4 L64.5290004,1.4 L27.5442205,0.4 L3.55409297,32.4 L0.555327026,81.4 L15.5491567,83.4 L40.5388729,105.4 L57.5318799,114.4 L90.5183052,105.4 L112.509255,55.4 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/kirklareli/" + id2}>
              <g
                id="640"
                transform="translate(9.529412, 150.944444)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="babaeski"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="24.4566022 3.05555556 39.4504319 0.0555555556 97.4265734 21.0555556 73.4364459 53.0555556 70.43768 102.055556 52.4450843 103.055556 26.4557795 90.0555556 24.4566022 77.0555556 8.46318387 63.0555556 0.466474702 49.0555556"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kirklareli/" + id2}>
              <g
                id="644"
                transform="translate(0.000000, 199.405556)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="pehlivankoy"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="9.99588647 0.594444444 0 18.5944444 1.99917729 44.5944444 15.9934183 46.5944444 35.9851913 41.5944444 33.986014 28.5944444 17.9925956 14.5944444"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kirklareli/" + id2}>
              <g
                id="642"
                transform="translate(37.323529, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kofcaz"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="92.6229947 15 68.6328671 4.5140362e-14 55.6382147 14 27.6497326 4.5140362e-14 0.660839161 22 0.660839161 52 16.6542575 52 61.6357466 66"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default KirklareliMap;
