import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function UsakMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 400 240"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Uşak" transform="translate(0, 0)">
            <NavLink to={"/usak/" + id2}>
              <g
                id="184"
                transform="translate(193.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="banaz"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="117 6 80 1 43 0 0 21 9 34 38 43 39 86 71 100 84 133 106 112 100 87 132 61 127 32 105 23"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/usak/" + id2}>
              <g
                id="1001"
                transform="translate(62.000000, 21.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="131 0 72 20 21 19 0 41 47 78 73 84 85 103 129 117 146 86 170 65 169 22 140 13"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/usak/" + id2}>
              <g
                id="1003"
                transform="translate(0.000000, 62.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="esme"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="62 0 24 12 1 104 0 112 19 133 22 150 101 153 99 146 96 94 109 37"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/usak/" + id2}>
              <g
                id="1006"
                transform="translate(96.000000, 99.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ulubey"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="13 0 0 57 3 109 8 109 16 137 21 132 17 118 19 99 25 89 29 92 20 109 22 114 30 118 60 98 71 98 78 77 74 68 95 52 95 39 51 25 39 6"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/usak/" + id2}>
              <g
                id="1005"
                transform="translate(191.000000, 86.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sivasli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 52 0 65 36 86 54 77 75 58 86 47 73 14 41 0 17 21"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/usak/" + id2}>
              <g
                id="1004"
                transform="translate(167.000000, 151.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="karahalli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="24 0 3 16 7 25 0 46 31 68 45 72 60 21"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default UsakMap;
