import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function EdirneMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 650 550"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Edirne" transform="translate(0, 0)">
            <NavLink to={"/edirne/" + id2}>
              <g
                id="348"
                transform="translate(164.056338, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="lalapasa"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="105.597951 0 105.597951 29.9615877 39.6824584 68.9116517 0.732394366 46.9398207 7.7234315 14.9807939 28.6965429 8.98847631"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/edirne/" + id2}>
              <g
                id="350"
                transform="translate(203.507042, 29.478873)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="suloglu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="66.1472471 0.482714469 69.1434059 47.4225352 54.162612 80.3802817 42.177977 91.3661972 38.1830986 68.3956466 10.2189501 59.4071703 11.2176697 48.4212548 0.231754161 39.4327785"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/edirne/" + id2}>
              <g
                id="343"
                transform="translate(103.816901, 46.140845)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="60.971831 0.798975672 99.921895 22.7708067 110.90781 31.759283 109.909091 42.7451985 101.919334 50.7349552 78.9487836 114.653009 88.9359795 140.619718 62.9692702 134.627401 63.9679898 91.6824584 41.9961588 72.7067862 34.006402 53.731114 0.0499359795 36.7528809 12.0345711 7.7900128 40.9974392 10.7861716"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/edirne/" + id2}>
              <g
                id="345"
                transform="translate(182.000000, 88.436620)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="havsa"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="31.7259923 0.449423816 59.6901408 9.43790013 63.6850192 32.4084507 83.659411 43.3943662 59.6901408 89.3354673 49.7029449 107.31242 27.731114 96.3265045 10.7528809 98.3239437 0.765685019 72.3572343 23.7362356 8.43918054"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/edirne/" + id2}>
              <g
                id="351"
                transform="translate(111.507042, 180.718310)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="uzunkopru"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="120.195903 15.0307298 98.2240717 4.04481434 81.2458387 6.04225352 55.2791293 0.0499359795 57.2765685 28.0140845 43.2944942 37.0025608 29.31242 33.0076825 34.3060179 66.9641485 0.349551857 112.90525 21.3226633 114.902689 41.2970551 125.888604 44.2932138 132.879641 60.2727273 133.878361 68.262484 126.887324 106.213828 112.90525 127.18694 91.9321383 133.179257 70.9590269 122.193342 40.9974392"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/edirne/" + id2}>
              <g
                id="349"
                transform="translate(84.591549, 212.760563)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="meric"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="56.2279129 0.965428937 4.29449424 24.9346991 0.299615877 96.8425096 27.2650448 80.8629962 61.2215109 34.921895"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/edirne/" + id2}>
              <g
                id="346"
                transform="translate(26.915493, 293.507042)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ipsala"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="128.884763 20.0909091 125.888604 13.099872 105.914213 2.11395647 84.9411012 0.116517286 57.9756722 16.0960307 53.9807939 32.0755442 55.978233 45.0588988 42.9948784 49.0537772 42.9948784 59.0409731 18.0268886 76.0192061 0.0499359795 91 20.0243278 91.9987196 44.9923175 103.983355 81.9449424 86.006402"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/edirne/" + id2}>
              <g
                id="347"
                transform="translate(58.676056, 312.732394)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kesan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="113.103713 1.86427657 97.1241997 0.865556978 50.184379 66.7810499 13.2317542 84.7580026 0.248399488 106.729834 19.2240717 113.720871 15.2291933 138.68886 45.190781 138.68886 57.1754161 148.676056 82.1434059 135.692702 118.097311 132.696543 132.079385 103.733675 117.098592 70.7759283 125.088348 9.85403329"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/edirne/" + id2}>
              <g
                id="344"
                transform="translate(0.000000, 384.507042)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="enez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="71.9078105 12.9833547 46.9398207 0.99871959 26.9654289 0 17.9769526 16.978233 0 19.9743918 2.99615877 53.9308579 18.9756722 67.9129321 38.950064 65.915493 64.9167734 71.9078105 73.9052497 66.9142125 77.900128 41.9462228 58.9244558 34.9551857"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default EdirneMap;
