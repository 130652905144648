import React from "react";

import styles from "../../css/ads/ad2.module.css"
function Ad2() {
  return (
    <div
     className={styles.ad}
    >
      
    </div>
  );
}

export default Ad2;
