import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function CankiriMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 550 420"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Çankırı" transform="translate(0, 0)">
            <NavLink to={"/cankiri/" + id2}>
              <g
                id="274"
                transform="translate(310.000000, 195.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kizilirmak"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="82 2 48 0 26 20 20 44 0 58 35 74 74 54 93 24"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/cankiri/" + id2}>
              <g
                id="268"
                transform="translate(238.000000, 89.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="154 108 161 102 164 79 149 77 150 53 138 58 118 91 105 91 54 34 51 7 45 0 22 4 27 18 19 42 1 63 24 95 22 113 0 140 50 157 72 164 92 150 98 126 120 106"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/cankiri/" + id2}>
              <g
                id="279"
                transform="translate(283.000000, 76.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yaprakli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="105 66 108 54 69 4 22 0 0 13 6 20 9 47 60 104 73 104 93 71"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/cankiri/" + id2}>
              <g
                id="273"
                transform="translate(211.000000, 4.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ilgaz"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="94 72 93 32 101 7 75 0 47 2 18 5 1 17 9 27 0 51 3 65 13 76 27 75 49 89 72 85"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/cankiri/" + id2}>
              <g
                id="275"
                transform="translate(179.000000, 79.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="korgun"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="45 1 20 21 1 24 6 44 0 57 6 77 26 90 36 73 60 73 78 52 86 28 81 14 59 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/cankiri/" + id2}>
              <g
                id="272"
                transform="translate(205.000000, 152.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="eldivan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="33 77 8 72 14 42 8.8817842e-16 17 10 0 34 0 57 32 55 50"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/cankiri/" + id2}>
              <g
                id="278"
                transform="translate(127.000000, 156.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sabanozu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="86 68 60 69 46 93 8 66 0 43 16 32 10 10 36 9 58 0 78 13 92 38"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/cankiri/" + id2}>
              <g
                id="277"
                transform="translate(82.000000, 115.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="orta"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="97 21 63 4 40 0 12 7 0 30 28 37 14 53 20 67 45 84 61 73 55 51 81 50 103 41"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/cankiri/" + id2}>
              <g
                id="276"
                transform="translate(145.000000, 21.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kursunlu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 98 11 66 7 34 34 31 52 22 54 13 67 -1.77635684e-15 75 10 66 34 69 48 79 59 54 79 36 82 40 102 34 115"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/cankiri/" + id2}>
              <g
                id="269"
                transform="translate(106.000000, 26.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="atkaracalar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="39 93 16 89 0 54 13 16 9 0 28 7 35 13 48 16 46 29 50 61"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/cankiri/" + id2}>
              <g
                id="271"
                transform="translate(0.000000, 26.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cerkes"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="122 89 94 96 82 119 64 113 39 98 0 96 5 73 3 59 30 43 55 17 64 0 89 6 115 0 119 16 106 54"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/cankiri/" + id2}>
              <g
                id="270"
                transform="translate(115.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bayramoren"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 26 11 0 26 3 51 1 68 5 84 34 82 43 64 52 37 55 39 42 26 39 19 33"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default CankiriMap;
