import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function AnkaraMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          style={{ position: "relative" }}
          viewBox="0 0 1000 680"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Ankara" transform="translate(0, 0)">
            <NavLink to={"/ankara/" + id2}>
              <g
                id="91"
                transform="translate(0.000000, 99.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="nallihan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="229 25 229 120 221 123 222 115 221 104 213 104 214 114 182 113 158 115 156 109 146 106 153 100 145 99 130 100 130 94 122 95 94 105 78 98 76 93 50 105 51 111 14 107 6 89 0 77 67 43 75 11 80 0 121 19 158 10 166 24 201 34"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ankara/" + id2}>
              <g
                id="76"
                transform="translate(228.000000, 80.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="beypazari"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="1 44 9 41 49 41 80 19 112 3 122 0 135 33 110 47 106 107 65 194 48 201 32 199 37 189 17 189 8 170 0 147 27 131 28 126 18 130 1 139"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ankara/" + id2}>
              <g
                id="84"
                transform="translate(334.000000, 113.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gudul"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="29 0 48 16 55 51 56 62 33 80 0 74 4 14"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ankara/" + id2}>
              <g
                id="74"
                transform="translate(293.000000, 155.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ayas"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="96 8 146 0 139 38 114 59 109 85 116 104 97 126 89 142 41 112 0 119 41 32 74 38 97 20"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ankara/" + id2}>
              <g
                id="86"
                transform="translate(432.000000, 140.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kahramankazan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="7 15 31 0 47 11 62 13 64 30 50 46 46 66 29 68 15 92 0 53"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ankara/" + id2}>
              <g
                id="94"
                transform="translate(390.000000, 193.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sincan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="57 39 57 68 49 81 22 98 0 88 19 66 12 47 17 21 42 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ankara/" + id2}>
              <g
                id="81"
                transform="translate(439.000000, 232.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="etimesgut"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="8 0 25 5 31 19 26 33 15 39 0 42 8 29"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ankara/" + id2}>
              <g
                id="88"
                transform="translate(447.000000, 206.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kecioren"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="31 0 29 11 42 24 75 25 75 31 50 47 49 56 23 45 17 31 0 26 14 2"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ankara/" + id2}>
              <g
                id="93"
                transform="translate(476.000000, 170.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="pursaklar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="20 0 25 26 80 51 76 58 46 61 13 60 0 47 2 36 6 16"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ankara/" + id2}>
              <g
                id="96"
                transform="translate(412.000000, 251.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yenimahalle"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 40 30 76 63 41 84 11 58 0 53 14 42 20 27 23"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ankara/" + id2}>
              <g
                id="78"
                transform="translate(476.000000, 260.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cankaya"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="20 2 51 0 76 36 65 44 0 31"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ankara/" + id2}>
              <g
                id="90"
                transform="translate(527.000000, 239.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="mamak"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 21 36 0 51 4 55 33 47 54 25 57"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ankara/" + id2}>
              <g
                id="80"
                transform="translate(574.000000, 223.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="elmadag"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 70 12 74 25 93 38 93 62 77 72 26 78 19 41 0 8 12 4 20 8 49"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ankara/" + id2}>
              <g
                id="73"
                transform="translate(496.000000, 227.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="altindag"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="56 1 73 0 86 8 82 16 67 12 31 33 0 35 1 26 26 10 26 4"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ankara/" + id2}>
              <g
                id="72"
                transform="translate(552.000000, 184.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="akyurt"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="4 37 7 26 31 0 48 3 63 2 68 8 58 17 63 39 30 51 16 43 0 44"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ankara/" + id2}>
              <g
                id="79"
                transform="translate(494.000000, 60.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cubuk"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="106 127 125 95 125 67 87 40 79 17 54 0 27 20 28 69 1 83 0 93 2 111 7 136 62 161 65 150 89 124"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ankara/" + id2}>
              <g
                id="89"
                transform="translate(363.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kizilcahamam"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="185 60 179 46 193 30 165 23 147 17 122 2 83 0 39 9 82 36 77 60 79 94 0 113 19 129 26 163 76 155 100 140 116 151 131 153 132 143 159 129 158 80"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ankara/" + id2}>
              <g
                id="77"
                transform="translate(350.000000, 9.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="camlidere"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="52 0 47 11 56 25 54 37 0 71 13 104 92 85 90 50 95 27"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ankara/" + id2}>
              <g
                id="92"
                transform="translate(244.000000, 267.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="polatli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="32 14 8 46 30 70 50 125 60 138 48 177 69 200 54 221 39 220 0 250 19 262 41 301 72 302 116 293 136 222 121 192 126 159 198 60 168 24 146 14 138 30 90 0 49 7"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ankara/" + id2}>
              <g
                id="83"
                transform="translate(442.000000, 291.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="golbasi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 36 49 86 69 151 84 139 83 69 73 47 99 13 34 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ankara/" + id2}>
              <g
                id="75"
                transform="translate(511.000000, 293.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bala"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="125 7 113 53 107 60 109 70 121 81 122 88 141 100 147 143 144 146 147 152 153 160 151 169 155 181 168 181 173 186 169 190 165 196 172 197 175 206 171 207 157 199 104 188 55 202 37 191 25 198 3 173 0 149 15 137 14 67 4 45 30 11 41 3 63 0 75 4 88 23 100 23"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ankara/" + id2}>
              <g
                id="95"
                transform="translate(566.000000, 481.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sereflikochisar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 14 66 62 63 54 58 48 65 42 94 71 103 98 96 96 92 97 94 125 89 137 94 144 125 172 128 170 124 154 130 137 138 148 149 164 148 180 160 198 174 183 191 128 180 118 171 120 162 110 167 91 159 74 161 50 144 36 133 35 131 29 124 32 114 39 110 33 97 36 106 25 101 21 95 25 93 21 93 15 101 15 109 15 102 11 49 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ankara/" + id2}>
              <g
                id="82"
                transform="translate(725.000000, 531.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="evren"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="2 0 7 8 14 7 18 19 42 20 45 23 47 29 19 46 15 62 21 68 12 70 3 60 8 41 0 24"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ankara/" + id2}>
              <g
                id="85"
                transform="translate(360.000000, 327.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="haymana"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="82 0 10 99 5 132 20 162 0 233 29 248 98 229 105 215 121 216 143 193 148 170 167 173 176 164 154 139 151 115 131 50"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/ankara/" + id2}>
              <g
                id="87"
                transform="translate(600.000000, 102.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kalecik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="19 25 19 53 0 85 14 84 20 90 10 99 15 121 52 140 67 147 80 142 111 113 101 108 104 97 99 80 133 22 84 5 59 0 33 1"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default AnkaraMap;
