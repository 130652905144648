import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function KutahyaMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 550 400"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Kütahya" transform="translate(0, 0)">
            <NavLink to={"/kutahya/" + id2}>
              <g
                id="710"
                transform="translate(208.515081, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="domanic"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="97.4217429 15.9905604 9.43991113 0 0.44176924 38.9769909 38.4339239 71.9575217 100.421124 68.9592916"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kutahya/" + id2}>
              <g
                id="718"
                transform="translate(88.893271, 38.395939)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="tavsanli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="120.063579 0.581052339 95.0687404 16.5716127 80.0718373 47.5533234 61.07576 62.5444738 15.085257 54.5491936 0.088353848 78.5350342 27.0827795 106.518515 42.0796826 106.518515 67.0745212 92.5267745 110.065644 111.515565 144.058624 142.497276 194.048301 143.496686 207.045617 136.500816 215.043966 120.510255 201.046856 93.5261845 222.04252 45.5545034 234.040043 36.5598132 220.042933 30.563353 158.055734 33.5615831"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kutahya/" + id2}>
              <g
                id="706"
                transform="translate(289.726218, 74.597824)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="33.2070962 0.357928241 21.2095737 9.35261845 0.213909316 57.3242996 14.2110189 84.3083702 6.21267057 100.298931 82.1969798 170.257632 102.192851 168.258812 135.186038 202.238753 182.176334 165.260582 155.181908 120.287131 169.179018 105.295981 170.178812 78.3119101 134.186244 42.3331492 130.18707 15.3490786 94.1945023 15.3490786 78.1978057 16.3484886"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kutahya/" + id2}>
              <g
                id="708"
                transform="translate(268.874710, 174.427264)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="aslanapa"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="103.048488 70.4281919 27.0641787 0.46949029 14.0668626 7.46536045 11.067482 50.4399915 19.0658304 72.427012 1.06954658 90.4163924 18.0660368 142.385714"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kutahya/" + id2}>
              <g
                id="470"
                transform="translate(286.433875, 242.442926)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="altintas"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="85.4893232 2.41252931 0.506872075 74.370051 6.50563333 90.3606114 43.4979944 95.3576615 62.4940717 83.3647412 77.4909749 98.3558916 118.48251 91.3600214 138.478381 34.3936501 105.485194 0.413709266"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kutahya/" + id2}>
              <g
                id="711"
                transform="translate(317.162413, 324.719938)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="dumlupinar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="87.7539718 9.08301017 46.7624366 16.0788803 31.7655334 1.08772998 12.7694561 13.0806503 0.771933619 30.0706207 22.7673916 39.0653109 27.7663593 68.0482016"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kutahya/" + id2}>
              <g
                id="713"
                transform="translate(102.062645, 240.248873)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gediz"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="167.881612 24.5947834 190.876863 92.554665 153.884502 91.555255 110.89338 112.542865 51.9055606 132.531066 0.916089897 131.531656 0.916089897 125.535196 61.903496 67.5694144 64.9028766 43.5835739 47.9063864 26.5936035 54.9049412 15.6000932 80.8995733 13.6012732 124.890489 0.608942852 136.888012 19.5977333"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kutahya/" + id2}>
              <g
                id="709"
                transform="translate(226.074246, 223.793471)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cavdarhisar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="53.867946 1.07378472 61.8662944 23.0608052 43.8700106 41.0501857 12.8764108 36.0531355 0.878888277 17.0643451"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kutahya/" + id2}>
              <g
                id="712"
                transform="translate(113.037123, 130.546191)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="emet"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="169.90445 51.3464331 166.905069 94.3210641 113.916011 110.311625 69.9250954 123.303955 86.9215856 89.324014 0.93934091 70.3352236 17.9358311 14.3682622 42.9306697 0.376521916 85.921792 19.3653124 119.914773 50.3470231"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kutahya/" + id2}>
              <g
                id="714"
                transform="translate(111.939675, 200.755907)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="hisarcik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="2.03678871 0.125507305 1.03699516 33.1060381 46.0277046 55.0930586 71.0225432 53.0942386 88.0190334 19.1142978"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kutahya/" + id2}>
              <g
                id="716"
                transform="translate(0.000000, 116.284843)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="simav"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="130.972954 28.6296109 115.976051 28.6296109 88.9816253 0.646130201 83.9826576 39.6231211 36.9923611 76.601292 6.9985548 74.6024719 0 82.5977521 15.9966967 109.581823 11.9975225 122.574153 21.9954579 149.558224 47.9900901 146.559994 76.9841028 177.541704 71.9851351 191.533445 88.9816253 202.526955 86.9820382 211.521645 102.978735 207.524005 107.977703 188.535215 137.971509 166.548194 148.969238 151.557044 157.96738 139.564123 112.97667 117.577103 113.976464 84.5965722"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kutahya/" + id2}>
              <g
                id="715"
                transform="translate(86.698376, 279.741838)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="pazarlar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="53.2727201 1.0923784 21.2793268 25.078219 15.2805655 44.0670094 0.283662354 48.0646495 11.2813913 69.05226 42.2749912 44.0670094 44.2745782 25.078219"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kutahya/" + id2}>
              <g
                id="717"
                transform="translate(97.672854, 266.577516)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="saphane"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="52.2961776 0.264959867 41.2984486 15.2561102 33.3001003 38.2425408 31.3005132 57.2313312 0.306913367 82.2165818 5.30588108 99.2065522 66.2932872 41.2407708 69.2926678 17.2549303"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default KutahyaMap;
