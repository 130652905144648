import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function IzmirMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 750 520"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="İzmir" transform="translate(0, 0)">
            <NavLink to={"/izmir/" + id2}>
              <g
                id="533"
                transform="translate(0.000000, 327.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cesme"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="58 9 47 16 41 8 39 21 25 14 24 0 13 2 13 23 0 25 0 36 30 50 42 39 39 51 50 60 58 60 65 28"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="540"
                transform="translate(43.000000, 225.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="karaburun"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="58 83 58 74 70 74 65 52 38 0 5 0 0 32 9 62 2 77 7 79 10 68 28 78"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="548"
                transform="translate(189.000000, 323.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="narlidere"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="22 0 0 9 0 19 14 27 21 22 28 15"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="554"
                transform="translate(58.000000, 303.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="urla"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="82 62 114 74 122 75 119 67 98.9832698 53.5641126 95 45 100 34 91 38 83 33 79 13 64 12 62 37 68 47 64 52 51 40 53 30 39 13 43 5 13 0 17 11 3 9 7 23 0 33 7 52 0 84 8 89 14 91 19 112 36 118 45 98 45 87 68 84 67 63"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="550"
                transform="translate(125.000000, 365.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="seferihisar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 1 1 22 14 22 18 28 14 38 28 45 33 82 43 75 59 72 74 50 55 13 47 12 16 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="546"
                transform="translate(177.000000, 350.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="menderes"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 20 3 28 22 65 6 87 36 101 51 119 67 123 74 118 99 107 79 42 92 21 87 20 79 20 47 9 26 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="538"
                transform="translate(153.000000, 332.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="guzelbahce"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="36 0 4 5 0 16 5 26 24 38 50 18 36 10"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="526"
                transform="translate(209.000000, 323.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="balcova"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="25 4 2 0 8 15 0 22 14 21"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="539"
                transform="translate(203.000000, 327.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="karabaglar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="31 0 20 17 7 18 0 23 21 32 27 23 38 21 38 5"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="537"
                transform="translate(224.000000, 347.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gaziemir"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="40 23 32 23 0 12 6 3 17 1 31 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="551"
                transform="translate(251.000000, 457.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="selcuk"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="66 27 40 52 6 45 4 37 11 27 0 11 25 0 56 7"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="544"
                transform="translate(493.000000, 367.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kiraz"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="45 79 69 84 87 84 93 46 47 8 24 7 9 9 0 0 7 62"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="530"
                transform="translate(494.000000, 429.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="beydag"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="6 0 44 17 28 46 16 52 0 53"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="549"
                transform="translate(405.000000, 340.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="odemis"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="5 31 17 38 25 30 26 11 29 2 39 0 80 8 88 27 95 89 89 142 52 142 37 147 37 96 0 70"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="552"
                transform="translate(307.000000, 410.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="tire"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="98 0 135 26 135 77 93 78 75 79 62 71 39 58 10 74 0 54 23 29 19 16 86 16"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="553"
                transform="translate(256.000000, 360.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="torbali"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="13 11 0 32 20 97 51 104 74 79 70 66 60 43 74 27 71 17 32 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="527"
                transform="translate(316.000000, 340.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bayindir"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="77 0 94 31 89 70 77 86 10 86 0 63 14 47 11 37"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="542"
                transform="translate(271.000000, 286.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kemalpasa"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="56 91 122 54 83 30 45 3 30 0 5 45 0 55 17 74"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="532"
                transform="translate(241.000000, 324.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="buca"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="35 7 30 17 47 36 28 47 23 46 14 23 0 24 0 8 10 0 16 8"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="528"
                transform="translate(235.000000, 279.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bayrakli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="15 0 17 32 1 32 0 2"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="545"
                transform="translate(234.000000, 311.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="konak"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="17 13 7 21 0 16 2 0 18 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="531"
                transform="translate(250.000000, 279.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bornova"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="2 32 1 45 7 53 26 52 51 7 29 1 18 4 0 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="541"
                transform="translate(218.000000, 281.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="karsiyaka"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="17 0 18 30 7 31 0 11"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="534"
                transform="translate(190.000000, 292.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cigli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="28 0 35 20 21 20 11 26 2 25 0 17"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="547"
                transform="translate(161.000000, 210.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="menemen"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="86 0 108 24 118 70 107 73 89 69 74 71 57 82 29 99 9 66 11 48 3 52 0 48 14 37 21 45 35 35 38 16"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="536"
                transform="translate(146.000000, 209.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="foca"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="45 6 26 0 3 8 0 33 15 49 29 38 36 46 50 36 53 17"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="420"
                transform="translate(191.000000, 160.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="aliaga"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="43 0 41 8 43 14 32 15 1 28 0 55 8 66 56 50 67 8"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="543"
                transform="translate(285.000000, 84.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kinik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="62 -2.27373675e-13 69 31 67 51 60 58 27 76 0 45 0 18 23 1"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="535"
                transform="translate(166.000000, 44.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="dikili"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="21 0 0 26 14 33 28 57 9 71 4 89 18 111 37 106 47 105 60 79 56 54 68 36"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/izmir/" + id2}>
              <g
                id="529"
                transform="translate(187.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bergama"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 44 25 25 50 12 89 0 146 15 161 48 160 84 121 85 98 102 98 129 125 160 83 161 85 174 71 168 47 160 47 153 42 151 33 159 26 149 39 123 35 98 47 80"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default IzmirMap;
