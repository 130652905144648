import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function DiyarbakirMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 750 450"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Diyarbakır" transform="translate(0, 0)">
            <NavLink to={"/diyarbakir/" + id2}>
              <g
                id="328"
                transform="translate(443.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kulp"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="149 69 118 2 90 0 69 11 2 53 0 89 31 125 85 161 103 149 129 84"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/diyarbakir/" + id2}>
              <g
                id="330"
                transform="translate(400.000000, 125.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="silvan"
                  d="M74,0 L44,13 L74,34 L57,70 L8,84 L0,103 L33,132 C33,132 59,115 60,115 C61,115 142,126 142,126 L159,89 L170,63 L146,24 L128,36 L74,0 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/diyarbakir/" + id2}>
              <g
                id="325"
                transform="translate(393.000000, 138.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="hazro"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="51 0 0 14 2 42 15 71 64 57 81 21"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/diyarbakir/" + id2}>
              <g
                id="327"
                transform="translate(341.000000, 152.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="kocakoy"
                  d="M52,0 C52,0 15,12 14,12 C13,12 0,13 0,13 L4,35 L24,44 L54,28 L52,0 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/diyarbakir/" + id2}>
              <g
                id="324"
                transform="translate(289.000000, 96.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="hani"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="52 69 18 55 19 31 0 26 9 9 23 7 44 0 84 20 91 37 66 45 66 68"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/diyarbakir/" + id2}>
              <g
                id="321"
                transform="translate(197.000000, 105.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="dicle"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="144 60 123 73 84 71 68 57 24 66 0 36 2 3 33 5 101 0 92 17 111 22 110 46"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/diyarbakir/" + id2}>
              <g
                id="322"
                transform="translate(221.000000, 162.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="egil"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="99 16 93 42 74 57 23 60 2 43 0 9 44 0 60 14"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/diyarbakir/" + id2}>
              <g
                id="323"
                transform="translate(117.000000, 141.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ergani"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="80 0 60 3 3 36 0 42 50 107 20 115 9 176 35 193 68 172 75 153 106 64 104 30"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/diyarbakir/" + id2}>
              <g
                id="326"
                transform="translate(192.000000, 205.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kayapinar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 89 113 78 52 17 31 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/diyarbakir/" + id2}>
              <g
                id="316"
                transform="translate(184.000000, 283.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="baglar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="121 0 128 40 25 54 0.999999999 30 8 11"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/diyarbakir/" + id2}>
              <g
                id="331"
                transform="translate(305.000000, 165.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="sur"
                  d="M7,158 C6.99999896,157.999999 59,144 59,144 L56,88 L95,63 L103,44 L90,15 L60,31 L40,22 L36,0 L15,13 L9,39 L0,118 C0,118 7.00000104,158.000001 7,158 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/diyarbakir/" + id2}>
              <g
                id="319"
                transform="translate(201.000000, 309.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cinar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="163 0 222 37 224 66 204 73 111 109 14 113 0 87 8 28 111 14"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/diyarbakir/" + id2}>
              <g
                id="317"
                transform="translate(361.000000, 228.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bismil"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="181 23 149 80 172 93 161 111 62 118 3 81 0 25 39 0 72 29 99 12"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/diyarbakir/" + id2}>
              <g
                id="320"
                transform="translate(0.000000, 162.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cungus"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="117 21 103 20 49 71 45 60 7 55 8.8817842e-16 45 7 37 12 38 15 12 62 0 120 15"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/diyarbakir/" + id2}>
              <g
                id="318"
                transform="translate(29.000000, 182.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cermik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="88 1 74 0 20 51 11 66 20 71 0 90 97 135 108 74 138 66"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/diyarbakir/" + id2}>
              <g
                id="332"
                transform="translate(244.000000, 204.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yenisehir"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 18 51 15 70 0 61 79"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/diyarbakir/" + id2}>
              <g
                id="329"
                transform="translate(333.000000, 47.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="lice"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="22 117 22 94 47 86 40 69 0 49 15 0 112 6 110 41 141 78 111 91 60 105"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default DiyarbakirMap;
