import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function AydinMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 500 250"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Aydın" transform="translate(0, 0)">
            <NavLink to={"/aydin/" + id2}>
              <g
                id="146"
                transform="translate(373.693023, 8.995392)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kuyucak"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="62.3069767 4.99021213 47.3069767 0.994325323 26.3069767 36.9573066 0.306976744 46.9470237 8.30697674 89.9028069 25.3069767 77.9151464 71.3069767 95.8966371 83.3069767 95.8966371 84.3069767 61.9315992 64.3069767 27.9665613 73.3069767 16.9778726"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/aydin/" + id2}>
              <g
                id="135"
                transform="translate(437.851163, 25.861751)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="buharkent"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="9.14883721 0.11151312 41.1488372 26.0847774 40.1488372 35.0755227 20.1488372 45.0652397 0.148837209 11.1002019"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/aydin/" + id2}>
              <g
                id="147"
                transform="translate(320.790698, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="nazilli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="100.209302 9.98971703 91.2093023 6.99280192 70.2093023 4.99485851 52.2093023 4.99485851 28.2093023 0 11.2093023 28.9701794 0.209302326 34.9640096 18.2093023 88.9084815 27.2093023 116.879689 61.2093023 98.8981986 53.2093023 55.9424154 79.2093023 45.9526983"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/aydin/" + id2}>
              <g
                id="149"
                transform="translate(282.520930, 34.857143)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sultanhisar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="38.4790698 0.10686674 22.4790698 1.10583844 0.479069767 31.0749895 2.47906977 52.0533953 17.4790698 46.0595651 33.4790698 57.0482538 45.4790698 56.0492821 56.4790698 54.0513387"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/aydin/" + id2}>
              <g
                id="134"
                transform="translate(303.906977, 88.829493)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bozdogan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="35.0930233 0.0789884602 24.0930233 2.07693187 19.0930233 32.0460829 0.0930232558 48.0296302 11.0930233 66.0111208 19.0930233 101.974102 47.0930233 108.966904 77.0930233 132.942225 107.093023 131.943253 111.093023 127.947366 104.093023 116.958678 97.0930233 123.95148 93.0930233 121.953536 98.0930233 111.963819 96.0930233 107.967932 85.0930233 100.97513 93.0930233 84.9915832 78.0930233 10.0687055 44.0930233 28.0501961"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/aydin/" + id2}>
              <g
                id="141"
                transform="translate(381.572093, 86.580645)"
                fill="#f0f0f0"
                stroke="#000000"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="karacasu"
                  points="17.427907 0.329892981 0.427906977 12.3175534 15.427907 87.2404311 7.42790698 103.223978 18.427907 110.21678 11.427907 99.2280916 14.427907 97.2301482 20.427907 105.221922 28.427907 104.22295 29.427907 108.218837 44.427907 104.22295 63.427907 88.2394028 90.427907 77.2507141 102.427907 52.2764215 75.427907 18.3113836 63.427907 18.3113836"
                  fill="#f0f0f0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/aydin/" + id2}>
              <g
                id="136"
                transform="translate(191.348837, 118.064516)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="cine"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="131.651163 72.7390791 116.651163 82.7287961 41.6511628 91.7195415 32.6511628 89.7215981 39.6511628 60.7514187 0.651162791 35.7771261 25.6511628 5.80797502 65.6511628 0.813116502 94.6511628 8.80489012 112.651163 18.7946072 123.651163 36.7760978"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/aydin/" + id2}>
              <g
                id="144"
                transform="translate(258.883721, 34.857143)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kosk"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="26.1162791 52.0533953 15.1162791 61.0441406 1.11627907 58.0472255 7.11627907 40.0657349 9.11627907 1.10583844 46.1162791 1.10583844 24.1162791 31.0749895"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/aydin/" + id2}>
              <g
                id="138"
                transform="translate(196.976744, 34.857143)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="efeler"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="71.0232558 1.10583844 56.0232558 6.10069696 14.0232558 7.09966866 0.023255814 67.0379708 19.0232558 77.0276879 20.0232558 89.0153483 60.0232558 84.0204898 89.0232558 92.0122634 88.0232558 52.0533953 77.0232558 61.0441406 63.0232558 58.0472255 69.0232558 40.0657349"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/aydin/" + id2}>
              <g
                id="140"
                transform="translate(159.832558, 34.857143)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="incirliova"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="51.1674419 7.09966866 33.1674419 8.09864036 20.1674419 0.10686674 0.16744186 45.0605934 7.16744186 60.0451689 26.1674419 59.0461972 37.1674419 67.0379708"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/aydin/" + id2}>
              <g
                id="139"
                transform="translate(88.920930, 21.364055)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="germencik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="91.0790698 13.5999543 68.0790698 0.613322162 39.0790698 16.5968694 13.0790698 41.571162 1.07906977 59.5526526 36.0790698 58.5536809 30.0790698 73.5382565 44.0790698 75.5361999 55.0790698 70.5413414 78.0790698 73.5382565 71.0790698 58.5536809"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/aydin/" + id2}>
              <g
                id="145"
                transform="translate(0.000000, 55.096774)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kusadasi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="102 7.83844308 68 0.845641162 57 10.8353582 66 37.8075942 48 58.7859999 5 60.7839433 0 71.7726321 63 68.775717 82 42.8024527 90 25.8199337"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/aydin/" + id2}>
              <g
                id="148"
                transform="translate(0.000000, 79.834101)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="soke"
                  d="M90,1.08260654 L125,0.0836348402 L119,15.0682104 L133,17.0661538 L161,75.0065125 L152,100.979777 L108,98.9818334 L98,102.97772 L105,91.9890315 L97,89.9910881 L94,97.9828617 L87,101.978749 L88,105.974635 L54,98.9818334 L42,102.97772 L42,113.966409 L34,114.965381 L36,93.9869749 L55,91.9890315 L55,86.994173 C55,86.994173 34,88.9921164 34,87.9931447 C34,86.994173 31,71.0106257 31,71.0106257 L34,71.0106257 L35,81.0003428 L43,86.994173 L50,83.9972579 L46,74.0075408 L29,61.0209087 L9,52.0301634 L0,47.0353049 L63,44.0383898 L90,1.08260654 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/aydin/" + id2}>
              <g
                id="137"
                transform="translate(37.144186, 178.783410)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="didim"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="50.855814 7.02532658 16.855814 0.0325246601 4.85581395 4.02841147 4.85581395 15.0171002 9.85581395 14.0181285 10.855814 37.9934494 0.855813953 54.9759683 13.855814 61.9687702 23.855814 57.9728834 32.855814 64.9656853 32.855814 50.9800815 41.855814 51.9790532 49.855814 38.9924211 60.855814 39.9913928 60.855814 49.9811098 54.855814 49.9811098 55.855814 53.9769966 62.855814 54.9759683 69.855814 46.9841947 64.855814 37.9934494 55.855814 29.002704 59.855814 16.0160719"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/aydin/" + id2}>
              <g
                id="143"
                transform="translate(132.818605, 91.078341)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kocarli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="19.1813953 89.7355372 44.1813953 102.722169 40.1813953 80.7447919 59.1813953 62.7633012 84.1813953 32.7941501 83.1813953 20.8064897 64.1813953 10.8167727 53.1813953 2.82499905 34.1813953 3.82397075 11.1813953 0.827055642 0.181395349 5.82191416 28.1813953 63.7622729"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/aydin/" + id2}>
              <g
                id="142"
                transform="translate(172.213953, 130.433180)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="karpuzlu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="4.78604651 63.3673306 22.7860465 78.3519062 51.7860465 77.3529345 58.7860465 48.3827551 19.7860465 23.4084625 0.786046512 41.3899532"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/aydin/" + id2}>
              <g
                id="150"
                transform="translate(284.772093, 79.834101)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yenipazar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="43.227907 11.0723236 31.227907 12.0712953 15.227907 1.08260654 0.227906977 7.07643676 1.22790698 47.0353049 19.227907 57.0250219 38.227907 41.0414747"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default AydinMap;
