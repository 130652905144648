import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";
function BalikesirMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 900 600"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Balıkesir" transform="translate(0, 0)">
            <NavLink to={"/balikesir/" + id2}>
              <g
                id="168"
                transform="translate(270.389931, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="marmara"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="54.40578 16.9545804 20.4271518 31.9145043 0.439723424 22.9385499 4.43720909 2.99198477 32.4196088 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/balikesir/" + id2}>
              <g
                id="160"
                transform="translate(250.822238, 33.729642)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="erdek"
                  d="M23.00553,16.1367712 L15.0105587,8.15814515 L5.01684452,22.1207408 L5.01684452,31.0966951 L21.0067872,27.1073821 L17.0093015,20.1260843 L23.00553,16.1367712 Z M9.01433019,1.17684734 L1.01935885,4.16883212 L1.01935885,7.16081689 L11.013073,5.16616038 L9.01433019,1.17684734 Z M47.9898155,26.1100538 L44.9917012,33.0913516 L51.9873011,36.0833364 L50.9879297,43.0646342 L40.9942155,37.0806646 L30.00113,34.0886799 L30.00113,28.1047103 L35.9973585,28.1047103 L34.997987,18.1314277 L39.9948441,19.128756 L41.993587,24.1153973 L47.9898155,26.1100538 Z M64.9791296,14.1421147 L142.9301,34.0886799 L143.929472,46.056619 L108.951472,67.9978406 L102.955243,67.9978406 L78.9703294,59.0218863 L65.978501,40.0726494 L54.9854154,27.1073821 L64.9791296,14.1421147 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/balikesir/" + id2}>
              <g
                id="155"
                transform="translate(286.399861, 99.413681)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bandirma"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="144.32922 1.3164733 86.3656779 11.2897559 73.3738495 2.31380156 67.377621 2.31380156 37.3964784 22.2603667 0.419735995 14.2817407 0.419735995 24.2550232 51.3876783 52.1802145 49.3889355 67.1401383 58.3832782 69.1347949 74.3732209 53.1775427 78.3707066 47.1935732 99.3575063 47.1935732 108.351849 58.164184 109.351221 82.1000622 103.354992 86.0893753 116.34682 96.0626578 128.339277 92.0733448 125.341163 79.1080774 127.339906 63.1508253 119.344935 44.2015884 143.329849 13.2844124"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/balikesir/" + id2}>
              <g
                id="167"
                transform="translate(302.409791, 165.097720)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="manyas"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="112.329347 26.3893057 113.328719 31.375947 77.3513477 65.2851078 76.3519763 84.2343447 64.3595193 82.2396882 54.3658051 88.2236577 32.3796339 90.2183142 23.3852912 82.2396882 14.3909484 84.2343447 10.3934627 73.2637339 0.399748567 72.2664056 12.3922056 51.3225122 33.3790053 1.45609926 42.3733481 3.45075577 38.3758624 6.44274055 46.3708338 13.4240384 51.3676909 20.4053362 56.364548 15.4186949 67.3576335 12.4267101 87.3450619 20.4053362 100.33689 30.3786187"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/balikesir/" + id2}>
              <g
                id="162"
                transform="translate(220.581259, 113.615635)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gonen"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="117.20628 37.9782601 115.207537 52.9381839 94.2207373 102.804597 82.2282802 123.74849 62.2408519 123.74849 30.2609665 135.716429 21.2666238 88.8420012 1.27919541 58.9221535 31.2603379 26.010321 41.2540521 6.06375581 66.2383376 0.0797862607 66.2383376 10.0530688"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/balikesir/" + id2}>
              <g
                id="171"
                transform="translate(355.776225, 195.276873)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="susurluk"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="59.9622851 1.19679391 23.984914 35.1059547 22.9855426 54.0551916 10.9930856 52.0605351 0.999371418 58.0445046 10.9930856 67.020459 4.99685709 89.9590089 40.9742281 113.894887 66.957885 106.913589 92.9415418 81.9803828 72.9541135 35.1059547 69.9559992 10.1727482"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/balikesir/" + id2}>
              <g
                id="154"
                transform="translate(179.666993, 236.107492)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="balya"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="155.122431 19.2085423 146.128089 11.2299162 137.133746 13.2245727 133.13626 2.25396186 123.142546 1.25663361 103.155118 1.25663361 71.1752324 13.2245727 43.1928327 63.0909856 0.219861712 82.0402225 19.2079186 99.9921312 67.1777467 107.970757 82.168318 99.9921312 99.1576321 105.976101"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/balikesir/" + id2}>
              <g
                id="165"
                transform="translate(277.505455, 252.084691)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="karesi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="79.2701408 1.23668704 57.2839697 3.23134356 1.31917027 89.998902 55.2852268 98.9748563 82.2682551 84.0149325 100.256941 66.0630238 119.244998 57.0870695 83.2676265 33.1511913 89.263855 10.2126414"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/balikesir/" + id2}>
              <g
                id="166"
                transform="translate(375.343917, 276.938111)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kepsut"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="73.3738495 0.319145043 47.3901926 25.2523515 21.4065358 32.2336493 2.41847883 41.2096036 0.419735995 65.1454818 11.4128216 103.043956 50.3883069 116.009223 60.3820211 96.0626578 84.3669351 83.0973905 94.3606492 64.1481536 126.340535 45.1989167 108.351849 17.2737254"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/balikesir/" + id2}>
              <g
                id="158"
                transform="translate(425.152588, 315.993485)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="dursunbey"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="76.5318632 6.14354207 44.5519778 25.092779 34.5582636 44.0420159 10.5733496 57.0072832 0.579635422 76.9538484 33.5588922 83.9351462 95.5199201 147.764155 100.516777 158.734766 130.49792 160.729422 177.468376 123.828277 182.465233 84.9324745 197.455805 60.9965963 182.465233 48.0313289 156.481577 53.0179702 135.494777 36.0633898 128.499177 6.14354207 115.507348 1.15690078"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/balikesir/" + id2}>
              <g
                id="170"
                transform="translate(336.208532, 429.609121)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sindirgi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="189.460833 45.1191304 184.463976 34.1485196 121.503577 37.1405043 77.5312346 17.1939392 51.5475777 22.1805805 5.57649251 1.23668704 0.579635422 11.2099696 6.57586393 15.1992827 6.57586393 38.1378326 16.5695781 57.0870695 22.5658066 71.0496651 49.5488349 90.9962303 62.5406633 113.93478 95.5199201 110.942795 109.51112 99.9721846 195.457062 91.9935585 198.455176 79.0282912 182.465233 53.0977565"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/balikesir/" + id2}>
              <g
                id="156"
                transform="translate(337.987413, 379.902280)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bigadic"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="182.685095 83.85536 120.724067 20.0263514 87.7448105 13.0450536 48.7693252 0.0797862607 0.799497134 4.06909929 11.7925827 31.9942905 3.79761139 50.9435274 49.7686966 71.8874209 75.7523535 66.9007796 119.724696 86.8473447"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/balikesir/" + id2}>
              <g
                id="152"
                transform="translate(268.611050, 317.768730)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="altieylul"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="118.145689 62.2133367 107.152603 24.3148629 109.151346 0.378984738 91.1626607 18.3308934 64.1796324 33.2908173 10.2135759 24.3148629 0.219861712 55.2320389 17.2091758 61.2160085 47.1903183 133.023643 68.1771181 123.050361 73.1739752 113.077078 81.1689465 94.127841 70.1758609 66.2026498"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/balikesir/" + id2}>
              <g
                id="169"
                transform="translate(229.475665, 372.801303)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="savastepe"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="56.3445605 6.1834352 86.3257031 77.9910698 73.3338746 76.9937415 61.3414176 67.020459 32.3596465 67.020459 0.379761139 46.0765655 5.37661823 22.1406873 39.3552464 0.199465652"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/balikesir/" + id2}>
              <g
                id="164"
                transform="translate(147.647133, 335.521173)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ivrindi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="131.177492 6.56241994 121.183778 37.4795959 87.2051499 59.4208176 82.2082928 83.3566958 67.2177215 79.3673828 58.2233788 94.3273067 1.25920799 79.3673828 1.25920799 73.3834132 7.25543649 47.4528785 45.2315504 30.4982981 51.2277789 0.57845039 99.1976069 8.55707646 114.188178 0.57845039"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/balikesir/" + id2}>
              <g
                id="163"
                transform="translate(110.290630, 300.016287)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="havran"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="69.5962255 18.1314277 88.5842825 36.0833364 82.5880539 66.0031841 44.6119401 82.9577645 38.6157116 108.888299 19.6276546 101.907001 13.6314261 66.0031841 1.63896912 61.0165428 43.6125687 1.17684734"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/balikesir/" + id2}>
              <g
                id="159"
                transform="translate(12.452168, 282.263844)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="edremit"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="141.45103 18.9292903 88.4843453 0.977381693 67.4975455 14.9399773 35.5176602 15.9373056 19.5277175 25.9105881 0.539660565 70.7903598 57.5038314 69.7930315 70.4956598 62.8117337 83.4874882 71.787688 82.4881168 80.7636424 99.4774309 78.7689858"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/balikesir/" + id2}>
              <g
                id="157"
                transform="translate(67.597483, 360.374593)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="burhaniye"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="27.342802 2.65289317 0.35977371 34.5673974 32.3396591 45.5380083 42.3333732 66.4819017 81.3088585 54.5139626 81.3088585 48.529993 62.3208016 41.5486952 56.3245731 5.64487794 44.3321161 0.65823665"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/balikesir/" + id2}>
              <g
                id="161"
                transform="translate(40.914266, 394.104235)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gomec"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="27.0429906 0.837755737 1.0593337 26.7682905 9.05430504 34.7469165 45.0316761 45.7175274 69.0165901 32.75226 59.0228759 11.8083666"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/balikesir/" + id2}>
              <g
                id="153"
                transform="translate(0.000000, 406.530945)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="ayvalik"
                  d="M41.9735995,14.3415804 L22.9855426,17.3335651 L16.9893141,39.2747868 L12.9918284,27.3068477 L0,38.2774586 L4.99685709,43.2640998 L31.9798854,49.2480694 L38.9754853,78.1705889 L59.9622851,52.2400542 L85.9459419,33.2908173 L49.9685709,22.3202064 L41.9735995,14.3415804 Z M14.9905713,7.36028255 L8.99434276,8.3576108 L19.9874284,21.3228782 L6.99559992,22.3202064 L4.99685709,13.3442521 L2.99811425,0.378984738 L17.9886855,2.37364125 L14.9905713,7.36028255 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default BalikesirMap;
