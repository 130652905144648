import React, { useContext, useEffect } from "react";
import Navbar from "../components/Navbar";
import styles from "../css/blogDetail/blogDetail.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useLocation } from "react-router-dom";
import { Context } from "../context/Context";
import Footer from "../components/Footer";
import facebook from "../assets/facebookb.svg";
import share from "../assets/Linkb.svg";
import twitter from "../assets/xb.svg";
import whatsapp from "../assets/whatsappb.svg";
import telegram from "../assets/telegramb.svg";

function BlogDetailPage() {
  const { blog, dispatch } = useContext(Context);

  const myHeaders = new Headers();
  myHeaders.append(
    "Authorization",
    "Token 8f6c14510d09a853e6145cc52567a9bebe86c172"
  );

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  useEffect(() => {
    fetch("https://api.ibrahimaluc.com/api/blog/", requestOptions)
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: "GET_BLOG",
          payload: data.articles,
        });
      })
      .catch((error) => {
        console.error("Hata:", error);
      });
    window.scrollTo(0, 0);
  }, []);
  let url = "https://api.ibrahimaluc.com";

  const handleCopyToClipboard = () => {
    toast.success("Kopyalandı!", {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 2000,
    });
  };
  const location = useLocation();
  const { pathname } = location;
  const words = pathname.split("/").filter((word) => word.trim() !== "");

  return (
    <>
      <Navbar />
      <div className={styles.container}>
        {blog.map((article, index) => {
          if (article.slug === words[1]) {
            return (
              <div key={index} className={styles.card}>
                <div className={styles.blog_image_container}>
                  {" "}
                  <img
                    className={styles.blog_image}
                    src={url + article.image}
                    alt="Article"
                  />
                </div>
                <div className={styles.card_upper}>
                  <div className={styles.info}>
                    <div className={styles.author}>
                      <img
                        className={styles.author_picture}
                        src={url + article.author.photo}
                        alt=""
                      />
                      <p className={styles.author_name}>
                        {article.author.name}
                      </p>
                    </div>
                    <div className={styles.time}>
                      <p className={styles.update}> {article.updated_at}</p>
                      <FontAwesomeIcon
                        className={styles.clock}
                        icon={faClock}
                      />
                      <p className={styles.avg_read_minute}>
                        {article.avg_read_minute} dk
                      </p>
                    </div>
                  </div>
                  <div className={styles.social}>
                    <CopyToClipboard text={window.location.href}>
                      <img
                        onClick={handleCopyToClipboard}
                        className={styles.icon}
                        src={share}
                        alt=""
                      />
                    </CopyToClipboard>
                    <FacebookShareButton
                      url={"https://www.example.com"}
                      quote={"Dummy text!"}
                    >
                      <img className={styles.icon} src={facebook} alt="" />
                    </FacebookShareButton>
                    <TwitterShareButton url="deneme">
                      <img className={styles.icon} src={twitter} alt="" />
                    </TwitterShareButton>

                    <WhatsappShareButton>
                      <img className={styles.icon} src={whatsapp} alt="" />
                    </WhatsappShareButton>
                    <TelegramShareButton url="deneme">
                      <img className={styles.icon} src={telegram} alt="" />
                    </TelegramShareButton>
                  </div>
                </div>

                <div className={styles.tags}>
                  {article.tags.map((tag, index) => (
                    <p key={index} className={styles.tag}>
                      {tag.name}
                    </p>
                  ))}
                </div>
                <h1 className={styles.article_title}>{article.title}</h1>
                <div
                  className={styles.article}
                  dangerouslySetInnerHTML={{
                    __html: article.html,
                  }}
                />
              </div>
            );
          } else {
            // Return null if the title doesn't match to exclude it from rendering
            return null;
          }
        })}
      </div>
      <Footer />
    </>
  );
}

export default BlogDetailPage;
