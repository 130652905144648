import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function MersinMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 750 470"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Mersin" transform="translate(0, 0)">
            <NavLink to={"/mersin/" + id2}>
              <g
                id="524"
                transform="translate(0.000000, 319.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="anamur"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 28 10 54 12 98 1 116 57 138 95 119 90 103 100 86 99 70 122 42 130 7 114 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mersin/" + id2}>
              <g
                id="769"
                transform="translate(114.000000, 283.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gulnar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 36 36 0 46 11 46 16 52 13 61 17 68 8 102 5 129 13 137 31 153 29 167 38 158 63 186 86 186 137 179 127 159 135 155 150 124 140 125 121 109 103 65 86 54 77 31 85 29 53 16 43"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mersin/" + id2}>
              <g
                id="766"
                transform="translate(90.000000, 326.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bozyazi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="40 0 32 35 9 63 10 79 0 96 5 112 39 117 43 123 59 106 86 103 80 91 81 78 55 42 53 10"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mersin/" + id2}>
              <g
                id="765"
                transform="translate(145.000000, 360.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="aydincik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="31 69 25 57 26 44 0 8 23 0 34 9 78 26 94 44 93 63 81 70 66 63 58 70"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mersin/" + id2}>
              <g
                id="772"
                transform="translate(272.000000, 130.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="silifke"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="28 290 67 264 72 239 87 238 102 253 100 268 134 239 132 207 166 185 139 170 142 146 69 38 70 7 55 10 25 0 2 19 30 100 47 133 9 191 0 216 28 239"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mersin/" + id2}>
              <g
                id="771"
                transform="translate(106.000000, 149.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="mut"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="168 0 68 19 53 47 41 35 0 40 14 93 76 142 110 139 137 147 145 165 161 163 175 172 213 114 195 79"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mersin/" + id2}>
              <g
                id="768"
                transform="translate(341.000000, 78.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="erdemli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="97 237 166 168 156 136 121 97 82 93 80 67 93 0 48 25 1 59 0 90 73 198 70 222"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mersin/" + id2}>
              <g
                id="770"
                transform="translate(421.000000, 133.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="mezitli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="86 113 94 100 110 95 115 85 87 54 95 32 71 1 51 0 24 14 0 12 2 38 41 42 76 81"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mersin/" + id2}>
              <g
                id="775"
                transform="translate(508.000000, 165.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yenişehir"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="8 0 0 22 28 53 58 39 26 6"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mersin/" + id2}>
              <g
                id="774"
                transform="translate(421.000000, 67.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="toroslar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="13 11 60 0 72 18 90 27 104 52 126 56 138 82 151 90 144 104 159 134 145 137 113 104 95 98 71 67 51 66 24 80 0 78"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mersin/" + id2}>
              <g
                id="767"
                transform="translate(481.000000, 15.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="camliyayla"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 52 23 40 22 15 62 0 68 45 96 58 108 77 123 83 121 91 66 108 44 104 30 79 12 70"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mersin/" + id2}>
              <g
                id="773"
                transform="translate(543.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="tarsus"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 15 44 0 58 32 93 34 108 52 93 77 99 116 109 112 141 121 134 156 158 188 151 203 88 223 62 206 29 157 16 149 4 123 59 106 61 98 46 92 34 73 6 60"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mersin/" + id2}>
              <g
                id="763"
                transform="translate(565.000000, 157.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="akdeniz"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="7 0 0 14 15 44 40 49"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default MersinMap;
