import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function BayburtMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 400 220"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Bayburt" transform="translate(0, 0)">
            <NavLink to={"/bayburt/" + id2}>
              <g
                id="186"
                transform="translate(58.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="aydintepe"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="44 0 64 9 88 12 76 76 0 44 42 25"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bayburt/" + id2}>
              <g
                id="117"
                transform="translate(37.000000, 5.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="merkez"
                  d="M21,39 L0,78 L43,114 L49,172 L145,173 L210,144 L240,98 L199,93 L173,70 L196,55 L179,34 L195,0 L109,7 L97,71 L21,39 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/bayburt/" + id2}>
              <g
                id="187"
                transform="translate(0.000000, 83.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="demirozu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="37 0 38 7 51 36 0 85 6 110 38 133 50 116 86 94 80 36"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default BayburtMap;
