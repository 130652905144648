import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function GiresunMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 500 350"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Giresun" transform="translate(0, 0)">
            <NavLink to={"/giresun/" + id2}>
              <g
                id="433"
                transform="translate(0.000000, 52.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="pirariz"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="29 0 44 3 47 29 27 47 0 71 8 57 12 35 18 23"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/giresun/" + id2}>
              <g
                id="423"
                transform="translate(0.000000, 53.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bulancak"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="44 2 60 5 75 0 87 10 71 52 77 85 66 115 49 126 41 118 21 120 15 90 1 81 0 70 47 28"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/giresun/" + id2}>
              <g
                id="421"
                transform="translate(71.000000, 63.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="16 0 38 0 48 13 64 18 52 33 6 75 0 42"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/giresun/" + id2}>
              <g
                id="432"
                transform="translate(109.000000, 40.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="keşap"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 23 37 19 48 0 62 5 58 21 42 31 40 41 53 51 51 71 25 69 14 56 26 41 10 36"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/giresun/" + id2}>
              <g
                id="428"
                transform="translate(167.000000, 37.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="espiye"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="4 8 14 13 27 0 31 24 48 67 42 67 30 59 19 56 0 24"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/giresun/" + id2}>
              <g
                id="430"
                transform="translate(194.000000, 13.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gorele"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 24 5 14 40 0 43 38 41 70 45 75 38 81 24 51 4 48"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/giresun/" + id2}>
              <g
                id="435"
                transform="translate(234.000000, 13.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="tirebolu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 0 32 1 54 45 24 37 3 38"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/giresun/" + id2}>
              <g
                id="429"
                transform="translate(266.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="eylesil"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 14 24 0 32 1 24 22 22 58"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/giresun/" + id2}>
              <g
                id="425"
                transform="translate(235.000000, 50.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="canakci"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="53 8 68 42 40 57 4 38 0 33 2 1 23 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/giresun/" + id2}>
              <g
                id="427"
                transform="translate(232.000000, 88.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="dogankent"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="43 19 34 20 22 17 20 26 28 33 10 49 0 6 7 1.11022302e-16"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/giresun/" + id2}>
              <g
                id="431"
                transform="translate(176.000000, 61.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="guce"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="66 76 33 122 17 111 2 109 0 95 13 68 37 58 39 43 22 0 42 3 56 33"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/giresun/" + id2}>
              <g
                id="422"
                transform="translate(150.000000, 145.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="alucra"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="26 11 4 0 0 41 0 56 16 60 29 97 42 108 31 123 16 137 28 154 53 146 90 149 103 111 135 97 133 89 114 80 102 63 71 57 59 38 43 27 28 25"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/giresun/" + id2}>
              <g
                id="436"
                transform="translate(149.000000, 61.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yaglidere"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="11 50 5 84 27 95 40 68 64 58 66 43 60 43 48 35 37 32 18 0 2 10 0 20 13 30"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/giresun/" + id2}>
              <g
                id="426"
                transform="translate(48.000000, 96.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="dereli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="1 83 0 95 34 113 73 113 85 86 102 89 106 49 112 15 86 13 75 0 29 42 18 72"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/giresun/" + id2}>
              <g
                id="434"
                transform="translate(46.000000, 182.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sebinkarahisar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="2 9 0 14 7 38 23 73 18 109 30 107 39 99 42 101 39 110 41 113 51 117 63 114 67 117 60 120 52 124 56 130 65 134 76 131 83 131 86 126 108 136 132 117 120 100 135 86 146 71 133 60 120 23 104 19 104 3 87 0 75 27 36 27"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/giresun/" + id2}>
              <g
                id="424"
                transform="translate(154.000000, 291.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="camoluk"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="86 3 86 26 104 47 98 55 83 55 61 45 27 44 0 27 24 8 49 0"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default GiresunMap;
