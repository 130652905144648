import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function KirikkaleMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 600 330"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Kırıkkale" transform="translate(0, 0)">
            <NavLink to={"/kirikkale/" + id2}>
              <g
                id="632"
                transform="translate(34.000000, 238.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="celebi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="68 44 19 0 13 5 0 31 6 74 13 77 17 86"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kirikkale/" + id2}>
              <g
                id="635"
                transform="translate(53.000000, 162.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="keskin"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="49 120 77 117 114 74 136 31 132 19 106 15 96 1.77635684e-15 58 12 9 40 2 58 6 62 6 69 0 76"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kirikkale/" + id2}>
              <g
                id="633"
                transform="translate(148.000000, 45.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="delice"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="41 148 72 137 53 107 59 94 91 86 70 58 58 19 59 5 50 0 16 33 16 89 0 104 1 117 11 132 37 136"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kirikkale/" + id2}>
              <g
                id="636"
                transform="translate(81.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sulakyurt"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="117 45 92 23 56 7 34 0 0 58 5 75 2 86 12 91 83 78"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kirikkale/" + id2}>
              <g
                id="631"
                transform="translate(62.000000, 78.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="baliseyh"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="102 0 31 13 0 42 6 52 49 96 87 84 86 71 102 56"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kirikkale/" + id2}>
              <g
                id="637"
                transform="translate(18.000000, 118.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yahsisan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="44 2 31 7 16 0 10 7 0 58 29 57 50 12"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kirikkale/" + id2}>
              <g
                id="629"
                transform="translate(42.000000, 130.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="26 0 69 44 20 72 14 72 13 78 6 84 0 72 7 57 5 45"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kirikkale/" + id2}>
              <g
                id="630"
                transform="translate(6.000000, 175.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bahsili"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="41 0 12 1 0 47 42 49 43 38 36 27 43 12"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kirikkale/" + id2}>
              <g
                id="634"
                transform="translate(0.000000, 222.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="karakecili"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="6 0 0 7 2 17 14 28 15 35 34 47 47 21 42 17 51 8 48 1"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default KirikkaleMap;
