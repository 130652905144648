import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function ArtvinMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 480 330"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Artvin" transform="translate(0, 0)">
            <NavLink to={"/artvin/" + id2}>
              <g
                id="127"
                transform="translate(13.000000, 66.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="arhavi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 18 15 0 54 8 48 74 21 57 12 32"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/artvin/" + id2}>
              <g
                id="129"
                transform="translate(28.000000, 6.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="hopa"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 60 22 51 51 0 72 11 68 30 50 35 39 68"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/artvin/" + id2}>
              <g
                id="128"
                transform="translate(67.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="borcka"
                  d="M33,17 L79,31 L109,0 L141,7 L131,50 L103,88 C103,88 60,86 57,86 C54,86 0,74 0,74 L11,41 L29,36 L33,17 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/artvin/" + id2}>
              <g
                id="131"
                transform="translate(198.000000, 7.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="savsat"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="10 0 122 8 140 35 145 71 103 140 74 122 55 85 0 43"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/artvin/" + id2}>
              <g
                id="126"
                transform="translate(122.000000, 50.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ardanuc"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="76 0 48 38 0 36 75 142 161 145 179 97 150 79 131 42"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/artvin/" + id2}>
              <g
                id="125"
                transform="translate(78.000000, 86.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="119 106 108 115 73 92 0 67 29 45 44 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/artvin/" + id2}>
              <g
                id="130"
                transform="translate(61.000000, 74.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="murgul"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="61 12 6 0 0 66 3 73 17 79 46 57"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/artvin/" + id2}>
              <g
                id="132"
                transform="translate(0.000000, 147.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yusufeli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="186 54 182 108 152 148 115 142 57 184 47 126 0 102 9 70 47 23 64 0 78 6 151 31"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default ArtvinMap;
