import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function MuglaMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 800 450"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Muğla" transform="translate(0, 0)">
            <NavLink to={"/mugla/" + id2}>
              <g
                id="785"
                transform="translate(45.619614, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="milas"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="15.3289152 3.9957518 24.321321 0 68.2841943 1.9978759 93.2630995 13.9851313 112.247067 29.9681385 140.223441 28.9692005 149.215847 30.9670764 140.223441 55.9405251 146.218379 91.9022913 165.202347 104.888485 178.191377 126.86512 175.193909 145.844941 159.207409 156.833258 159.207409 178.809893 142.221754 181.806707 122.23863 177.810955 106.25213 186.801396 83.2715374 182.805645 89.2664747 168.820513 83.2715374 148.841754 73.2799753 139.851313 70.2825067 124.867244 48.3010701 134.856623 41.3069766 132.858747 43.305289 127.864057 36.3111956 113.878926 48.3010701 109.883174 50.2993825 119.872554 56.2943198 115.876802 52.2976949 109.883174 53.2968511 101.891671 63.2884132 96.8969811 69.2833505 101.891671 70.2825067 94.8991052 54.2960073 83.9107877 45.3036014 88.9054775 38.309508 102.890609 33.3137269 100.892733 37.3103518 89.9044154 32.3145707 84.9097257 24.321321 94.8991052 20.3246962 90.9033534 30.3162583 71.9235323 29.3171021 63.9320287 24.321321 63.9320287 25.3204773 71.9235323 16.3280714 76.9182221 1.34072822 66.9288426 5.33735306 51.9447733 8.33482168 49.9468974 9.33397789 53.9426492 16.3280714 54.9415872 23.3221648 46.9500836 18.3263838 37.9596421 9.33397789 28.9692005 13.3306027 15.9830072 39.3086642 19.978759 46.3027577 16.9819451 41.3069766 11.9872554 39.3086642 7.99150359 32.3145707 8.99044154 27.3187897 2.99681385 21.3238524 3.9957518"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mugla/" + id2}>
              <g
                id="789"
                transform="translate(185.624635, 20.445704)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yatagan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="9.21082608 10.521372 84.1475418 1.53093048 135.104509 61.4672074 38.1863562 106.419415 25.1973254 84.4427803 6.21335745 71.4565869 0.218420195 34.4958828"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mugla/" + id2}>
              <g
                id="781"
                transform="translate(268.998411, 11.009225)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kavaklidere"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0.773765158 10.9674094 15.7611083 0.978029945 44.7366384 7.97059559 73.7121685 31.9451064 70.7146998 52.9228033 51.7307319 70.9036864"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mugla/" + id2}>
              <g
                id="784"
                transform="translate(204.501716, 40.891409)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="mentese"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="138.208864 2.06292302 168.18355 1.06398507 168.18355 9.05548866 164.186925 11.0533646 166.185237 12.0523025 172.180175 4.06079892 173.179331 17.0469923 196.159924 42.020441 206.151486 75.9843312 217.142204 80.979021 243.120266 101.956718 192.163299 106.951408 179.174268 95.9630902 129.216458 106.951408 104.237552 104.953532 82.2561159 136.919546 28.3016805 138.917422 28.3016805 145.909988 17.3109622 139.91636 0.325306673 137.918484 0.325306673 115.941849 16.311806 104.953532 19.3092747 85.9737107 116.227427 41.021503 135.211395 23.0406199"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mugla/" + id2}>
              <g
                id="788"
                transform="translate(275.290772, 136.828945)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ula"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="121.374243 11.0138717 108.385213 0.0255542266 58.4274021 11.0138717 33.4484968 9.01599577 11.4670602 40.9820101 18.4611537 41.9809481 15.4636851 47.9745758 1.47549812 49.9724517 30.4510282 78.9416522 40.4425903 63.957583 62.4240269 54.9671414 91.399557 14.0106855"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mugla/" + id2}>
              <g
                id="782"
                transform="translate(297.314034, 141.547184)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="koycegiz"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="150.307948 1.30094245 176.28601 20.2807635 195.269978 35.2648327 182.280947 67.2308471 120.333262 109.186241 97.3526691 107.188365 73.3729201 98.1979235 92.3568881 89.207482 95.3543567 82.2149163 101.349294 81.2159784 92.3568881 75.2223507 71.3746077 83.2138543 75.3712325 90.2064199 70.3754515 92.2042958 66.3788266 90.2064199 61.3830456 94.2021717 70.3754515 106.189427 65.3796704 124.17031 50.3923273 118.176682 44.39739 122.172434 44.39739 128.166062 34.4058279 127.167124 36.4041403 119.17562 28.4108906 117.177745 24.4142658 109.186241 29.4100469 99.1968614 25.413422 95.2011096 16.4210161 103.192613 12.4243913 98.1979235 5.43029782 105.190489 1.43367298 95.2011096 8.42776645 74.2234127 18.4193285 59.2393435 40.4007652 50.2489019 69.3762952 9.29244604 100.350138 6.29563219"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mugla/" + id2}>
              <g
                id="777"
                transform="translate(0.000000, 121.101480)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="bodrum"
                  d="M93.9206837,13.7551432 L93.9206837,17.750895 L82.9299654,15.7530191 L63.9459974,26.7413366 L51.9561229,12.7562053 L41.9645608,12.7562053 L41.9645608,5.76363965 L36.9687798,2.76682581 L32.9721549,9.75939145 L26.9772177,0.76894991 L23.979749,12.7562053 L9.9915621,7.76151555 L5.99493726,12.7562053 L15.9864994,14.7540812 L15.9864994,18.749833 L0,30.7370884 L0,40.7264679 L4.99578105,47.7190335 L4.99578105,65.6999166 L14.9873431,67.6977925 L26.9772177,57.708413 L25.9780615,48.7179715 L31.9729987,47.7190335 L36.9687798,45.7211576 L37.967936,54.7115992 L41.9645608,53.7126612 L41.9645608,45.7211576 L44.9620294,44.7222197 L44.9620294,48.7179715 L52.9552791,48.7179715 L65.9443098,61.7041648 L75.9358719,59.7062889 L87.9257465,66.6988545 L92.9215275,60.7052268 L99.915621,67.6977925 L107.908871,66.6988545 L109.907183,61.7041648 L128.891151,61.7041648 L134.886088,47.7190335 L128.891151,27.7402745 L118.899589,18.749833 L115.90212,3.76576376 L93.9206837,13.7551432 Z M48.9586543,60.7052268 L57.9510602,60.7052268 L67.9426223,67.6977925 L66.9434661,70.6946063 L59.9493726,72.6924822 L48.9586543,60.7052268 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/mugla/" + id2}>
              <g
                id="783"
                transform="translate(182.478455, 185.584086)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="marmaris"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="94.2878155 1.21731043 89.2920345 4.21412428 90.2911907 9.20881402 82.297941 6.21200018 74.3046913 15.2024417 75.3038475 24.1928833 64.3131292 24.1928833 64.3131292 36.1801386 62.3148168 36.1801386 61.3156606 32.1843868 54.3215671 26.1907592 32.3401305 24.1928833 34.3384429 27.1896971 25.3460371 28.1886351 25.3460371 36.1801386 33.3392867 47.1684561 20.350256 48.167394 19.3510998 60.1546494 23.3477246 63.1514633 29.3426619 59.1557115 36.3367554 63.1514633 46.3283175 70.1440289 51.3240985 84.1291602 43.3308488 83.1302222 38.3350678 89.1238499 47.3274737 93.1196017 42.3316926 102.110043 34.3384429 94.1185397 19.3510998 103.108981 10.3586939 103.108981 5.36291287 110.101547 12.3570063 114.097299 23.3477246 114.097299 25.3460371 106.105795 28.3435057 107.104733 28.3435057 113.098361 29.3426619 119.091988 38.3350678 123.08774 30.3418181 134.076058 14.3553188 135.074996 7.36122528 131.079244 0.367131817 136.073934 5.36291287 141.068623 7.36122528 150.059065 13.3561625 147.062251 21.3494122 149.060127 30.3418181 142.067561 43.3308488 142.067561 60.3165044 116.095175 76.3030038 113.098361 80.2996286 102.110043 94.2878155 102.110043 96.286128 95.1174776 91.2903469 93.1196017 85.2954096 86.1270361 82.297941 71.1429669 88.2928783 60.1546494 95.2869717 62.1525253 103.280221 69.145091 90.2911907 75.1387187 92.2895031 79.1344705 99.2835966 79.1344705 103.280221 72.1419048 125.261658 82.1312843 131.256595 69.145091 127.25997 65.1493392 120.265877 65.1493392 120.265877 61.1535874 116.269252 51.1642079 123.263346 30.186511"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mugla/" + id2}>
              <g
                id="779"
                transform="translate(23.596352, 242.202960)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="datca"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="195.218858 6.53258956 188.224765 14.5240932 174.236578 12.5262173 146.260204 7.53152751 140.265267 9.52940341 123.279611 4.53471367 112.288893 11.5272793 95.3032371 11.5272793 81.3150501 1.53789982 78.3175815 2.53683777 72.3226443 14.5240932 37.3521769 19.5187829 32.3563959 14.5240932 19.3673651 18.519845 19.3673651 27.5102865 1.38255336 29.5081624 2.38170957 40.4964798 10.3749592 43.4932937 12.3732717 49.4869214 19.3673651 46.4901075 32.3563959 53.4826732 42.347958 44.4922316 45.3454266 47.4890455 55.3369887 44.4922316 84.3125188 54.4816111 86.3108312 41.4954178 92.3057684 36.500728 92.3057684 27.5102865 100.299018 22.5155967 126.27708 23.5145347 131.272861 28.5092244 140.265267 28.5092244 142.263579 25.5124106 145.261048 29.5081624 148.258516 24.5134726 152.255141 25.5124106 152.255141 29.5081624 158.250078 29.5081624 180.231515 26.5113485 185.227296 20.5177208 188.224765 23.5145347 205.21042 13.5251552"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mugla/" + id2}>
              <g
                id="786"
                transform="translate(361.810729, 239.057467)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ortaca"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="55.8365668 11.6759584 32.855974 9.67808255 8.87622493 0.687641007 9.87538114 7.68020665 5.8787563 8.6791446 0.882975255 26.6600277 12.8728498 31.6547174 9.87538114 33.6525933 1.88213146 31.6547174 0.882975255 39.646221 3.88044388 44.6409108 0.882975255 47.6377246 0.882975255 58.626042 3.88044388 62.6217938 6.87791251 58.626042 12.8728498 58.626042 15.8703184 65.6186077 28.8593491 62.6217938 42.8475361 66.6175456 55.8365668 33.6525933"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mugla/" + id2}>
              <g
                id="778"
                transform="translate(404.284162, 176.147607)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="dalaman"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0.374102674 129.527405 7.36819614 135.521033 5.36988372 141.514661 15.3614458 147.508288 11.364821 155.499792 16.360602 165.489171 25.3530079 165.489171 28.3504765 152.502978 35.34457 146.50935 29.3496328 144.511475 24.3538517 148.507226 23.3546955 131.525281 34.3454138 130.526343 44.3369759 110.547584 57.3260066 87.5720115 86.3015367 75.5847561 111.280442 69.5911284 93.2956302 46.6155555 95.2939426 21.6421068 88.2998491 0.664409892 75.3108184 32.6304243 13.3631334 74.5858181 13.3631334 96.562453"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mugla/" + id2}>
              <g
                id="780"
                transform="translate(448.330686, 245.348453)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="fethiye"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="67.2339184 0.390282734 42.2550132 6.38391043 13.2794831 18.3711658 0.290452387 41.3467386 6.28538965 52.3350561 10.2820145 53.333994 12.2803269 58.3286838 33.2626073 68.3180633 42.2550132 69.3170012 48.2499505 80.3053187 45.2524818 87.2978843 38.2583884 80.3053187 33.2626073 91.2936361 24.2702014 90.2946981 20.2735766 102.281954 26.2685138 109.274519 15.2777955 112.271333 16.2769517 116.267085 27.26767 116.267085 30.2651387 110.273457 38.2583884 110.273457 39.2575446 114.269209 47.2507942 116.267085 47.2507942 126.256464 39.2575446 138.24372 44.2533256 147.234161 42.2550132 160.220355 38.2583884 161.219293 38.2583884 169.210796 48.2499505 174.205486 49.2491067 180.199114 61.2389812 186.192741 75.2271681 165.215044 74.2280119 127.255402 85.2187302 91.2936361 89.2153551 10.3796622"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mugla/" + id2}>
              <g
                id="787"
                transform="translate(508.108111, 206.029790)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="seydikemer"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="29.4379303 49.6983245 43.4261172 44.7036348 55.4159917 21.7280619 58.4134604 0.750365017 66.40671 5.74505476 68.4050225 11.7386825 79.3957408 20.729124 66.40671 43.7046968 69.4041787 50.6972625 83.3923656 48.6993866 120.361145 21.7280619 136.347645 13.7365584 158.329081 23.7259378 168.320643 37.7110691 148.337519 67.6792076 134.349332 115.628229 114.366208 141.600616 43.4261172 184.554948 21.4446806 239.496535 15.4497433 231.505031 1.46155641 225.511404 15.4497433 204.533707 14.4505871 166.574065 25.4413054 130.612298"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default MuglaMap;
