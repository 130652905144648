import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function KayseriMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 600 530"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Kayseri" transform="translate(0, 0)">
            <NavLink to={"/kayseri/" + id2}>
              <g
                id="627"
                transform="translate(0.000000, 260.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yesilhisar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="45 0 1 36 0 54 0 66 24 68 41 121 91 134 115 110 98 80 107 36"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kayseri/" + id2}>
              <g
                id="617"
                transform="translate(23.000000, 169.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="incesu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="22 91 28 71 15 56 15 27 0 14 49 0 84 20 78 51 95 59 120 96 84 127"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kayseri/" + id2}>
              <g
                id="616"
                transform="translate(101.000000, 198.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="hacilar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="4 0 0 22 17 30 42 67 57 65 58 39 39 15 17 9"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kayseri/" + id2}>
              <g
                id="619"
                transform="translate(105.000000, 151.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="melikgazi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 47 2 38 35 45 56 18 83 19 96 3 115 0 124 21 113 51 97 47 77 55 63 82 74 110 53 112 54 86 35 62 13 56"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kayseri/" + id2}>
              <g
                id="624"
                transform="translate(168.000000, 198.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="talas"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="50 4 71 52 11 63 0 35 14 8 34 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kayseri/" + id2}>
              <g
                id="613"
                transform="translate(209.000000, 97.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bunyan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="30 153 48 154 62 146 77 152 105 122 114 56 99 52 101 23 92 14 28 12 15 0 0 15 11 54 20 75 9 104"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kayseri/" + id2}>
              <g
                id="618"
                transform="translate(23.000000, 77.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kocasinan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="201 20 198 10 178 0 168 18 119 53 98 52 86 38 46 36 36 25 15 28 0 106 49 92 84 112 117 119 138 92 165 93 178 77 197 74 186 35"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kayseri/" + id2}>
              <g
                id="615"
                transform="translate(109.000000, 43.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="felahiye"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="92 34 79 0 58 3 44 6 42 15 55 27 54 34 17 55 0 72 12 86 33 87 82 52"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kayseri/" + id2}>
              <g
                id="620"
                transform="translate(188.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ozvatan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 43 58 0 74 15 33 87 13 77"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kayseri/" + id2}>
              <g
                id="622"
                transform="translate(221.000000, 15.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sarioglan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="41 0 48 11 33 27 56 30 77 48 100 52 80 96 16 94 3 82 0 72"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kayseri/" + id2}>
              <g
                id="612"
                transform="translate(301.000000, 61.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="akkisla"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="20 6 82 0 83 19 53 83 22 92 7 88 9 59 0 50"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kayseri/" + id2}>
              <g
                id="621"
                transform="translate(286.000000, 42.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="pinarbasi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="97 19 105 15 133 25 182 0 215 30 257 54 191 158 116 214 82 226 57 217 14 221 0 207 28 177 37 111 68 102 98 38"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kayseri/" + id2}>
              <g
                id="623"
                transform="translate(354.000000, 200.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sariz"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="123 0 138 51 122 60 129 87 103 93 56 161 43 152 40 127 0 108 14 68 48 56"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kayseri/" + id2}>
              <g
                id="625"
                transform="translate(179.000000, 243.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="tomazra"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="189 25 164 16 121 20 107 6 92 0 77 8 60 7 0 18 2 37 24 52 36 93 64 107 88 98 126 115 175 65"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kayseri/" + id2}>
              <g
                id="614"
                transform="translate(98.000000, 261.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="develi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="81 0 59 2 45 4 9 35 0 79 83 115 97 187 138 167 179 149 193 126 207 97 169 80 145 89 117 75 105 34 83 19"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/kayseri/" + id2}>
              <g
                id="626"
                transform="translate(91.000000, 340.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yahyali"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="104 109 90 36 7 0 24 30 0 54 10 65 18 136 1 179 92 187 94 122"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default KayseriMap;
