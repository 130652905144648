import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function AksarayMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 600 360"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Aksaray" transform="translate(0, 0)">
            <NavLink to={"/aksaray/" + id2}>
              <g
                id="60"
                transform="translate(250.000000, 221.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="guzelyurt"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="58 0 73 39 37 60 0 29 17 2"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/aksaray/" + id2}>
              <g
                id="59"
                transform="translate(256.000000, 150.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gulagac"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="11 73 0 64 4 43 39 26 49 0 63 12 66 30 53 55 52 71"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/aksaray/" + id2}>
              <g
                id="56"
                transform="translate(76.000000, 105.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="229 45 197 29 174 35 112 28 95 10 61 0 48 15 52 28 62 38 58 42 46 42 29 48 25 68 15 82 20 92 0 116 4 251 18 251 143 236 211 176 174 145 191 118 180 109 184 88 219 71"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/aksaray/" + id2}>
              <g
                id="58"
                transform="translate(0.000000, 159.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="eskil"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="91 28 88 40 83 40 83 32 78 27 79 21 39 0 8 56 0 91 52 197 80 197 76 62 96 38"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/aksaray/" + id2}>
              <g
                id="61"
                transform="translate(171.000000, 21.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ortakoy"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="102 113 103 62 82 52 50 3 39 0 20 15 44 52 0 94 17 112 79 119"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/aksaray/" + id2}>
              <g
                id="57"
                transform="translate(137.000000, 36.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="agacoren"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="34 79 0 69 18 14 43 13 54 0 78 37"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/aksaray/" + id2}>
              <g
                id="62"
                transform="translate(138.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sariyahsi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="17 51 6 41 0 35 4 19 32 2 42 8 53 0 63 4 72 22 53 37 42 50"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default AksarayMap;
