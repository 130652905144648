import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function GumushaneMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 500 300"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Gümüşhane" transform="translate(0, 0)">
            <NavLink to={"/gumushane/" + id2}>
              <g id="441" fill="#f0f0f0" opacity="1">
                <polygon
                  id="kurtun"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="101 5 94 0 66 15 57 16 45 13 43 22 51 29 33 45 0 91 12 110 43 116 128 20 118 16"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/gumushane/" + id2}>
              <g
                id="443"
                transform="translate(43.000000, 19.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="torul"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="85 1 148 32 87 143 33 131 31 123 11 114 0 97"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/gumushane/" + id2}>
              <g
                id="438"
                transform="translate(130.000000, 14.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="61 37 84 57 98 55 115 35 117 6 124 0 132 5 135 15 129 30 138 34 150 33 158 50 169 46 171 35 166 25 173 20 184 23 191 39 199 46 197 71 155 90 134 129 135 136 97 145 78 137 58 163 0 148"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/gumushane/" + id2}>
              <g
                id="440"
                transform="translate(188.000000, 150.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kose"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="77 0 90 29 39 78 2 44 0 27 20 1 40 9"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/gumushane/" + id2}>
              <g
                id="439"
                transform="translate(95.000000, 162.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kelkit"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="132 66 138 91 170 114 125 143 0 105 7 83 27 79 35 0 93 15 95 32"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/gumushane/" + id2}>
              <g
                id="442"
                transform="translate(31.000000, 150.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="siran"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="99 12 45 0 13 14 0 52 0 75 18 96 64 117 71 95 91 91"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default GumushaneMap;
