import { createContext } from "react";
import { useReducer } from "react";
import Reducer from "../reducer/Reducer"

export const Context = createContext();
const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, {
    isHovered: false,
    isHovered2: false,
    id: "",
    id2: "",
    id3: "",
    infos: [],
    district: [],
    latestUpdate:[],
    articles: [],
    spotify:[],
    youtube:[],  
    mouseX: 0,
    mouseY: 0,      
    mouseX2: 0,
    mouseY2: 0,
    popup:[],
    blog: [],
    shuffle:[]
  });

  const data = {
    ...state,
    dispatch,
  };

  return <Context.Provider value={data}>{children}</Context.Provider>;
};
export default Provider;
