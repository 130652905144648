import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function OsmaniyeMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 350 270"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Osmaniye" transform="translate(0, 0)">
            <NavLink to={"/osmaniye/" + id2}>
              <g
                id="838"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kadirli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="115 0 65 10 59 107 12 146 8 172 0 190 9 191 42 180 69 161 99 180 109 161 101 158 98 150 101 142 106 141 105 129 107 126 108 40 91 35 89 27 112 15"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/osmaniye/" + id2}>
              <g
                id="839"
                transform="translate(6.000000, 10.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sumbas"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="59 0 22 56 14 106 0 118 6 136 53 97"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/osmaniye/" + id2}>
              <g
                id="836"
                transform="translate(99.000000, 115.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="duzici"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="56 6 60 4 73 0 92 3 109 26 62 71 54 71 0 65 10 46 12 45 13 41 9 37 22 21 25 15 29 16 29 27 33 28 37 18 55 11"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/osmaniye/" + id2}>
              <g
                id="835"
                transform="translate(160.000000, 141.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="bahce"
                  d="M48,0 L65,30 L44,49 L31.9999994,51 L21,50 C21,50 -3.41948692e-13,45 1,45 C2,45 48,0 48,0 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/osmaniye/" + id2}>
              <g
                id="834"
                transform="translate(42.000000, 161.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="111 25 57 19 27 0 0 19 16 29 13 78 60 81 80 100 123 102 129 69 120 63 131 52 124 41 111 40"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/osmaniye/" + id2}>
              <g
                id="837"
                transform="translate(153.000000, 186.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="hasanbeyli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="9 0 0 0 0 15 13 16 20 27 9 38 18 44 51 4 39 6 27 5"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default OsmaniyeMap;
