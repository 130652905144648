import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function BoluMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 550 300"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Bolu" transform="translate(0, 0)">
            <NavLink to={"/bolu/" + id2}>
              <g
                id="220"
                transform="translate(313.779070, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="mengen"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="134.22093 4.99960317 117.22093 0 101.22093 2.9997619 7.22093023 8.99928571 0.220930233 39.9968254 42.2209302 74.9940476 56.2209302 73.994127 88.2209302 57.9953968 95.2209302 60.9951587 124.22093 62.995 146.22093 45.9963492 140.22093 14.9988095"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bolu/" + id2}>
              <g
                id="217"
                transform="translate(391.918605, 45.170833)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="gerede"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="68.0813953 0.825515873 79.0813953 12.8245635 118.081395 21.8238492 131.081395 34.8228175 133.081395 48.8217063 128.081395 71.819881 84.0813953 80.8191667 79.0813953 91.8182937 88.0813953 105.817183 86.0813953 117.81623 32.0813953 151.813532 22.0813953 154.813294 1.08139535 132.81504 26.0813953 99.8176587 11.0813953 71.819881 11.0813953 47.8217857 9.08139535 22.8237698 17.0813953 15.8243254 46.0813953 17.8241667"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bolu/" + id2}>
              <g
                id="223"
                transform="translate(368.720930, 57.379167)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yenicaga"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="40.2790698 3.61599206 33.2790698 0.616230159 1.27906977 16.6149603 0.279069767 42.6128968 34.2790698 36.613373 32.2790698 10.6154365"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bolu/" + id2}>
              <g
                id="216"
                transform="translate(350.406977, 92.783333)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="dortdivan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="52.5930233 1.20920635 18.5930233 7.20873016 19.5930233 14.2081746 30.5930233 22.2075397 0.593023256 79.2030159 21.5930233 90.2021429 42.5930233 85.2025397 67.5930233 52.2051587 52.5930233 24.207381"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bolu/" + id2}>
              <g
                id="215"
                transform="translate(220.988372, 39.066667)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="merkez"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="149.011628 34.9274603 135.011628 35.927381 93.0116279 0.93015873 71.0116279 15.9289683 19.0116279 23.9283333 6.01162791 43.926746 1.01162791 84.9234921 4.01162791 97.9224603 29.0116279 115.921032 70.0116279 116.920952 105.011628 111.921349 130.011628 132.919683 160.011628 75.9242063 149.011628 66.9249206 148.011628 60.9253968"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bolu/" + id2}>
              <g
                id="219"
                transform="translate(290.581395, 150.162500)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kibriscik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0.418604651 5.82511905 20.4186047 87.8186111 60.4186047 87.8186111 91.4186047 65.8203571 123.418605 49.821627 102.418605 27.823373 81.4186047 32.8229762 60.4186047 21.8238492 35.4186047 0.825515873"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bolu/" + id2}>
              <g
                id="222"
                transform="translate(231.976744, 153.825000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="seben"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="17.0232558 1.16269841 0.023255814 72.1570635 8.02325581 86.1559524 43.0232558 96.1551587 79.0232558 84.1561111 59.0232558 2.16261905"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bolu/" + id2}>
              <g
                id="221"
                transform="translate(92.790698, 117.200000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="mudurnu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="129.209302 6.79015873 83.2093023 15.7894444 67.2093023 2.79047619 39.2093023 5.7902381 15.2093023 6.79015873 7.20930233 0.790634921 1.20930233 39.7875397 27.2093023 54.7863492 40.2093023 81.7842063 41.2093023 103.78246 56.2093023 109.781984 61.2093023 98.7828571 102.209302 118.78127 139.209302 108.782063 157.209302 37.7876984 132.209302 19.789127"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bolu/" + id2}>
              <g
                id="218"
                transform="translate(0.000000, 156.266667)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="goynuk"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="94 0.720873016 83 12.7199206 30 12.7199206 10 40.7176984 16 63.715873 0 77.7147619 16 90.7137302 19 111.712063 30 128.710714 56 128.710714 74 136.710079 141 102.712778 149 70.7153175 134 64.7157937 133 42.7175397 120 14.7197619"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default BoluMap;
