import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function BitlisMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 600 400"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Bitlis" transform="translate(0, 0)">
            <NavLink to={"/bitlis/" + id2}>
              <g
                id="211"
                transform="translate(218.000000, 269.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="hizan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="130 104 126 27 106 6 90 3 75 30 27 0 0 46 61 82 107 123"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bitlis/" + id2}>
              <g
                id="207"
                transform="translate(83.000000, 206.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="merkez"
                  d="M162,63 C162,63 106,26 105,26 C104,26 88,0 88,0 L33,7 L42,65 L19,65 L0,95 L62,113 L97,90 L135,109 L162,63 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/bitlis/" + id2}>
              <g
                id="213"
                transform="translate(171.000000, 127.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="tatvan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="153 148 160 72 141 76 102 59 90 69 69 57 36 68 56 34 77 23 59 0 15 9 0 79 16 105 74 142 122 172 137 145"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bitlis/" + id2}>
              <g
                id="212"
                transform="translate(0.000000, 170.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="mutki"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="116 43 102 0 35 4 0 17 23 26 38 59 56 122 83 131 102 101 125 101"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bitlis/" + id2}>
              <g
                id="210"
                transform="translate(102.000000, 131.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="guroymak"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 39 21 33 28 0 84 5 69 75 14 82"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bitlis/" + id2}>
              <g
                id="197"
                transform="translate(121.000000, 44.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ahlat"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="9 87 20 77 0 67 1 54 39 44 54 20 115 0 141 5 158 48 115 68 109 83 65 92"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/bitlis/" + id2}>
              <g
                id="71"
                transform="translate(262.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="adilcevaz"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 46 48 36 103 0 140 17 124 24 133 47 152 45 143 32 163 34 158 55 131 78 73 82 17 89"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default BitlisMap;
