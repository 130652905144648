import React, { useContext } from "react";
import "@splidejs/react-splide/css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import styles from "../../css/podcast/podcast.module.css";
import { Context } from "../../context/Context";
import Ad from "../ads/Ad"
function Podcast() {
  const { spotify } = useContext(Context);

  return (
    <div className={styles.container} id="podcast" >
      <h1  className={styles.header}>Podcast</h1>
      <hr className={styles.hr} />
      <div  className={styles.main_container}>
        <Ad/>
        <Splide
          className={styles.splide_main}
          options={{
            type: "loop",
            perPage: 1,
            autoplay: true,
            interval: 5000,
            speed: 4000,
            pagination: true,
            focus: "center",
            arrows: false,
            
          }}
        >
          {spotify.map((data, index) => (
            <SplideSlide className={styles.splide} key={index}>
              <iframe
                id={index}
                title={index}
                className={styles.iframe}
                style={{ borderRadius: "12px" }}
                src={`https://open.spotify.com/embed/episode/${data.episode_id}?utm_source=generator`}                               
                allowFullScreen=""
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                loading="lazy"
              ></iframe>
            </SplideSlide>
          ))}
        </Splide>
        <Ad/>
      </div>
    </div>
  );
}

export default Podcast;
