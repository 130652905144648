import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function SanliurfaMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 700 460"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Şanlıurfa" transform="translate(0, 0)">
            <NavLink to={"/sanliurfa/" + id2}>
              <g
                id="931"
                transform="translate(0.000000, 202.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="halfeti"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="42 0 57 5 101 69 6 109 0 93 4 83 0 69 10 50 14 35"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sanliurfa/" + id2}>
              <g
                id="928"
                transform="translate(57.000000, 189.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="bozova"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 18 28 21 69 0 64 5 66 12 91 16 135 37 153 38 151 32 165 25 168 32 156 79 121 102 110 92 102 99 98 136 78 120 44 82"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sanliurfa/" + id2}>
              <g
                id="927"
                transform="translate(6.000000, 271.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="birecik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="129 38 104 57 87 51 88 70 90 114 55 132 52 125 53 122 48 107 32 83 9 69 12 65 5 55 0 40 95 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sanliurfa/" + id2}>
              <g
                id="937"
                transform="translate(93.000000, 309.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="suruc"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="3 76 40 72 84 83 105 97 117 72 87 33 62 16 42 0 17 19 0 13"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sanliurfa/" + id2}>
              <g
                id="935"
                transform="translate(155.000000, 176.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="karakopru"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="25 166 105 111 140 44 135 25 116 33 75 28 50 0 47 3 49 11 60 20 61 25 36 23 27 27 31 37 53 45 67 38 70 45 58 92 23 115 12 105 4 112 0 149"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sanliurfa/" + id2}>
              <g
                id="224"
                transform="translate(198.000000, 368.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="akcakale"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="12 13 27 19 88 0 104 41 146 52 204 50 223 29 247 41 261 65 212 77 179 86 126 72 70 78 26 55 0 38"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sanliurfa/" + id2}>
              <g
                id="933"
                transform="translate(286.000000, 360.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="harran"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 7 16 0 82 26 101 17 115 20 135 37 116 58 57 60 16 49"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sanliurfa/" + id2}>
              <g
                id="930"
                transform="translate(180.000000, 287.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="eyyubiye"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="221 93 218 72 230 55 208 56 215 24 80 0 0 55 30 94 45 100 106 81 122 73 188 99 207 90"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sanliurfa/" + id2}>
              <g
                id="929"
                transform="translate(398.000000, 282.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ceylanpinar"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="61 151 248 76 214 39 213 0 160 9 136 45 96 55 37 30 12 60 0 76 3 98 23 115 47 127"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sanliurfa/" + id2}>
              <g
                id="938"
                transform="translate(388.000000, 162.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="viransehir"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="222.94676 120 151.963711 0 107.974216 0 79.9809005 21 39.9904503 108 8.99785131 109 6.9983288 149 0 181 21.9947476 180 46.9887791 150 105.974693 175 145.965143 165 169.959414 129"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sanliurfa/" + id2}>
              <g
                id="936"
                transform="translate(284.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="siverek"
                  d="M256,162 L247,149 L234,124 L242,65 L218,41 L185,62 L159,45 L62,0 L47,0 L32,13 L31,34 L12,50 L5,76 L13,81 L24,89 L1,103 L0,116 L19,107 L17,121 L44,127 L40,132 L47,137 L79,143 L83,164 L59,200 L114,271 L144,270 L184,183 L212,162 L256,162 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/sanliurfa/" + id2}>
              <g
                id="934"
                transform="translate(205.000000, 123.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="hilvan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="138 77 90 97 85 78 67 86 25 81 0 53 5 40 13 28 40 19 64 15 61 2 75 0 80 17 90 11 102 8 109 15 119 9 126 14 158 20 162 41"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sanliurfa/" + id2}>
              <g
                id="932"
                transform="translate(260.000000, 200.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="haliliye"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="83 0 35 20 0 87 135 111 138 71"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default SanliurfaMap;
