import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function SakaryaMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 400 300"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Sakarya" transform="translate(0, 0)">
            <NavLink to={"/sakarya/" + id2}>
              <g
                id="864"
                transform="translate(101.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="kaynarca"
                  d="M19,0 C19.0000015,-1.75007142e-06 39,11 39,11 L51,40 L39,51 L48,63 L41,84 L28,86 L0,77 C0,77 18.9999985,1.75007142e-06 19,0 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/sakarya/" + id2}>
              <g
                id="863"
                transform="translate(140.000000, 11.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="karasu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 0 95 28 92 46 80 89 34 69 26 57 30 42 12 29"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sakarya/" + id2}>
              <g
                id="865"
                transform="translate(220.000000, 39.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="kocali"
                  d="M15,0 C14.9999983,-9.94675929e-07 58,4 58,4 L62,21 L50,47 L22,68 L0,61 L12,18 C12,18 15.0000017,9.94675929e-07 15,0 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/sakarya/" + id2}>
              <g
                id="859"
                transform="translate(140.000000, 40.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="ferizli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="33 48 2 44 9 23 0 11 12 0 30 13 26 28 34 40"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sakarya/" + id2}>
              <g
                id="861"
                transform="translate(167.000000, 80.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="hendek"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="7 0 6 8 4 31 0 38 24 79 59 99 84 102 93 88 84 68 72 66 69 58 73 41 75 27 53 20"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sakarya/" + id2}>
              <g
                id="352"
                transform="translate(156.000000, 118.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="akyazi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="11 0 2 12 0 45 22 82 8 105 25 115 78 115 89 103 95 64 70 61 35 41"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sakarya/" + id2}>
              <g
                id="868"
                transform="translate(83.000000, 77.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="serdivan"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="75 53 57 60 35 57 32 69 24 73 0 73 2 56 14 28 18 0 46 9 44 19 53 29 88 34 84 41"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sakarya/" + id2}>
              <g
                id="869"
                transform="translate(127.000000, 84.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sogutlu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="2 2 0 12 9 22 44 27 46 4 15 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sakarya/" + id2}>
              <g
                id="858"
                transform="translate(115.000000, 130.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="erenler"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 16 17 20 17 35 4 47 12 52 41 33 43 0 25 7 3 4"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sakarya/" + id2}>
              <g
                id="857"
                transform="translate(107.000000, 146.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="arifiye"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="2 12 7 15 6 29 12 31 25 19 25 4 8 0 0 4"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sakarya/" + id2}>
              <g
                id="867"
                transform="translate(61.000000, 158.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="sapanca"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="48 0 41 5 33 6 19 4 15 4 0 24 19 31 30 21 52 17 53 3"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sakarya/" + id2}>
              <g
                id="866"
                transform="translate(0.000000, 182.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="pamukova"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="80 7 74 49 54 57 13 55 10 34 0 28 26 8 61 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sakarya/" + id2}>
              <g
                id="860"
                transform="translate(13.000000, 175.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="geyve"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="151 48 105 63 101 91 64 97 47 96 29 76 12 76 0 62 41 64 61 56 67 14 78 4 100 0 106 2 114 7"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sakarya/" + id2}>
              <g
                id="870"
                transform="translate(114.000000, 223.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="tarakli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="67 10 47 38 53 61 38 75 29 71 12 63 14 47 0 43 4 15 50 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/sakarya/" + id2}>
              <g
                id="862"
                transform="translate(127.000000, 163.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="karapurcek"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 19 37 60 51 37 29 0"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default SakaryaMap;
