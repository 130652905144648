import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { Context } from "../../context/Context";

function MardinMap() {
  const { dispatch, id2 } = useContext(Context);
  const getId = (event) => {
    dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_MOUSEX2",
      payload: event.clientX,
    });
    dispatch({
      type: "GET_MOUSEY2",
      payload: event.clientY,
    });
    dispatch({
      type: "SET_HOVER2",
      payload: true,
    });
  };
  const handleMouseLeave = () => {
    dispatch({ type: "SET_HOVER2", payload: false });
  };
  const onClick = (event) => {
    sessionStorage.setItem("myData2", event.target.id);
    sessionStorage.setItem("myData3", event.target.id);
     dispatch({
      type: "GET_ID2",
      payload: event.target.id,
    });
    dispatch({
      type: "GET_ID3",
      payload: event.target.id,
    });
  };
  return (
    <div>
      <div class="provincie-map-area">
        <svg
          width="100%"
          height="100%"
          style={{ position: "relative" }}
          viewBox="0 0 550 300"
          preserveAspectRatio="xMidYMid meet"
        >
          <g id="Mardin" transform="translate(0, 0)">
            <NavLink to={"/mardin/" + id2}>
              <g
                id="754"
                transform="translate(0.000000, 82.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="derik"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 4 96 0 102 33 160 68 146 111 79 136 8 16"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mardin/" + id2}>
              <g
                id="756"
                transform="translate(96.000000, 46.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="mazidagi"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 36 94 0 119 47 91 69 100 93 64 104 6 69"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mardin/" + id2}>
              <g
                id="760"
                transform="translate(190.000000, 3.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="savur"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 43 20 37 18 7 118 0 151 68 134 81 78 94 25 90"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mardin/" + id2}>
              <g
                id="752"
                transform="translate(187.000000, 93.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="artuklu"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="81 4 88 18 66 30 67 41 96 36 126 64 145 110 58 120 37 53 9 46 0 22 28 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mardin/" + id2}>
              <g
                id="761"
                transform="translate(253.000000, 111.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="yesilli"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="22 0 0 11.5 1 23 30 18"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mardin/" + id2}>
              <g
                id="759"
                transform="translate(268.000000, 84.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="omerli"
                  d="M45,73 L51,50 L93,53 L56,0 L0,13 L7,27 L15,45 L45,73 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/mardin/" + id2}>
              <g
                id="758"
                transform="translate(313.000000, 134.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="nusaybin"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="48 3 67 21 152 2 152 21 178 19 175 61 74 82 19 69 0 23 6 0"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mardin/" + id2}>
              <g
                id="757"
                transform="translate(323.000000, 59.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <path
                  id="midyat"
                  d="M18,12 C18,12 117,0 117,0 C117,0 150,26 150,26 L142,77 L57,96 L38,78 L1,25 L18,12 Z"
                  stroke="#000000"
                  fill="#f0f0f0"
                ></path>
              </g>
            </NavLink>
            <NavLink to={"/mardin/" + id2}>
              <g
                id="753"
                transform="translate(440.000000, 0.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="dargecit"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 59 37 23 84 0 96 31 87 66 33 85"
                ></polygon>
              </g>
            </NavLink>
            <NavLink to={"/mardin/" + id2}>
              <g
                id="755"
                transform="translate(79.000000, 139.000000)"
                fill="#f0f0f0"
                opacity="1"
                onMouseEnter={getId}
                onMouseLeave={handleMouseLeave}
                onClick={onClick}
              >
                <polygon
                  id="kiziltepe"
                  stroke="#000000"
                  fill="#f0f0f0"
                  points="0 79 1 118 35 155 89 108 166 74 145 7 117 0 81 11 67 54"
                ></polygon>
              </g>
            </NavLink>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default MardinMap;
