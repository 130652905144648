import React from 'react'
import { Routes,Route } from 'react-router-dom'
import HomePage from '../pages/HomePage'
import ProvinceDetailPage from '../pages/ProvinceDetailPage'
import DistrictDetailPage from '../pages/DistrictDetailPage'
import BlogPage from '../pages/BlogPage'
import BlogDetailPage from '../pages/BlogDetailPage'
import CerezPolitikasi from '../pages/CerezPolitikasi'
import KullanimKosullari from '../pages/KullanimKosullari'
import BizKimiz from '../pages/BizKimiz'
import Iletisim from '../pages/Iletisim'
import GizlilikSozlesmesi from '../pages/GizlilikSozlesmesi'
import YayinIlkeleri from '../pages/YayinIlkeleri'
import Metadoloji from '../pages/Metadoloji'
import Degerlerimiz from '../pages/Degerlerimiz'

function HomeLayout() {
  return (
    <>
        <Routes>
            <Route index={true} element={<HomePage/>}/>
            <Route path=":id" element={<ProvinceDetailPage/>}/>
            <Route path="/:id/:id2" element={<DistrictDetailPage/>}/>
            <Route path="/blog" element={<BlogPage/>}/>
            <Route path="/blog/:articleTitle" element={<BlogDetailPage/>}/>
            <Route path="/cerez-politasi" element={<CerezPolitikasi/>}/>
            <Route path="/kullanim-kosullari" element={<KullanimKosullari/>}/>
            <Route path="/biz-kimiz" element={<BizKimiz/>}/>
            <Route path="/iletisim" element={<Iletisim/>}/>
            <Route path="/gizlilik-sozlesmesi" element={<GizlilikSozlesmesi/>}/>
            <Route path="/yayin-ilkeleri" element={<YayinIlkeleri/>}/>
            <Route path="/metodoloji" element={<Metadoloji/>}/>
            <Route path="/degerlerimiz" element={<Degerlerimiz/>}/>
        </Routes>
    </>
  )
}

export default HomeLayout